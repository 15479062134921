import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class NotificationBean extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    flag: PropTypes.bool,
    typeFail: PropTypes.string,
    messageFail: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string,
  }

  static defaultProps = {
    type: 'info',
    position: 'left',
    flag: true,
    className: '',
  }

  render() {
    const { type, message, position, flag, typeFail, messageFail, className, icon } = this.props
    const result_type = flag ? type : typeFail

    if (result_type === 'hidden') {
      return null
    }

    const result_message = flag ? message : messageFail

    const bean_root_classes = classNames({
      'label': true,
      'label-default': result_type === 'default',
      'label-info': result_type === 'info',
      'label-success': result_type === 'success',
      'label-warning': result_type === 'warning',
      'label-danger': result_type === 'danger',
      'pull-left margin-right-5': position === 'left',
      'pull-right margin-left-5': position === 'right',
    }, className);

    let result_icon = icon
    let iconClass = ''

    if (!result_icon) {
      switch (result_type) {
        case 'success':
          result_icon = 'fa-check'
          break;
        case 'warning':
          result_icon = 'fa-exclamation-triangle'
          break;
        case 'error':
          result_icon = 'fa-times'
          break;
        case 'danger':
          result_icon = 'fa-times'
          break;
        default:
          result_icon = 'fa-info-circle'
      }
    }
    iconClass = classNames('fa', 'fa-fw', result_icon)

    return (
      <span className={bean_root_classes}><span className={iconClass} /> {result_message}</span>
    )
  }
}

export default NotificationBean