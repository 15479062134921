import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { Nav, NavItem } from 'react-bootstrap'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getLotBids, removeUserBid, bidRegister, bidConfirm, advancedAdjustment, inputChange, statusReset, fullReset } from 'rdx/modules/admin/lot_actions'

import FieldSmartSelect from '../../shared/FieldSmartSelect'
import DataList from '../../shared/DataList'
import DataListRow from '../../shared/DataListRow'
import DataListContainer from '../../shared/DataListContainer'
import DataListBody from '../../shared/DataListBody'
import SimpleContainer from '../../shared/SimpleContainer'
import FormContainer from '../../shared/FormContainer'
import FormRow from '../../shared/FormRow'
import Tab from '../../shared/tab'
import FusedFields from '../../shared/fused-fields'
import NotificationBox from '../../shared/NotificationBox'
import StatusBox from '../../shared/StatusBox'
import BidResult from '../../shared/BidResult'

import { admin_lotactions_lotinfo_options, admin_lotactions_lotbids_options, modalStyles } from 'lib/constants'

LotActionsAdmin.propTypes = {
  lot: PropTypes.object.isRequired,
  lotCard: PropTypes.element.isRequired,
  view: PropTypes.string,
  auction_mode: PropTypes.number.isRequired,
  lot_actions: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  getLotBids: PropTypes.func.isRequired,
  removeUserBid: PropTypes.func.isRequired,
  bidRegister: PropTypes.func.isRequired,
  bidConfirm: PropTypes.func.isRequired,
  advancedAdjustment: PropTypes.func.isRequired,
  inputChange: PropTypes.func.isRequired,
  statusReset: PropTypes.func.isRequired,
  fullReset: PropTypes.func.isRequired,
}

LotActionsAdmin.defaultProps = {
  view: 'standard',
}

function LotActionsAdmin({ lot, lotCard, view, auction_mode, lot_actions, users, getLotBids, removeUserBid, bidRegister, bidConfirm, advancedAdjustment, inputChange, statusReset, fullReset }) {
  const [showPopup, setShowPopup] = useState(false)
  const [selectedTab, setSelectedTab] = useState(1)

  useEffect(() => {
    getLotBids(lot.id)
    return fullReset
  }, [lot.id, getLotBids, fullReset])

  const closeBidPopup = () => {
    setShowPopup(false)
  }

  const _handleSelect = (key) => {
    if (selectedTab !== key) {
      statusReset()
      setSelectedTab(key)
    }
  }

  const _placeAdminBid = () => {
    if (lot_actions.fields.admin_bid_value > 0) {
      bidRegister(lot)
      setShowPopup(true)
    }
  }

  const _setReservePrice = () => {
    advancedAdjustment(2, lot)
  }

  const _removeUserBid = (bid_id) => {
    removeUserBid(bid_id, lot.id)
  }

  const selected_user_id = lot_actions.fields.admin_bid_user_id
  const selected_user_name = lot_actions.userLookup.data.user_fullname
  const userSelected = Number.isInteger(selected_user_id)

  const userlookup_component = <FieldSmartSelect
    name='admin_bid_user_id'
    addonBefore='User'
    groupClassName='col-lg-8'
    options={users}
    valueField='id'
    displayField='user_fullname_with_id'
  />
  const button_bidnow = <button className="btn btn-u rounded-right btn-u-blue" disabled={!userSelected} onClick={_placeAdminBid}><span style={{fontWeight: 'bold'}}>Bid <i className="fa fa-arrow-right"></i></span></button>
  const bid_component =
    <div className="input-group col-lg-4">
      <input name="admin_bid_value" className="form-control" placeholder="Enter Bid..." data-type="unsigned-int" />
      <div className="input-group-btn">
        {button_bidnow}
      </div>
    </div>
  const button_setreserve = <button className="btn btn-u rounded-right btn-u-blue" onClick={_setReservePrice}><span style={{fontWeight: 'bold'}}>Set Reserve Price <i className="fa fa-arrow-right"></i></span></button>
  const setreserve_component =
    <div className="input-group col-lg-6">
      <input name="new_price_reserve" className="form-control" placeholder="Enter New Reserve Price..." data-type="unsigned-int" />
      <div className="input-group-btn">
        {button_setreserve}
      </div>
    </div>

  let actionBox_content = <DataListContainer
    useTable={view === 'standard'}
    tableClass='table table-striped datalist-table-status table-text-vertical-center'
    showHeader={true}
    headerColumnVisibleKey={auction_mode}
    showTopPagingControl={false}
    showBottomPagingControl={false}
    metadata={admin_lotactions_lotinfo_options}
    totalItemCount={1}
  >
    <DataListBody
      data={[lot]}
      datakey='lot_number'
      useTable={view === 'standard'}
    >
      {lotCard}
    </DataListBody>
  </DataListContainer>

  const nav = <Nav bsStyle='tabs' activeKey={selectedTab} onSelect={_handleSelect}>
    <NavItem eventKey={1} key={1}><span className={'fa fa-lightbulb-o'} />{' View Bids'}</NavItem>
    <NavItem eventKey={2} key={2}><span className={'fa fa-toggle-down'} />{ ' Set Reserve Price' }</NavItem>
    <NavItem eventKey={3} key={3}><span className={'fa fa-pencil'} />{' Bid on behalf of user'}</NavItem>
  </Nav>

  const lotBids_data = <SimpleContainer className='margin-bottom-5' style={{ borderBottom: '1px solid #AAA', maxHeight: '500px', overflowY: 'auto' }}>
    <DataList
      useTable={true}
      tableStyle={{ fontSize: '13px' }}
      showHeader={true}
      errorCondition={lot_actions.lotBids.meta.status > 1}
      errorMessage={lot_actions.lotBids.meta.statusText}
      isLoading={lot_actions.lotBids.processing}
      showTopPagingControl={false}
      showBottomPagingControl={false}
      dataIndex={lot_actions.lotBids.order}
      data={lot_actions.lotBids.data}
      totalItemCount={lot_actions.lotBids.info.count}
      metadata={admin_lotactions_lotbids_options}
      datakey='id'
      removeFunction={_removeUserBid}
      allowUpdateFunction={admin_lotactions_lotbids_options.helpers.allowUpdateFunction}
      pageSize={400}
    >
      <DataListRow />
    </DataList>
  </SimpleContainer>

  return <div>
    {actionBox_content}
    <div className='tab-v1 tab-v1-darkgrey'>
      {nav}
      <Tab isActive={selectedTab === 1}>
        <div style={{ paddingTop: '5px', minHeight: '250px' }}>
          {lotBids_data}
        </div>
      </Tab>
      <Tab isActive={selectedTab === 2}>
        <div style={{ paddingTop: '5px', minHeight: '240px' }}>
          <FusedFields data={lot_actions.fields} onUpdate={inputChange}>
            <FormContainer rowClassName="fixed-gutter-5 margin-bottom-5" noMargins={true} addonBeforeStyle={{ textAlign: 'left', flexShrink: '0' }}>
              <FormRow>
                <div className="col-lg-6"></div>
                {setreserve_component}
              </FormRow>
            </FormContainer>
          </FusedFields>
          <StatusBox size="small" data={lot_actions.advancedAdjustment} />
        </div>
      </Tab>
      <Tab isActive={selectedTab === 3}>
        <div style={{ paddingTop: '5px', minHeight: '250px' }}>
          <FusedFields data={lot_actions.fields} onUpdate={inputChange}>
            <FormContainer rowClassName="fixed-gutter-5 margin-bottom-5" noMargins={true} addonBeforeStyle={{ textAlign: 'left', flexShrink: '0' }}>
              <FormRow>
                {userlookup_component}
                {bid_component}
              </FormRow>
              <FormRow>
                <NotificationBox size="small" mode={userSelected ? "info" : "warning"}>
                  <div>{userSelected ? "Selected User: " + selected_user_name + " (" + selected_user_id + ")" : "No User Selected"}</div>
                </NotificationBox>
              </FormRow>
            </FormContainer>
          </FusedFields>
        </div>
      </Tab>
    </div>
    <Modal
      closeTimeoutMS={150}
      isOpen={showPopup}
      onRequestClose={() => {}}
      style={modalStyles}
      contentLabel="Test" >
      <BidResult
        potentialBid={lot_actions.potentialBid}
        confirmAction={bidConfirm}
        closeAction={closeBidPopup}
        allowAdminOverrides={auction_mode > 2}
      />
    </Modal>
  </div>
}

function mapStateToProps(state) {
  return {
    lot_actions: state.admin.lot_actions,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ bidRegister, bidConfirm, getLotBids, removeUserBid, advancedAdjustment, inputChange, statusReset, fullReset }, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LotActionsAdmin)
