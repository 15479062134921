import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
// import type { BodyScrollOptions } from 'body-scroll-lock'

import baseStyles from './baseStyles'
import BurgerIcon from './BurgerIcon'

const styles = {}

// const bsloptions: BodyScrollOptions = {
//   reserveScrollBarGap: true,
// }

const bsloptions = {
  reserveScrollBarGap: true,
}

export default class MenuShell extends Component {
  constructor(props) {
    super(props)
    this.styles = { ...baseStyles, ...props.styles }
  }

  scrollableElement = null

  closeMenu = () => {
    this.props.toggleMenu(this.props.id, { isOpen: false })
  }

  // Builds styles incrementally for a given element
  getStyles(el) {
    // Set base styles
    let output = baseStyles[el] ? this.getStyle(baseStyles[el]) : {}

    // Add custom styles
    if (this.props.styles[el]) {
      output = {
        ...output,
        ...this.props.styles[el],
      }
    }

    return output
  }

  getStyle(style) {
    const { width } = this.props
    const formattedWidth = typeof width !== 'string' ? `${width}px` : width
    return style(this.props.isOpen, formattedWidth, this.props.right)
  }

  listenForClose(e) {
    e = e || window.event

    if (this.props.isOpen && (e.key === 'Escape' || e.keyCode === 27)) {
      this.props.toggleMenu(this.props.id, { isOpen: false })
    }
  }

  shouldDisableOverlayClick() {
    if (typeof this.props.disableOverlayClick === 'function') {
      return this.props.disableOverlayClick()
    } else {
      return this.props.disableOverlayClick
    }
  }

  componentDidMount() {
    this.scrollableElement = document.querySelector('#' + this.props.id + '-container .bm-menu .menu-content')
    // Bind ESC key handler (unless disabled or custom function supplied).
    if (this.props.customOnKeyDown) {
      window.onkeydown = this.props.customOnKeyDown
    } else if (!this.props.disableCloseOnEsc) {
      window.onkeydown = this.listenForClose.bind(this)
    }
  }

  componentWillUnmount() {
    window.onkeydown = null
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        disableBodyScroll(this.scrollableElement, bsloptions)
      } else {
        enableBodyScroll(this.scrollableElement)
      }
    }
  }

  render() {
    return (
      <div>
        {!this.props.noOverlay && (
          <div
            className={`bm-overlay ${this.props.overlayClassName}`.trim()}
            onClick={() =>
              !this.shouldDisableOverlayClick() && this.props.toggleMenu(this.props.id, { isOpen: false })
            }
            style={this.getStyles('overlay')}
          />
        )}
        <div
          id={`${this.props.id}-container`}
          className={`bm-menu-wrap ${this.props.className}`.trim()}
          style={this.getStyles('menuWrap')}
        >
          <div
            className={`bm-menu ${this.props.menuClassName}`.trim()}
            style={this.getStyles('menu')}
          >
            <div className={`bm-item-list ${
                this.props.itemListClassName
              }`.trim()}
              style={this.getStyles('itemList')}
            >
              {/* {cloneChildrenRecursive(this.props.children, (child, data) => {
                if (child) {
                  if (child.type === MenuLink) {
                    return React.cloneElement(child, data)
                  } else {
                    return child
                  }
                }
              }, { closeMenu: this.closeMenu })} */}
              {this.props.children}
            </div>
          </div>
        </div>
        {this.props.customBurgerIcon !== false && (
          <div style={this.getStyles('burgerIcon')}>
            <BurgerIcon
              onClick={() => this.props.toggleMenu(this.props.id)}
              styles={this.props.styles}
              customIcon={this.props.customBurgerIcon}
              className={this.props.burgerButtonClassName}
              barClassName={this.props.burgerBarClassName}
            />
          </div>
        )}
      </div>
    )
  }
}

MenuShell.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  bodyClassName: PropTypes.string,
  burgerBarClassName: PropTypes.string,
  burgerButtonClassName: PropTypes.string,
  customBurgerIcon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.oneOf([false])
  ]),
  customOnKeyDown: PropTypes.func,
  disableCloseOnEsc: PropTypes.bool,
  disableOverlayClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  itemClassName: PropTypes.string,
  itemListClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  noOverlay: PropTypes.bool,
  outerContainerId:
    styles && styles.outerContainer
      ? PropTypes.string.isRequired
      : PropTypes.string,
  overlayClassName: PropTypes.string,
  pageWrapId:
    styles && styles.pageWrap
      ? PropTypes.string.isRequired
      : PropTypes.string,
  right: PropTypes.bool,
  styles: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

MenuShell.defaultProps = {
  bodyClassName: '',
  burgerBarClassName: '',
  burgerButtonClassName: '',
  className: '',
  disableCloseOnEsc: false,
  id: '',
  itemClassName: '',
  itemListClassName: '',
  menuClassName: '',
  noOverlay: false,
  outerContainerId: '',
  overlayClassName: '',
  pageWrapId: '',
  styles: {},
  width: 300,
}

