import React, { Component } from 'react'
import PropTypes from 'prop-types'

class PageTitle extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  }

  render() {
    return (
      <div className="breadcrumbs">
        <div className="container">
          <h1 className="pull-left">{this.props.title}</h1>
          <ul className="pull-right breadcrumb"></ul>
        </div>
      </div>
    )
  }
}

export default PageTitle