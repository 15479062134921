import React from 'react'
import PropTypes from 'prop-types'

import InvoiceLots from './InvoiceLots'

import FieldText from '../shared/FieldText'
import FormContainer from '../shared/FormContainer'
import FormRow from '../shared/FormRow'
import FieldBase from '../shared/FieldBase'
import FieldCheckbox from '../shared/FieldCheckbox'
import InputGroupMultiple from '../shared/InputGroupMultiple'
import SimpleContainer from '../shared/SimpleContainer'
import FusedFields from '../shared/fused-fields'
import DataList from '../shared/DataList'
import DataListRow from '../shared/DataListRow'

import NotificationBean from '../shared/NotificationBean'
import StatusBox from '../shared/StatusBox'

import { makeOptions, makeOptionsBoolean, getKeyBySubValue } from 'lib/utility'
import { invoice_optionsNEW as invoice_options } from 'lib/constants'
import { calcInvoiceCosts } from 'lib/utility_invoice'
import { invoicePackages_specs } from 'lib/list_specifications'

InvoiceContentAdmin.propTypes = {
  invoice: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  packageAdd: PropTypes.func.isRequired,
  packageRemove: PropTypes.func.isRequired,
  packagesEmail: PropTypes.func.isRequired,
  adminUpdateInvoice: PropTypes.func.isRequired,
}

function InvoiceContentAdmin({ invoice, setFieldValue, packageAdd, packageRemove, packagesEmail, adminUpdateInvoice }) {
  const address = invoice.content.address
  const shipping_options = invoice.content.shipping_options
  const admin_info = invoice.content.admin_info
  const packages = invoice.content.packages
  const calculatedCosts = calcInvoiceCosts({ primaryData: invoice.content.main, auxData: admin_info.data, options: admin_info.data, carrier: invoice.content.shipping_options.data.ship_carrier_preference, foreign: address.shipping.data.country !== 'US', state: address.shipping.data.state, mode: 'admin' })

  const _packageAdd = () => {
    const data = invoice.content.packagesFields.data
    // ZZZ - do we need validation?
    packageAdd(data, invoice.content.invoice_id)
  }

  const packagesData = <DataList
    useTable={true}
    tableStyle={{ fontSize: '13px' }}
    showHeader={true}
    headerClass='header-orange'
    // errorCondition={packages.conditionFail}
    // errorMessage={packages.errorMessage}
    // isLoading={packages.isFetching}
    showTopPagingControl={false}
    showBottomPagingControl={false}
    data={packages}
    totalItemCount={invoice.content.package_count}
    metadata={invoicePackages_specs.admin}
    datakey='id'
    headerColumnVisibleKey={address.shipping.data.country}
    removeFunction={packageRemove}
    pageSize={400}
  >
    <DataListRow />
  </DataList>

  const admin_section = <FormRow style={{ marginBottom: '5px' }}>
    <div className='col-lg-12 margin-bottom-5'>
      <div className="clearfix">
        <NotificationBean flag={invoice.content.ready} message={'Ready - ' + invoice.content.ready_date} messageFail="Not Ready" type="success" typeFail="warning" position="left" />
        <NotificationBean flag={invoice.content.approved} message={'Approved - ' + invoice.content.approved_date} messageFail="Not Approved" type="success" typeFail="warning" position="left" />
        <NotificationBean flag={invoice.content.paid} message={'Paid - ' + invoice.content.paid_date} messageFail="Unpaid" type="success" typeFail="warning" position="left" />
        <NotificationBean flag={invoice.content.changed} message={'User Changed - ' + invoice.content.changed_date} messageFail="Unchanged" type="info" typeFail="hidden" position="left" />
        <NotificationBean flag={invoice.content.init} message={'Initial Save - ' + invoice.content.init_date } messageFail="-" type="info" typeFail="hidden" position="right" />
      </div>
    </div>
    <div className='col-lg-12 margin-bottom-10'>
      <div className="clearfix" style={{ padding: '5px', border: '1px solid #ddd' }}>
        <FusedFields data={address.shipping.data} onUpdate={setFieldValue} options={{ group: 'address', subgroup: 'shipping' }}>
          <SimpleContainer className="margin-bottom-5" title='Shipping Address' titleBackgroundColor='#f6da97' titleIcon='fa fa-fw fa-map-marker'>
            <FormContainer rowClassName="fixed-gutter-5" size='small' noMargins={true} addonBeforeStyle={{ width: '100px', textAlign: 'left', flexShrink: '0' }}>
              <FormRow>
                <InputGroupMultiple className="col-lg-6" addonBefore="Name">
                  <FieldBase type="rawinput" className="form-control" name="firstname" placeholder="First" maxLength={ 50 } style={{ width: '50%' }} />
                  <FieldBase type="rawinput" className="form-control" name="lastname" placeholder="Last" maxLength={ 50 } style={{ width: '50%' }} />
                </InputGroupMultiple>
                <FieldText name='address_line1' addonBefore='Address1' groupClassName='col-lg-6' />
              </FormRow>
              <FormRow>
                <FieldText name='company' addonBefore='Company' groupClassName='col-lg-6' />
                <FieldText name='address_line2' addonBefore='Address2' groupClassName='col-lg-6' />
              </FormRow>
              <FormRow>
                <FieldText name='phone' addonBefore='Phone' groupClassName='col-lg-6' />
                <FieldText name='city' addonBefore='City' groupClassName='col-lg-6' />
              </FormRow>
              <FormRow>
                <FieldBase type='countryDropdown' name='country' addonBefore='Country' groupClassName='col-lg-6' />
                <FieldText name='state' addonBefore='State' groupClassName='col-lg-3' />
                <FieldText name='postalcode' addonBefore='Zip' groupClassName='col-lg-3' />
              </FormRow>
            </FormContainer>
          </SimpleContainer>
        </FusedFields>
        <FusedFields data={admin_info.data} onUpdate={setFieldValue} options={{ group: 'admin_info' }}>
          <SimpleContainer className="margin-bottom-5" title='Admin Data' titleBackgroundColor='#f6da97' titleIcon='fa fa-fw fa-asterisk'>
            <FormContainer rowClassName="fixed-gutter-5" size='small' noMargins={true} addonBeforeStyle={{ width: '100px', textAlign: 'left', flexShrink: '0' }}>
              <FormRow>
                <FieldText name='amount_shipping' addonBefore='Shipping' groupClassName='col-lg-4' data-type="float" />
                <FieldBase type="text" name='amount_tax' groupClassName='col-lg-4' disabled={admin_info.data.amount_tax_override === 0} forceValue={true} forcedValue={calculatedCosts.tax} addonBefore={<span><input type="checkbox" name="amount_tax_override" checked={ admin_info.data && admin_info.data.amount_tax_override === 1 } /><span className="margin-left-5" style={{ verticalAlign: 'top' }}>Tax</span></span>} data-type="float" />
                {/* <FieldText name='inv_ship_date' addonBefore='Ship Date' groupClassName='col-lg-4' /> */}
                <FieldBase type="select" name='inv_approved' data-type='int' addonBefore='Approved' groupClassName='col-lg-4'>
                  {makeOptionsBoolean()}
                </FieldBase>
              </FormRow>
              <FormRow>
                <FieldText name='amount_deposit' addonBefore='Deposit' groupClassName='col-lg-4' data-type="float" />
                <FieldBase type="select" name='inv_options_digital_images' data-type='int' addonBefore='Images' groupClassName='col-lg-4'>
                  {makeOptionsBoolean()}
                </FieldBase>
                {/* <FieldText name='inv_ship_tracking' addonBefore='Tracking #' groupClassName='col-lg-4' /> */}
                <FieldBase type="select" name='inv_paid' data-type='int' addonBefore='Paid' groupClassName='col-lg-4'>
                  {makeOptionsBoolean()}
                </FieldBase>
              </FormRow>
              <FormRow>
                <InputGroupMultiple className="col-lg-4">
                  <FieldBase type="rawinput" className="form-control" name="amount_misc_desc" placeholder="Misc Label" style={{ width: '100px', flexShrink: '0' }} />
                  <FieldBase type="rawinput" className="form-control" name="amount_misc" placeholder="Misc Amount" data-type="float" />
                </InputGroupMultiple>
                <FieldBase type="select" name='inv_options_insurance' data-type='int' addonBefore='Insurance' groupClassName='col-lg-4'>
                  {makeOptionsBoolean()}
                </FieldBase>
                {/* <FieldText name='inv_options_insurance_custom' addonBefore='Custom Ins' groupClassName='col-lg-4' data-type="int" /> */}
                {address.shipping.data.country === 'US' ? <FieldBase type="select" name='inv_options_delivery_signature' data-type='int' addonBefore='Sig Required' groupClassName='col-lg-4'>
                  {makeOptionsBoolean()}
                </FieldBase> : <div className='col-lg-4' />}
              </FormRow>
            </FormContainer>
          </SimpleContainer>
        </FusedFields>
        <SimpleContainer title='User Shipping Preferences/Notes' titleBackgroundColor='#f6da97' titleIcon='fa fa-fw fa-address-card-o' style={{ height: '100%', display: 'flex', flexDirection: 'column', marginBottom: '5px' }}>
          <FusedFields data={shipping_options.data} onUpdate={setFieldValue} options={{ group: 'shipping_options' }} wrapped={false}>
            <FormContainer rowClassName="fixed-gutter-5" size='small' noMargins={true} style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
              {/* <FormRow>
                <div className="margin-bottom-5 clearfix">
                  <NotificationBean message={shipping_options.data.ship_carrier_preference ? invoice_options.options_carrier_us[getKeyBySubValue(invoice_options.options_carrier_us, shipping_options.data.ship_carrier_preference)].text : 'No Carrier Preference'} type="default" position="left" />
                  <NotificationBean message={shipping_options.data.ship_insure_full ? 'Insure Packages' : 'No Insurance'} type={shipping_options.data.ship_insure_full ? 'success' : 'danger'} position="left" />
                  {address.shipping.data.country === 'US' ? <NotificationBean message={shipping_options.data.ship_signature_required ? 'Signature Required' : 'Signature Not Required'} type={shipping_options.data.ship_signature_required ? 'success' : 'danger'} position="left" />
                    : <NotificationBean message={shipping_options.data.ship_customs_declaration ? invoice_options.options_customs_declaration[getKeyBySubValue(invoice_options.options_customs_declaration, shipping_options.data.ship_customs_declaration)].text : 'No Customs Declaration'} type={shipping_options.data.ship_customs_declaration ? 'success' : 'danger'} position="left" />}
                </div>
              </FormRow> */}
              <FormRow>
                <FieldBase type="select" name='ship_carrier_preference' addonBefore="Carrier" groupClassName='col-lg-6'>
                  {makeOptions(address.shipping.data.country === 'US' ? invoice_options.options_carrier_us : invoice_options.options_carrier_other, { customOption: { text: 'Carrier...', value: 0 } })}
                </FieldBase>
                {/* <FieldBase type="select" name='ship_insure_full' data-type='int' addonBefore='Insurance' groupClassName='col-lg-3'>
                  {makeOptionsBoolean()}
                </FieldBase> */}
                {address.shipping.data.country !== 'US' ?
                  <FieldBase type="select" name='ship_customs_declaration' data-type='int' addonBefore="Customs" groupClassName='col-lg-6'>
                    {makeOptions(invoice_options.options_customs_declaration, { customOption: { text: 'Customs...', value: 0 } })}
                  </FieldBase>
                  : <div className='col-lg-6' />}
                {/* {address.shipping.data.country === 'US' ?
                  <FieldBase type="select" name='ship_signature_required' data-type='int' addonBefore='Sig Required' groupClassName='col-lg-4'>
                    {makeOptionsBoolean()}
                  </FieldBase>
                  : null} */}
              </FormRow>
              <FormRow className="margin-top-5" style={{ flexGrow: '1' }}>
                <FieldBase type="textarea" name='ship_notes_admin' addonBefore='Admin Notes' groupClassName='col-lg-6' groupStyle={{ height: '100%' }} style={{ minHeight: '80px' }} maxLength={700} />
                <FieldBase type="textarea" name='ship_notes_user' addonBefore='User Notes' groupClassName='col-lg-6' groupStyle={{ height: '100%' }} style={{ minHeight: '80px' }} maxLength={700} />
              </FormRow>
            </FormContainer>
          </FusedFields>
        </SimpleContainer>

        <div className="flex-container-base" style={{ flexWrap: 'wrap', justifyContent: 'flex-end', columnGap: '10px' }}>
          <div style={{ flex: '1 0', minWidth: '640px' }}>
            <SimpleContainer style={{ border: '1px solid #aaa', padding: '5px' }}>
              <div style={{ flexGrow: '1', minWidth: 0 }}>
                {packagesData}
              </div>
              <FusedFields data={invoice.content.packagesFields.data} onUpdate={setFieldValue} onLeave={setFieldValue} options={{ group: 'packagesFields' }}>
                <FormContainer rowClassName="fixed-gutter-5" size='small' noMargins={true}>
                  <FormRow>
                    <InputGroupMultiple>
                      <FieldBase type="rawinput" className="form-control" name="ship_date" autoComplete="off" placeholder="Ship Date" style={{ minWidth: '85px', maxWidth: '85px' }} />
                      <FieldBase type="rawselect" name='carrier' style={{ minWidth: '200px' }}>
                        {makeOptions(address.shipping.data.country === 'US' ? invoice_options.options_carrier_us : invoice_options.options_carrier_other, { customOption: { text: 'Carrier...', value: 0 } })}
                      </FieldBase>
                      <FieldBase type="rawinput" className="form-control" name="tracking" autoComplete="off" placeholder="Tracking#" style={{ minWidth: '150px' }} />
                      <FieldCheckbox mode="button-group" className="form-control" name="insure_full" label="Insure" style={{ width: '57px' }} />
                      {address.shipping.data.country === 'US' ? <FieldCheckbox mode="button-group" className="form-control" name="signature_required" label="SigReq" style={{ width: '59px' }} />
                        : <FieldBase type="rawselect" name='customs_declaration' data-type='int' style={{ minWidth: '150px' }}>
                          {makeOptions(invoice_options.options_customs_declaration, { customOption: { text: 'Customs...', value: 0 } })}
                        </FieldBase>}
                      <span className="input-group-btn" style={{ width: 'unset' }}>
                        <button className="btn btn-u btn-u-sm btn-u-default" onClick={_packageAdd} style={{ lineHeight: '24px' }}><span style={{ fontWeight: 'bold' }}><span className="fa fa-fw fa-plus" /> Add</span></button>
                      </span>
                    </InputGroupMultiple>
                  </FormRow>
                </FormContainer>
              </FusedFields>
            </SimpleContainer>
          </div>
          <div style={{ flex: '0 0 290px', minWidth: 0 }}>
            <SimpleContainer style={{ height: '100%', display: 'flex', flexDirection: 'column', flexGrow: '1', alignItems: 'end', justifyContent: 'space-between', border: '1px solid #aaa', padding: '5px' }}>
              <div>
                {invoice.content.approved && <button className="btn btn-u btn-u-sm rounded btn-u-orange" onClick={packagesEmail} style={{ lineHeight: '24px', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}><span className="fa fa-fw fa-truck" /> Send Shipment Info</span></button>}
              </div>
              <div>
                {!invoice.content.ready && <button className="btn btn-u btn-u-sm rounded btn-u-success" onClick={() => adminUpdateInvoice(1)} style={{ lineHeight: '24px' }}><span style={{ fontWeight: 'bold' }}><span className="fa fa-fw fa-check-square" /> Mark as Ready</span></button>}
                {invoice.content.ready && <button className="btn btn-u btn-u-sm rounded btn-u-danger" onClick={() => adminUpdateInvoice(2)} style={{ lineHeight: '24px' }}><span style={{ fontWeight: 'bold' }}><span className="fa fa-fw fa-exclamation-circle" /> Mark as Not Ready</span></button>}
                <button className="btn btn-u btn-u-sm rounded btn-u-primary" onClick={() => adminUpdateInvoice(0)} style={{ lineHeight: '24px', marginLeft: '5px' }}><span style={{ fontWeight: 'bold' }}><span className="fa fa-fw fa-check-square" /> Update</span></button>
              </div>
            </SimpleContainer>
          </div>
        </div>

        <StatusBox data={invoice.actions.adminUpdate} className="margin-top-5" />
        <StatusBox data={invoice.actions.packagesEmail} className="margin-top-5" />
      </div>
    </div>
  </FormRow>

  const lot_section = <FormRow style={{ marginBottom: '20px' }}>
    <div className="col-md-12">
      <InvoiceLots mode="admin" invoice={invoice} />
    </div>
  </FormRow>

  return <div className="page-base page-admin">
    {admin_section}
    {lot_section}
  </div>
}

export default InvoiceContentAdmin