import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { browserHistory } from 'react-router'

import FusedFields from '../shared/fused-fields'

const fields_initialstate = {
  goToLot: '',
}

GoToLot.propTypes = {
  current_auction: PropTypes.number.isRequired,
}

function GoToLot({ current_auction }) {
  const [fields, setFields] = useState(fields_initialstate)

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _goToLot = () => {
    if (fields.goToLot && fields.goToLot !== '') {
      browserHistory.push('/catalog/lot/' + current_auction + '/' + fields.goToLot)
      setFields(fields_initialstate)
    }
  }

  const _handle_local_keyDown = (key, keyCode) => {
    if (key === 'Enter' || keyCode === 13) {
      _goToLot()
    }
  }

  const GoToLotButton = <button className='btn btn-u btn-u-darkred btn-u-contrast-border' style={{marginRight: '6px', borderRadius: '4px 0 0 4px', fontWeight: 'bold'}} onClick={_goToLot}>Go To Lot</button>

  const content = (
    <div className="input-group">
      <span className="input-group-btn">
        {GoToLotButton}
      </span>
      <FusedFields data={fields} onUpdate={_handleUpdate} onKeyDown={_handle_local_keyDown}>
        <input type="text" name="goToLot" className="form-control" placeholder="Lot#" style={{ borderRadius: '0 4px 4px 0'}} autoComplete="off" data-type='unsigned-int' />
      </FusedFields>
    </div>
  );

  const container = <div className="margin-bottom-5 dark-grey-background" style={{ borderRadius: '5px', padding: '5px' }}>
    {content}
  </div>

  return container
}

export default GoToLot
