import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'

function content(props) {
  return (
    <div className="general-table-defaults">
      <Helmet>
        <title>References Cited</title>
        <link rel="canonical" href={props.location.pathname} />
      </Helmet>
      <div className="titlebar heading heading-v1 text-center">
        <h2>References Cited</h2>
      </div>

      <div className="quote-block">
        <blockquote>Augustyn & Cohen Manhattan in Maps: 1527-1995 <div>[1997]</div></blockquote>
        <blockquote>Albert &quot;Expedition to the Southwest&quot; <div>[1999]</div></blockquote>
        <blockquote>Alexander &quot;Medieval Illuminators and Their Methods of Work&quot; <div>[1992]</div></blockquote>
        <blockquote>Allen &quot;The Atlas of Atlases&quot; <div>[1992]</div></blockquote>
        <blockquote>American-Scandanavian Foundation &quot;Scandia: Important Early Maps of the Northern Regions & Maps and Charts of Norway&quot; <div>[2002]</div></blockquote>
        <blockquote>Andreae & Koeman &quot;Kaarten en Kaartekenaars&quot; <div>[1972]</div></blockquote>
        <blockquote>Ankary &quot;The Arabian Peninsula in Old European Maps&quot; <div>[2001]</div></blockquote>
        <blockquote>Baltimore Museum of Art &quot;The World Encompassed&quot; <div>[1952]</div></blockquote>
        <blockquote>Barber &quot;The Map Book&quot; <div>[2005]</div></blockquote>
        <blockquote>Barron &quot;Decorative Maps&quot; <div>[1989]</div></blockquote>
        <blockquote>Baughman &quot;Kansas In Maps&quot; <div>[1961]</div></blockquote>
        <blockquote>Baynton-Williams, Ashley &quot;The Curious Map Book&quot; <div>[2015]</div></blockquote>
        <blockquote>Baynton-Williams, Ashley &quot;Map Forum (Issues 1-11)&quot; <div>[2004-05]</div></blockquote>
        <blockquote>Bedini &quot;Thinkers and Tinkers - Early American Men of Science&quot; <div>[1975]</div></blockquote>
        <blockquote>Betz &quot;The Mapping of Africa&quot; <div>[2007]</div></blockquote>
        <blockquote>Blake &quot;The Sea Chart The Illustrated History of Nautical Maps and Navigational Charts&quot; <div>[2004]</div></blockquote>
        <blockquote>Blevins &quot;Mapping Wyoming&quot; <div>[2007]</div></blockquote>
        <blockquote>Boorstin &quot;The Discoverers - A History of man&apos;s Search to Know His World and Himself&quot; <div>[1983]</div></blockquote>
        <blockquote>Bornholt &quot;Four Centuries of Geographic Expressions of the Central American Isthmus 1500 - 1900&quot; <div>[2007]</div></blockquote>
        <blockquote>Bosse &quot;Civil War Newspaper Maps&quot; <div>[1993]</div></blockquote>
        <blockquote>Boston Rare Maps &quot;Catalog Four - Mapping the American Century&quot; <div>[2022]</div></blockquote>
        <blockquote>Branch &quot;An Atlas of Rare City Maps Comparative Urban Design, 1830-1842&quot; <div>[1997]</div></blockquote>
        <blockquote>Brooke-Hitching, Edward &quot;The Phantom Atlas - The Greatest Myths, Lies and Blunders on Maps&quot; <div>[2018]</div></blockquote>
        <blockquote>Brown, Kevin J. &quot;A Journey Back in Time Through Maps&quot; <div>[2017]</div></blockquote>
        <blockquote>Bryars & Harper &quot;A History of the 20th Century in 100 Maps&quot; <div>[2014]</div></blockquote>
        <blockquote>Buisseret &quot;From Sea Charts to Satellite Images Interpreting North American History Through Maps&quot; <div>[1990]</div></blockquote>
        <blockquote>Burden &quot;The Mapping of North America (Volumes I and II)&quot; <div>[1996-2007]</div></blockquote>
        <blockquote>Caboara &quot;Regnum Chinae - The Printed Western Maps of China to 1735&quot; <div>[2022]</div></blockquote>
        <blockquote>Carhart &quot;Frederick de Wit and the First Concise Reference Atlas&quot; <div>[2011]</div></blockquote>
        <blockquote>Churchill, W. A. &quot;Watermarks in paper in Holland, England, France, Etc., in the XVII and XVIII Centuries and their Interconnection&quot; <div>[1965]</div></blockquote>
        <blockquote>Claussen & Friis &quot;Descriptive Catalog of Maps Published by Congress 1817-1843&quot; <div>[1941]</div></blockquote>
        <blockquote>Cobb &quot;New Hampshire Maps to 1900 an Annotated Checklist&quot; <div>[1981]</div></blockquote>
        <blockquote>Cohen &quot;Mapping the West: America&apos;s Westward Movement 1524-1890&quot; <div>[2002]</div></blockquote>
        <blockquote>Cohen & Augustyn &quot;Manhattan in Maps 1527-1995&quot; <div>[1997]</div></blockquote>
        <blockquote>Couto &quot;Historical Atlas of the Persian Gulf&quot; <div>[2006]</div></blockquote>
        <blockquote>Cueto &quot;Cuba in Old Maps&quot; <div>[1999]</div></blockquote>
        <blockquote>Cumming &quot;The Southeast in Early Maps with an annotated checklist of printed and manuscript regional and local maps of southeastern North America during the colonial period&quot; <div>[1958]</div></blockquote>
        <blockquote>Day &quot;Maps of Texas 1527-1900&quot; <div>[1964]</div></blockquote>
        <blockquote>Curtis, Philip & Jakob Sondergard Pedersen &quot;War Map: Pictorial Conflic Maps 1900-1950&quot; <div>[2016]</div></blockquote>
        <blockquote>de Hamel &quot;A History of Illuminated Manuscripts&quot; <div>[1986]</div></blockquote>
        <blockquote>Deak &quot;Picturing America 1497-1899: Volumes I and II&quot; <div>[1988]</div></blockquote>
        <blockquote>Delaney &quot;From Circle to Sphere: Historic Maps Since Columbus&quot; <div>[1992]</div></blockquote>
        <blockquote>Delaney, John &quot;Nova Caesarea: A Cartographic Record of the Garden State 1666-1888&quot; <div>[2014]</div></blockquote>
        <blockquote>Dotson, Eliane & Kenneth Baker &quot;Carto-Bibliography of Engraved MapsDepicting the State of Franklin&quot; <div>[2019]</div></blockquote>
        <blockquote>Dym, Jordana & Karl Offen &quot;Mapping Latin America A Cartographic Reader&quot; <div>[2011]</div></blockquote>
        <blockquote>Edwin Mellen Press &quot;The Cartographer and the Literati-Herman Moll and His Intellecual circle&quot; <div>[1997]</div></blockquote>
        <blockquote>Ehrenberg & Viola &quot;Mapping the West with Lewis & Clark&quot; <div>[2015]</div></blockquote>
        <blockquote>Ehrenberg & Zhang &quot;Reading Imperial Cartography - Ming-Qing Historical Maps in the Library of Congress&quot; <div>[2013]</div></blockquote>
        <blockquote>Ettlinger &quot;AniMap Plus&quot; <div>[2004]</div></blockquote>
        <blockquote>Evans, Jonathan Land &quot;A Carto-Bibliography for Bermuda, 1511-1948&quot; <div>[2016]</div></blockquote>
        <blockquote>Falk &quot;Alaskan Maps - A cartobibliography of Alaska to 1900&quot; <div>[1983]</div></blockquote>
        <blockquote>Faupel &quot;A Brief and True Report of the New Found Land of Virginia - A Study of the de Bry Engravings&quot; <div>[1989]</div></blockquote>
        <blockquote>Fell &quot;Early maps of South-East Asia&quot; <div>[1988]</div></blockquote>
        <blockquote>Fite & Freeman &quot;A Book of Old Maps&quot; <div>[1969]</div></blockquote>
        <blockquote>Fitzpatrick &quot;The Early Mapping of Hawai&apos;I&quot; <div>[1987]</div></blockquote>
        <blockquote>Ganado & Schiro &quot;German Malta Maps&quot; <div>[2011]</div></blockquote>
        <blockquote>Ginsberg &quot;Printed Maps of Scandinavia and the Arctic 1482-1601&quot; <div>[2006]</div></blockquote>
        <blockquote>Gohm &quot;Antique Maps&quot; <div>[1972]</div></blockquote>
        <blockquote>Gole, &quot;India Within the Ganges&quot; <div>[1983]</div></blockquote>
        <blockquote>Goss &quot;Blaeu&apos;s The Grand Atlas of the 17th Century World (facsimile atlas Atlas Major)&quot; <div>[1991]</div></blockquote>
        <blockquote>Goss &quot;The City Maps of Europe 16th Century Town Plans from Braun & Hogenberg&quot; <div>[1991]</div></blockquote>
        <blockquote>Goss &quot;The Mapmakers Art&quot; <div>[1993]</div></blockquote>
        <blockquote>Goss &quot;The Mapping of North America Three Centuries of Map-making 1500-1860&quot; <div>[1990]</div></blockquote>
        <blockquote>Greaves & Overton &quot;Atlas of Colorado Counties 1861-2001; Rocky Mountain Map Society Occasional Paper Series, No. 1&quot; <div>[2007]</div></blockquote>
        <blockquote>Guthorn &quot;United States Coastal Charts 1783-1861&quot; <div>[1984]</div></blockquote>
        <blockquote>Hayes &quot;Historical Atlas of the Pacific Northwest&quot; <div>[1999]</div></blockquote>
        <blockquote>Heckrotte &quot;California 49&quot; <div>[1999]</div></blockquote>
        <blockquote>Hill, Gillian &quot;Cartographical Curiosities&quot; <div>[1978]</div></blockquote>
        <blockquote>Hind &quot;Giovanni Battista Piranesi A Critical Study With a List of his Published Works and Detailed Catalogues of the Prisons and the Views of Rome&quot; <div>[1967]</div></blockquote>
        <blockquote>Hoppen &quot;Cartographica Curiosa 2&quot; <div>[1982]</div></blockquote>
        <blockquote>Hornsby, Stephen J. &quot;Picturing America: The Golden Age of Pictorial Maps&quot; <div>[2017]</div></blockquote>
        <blockquote>Hough &quot;Captain James Cook A Biography&quot; <div>[1997]</div></blockquote>
        <blockquote>Howes &quot;U. S. Iana (1650-1950)&quot; <div>[1962]</div></blockquote>
        <blockquote>Howgego &quot;Printed Maps of London&quot; <div>[1964]</div></blockquote>
        <blockquote>Howse & Sanderson &quot;The Sea Chart&quot; <div>[1973]</div></blockquote>
        <blockquote>Hubbard, Jason C. &quot;Japoniae Insulae The Mapping of Japan&quot; <div>[2012]</div></blockquote>
        <blockquote>IMCOS &quot;IMCOS Journal (various issues)&quot; <div>[1986-2012]</div></blockquote>
        <blockquote>IMOCS &quot;Exploring Old Landscapes - Parts of the Puzzle&quot; <div>[2000]</div></blockquote>
        <blockquote>Jolly &quot;Maps in British Periodicals Volumes I and II&quot; <div>[1990-91]</div></blockquote>
        <blockquote>Kanas &quot;Star Maps History, Artistry and Cartography&quot; <div>[2007]</div></blockquote>
        <blockquote>Kapp &quot;The Early Maps of Colombia up to 1825&quot; <div>[1971]</div></blockquote>
        <blockquote>Karpinski &quot;Bibliography of the Printed Maps of Michigan, 1804-1880&quot; <div>[2002]</div></blockquote>
        <blockquote>Katcher &quot;The Civil War Source Book&quot; <div>[1992]</div></blockquote>
        <blockquote>Kazumasa &quot;Japanese Maps of the Edo Period&quot; <div>[1998]</div></blockquote>
        <blockquote>Kershaw &quot;Early Printed Maps of Canada: Volumes I-IV&quot; <div>[1993]</div></blockquote>
        <blockquote>King &quot;Minature Antique Maps&quot; <div>[1996]</div></blockquote>
        <blockquote>King &quot;Miniature Antique Maps Second Edition&quot; <div>[1996]</div></blockquote>
        <blockquote>Koeman &quot;The History of Lucas Janszoon Waghenaer and His Spieghel der Zeevaerdt&quot; <div>[1964]</div></blockquote>
        <blockquote>Koeman &quot;The Sea on Paper&quot; <div>[1972]</div></blockquote>
        <blockquote>Koepp &quot;Exploration and Mapping of the American West&quot; <div>[1986]</div></blockquote>
        <blockquote>Kovarsky &quot;The True Geography of Our Country - Jefferson&apos;s Cartographic Vision&quot; <div>[2014]</div></blockquote>
        <blockquote>Lambourne &quot;John Gould&apos;s Birds&quot; <div>[1981]</div></blockquote>
        <blockquote>Laor &quot;Maps of the Holy Land Cartobibliography of Printed Maps, 1475-1900&quot; <div>[1986]</div></blockquote>
        <blockquote>Laurentius & Laurentius &quot;Watermarks 1450 - 1850 - A Concise History of Paper in Western Europe&quot; <div>[2023]</div></blockquote>
        <blockquote>Law &quot;The Printed Maps of Ireland to 1612&quot; <div>[1983]</div></blockquote>
        <blockquote>Lazare &quot;The Thomas Winthrop Streeter Collection of Americana Volumes One to Seven and Index&quot; <div>[1966]</div></blockquote>
        <blockquote>LeGear &quot;Geographical Atlases in the Library of Congress, volumes V, VI, VII, VIII, and IX&quot; <div>[1958]</div></blockquote>
        <blockquote>Lemmon, McGill & Wiese &quot;Charting Louisiana&quot; <div>[2003]</div></blockquote>
        <blockquote>Lister &quot;Old Maps and Globes&quot; <div>[1979]</div></blockquote>
        <blockquote>Low &quot;A Guide to Audubon&apos;s Birds of America&quot; <div>[2002]</div></blockquote>
        <blockquote>Lowery &quot;A Descriptive List of Maps of the Spanish Possessions within the present limits of the United States, 1502-1820&quot; <div>[1912]</div></blockquote>
        <blockquote>Luebke, Kaye & Moulton &quot;Mapping of the North American Plains - Essays in the History of Cartography&quot; <div>[1987]</div></blockquote>
        <blockquote>Lynam &quot;The Mapmaker&apos;s Art Essays on the History of Maps&quot; <div>[1953]</div></blockquote>
        <blockquote>Mackenzie &quot;British Prints: Dictionary and Price Guide&quot; <div>[1988]</div></blockquote>
        <blockquote>Manasek, F.J. &quot;Collecting Old Maps&quot; <div>[1998]</div></blockquote>
        <blockquote>Manasek, F.J., Marti Griggs & Curt Griggs &quot;Collecting Old Maps (Second Edition)&quot; <div>[2015]</div></blockquote>
        <blockquote>&quot;Map Collector Magazine (various issues)&quot; <div>[1979-96]</div></blockquote>
        <blockquote>Marcou &quot;Mapoteca Geologica Americana A Catalogue of Geological Maps of America (North and South) 1752-1881 in Geographic and Chronologic Order (Facsimile)&quot; <div>[2000]</div></blockquote>
        <blockquote>Martin & Martin &quot;Maps of Texas and the Southwest 1513-1900&quot; <div>[1984]</div></blockquote>
        <blockquote>Martin, Montgomery &quot;Antique Maps of the 19th Century World&quot; <div>[1989]</div></blockquote>
        <blockquote>McCorkle &quot;A Carto-Bibliography of the Maps in Eighteenth-Century British and American Geography Books&quot; <div>[2009]</div></blockquote>
        <blockquote>McCorkle &quot;New England in Early Printed Maps&quot; <div>[2001]</div></blockquote>
        <blockquote>McDermott, Grim & Mobley &quot;Eye of the Explorer Views of the Northern Pacific Railroad Survey, 1853-54&quot; <div>[2010]</div></blockquote>
        <blockquote>McElfresh &quot;Maps and Mapmakers of the Civil War&quot; <div>[1999]</div></blockquote>
        <blockquote>McElveen, Jr. &quot;‘Westward the Course of Empire&apos; - Exploring and Settling the American West 1803 - 1969&quot; <div>[2018]</div></blockquote>
        <blockquote>McGuirk &quot;The Last Great Cartographic Myth - Mer de l&apos;Ouest&quot; <div>[2011]</div></blockquote>
        <blockquote>McLaughlin &quot;The Mapping of California as an Island&quot; <div>[1995]</div></blockquote>
        <blockquote>&quot;Mercator&apos;s World (Volumes 1-8)&quot; <div>[1996-2003]</div></blockquote>
        <blockquote>Mickwitz & Miekkavaara &quot;The AE Nordenskiold Collection Volumes 1-3&quot; <div>[1984]</div></blockquote>
        <blockquote>Modelski &quot;Railroad Maps of North America; The First Hundred Years&quot; <div>[1984]</div></blockquote>
        <blockquote>Modelski &quot;Railroad Maps of the United States&quot; <div>[1975]</div></blockquote>
        <blockquote>Moffat &quot;Maps of Utah to 1900&quot; <div>[1981]</div></blockquote>
        <blockquote>Moreland & Bannister &quot;Antique Maps&quot; <div>[1986]</div></blockquote>
        <blockquote>Morison &quot;The European Discovery of America - The Northern Voyages&quot; <div>[1971]</div></blockquote>
        <blockquote>Moule & Barron &quot;The County Maps of Old England (facsimile atlas)&quot; <div>[1990]</div></blockquote>
        <blockquote>Muybridge &quot;San Francisco 1878 Portrait of the City&quot; <div>[1979]</div></blockquote>
        <blockquote>Myceum &quot;Changing Perceptions: Charting Alexandria, 1590-1999&quot; <div>[1999]</div></blockquote>
        <blockquote>Nebenzahl &quot;A Bibliography of Printed Battle Plans of the American Revolution 1775-1795&quot; <div>[1975]</div></blockquote>
        <blockquote>Nebenzahl &quot;Atlas of Columbus and the Great Discoveries&quot; <div>[1990]</div></blockquote>
        <blockquote>Nebenzahl &quot;Maps of the Holy Land&quot; <div>[1986]</div></blockquote>
        <blockquote>Nebenzahl & Higginbotham &quot;Atlas of the American Revolution&quot; <div>[1975]</div></blockquote>
        <blockquote>Newberry Library &quot;Mapping Manifest Destiny Chicago and the American West&quot; <div>[2007]</div></blockquote>
        <blockquote>Nikolin &quot;Ukraine in Antique/Old Maps end of 15th to first of 17th Century&quot; <div>[2006]</div></blockquote>
        <blockquote>Nikolin &quot;Ukraine in Antique/Old Maps from the mid-17th to the second half of 18th c.&quot; <div>[2009]</div></blockquote>
        <blockquote>Nordenskiold &quot;Facsimile Atlas: To the Early History of Cartography with Reproductions of the Most Important maps Printed in the XV and XVI Centuries&quot; <div>[1973]</div></blockquote>
        <blockquote>Nordyke &quot;Pacific Images - Views from Captain Cook&apos;s Third Voyage&quot; <div>[1999]</div></blockquote>
        <blockquote>Norwich &quot;Norwich&apos;s Maps of Africa&quot; <div>[1983]</div></blockquote>
        <blockquote>Paluka &quot;The Three Voyages of Captain Cook&quot; <div>[1974]</div></blockquote>
        <blockquote>Parry &quot;The Cartography of the East Indian Islands Insulae Indiae Orientalis&quot; <div>[2005]</div></blockquote>
        <blockquote>Pastoureau &quot;Les Atlas Francais XVIE - XVIIE Siecles&quot; <div>[1984]</div></blockquote>
        <blockquote>Pedley &quot;Bel Et Utile The work of the Robert De Vaugondy Family of Mapmakers&quot; <div>[1992]</div></blockquote>
        <blockquote>Pegg &quot;Cartographic Traditions in East Asian Maps&quot; <div>[2014]</div></blockquote>
        <blockquote>Perry & Prescott &quot;A Guide to Maps of Australia in Books Published 1780-1830&quot; <div>[1996]</div></blockquote>
        <blockquote>Peters &quot;America on Stone&quot; <div>[1976]</div></blockquote>
        <blockquote>Peters &quot;Currier & Ives: Printmakers to the American People&quot; <div>[1942]</div></blockquote>
        <blockquote>Peterson & Kastner &quot;The Bird Illustrated 1550-1900&quot; <div>[1988]</div></blockquote>
        <blockquote>Petto &quot;When France was King of Cartography The Patronage and Production of Maps in Early Mordern France&quot; <div>[1992]</div></blockquote>
        <blockquote>Phillips &quot;A List of Geographical Atlases in the Library of Congress volumes I, II, III, and IV&quot; <div>[1920]</div></blockquote>
        <blockquote>Phillips &quot;A List of Maps of America&quot; <div>[1901]</div></blockquote>
        <blockquote>Phillips &quot;Maps and Views of Washington and District of Columbia&quot; <div>[1996]</div></blockquote>
        <blockquote>Phillips &quot;Phillips&apos; Maps and Atlases of WWI&quot; <div>[1995]</div></blockquote>
        <blockquote>Phillips &quot;Virginia Cartography&quot; <div>[1995]</div></blockquote>
        <blockquote>Polk &quot;The Island of California A History of the Myth&quot; <div>[1991]</div></blockquote>
        <blockquote>Poortman & Augusteijn &quot;Kaarten in Bijbels (16e-18e eeuw)&quot; <div>[1995]</div></blockquote>
        <blockquote>Portinaro & Knirsch &quot;The Cartography of North America 1500-1800&quot; <div>[1987]</div></blockquote>
        <blockquote>Potter &quot;Collecting Antique Maps&quot; <div>[1992]</div></blockquote>
        <blockquote>Pratt &quot;Currier & Ives Chronicles of America&quot; <div>[1968]</div></blockquote>
        <blockquote>Pritchard & Taliaferro &quot;Degrees of Latitude&quot; <div>[2002]</div></blockquote>
        <blockquote>Putnam &quot;Early Sea Charts&quot; <div>[1983]</div></blockquote>
        <blockquote>Ray &quot;The Art of the French Illustrated Book 1700 to 1914&quot; <div>[1986]</div></blockquote>
        <blockquote>Reinhartz, Dennis &quot;The Art of the Map - An Illustrated History of Map Elements and Embellishments&quot; <div>[2012]</div></blockquote>
        <blockquote>Reinhartz, Dennis &quot;The Cartographer and the Literati - Herman Moll and His Intellectual Circle&quot; <div>[1997]</div></blockquote>
        <blockquote>Reinhartz & Colley &quot;The Mapping of the American Southwest&quot; <div>[1987]</div></blockquote>
        <blockquote>Reps &quot;Cities on Stone&quot; <div>[1979]</div></blockquote>
        <blockquote>Reps &quot;Views and Viewmakers of Urban America&quot; <div>[1984]</div></blockquote>
        <blockquote>Riddle &quot;Records and Maps of the Old Santa Fe Trail (with loose maps)&quot; <div>[1963]</div></blockquote>
        <blockquote>Ristow &quot;American Maps and Mapmakers Commercial Cartography in the Nineteenth Century&quot; <div>[1985]</div></blockquote>
        <blockquote>Rix &quot;Art in Nature Classic Botanical Prints From The Eighteenth to the Twentieth Century&quot; <div>[1991]</div></blockquote>
        <blockquote>Rix &quot;The Art of Botanical Illustration&quot; <div>[1981]</div></blockquote>
        <blockquote>Robinson &quot;Early Thematic Mapping in the History of Cartography&quot; <div>[1982]</div></blockquote>
        <blockquote>Robinson &quot;Elements of Cartography - Second Edition&quot; <div>[1960]</div></blockquote>
        <blockquote>Robson &quot;Captain Cook&apos;s World - Maps of the Life and Voyages of James Cook R.N.&quot; <div>[2000]</div></blockquote>
        <blockquote>Royal Scottish Geographical Society &quot;The Early Maps of Scotland Volume 1 & 2&quot; <div>[1973]</div></blockquote>
        <blockquote>Sabin, Joseph &quot;A Dictionary of Books Relating to America, From its Discovery to the Present Time&quot; <div>[1868]</div></blockquote>
        <blockquote>Samourkas Foundation &quot;A Catalogue of Printed Maps of Greece 1477-1800&quot; <div>[1992]</div></blockquote>
        <blockquote>Schilder &quot;Monumenta Cartographica Neerlandica VI&quot; <div>[2000]</div></blockquote>
        <blockquote>Schulten, Susan &quot;A History of America in 100 Maps&quot; <div>[2018]</div></blockquote>
        <blockquote>Schwartz &quot;The French and Indian War 1754-1763&quot; <div>[1992]</div></blockquote>
        <blockquote>Schwartz & Ehrenberg &quot;The Mapping of America&quot; <div>[1980]</div></blockquote>
        <blockquote>Schulten &quot;A History of America in 100 Maps&quot; <div>[2018]</div></blockquote>
        <blockquote>Sellers & Van Ee &quot;Maps and Charts of North America and the West Indies&quot; <div>[1999]</div></blockquote>
        <blockquote>Sheets &quot;American Maps 1795-1895 A guide to values&quot; <div>[1994]</div></blockquote>
        <blockquote>Shirley, Rodney &quot;Courtiers and Cannibals, Angels and Amazons&quot; <div>[2009]</div></blockquote>
        <blockquote>Shirley, Rodney &quot;Early Printed Maps of the British Isles 1477-1650 - Revised Edition&quot; <div>[1980]</div></blockquote>
        <blockquote>Shirley, Rodney &quot;Maps in the Atlases of The British Library - A Descriptive Catalogue AD 850 - 1800 - Vol. 1 & 2&quot; <div>[2004]</div></blockquote>
        <blockquote>Shirley, Rodney &quot;Printed Maps of the British Isles 1650-1750&quot; <div>[1983]</div></blockquote>
        <blockquote>Shirley, Rodney &quot;The Mapping of the World&quot; <div>[1984]</div></blockquote>
        <blockquote>Sinclair &quot;A Guide to Literature about New Jersey Maps and Mapping&quot; <div>[2002]</div></blockquote>
        <blockquote>Sitwell & Ripley &quot;Fine Bird Books 1700-1900&quot; <div>[1953]</div></blockquote>
        <blockquote>Skelton &quot;Explorers&apos; Maps&quot; <div>[1958]</div></blockquote>
        <blockquote>Skelton &quot;Maps - A Historical Survey of Their Study and Collecting&quot; <div>[1975]</div></blockquote>
        <blockquote>Smith &quot;Victorian Maps of the British Isles&quot; <div>[1985]</div></blockquote>
        <blockquote>Smith, Thomas H. &quot;The Mapping of Ohio&quot; <div>[1977]</div></blockquote>
        <blockquote>Ste. Claire &quot;Borders of Paradise: A History of Florida Through New World Maps&quot; <div>[1995]</div></blockquote>
        <blockquote>Stephenson &quot;Civil War Maps An Annotated List&quot; <div>[1961]</div></blockquote>
        <blockquote>Stephenson &quot;Civil War Maps An Annotated List of Maps and Atlases in the Library of Congress&quot; <div>[1989]</div></blockquote>
        <blockquote>Stephenson & McKee &quot;Virginia in Maps Four Centuries of Settlement, Growth and Development&quot; <div>[2000]</div></blockquote>
        <blockquote>Stevens &quot;Ptolemy&apos;s Geography A Brief Account of all the Printed Editions Down to 1730&quot; <div>[1908]</div></blockquote>
        <blockquote>Stevenson &quot;Terrestrial and Celestial Globes&quot; <div>[2004]</div></blockquote>
        <blockquote>Stommel &quot;Lost Islands&quot; <div>[1984]</div></blockquote>
        <blockquote>Streeter &quot;Bibliography of Texas 1795-1845&quot; <div>[1955]</div></blockquote>
        <blockquote>Stylianou &quot;The History of the Cartography of Cyprus&quot; <div>[1980]</div></blockquote>
        <blockquote>Suarez &quot;Early Mapping of Southeast Asia&quot; <div>[1999]</div></blockquote>
        <blockquote>Suarez &quot;Early Mapping of the Pacific&quot; <div>[2004]</div></blockquote>
        <blockquote>Sumira, Sylvia &quot;The Art and History of Globes&quot; <div>[2014]</div></blockquote>
        <blockquote>Taliaferro &quot;Cartographic Sources in the Rosenberg Library&quot; <div>[1988]</div></blockquote>
        <blockquote>Taschen &quot;Cities of the World Complete Edition of the Colour Plates of 1572-1617&quot; <div>[2008]</div></blockquote>
        <blockquote>Taschen &quot;Theodore de Bry America - The Complete Plates 1590 - 1602&quot; <div>[2019]</div></blockquote>
        <blockquote>Taylor, W. Taylor &quot;Texfake - An Account of the Theft and Forgery of Early Texas Printed Documents&quot; <div>[1991]</div></blockquote>
        <blockquote>The Culture House, Reykjavik &quot;Image of Iceland - Milestones in Cartography&quot; <div>[2003]</div></blockquote>
        <blockquote>Thompson &quot;Maps of Connecticut Two Volumes in One&quot; <div>[1995]</div></blockquote>
        <blockquote>Thorndale & Dollarhids &quot;Map Guide to the U.S. Federal Censuses 1790-1920&quot; <div>[1987]</div></blockquote>
        <blockquote>Tibbetts &quot;Arabia in Early Maps&quot; <div>[1978]</div></blockquote>
        <blockquote>Tooley &quot;Map Collector&apos;s circle (No. 1 - No. 110)&quot; <div>[1963-75]</div></blockquote>
        <blockquote>Tooley &quot;Maps and Map-Makers&quot; <div>[1990]</div></blockquote>
        <blockquote>Tooley &quot;The Mapping of America&quot; <div>[1985]</div></blockquote>
        <blockquote>Tooley &quot;The Mapping of Australia and Antarctica Second Revised Edition&quot; <div>[1985]</div></blockquote>
        <blockquote>Tooley &quot;Tooley&apos;s Dictionary of Mapmakers&quot; <div>[1979]</div></blockquote>
        <blockquote>Tooley &quot;Tooley&apos;s Dictionary of Mapmakers Supplement&quot; <div>[1985]</div></blockquote>
        <blockquote>Van den Broecke &quot;Abraham Ortelius 1527 - 1598 - Life Works Sources and Friends&quot; <div>[2015]</div></blockquote>
        <blockquote>Van den Broecke &quot;Ortelius Atlas Maps&quot; <div>[1996]</div></blockquote>
        <blockquote>Van der Heijden, H.A.M. &quot;De Oudste Gedrukte Kaarten van Europa&quot; <div>[1992]</div></blockquote>
        <blockquote>Van der Krogt, Peter &quot;Atlas Maior (facsimile)&quot; <div>[2006]</div></blockquote>
        <blockquote>Van der Krogt, Peter &quot;Koeman&apos;s Atlantes Neerlandici, Volume I-IV&quot; <div>[1997-2010]</div></blockquote>
        <blockquote>Van der Krogt, Peter &quot;Old Globes in the Netherlands&quot; <div>[1984]</div></blockquote>
        <blockquote>Van Ermen &quot;The United States in Old Maps and Prints&quot; <div>[1990]</div></blockquote>
        <blockquote>Vavra &quot;Exploration and Colonization of the New World 1492 - 1619 - An Analysis of the German Editions of Theodore de Bry&apos;s Part I (Virginia) 1590 and Part IV (Caribbean) 1594 of his Grand Voyages&quot; <div>[2023]</div></blockquote>
        <blockquote>Verner & Stuart-Stubbs &quot;The Northpart of America&quot; <div>[1979]</div></blockquote>
        <blockquote>Wagner &quot;Cartography of the Northwest Coast of America to the Year 1800 Volumes I & II&quot; <div>[1965]</div></blockquote>
        <blockquote>Walter &quot;Japan A Cartographic Vision&quot; <div>[1994]</div></blockquote>
        <blockquote>Warner &quot;The Sky Explored&quot; <div>[1979]</div></blockquote>
        <blockquote>Washington Map Society &quot;The Portolan Journal (various issues)&quot; <div>[2000-12]</div></blockquote>
        <blockquote>Wellfleet &quot;John Gould&apos;s Hummingbirds&quot; <div>[1990]</div></blockquote>
        <blockquote>Wheat &quot;Mapping the Trans-Mississippi West 1540-1861 (Volumes 1-5)&quot; <div>[2004]</div></blockquote>
        <blockquote>Wheat &quot;Maps of the California Gold Region 1848-1857&quot; <div>[1942]</div></blockquote>
        <blockquote>Wheat & Brun &quot;Maps and Charts Published in America Before 1800&quot; <div>[1985]</div></blockquote>
        <blockquote>Whitfield &quot;The Charting of the Oceans&quot; <div>[1996]</div></blockquote>
        <blockquote>Whitfield &quot;The Mapping of the Heavens&quot; <div>[1995]</div></blockquote>
        <blockquote>Williams, Anne D. &quot;The Jigsaw Puzzle: Piecing Together a History&quot; <div>[2004]</div></blockquote>
        <blockquote>Williams, Anne D. &quot;Jigsaw Puzzles: An Illustrated History and Price Guide&quot; <div>[1990]</div></blockquote>
        <blockquote>Williams & Johnson &quot;Tar Heel Maps: Colony and State 1590-1995&quot; <div>[1996]</div></blockquote>
        <blockquote>Wilson &quot;The Making of the Nuremberg Chronicle&quot; <div>[1976]</div></blockquote>
        <blockquote>Woodward &quot;Five Centuries of Map Printing&quot; <div>[1975]</div></blockquote>
        <blockquote>Wooldridge, William C. &quot;Mapping Virginia From the Age of Exploration to the Civil War&quot; <div>[2012]</div></blockquote>
        <blockquote>Yeo &quot;Mapping the Continent of Asia&quot; <div>[1994]</div></blockquote>
        <blockquote>Zoegner &quot;Karten in Bibliotheken&quot; <div>[1971]</div></blockquote>
      </div>
    </div>
  )
}

export default content
