import React from 'react'
import PropTypes from 'prop-types'

Resources.propTypes = {
  children: PropTypes.node,
}

function Resources({ children }) {
  return (
    <div className="resource-container shadow-wrapper">
      <div className="tag-box tag-box-v3 box-shadow shadow-effect-2 resource-content">
        {children}
      </div>
    </div>
  )
}

export default Resources
