// pulled some of the code from https://github.com/wankdanker/node-tracking-url/blob/master/index.js, then modified it

export function getShipMethod(ship_via_code='') {
  const sanitized_code = ship_via_code ? ship_via_code.trim().toUpperCase() : ''
  const ship_methods = {
    "PP" : { carrier: 'usps', description: "Priority Mail" },
    "PE" : { carrier: 'usps', description: "Express Mail" },
    "PA" : { carrier: 'usps', description: "First Class Mail" },
    "PG" : { carrier: 'usps', description: "USPS Ground Advantage" },
    "RA" : { carrier: 'usps', description: "Registered Mail" },
    "PB" : { carrier: 'usps', description: "Economy Mail" },
    "UG" : { carrier: 'ups', description: "UPS Ground" },
    "U1" : { carrier: 'ups', description: "UPS Next Day Air" },
    "US" : { carrier: 'ups', description: "UPS Next Day Air Saver" },
    "U2" : { carrier: 'ups', description: "UPS 2nd Day Air" },
    "U3" : { carrier: 'ups', description: "UPS 3rd Day Select" },
    "U4" : { carrier: 'ups', description: "UPS Worldwide Expedited" },
    "U5" : { carrier: 'ups', description: "UPS Worldwide Saver" },
    "U6" : { carrier: 'ups', description: "UPS Worldwide Express" },
    "FE" : { carrier: 'fedex', description: "FedEx Overnight" },
    "F2" : { carrier: 'fedex', description: "FedEx 2 Day" },
    "F3" : { carrier: 'fedex', description: "FedEx Express Saver" },
    "F4" : { carrier: 'fedex', description: "FedEx Ground" },
    "F5" : { carrier: 'fedex', description: "FedEx International Priority" },
    "F6" : { carrier: 'fedex', description: "FedEx International Economy" },
    "D1" : { carrier: 'dhl', description: "DHL Worldwide" },
    "OT" : { carrier: 'unspecified', description: "Other Shipping Method" },
  }
  return ship_methods[sanitized_code] || { carrier: 'unknown', description: 'Unknown Method (' + sanitized_code + ')' }
}

const carriers = {
	ups: {
    // url: 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums={trackingNumber}',
    url: 'https://www.ups.com/track?loc=en_US&tracknum={trackingNumber}',
		regs: [
			/\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/,
		]
	},
	fedex: {
		// url: 'https://www.fedex.com/apps/fedextrack/?tracknumbers={trackingNumber}',
    url: 'https://www.fedex.com/fedextrack/?trknbr={trackingNumber}',
		regs: [
			/(\b96\d{20}\b)|(\b\d{15}\b)|(\b\d{12}\b)/,
			/\b((98\d\d\d\d\d?\d\d\d\d|98\d\d) ?\d\d\d\d ?\d\d\d\d( ?\d\d\d)?)\b/,
			/^[0-9]{15}$/,
		]
	},
	usps: {
		url: 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1={trackingNumber}',
		regs: [
			/(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)/,
			/^E\D{1}\d{9}\D{2}$|^9\d{15,21}$/,
			/^91[0-9]+$/,
			/^[A-Za-z]{2}[0-9]+US$/,
		]
	},
	dhl: {
    url: 'https://www.dhl.com/global-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id={trackingNumber}',
		regs: [
			/^\d{10,11}$/,
		]
	}
}

export function getTrackingInfo(rawTrackingNumber, { carrier=null }={}) {
	let match = false

	//remove spaces from the tracking number
	const resolvedTrackingNumber = (rawTrackingNumber || "").replace(/ /gi, '')

  if (carrier) {
    return {
      carrier,
      url: carriers[carrier] ? carriers[carrier].url.replace(/\{trackingNumber\}/gi, resolvedTrackingNumber) : null,
    }
  } else {
    Object.entries(carriers).some(([k, v], i) => {
      // console.log('[k, v]:', k, v)
      return v.regs.some((reg) => {
        if (reg.test(resolvedTrackingNumber)) {
          match = {
            carrier: k,
            url: v.url.replace(/\{trackingNumber\}/gi, resolvedTrackingNumber),
          }
          console.log('match-carrier:', match)
          return true
        }
      })
    })
  }

  return match
}

export function getTrackingUrl(rawTrackingNumber, { carrier = null } = {}) {
  const trackingInfo = getTrackingInfo(rawTrackingNumber, { carrier })
  return trackingInfo.url
}