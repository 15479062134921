import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import {SiteView} from '../base/components/siteview-context'
import PrintButton from './PrintButton'

ContentPanel.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  titleSuffix: PropTypes.string,
  titleAuxElement: PropTypes.element,
  titleRight: PropTypes.string,
  footerElement: PropTypes.element,
  beanColor: PropTypes.string,
  beanIcon: PropTypes.string,
  beanText: PropTypes.string,
  filteredText: PropTypes.string,
  showBackToList: PropTypes.bool,
  backToListAction: PropTypes.func,
  showPagingControls: PropTypes.bool,
  auxClassContainer: PropTypes.string,
  auxClassHeader: PropTypes.string,
  auxClassContent: PropTypes.string,
  styleContainer: PropTypes.object,
  styleHeader: PropTypes.object,
  styleTitle: PropTypes.object,
  styleContent: PropTypes.object,
  showPrintView: PropTypes.bool,
  prevAction: PropTypes.func,
  nextAction: PropTypes.func,
}

ContentPanel.defaultProps = {
  title: 'Title',
  icon: 'fa fa-star fa-fw',
  beanColor: '#FFF',
  beanIcon: '',
  showPagingControls: false,
  showBackToList: false,
  showPrintView: true,
}

// Note that style overrides are less flexible in the context of standard/print views so we typically want to use aux classes for most styling

function ContentPanel({ children, title, icon, titleSuffix, titleAuxElement, titleRight, beanColor, beanIcon, beanText, filteredText, showBackToList, backToListAction, showPagingControls, auxClassContainer, auxClassHeader, auxClassContent, styleContainer, styleHeader, styleTitle, styleContent, footerElement, prevAction, nextAction, showPrintView }) {
  const mode = useContext(SiteView)

  const bean_filtered = filteredText ? <span className="flex-bean show-only-in-desktop"><span className='fa fa-filter' /> criteria: {filteredText}</span> : null
  const bean_extra = beanText ? <span className="flex-bean show-only-in-desktop" style={{ backgroundColor: beanColor }}><span className={beanIcon} /> {beanText}</span> : null
  const button_backToList = <a className="flex-bean bean-button bean-medium bean-dark-red show-only-in-desktop" onClick={backToListAction}><span><span className="fa fa-table" /> <span className="fa fa-arrow-left" /> Back to List</span></a>
  const button_previous = <a className="flex-bean bean-button bean-large bean-dark-red" onClick={prevAction} disabled={prevAction ? false : true}><span className="fa fa-angle-left text-vertical-align-top" /></a>
  const button_next = <a className="flex-bean bean-button bean-large bean-dark-red" onClick={nextAction} disabled={nextAction ? false : true}><span className="fa fa-angle-right text-vertical-align-top" /></a>
  const printview_link = <Link className="flex-bean bean-button bean-medium bean-dark-red show-only-in-desktop" to={ location => ({ ...location, query: { ...location.query, printview: null} }) } target="_blank"><i className="fa fa-fw fa-print"></i>Print View</Link>

  const combined_header = <div className={`base-header ${mode.classPrefix}-header ${auxClassHeader || ''}`} style={styleHeader}>
    <div className={`base-title ${mode.classPrefix}-title`} style={styleTitle}><i className={icon}></i> <span className="primary-title">{title}</span> {titleSuffix && titleSuffix} {titleAuxElement && titleAuxElement}</div>
    {mode.id !== 'printview' && <>{bean_extra}{bean_filtered}</>}
    {mode.id !== 'printview' ? <div className="flex-sub-container right-block">{showPrintView && printview_link}{showBackToList && button_backToList}{showPagingControls && <div className="flex-sub-container flex-gap-4">{button_previous}{button_next}</div>}</div> : titleRight && <span className="right-block padded-element title-right">{titleRight}</span>}
  </div>

  return <div className={`base-container ${mode.classPrefix}-container ${auxClassContainer || ''}`} style={styleContainer}>
    {mode.id === 'printview' && <PrintButton />}
    {combined_header}
    <div className={`base-content ${mode.classPrefix}-content ${auxClassContent || ''}`} style={styleContent}>
      {children}
    </div>
    {footerElement && footerElement}
  </div>
}

export default ContentPanel