import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Nav, NavItem } from 'react-bootstrap'

import FilterPanel from './filter-panel'
import FormItem from './form-item'
import ClipboardPanel from '../shared/clipboard-panel'

import { getData, getLot, closeLot, selectTab, fieldUpdate, fieldLeave, saveLot, pasteFromClipboard, refreshSerial } from 'rdx/modules/admin/lots'
import { copyToClipboard } from 'rdx/modules/admin/clipboard'

import Tab from '../shared/tab'
import DataList from '../shared/DataList'
import DataListRow from '../shared/DataListRow'

import { admin_lot_list_options, admin_lot_filter_options } from 'lib/constants'

import connectDataList from '../shared/composers/connectDataList'

const DataListBound = connectDataList(DataList, state => ({ statebranch: state.admin.lots.list }))

TabGroupItem.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  lookupData: PropTypes.object.isRequired, //injected via DashboardAdmin
  lots: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  getLot: PropTypes.func.isRequired,
  saveLot: PropTypes.func.isRequired,
  closeLot: PropTypes.func.isRequired,
  selectTab: PropTypes.func.isRequired,
  fieldUpdate: PropTypes.func.isRequired,
  fieldLeave: PropTypes.func.isRequired,
  pasteFromClipboard: PropTypes.func.isRequired,
  refreshSerial: PropTypes.func.isRequired,
  copyToClipboard: PropTypes.func.isRequired,
}

TabGroupItem.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getData(location)),
  ])
}

function TabGroupItem({ location, params, lookupData, lots, getData, getLot, saveLot, closeLot, selectTab, fieldUpdate, fieldLeave, pasteFromClipboard, refreshSerial, copyToClipboard }) {
  useEffect(() => {
    getData(location)
  }, [location, getData])

  const _handleSelect = (key) => {
    selectTab(key)
  }

  const _onItemClick = (item) => {
    if (lots.lot[item.id]) {
      selectTab(item.id)
    } else {
      getLot(item.id)
    }
  }

  const _onCloseClick = (e, id) => {
    e.stopPropagation()
    e.preventDefault()
    closeLot(id)
  }

  const nav = <Nav bsStyle='tabs' activeKey={lots.selectedTab} onSelect={_handleSelect}>
    <NavItem eventKey="list">{ 'Lot List' }</NavItem>
    {
      lots.lot_order.map((o) => {
        if (o) {
          let tabindicator = ''
          switch (lots.lot[o].update.status) {
            case 1:
              tabindicator = <span className='fa fa-circle ok' />
              break
            case 2:
              tabindicator = <span className='fa fa-circle error' />
              break
            default:
          }
          return <NavItem key={o} eventKey={o} tabIndex="-1">
            {tabindicator}
            {lots.lot[o].originalData.consignor_code ? (lots.lot[o].originalData.consignor_code + lots.lot[o].originalData.consignor_serial) : o}
            <span className='fa fa-close' onClick={(e) => _onCloseClick(e, o)} />
          </NavItem>
        }
      })
    }
    <NavItem eventKey="new" tabIndex="-1"><span className={'fa fa-plus' + (lots.lot.new.meta.status === 2 ? ' error' : '')} />{' Add Lot'}</NavItem>
    <NavItem eventKey="clipboard" tabIndex="-1"><span className={'fa fa-clipboard'} />{ ' Clipboard' }</NavItem>
  </Nav>

  return <div className='tab-v1 owa-margin10'>
    <div className="tab-container">
      {nav}
      <Tab isActive={lots.selectedTab === "list"}>
        <FilterPanel
          lookupData={lookupData}
          filterbuilderitems={admin_lot_filter_options}
          location={location}
        />
        <DataListBound
          useTable={true}
          tableLayoutFixed={false}
          showHeader={true}
          showTopPagingControl={true}
          pagingControlSize='slim'
          metadata={admin_lot_list_options}
          datakey='id'
          clickTargetFunction={_onItemClick}
          params={params}
          location={location}
        >
          <DataListRow />
        </DataListBound>
      </Tab>
      {
        lots.lot_order.map((o) => {
            return <Tab key={o} isActive={lots.selectedTab === o}>
              <FormItem
                lot={lots.lot[o]}
                isNew={false}
                lookupData={lookupData}
                fieldUpdate={fieldUpdate}
                fieldLeave={fieldLeave}
                saveLot={saveLot}
                copyToClipboard={copyToClipboard}
                pasteFromClipboard={pasteFromClipboard}
                refreshSerial={refreshSerial}
              />
            </Tab>
        })
      }
      <Tab isActive={lots.selectedTab === "new"}>
        <FormItem
          lot={lots.lot.new}
          isNew={true}
          lookupData={lookupData}
          fieldUpdate={fieldUpdate}
          fieldLeave={fieldLeave}
          saveLot={saveLot}
          copyToClipboard={copyToClipboard}
          pasteFromClipboard={pasteFromClipboard}
          refreshSerial={refreshSerial}
        />
      </Tab>
      <Tab isActive={lots.selectedTab === "clipboard"}>
        <ClipboardPanel lookupData={lookupData} />
      </Tab>
    </div>
  </div>
}

function mapStateToProps(state) {
  return {
    lots: state.admin.lots,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      getData,
      getLot,
      closeLot,
      selectTab,
      fieldUpdate,
      fieldLeave,
      saveLot,
      pasteFromClipboard,
      refreshSerial,
      copyToClipboard,
    }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabGroupItem)
