import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Logo from './Logo'

class HeaderLanding extends Component {
  static propTypes = {
    mode_big: PropTypes.bool,
    sitemode: PropTypes.string,
  }

  static defaultProps = {
    mode_big: true,
    sitemode: 'production',
  }

  render() {
    const { sitemode, mode_big } = this.props
    const wrapper_css = classNames({
      'header': true,
      'header-box': mode_big,
      'header-box-small': !mode_big,
    })

    // ZZZurgent - refactor landing page to be in sync with fixed header design
    return (
      <div className={wrapper_css}>
        <div className="topbar-container" style={{height: '32px'}}>
        </div>
        <div className="owa-container" style={{marginLeft: '5px', marginRight: '5px'}}>
          <Logo mode_big={mode_big} sitemode={sitemode} link_active={false} />
        </div>
      </div>
    )
  }
}

export default HeaderLanding