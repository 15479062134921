import { processQuery, get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

import { getSiteInfo } from '../siteinfo'

const base = 'owa/admin/resolution/'

const DATA = defineAction(base, 'DATA', 'async')
const APPROVE_ADJUSTMENT = defineAction(base, 'APPROVE_ADJUSTMENT', 'async')
const LOT_REFRESH = defineAction(base, 'LOT_REFRESH', 'async')

const ACTION_STATUS_RESET = base.concat('ACTION_STATUS_RESET')

const info_defaults = {
  pageSize: 20,
  count: 0,
}

const action_defaults = {
  lotRefresh: status_defaults,
  approveAdjustment: status_defaults,
}

const initialState = {
  lots: {
    data: {},
    order: [],
    info: info_defaults,
    meta: status_defaults,
  },
  actions: action_defaults,
}

function resolveAdjustedLot(lot) {
  let new_price_reserve = lot.price_reserve
  if (lot.cs_accept === 1) {
    new_price_reserve = lot.price_highbid
  } else if (lot.cs_reduce_reserve === 1) {
    new_price_reserve = lot.cs_reduce_reserve_price <= lot.price_highbid ? lot.price_highbid : lot.cs_reduce_reserve_price
  }
  return {
    ...lot,
    admin_reviewed_adjustment: true,
    price_reserve: new_price_reserve,
    reserve_met: lot.price_highbid >= new_price_reserve ? 1 : 0,
  }
}

function mapData(action) {
  let _order = []

  const _lots = action.payload.data.reduce((o, item, i) => {
    o[item.id] = item
    _order[i] = item.id
    return o
  }, {})

  return {
    data: _lots,
    order: _order,
    info: {
      ...info_defaults,
      count: action.payload.count,
    },
    meta: {
      ...status_defaults,
      ...action.meta,
    }
  }
}

// ZZZ - experimental reducer testing?
// export function reducer_nextgen(state = initialState, action = {}) {
//   if (action.base === base) {
//     // action is in the relevant branch
//     //console.log('action.base:', base)
//     console.log('action:', action)
//   }
// }

export default function reducer(state = initialState, action = {}) {
  // if (action.type.substr(0, action.type.lastIndexOf('/') + 1) === base) {
  //   // action is in the relevant branch
  //   // console.log('action:', action)
  // }
  switch (action.type) {
    case ACTION_STATUS_RESET:
      return {
        ...state,
        actions: action_defaults,
      }
    case DATA.request:
      return {
        ...state,
        lots: {
          ...state.lots,
          meta: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case DATA.fail:
      return {
        ...state,
        lots: {
          ...state.lots,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA.success:
      return {
        ...state,
        lots: mapData(action),
      }
    case LOT_REFRESH.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          lotRefresh: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case LOT_REFRESH.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          lotRefresh: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case LOT_REFRESH.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          lotRefresh: {
            ...status_defaults,
            ...action.meta,
          }
        },
        lots: {
          ...state.lots,
          data: {
            ...state.lots.data,
            [action.payload.data.id]: {
              ...state.lots.data[action.payload.data.id],
              ...action.payload.data,
            }
          }
        }
      }
    case APPROVE_ADJUSTMENT.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          approveAdjustment: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case APPROVE_ADJUSTMENT.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          approveAdjustment: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case APPROVE_ADJUSTMENT.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          approveAdjustment: {
            ...status_defaults,
            ...action.meta,
          }
        },
        lots: {
          ...state.lots,
          data: {
            ...state.lots.data,
            [action.foundation.lot_id]: resolveAdjustedLot(state.lots.data[action.foundation.lot_id]),
          }
        }
      }
    default:
      return state
  }
}

export function actionStatusReset() {
  return {
    type: ACTION_STATUS_RESET,
  }
}

export function getData(location, selectedLotID) {
  console.log('resolution-getData')
  return (dispatch, getState) => {
    const state = getState()
    const query = location.query

    const resolved_query = {
      ...query,
      // append selected lot ID to prevent lot data from disappearing while Lot Actions popup is open
      ...(selectedLotID && { selectedLotID }),
    }

    const querydata = processQuery(resolved_query, { whitelist: ['auction_id', 'consignor_id', 'status', 'lot_number', 'changes', 'selectedLotID'], pageSize: state.admin.resolution.lots.info.pageSize })
    const endpoint = '/api/admin/resolution' + querydata
    return dispatch(
      get({
        types: DATA,
        endpoint: endpoint,
        postSuccess: (result) => {
          if (getState().siteinfo.data.cachetime !== result.payload.siteinfo_cachetime) {
            console.log('*SITEINFO NEEDS REFRESH!*')
            dispatch(getSiteInfo())
          }
        }
      })
    )
  }
}

export function approveAdjustment(lot_id) {
  console.log('**approveAdjustment')
  return (dispatch) => {
    const endpoint = '/api/admin/resolution/approve-adjustment/' + lot_id

    return dispatch(
      get({
        types: APPROVE_ADJUSTMENT,
        endpoint: endpoint,
        config: {
          method: 'post',
        },
        foundation: { lot_id: lot_id }
      })
    )
  }
}

export function lotRefresh(lot_id) {
  return (dispatch) => {
    const endpoint = '/api/admin/resolution/lot/' + lot_id

    return dispatch(
      get({
        types: LOT_REFRESH,
        endpoint: endpoint,
      })
    )
  }
}
