import React from 'react'
import PropTypes from 'prop-types'

import FieldBase from './FieldBase'

FieldSmartSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  displayField: PropTypes.string,
  valueField: PropTypes.string,
}

FieldSmartSelect.defaultProps = {
  name: null,
  value: null,
  displayField: null,
  valueField: null,
}

function FieldSmartSelect({ name, value, options, displayField, valueField, ...other }) {
  return <FieldBase type='SmartSelect' name={name} value={value}
    optionsSmartSelect={{
      options,
      displayField: displayField ? displayField: valueField,
      valueField,
    }}
    {...other}
  />
}

export default FieldSmartSelect
