import { useState, useRef, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getSiteInfo } from 'rdx/modules/siteinfo'
import { getApplicableSlides } from 'rdx/modules/slides'
import { getArticlesRecent } from 'rdx/modules/info/articles/recent'

import { requestInterval } from 'lib/timers'
import { defaultRefreshInterval, defaultAuthenticatedRefreshInterval } from 'lib/constants'

const useSiteInfoRefresher = ({ runOnInit=false }={}) => {
  const siteinfo = useSelector(state => state.siteinfo.data)
  const status = useSelector(state => state.siteinfo.meta.status)
  const isAuthenticated = useSelector(state => state.auth.login.data.isAuthenticated)
  const dispatch = useDispatch()

  const [count, setCount] = useState(0)

  const refreshInterval = isAuthenticated ? defaultAuthenticatedRefreshInterval : defaultRefreshInterval
  const isInitialMount = useRef(true)

  const _refreshSiteInfo = useCallback(
    () => {
      dispatch(getSiteInfo())
    },[dispatch]
  )

  const _refreshSlides = useCallback(
    () => {
      dispatch(getApplicableSlides())
    },[dispatch]
  )

  const _refreshRecentArticles = useCallback(
    () => {
      dispatch(getArticlesRecent())
    },[dispatch]
  )

  // handles siteinfo refresh on initial mount and/or on dependency changes
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      // console.log('*refresh siteinfo (initial)')
      runOnInit && _refreshSiteInfo('siteinfo-initial-mount')
    } else {
      // refresh on dependency change (except on initial mount)
      // console.log('*refresh (dep-update)')
      _refreshSiteInfo('siteinfo-dep-update')
    }
  }, [runOnInit, _refreshSiteInfo, _refreshSlides])

  // refresh slide data if timestamp_slides changed in siteinfo
  useEffect(() => {
      // console.log('*refresh slides (timestamp-based):', siteinfo.timestamp_slides)
      _refreshSlides('slides-timestamp-based')
  }, [_refreshSlides, siteinfo.timestamp_slides, siteinfo.auction_mode, siteinfo.current_auction])

  // refresh article data if timestamp_articles_recent changed in siteinfo
  useEffect(() => {
      // console.log('*refresh recent articles (timestamp-based):', siteinfo.timestamp_articles_recent)
      _refreshRecentArticles('recent-articles-timestamp-based')
  }, [_refreshRecentArticles, siteinfo.timestamp_articles_recent])

  // handles siteinfo refresh on interval (slides and articles refresh based on timestamp value updates contained in siteinfo)
  useEffect(() => {
    // if initial request fails, try again after another standard interval and then every 10 intervals
    if (count === refreshInterval || (count === refreshInterval * 2) || (count > 0 && (count % (refreshInterval * 10) === 0))) {
      // console.log('*refresh siteinfo (timer-based)')
      _refreshSiteInfo('interval-reached')
    }
  }, [_refreshSiteInfo, count, refreshInterval])


  // controls interval ticks and resets
  useEffect(() => {
    if (status === 1) {
      setCount(0)
    }
    const interval = new requestInterval(() => setCount(c => c + 1), 1000)
    return () => interval.clear()
  }, [status])

  return { elapsedSeconds: count, secondsTillRefresh: refreshInterval - count }
}

export default useSiteInfoRefresher

