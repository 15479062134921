import fetchPonyfill from 'fetch-ponyfill'
import Promise from 'bluebird'

import { isClient } from 'lib/exenv'
import { isObject, isNonEmptyObject, isError } from './utility'
import { validation_defaults } from './state_defaults'

const { fetch, Request, Response, Headers } = fetchPonyfill(Promise)

export const basepath = isClient ? '' : __APIROOT__

export const get = meta => ({
  ['@@fetch']: meta
})

export function fetcher(url, config={}, cookie={}) {
  let options = {}
  let headers = new Headers();

  // Base Headers
  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');

  if (config.headers) {
    Object.getOwnPropertyNames(config.headers).forEach(function(key, idx, array) {
      //console.log(key + ' -> ' + config.headers[key]);
      headers.append(key, config.headers[key])
    })
  }

  if (cookie.value && !isClient) {
    //inject cookie data into options header for server-to-server requests
    headers.set('Cookie', cookie.value)
  }

  options = {
    credentials: config.credentials || 'same-origin',
    method: config.method || 'get',
    //mode: config.mode,
    //cache: config.cache,
    headers: headers,
  }

  if (config.body) {
    options.body = typeof config.body === 'string' ? config.body : JSON.stringify(config.body)
  }

  console.log('fetcher:', url, options)

  return new Promise((resolve, reject) => {
    return fetch(url, options)
    .then(response => {
      return response.json()
      .then(json =>
        ({
          ok: response.ok,
          status: response.status,
          json,
        })
      )
      .catch(error => {
        // received response from server, but failed to parse returned data
        const e = Error('Failure reading server response')
        e.meta = { source: 'fetcher-parse-error', x_response_status: response.status ? response.status : 0, x_error_message: error.message }
        return reject(e)
      })
    })
    .then(async (response) => {
      if (response.ok) {
        if (process.env.NODE_ENV !== 'production' && url !== basepath + '/api/public/logger') {
          await validateServerResponse(response.json, { url })
        }
        return resolve(response.json)
      } else {
        // received response from server, but the status is not OK
        const e = Error('Bad response from server')
        e.meta = { source: 'fetcher-bad-response', ...response.json.meta, x_response_status: response.status }
        return reject(e)
      }
    })
    .catch((error) => {
      // unable to contact server (network error) or another unspecified error
      const e = Error('Unable to contact server.  The server or internet may be down.  Please try again.')
      e.meta = { source: 'fetcher-network-error', x_error_message: error.message }
      return reject(e)
    })
  })
}

export function parseResult(result) {
  if (process.env.NODE_ENV === "development") { diagScrutinizeResult(result) }
  return {
    localtime: Date.now(),
    meta: { status: -1, statusText: 'No meta object returned from server' },
    ...(isObject(result) && { ...result }),
  }
}

export function parseError(base, o) {
  const { meta, payload } = o
  const { validation, ...other } = payload || {}

  if (process.env.NODE_ENV === "development") { diagScrutinizeResult(o, { resultType: 'error' }) }

  // handle messaging for specific http status codes (like 400, 403, 404)
  const resolvedInfo = resolveErrorInfo(meta, { ...(isError(o) && { originalMessage: o.message }) })

  return {
    localtime: Date.now(),
    source: base,
    meta: {
      status: 2,
      statusText: resolvedInfo.message,
      displayRefresh: resolvedInfo.displayRefresh,
      statusCategory: 'Error in Response from Server',
      ...(isObject(meta) && { ...meta }),
    },
    validation: { ...validation_defaults, ...(validation && { ...validation })},
    ...(isNonEmptyObject(other) && { payload: other }),
  }
}

export function abortWithError(message, { source='unknown' }={}) {
  const e = new Error(message)
  e.source = source

  // ZZZ - hook into some type of error reporting here for tracking?
  throw e
}

function resolveErrorInfo(meta, { originalMessage='Unknown Error' }) {
  let message, displayRefresh = false
  if (typeof meta === 'string') {
    message = meta
  } else if (meta && meta.x_response_status && meta.x_response_status !== 0) {
    switch (meta.x_response_status) {
      case 403:
        message = 'Not Authorized'
        displayRefresh = true
        break
      default:
        message = meta.x_response_status + ': ' + originalMessage
    }
  } else {
    message = originalMessage
  }
  return {
    message,
    displayRefresh
  }
}

export function processQuery(q, { whitelist=[], pageSize=0, sortKey='db', initialQueryTimestamp=0 }={}) {
  const page_size = typeof pageSize !== undefined ? (Number.isInteger(+pageSize) ? +pageSize : 20) : 20
  let qp = { offset: 0, limit: page_size }

  if (typeof q !== 'undefined' && q !== null && Object.keys(q).length !== 0 ) {
    const page = q.page ? (Number.isInteger(+q.page) ? +q.page - 1 : 0) : 0

    Object.entries(q)
    .filter(([key]) => whitelist.includes(key))
    .reduce((q, [key, val]) => Object.assign(qp, { [key]: val }), {})

    if (typeof q['sort_field_' + sortKey] !== 'undefined') {
      qp.sort_field = q['sort_field_' + sortKey]
      if (typeof q['sort_table_' + sortKey] !== 'undefined') {
        qp.sort_table = q['sort_table_' + sortKey]
      }
      if (typeof q['sort_direction_' + sortKey] !== 'undefined') {
        qp.sort_direction = q['sort_direction_' + sortKey]
      }
    }

    if (page > 0) {
      qp.offset = page * page_size
      qp.limit = page_size
    }

    if (initialQueryTimestamp !== 0) {
      qp.initialQueryTimestamp = initialQueryTimestamp
    }
  }

  return '?' + toQueryString(qp)
}

export function toQueryString(paramsObject) {
  return Object
    .keys(paramsObject)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
    .join('&')
}

// ------------------- Communication Diagnostics -----------------------------

function diagScrutinizeResult(result, { resultType='standard' }={}) {
  if (isError(result) || resultType === 'error') {
    // TODO: check for proper error attributes
  } else {
    // check for proper result attributes
    if (!isObject(result)) {
      console.warn('**parseResult Warning: result is not an object')
    } else {
      if (!isNonEmptyObject(result.payload)) {
        console.warn('**parseResult Warning: no payload returned')
      }
      if (result.payload && (result.payload.meta || result.payload.localtime)) {
        throw Error('payload contains invalid/conflicting properties')
      }
    }
  }
}

export async function validateServerResponse(response, { url='', type='clientResponse' }={}) {
  try {
    const messages = {
      errors: [],
      warnings: [],
    }
    if (!response) {
      messages.errors.push('● No response object!')
    } else {
      if (response.pass) {
        return null
      }

      if (response.generator !== 'responder') {
        messages.warnings.push('● Unauthorized response generator: ' + response.generator)
      }

      if (!response.meta) {
        messages.errors.push('● No meta component!')
      } else {
        const { status, rollback, id, timestamp, source, statusCategory, statusText, statusExtendedText, statusDirective, results=[], ...other } = response.meta

        if (invalidValue(source) || invalidValue(statusCategory) || invalidValue(statusText) || invalidValue(statusExtendedText) || invalidValue(statusDirective)) {
          messages.errors.push('● One or more response values that must be strings are a different data type!')
        }
        if (results && !Array.isArray(results)) {
          messages.errors.push('● Explicit results value in payload must be an array!')
        }
        if (missingValue(status) || missingValue(statusText) || missingValue(rollback) || missingValue(id) || missingValue(timestamp)) {
          messages.errors.push('● One or more required response values are missing!')
        }

        // generate warnings
        if (Object.keys(other).length) {
          // console.warn('response-extraneous-attributes:', other)
          messages.warnings.push('● extraneous attributes: ' + JSON.stringify(other))
        }
      }
    }
    if (messages.errors.length || messages.warnings.length) {
      await processErrors({ messages, response, type, url })
    }
    return null
  } catch(error) {
    processErrors({ messages: { errors: [error.message], warnings: [] }, response })
  }
}

function logEventToServer({ messages={ errors: [], warnings: [] }, response={}, type='', url='', fatal=false }={}) {
  const description = [ ...messages.errors, ...messages.warnings ].join(', ')
  return fetcher( basepath + '/api/public/logger', {
    method: 'post',
    body: JSON.stringify({ event: { description: description, type: type, level: fatal ? 1 : 0, obj: response, url: url, source: isClient ? 'client' : 'server' } }),
  })
  .then((json) => {
    if (json.status === 1) {
      // console.info('event logged to server')
    } else {
      throw Error('Unknown Server Error')
    }
  })
}

async function processErrors({ messages={}, response, type, url }={}) {
  const resolved_mode = messages.errors.length ? 'error' : 'warning'
  const resolved_type = type === 'clientResponse' ? '' : '● ' + type + ":\n"

  if (isClient) {
    if (resolved_mode === 'error') {
      console.error(`IMPROPER RESPONSE DETECTED! \n${resolved_type}%c${messages.errors.join("\n")}\n%c${messages.warnings.join("\n")}\n`, 'background-color: #faf0f0; color: #ff0000', 'background-color: #fdfce5; color: #544103', response)
    } else {
      console.warn(`IMPROPER RESPONSE DETECTED! \n${resolved_type}%c${messages.warnings.join("\n")}\n`, 'background-color: #fdfce5; color: #544103', response)
    }
  } else {
    if (resolved_mode === 'error') {
      console.error('\x1b[41m', `IMPROPER RESPONSE DETECTED! \n${resolved_type}${messages.errors.join("\n")}\n${messages.warnings.join("\n")}\n`, response, '\x1b[0m')
    } else {
      console.warn('\x1b[41m', `IMPROPER RESPONSE DETECTED! \n${resolved_type}${messages.warnings.join("\n")}\n`, response, '\x1b[0m')
    }
  }

  await logEventToServer({ messages, response, type, url, fatal: resolved_mode === 'error' })
  return null
}

function invalidValue(val, type='string') {
  if (typeof val === 'undefined' || typeof val === type) {
    return false
  }
  return true
}

function missingValue(val) {
  if (typeof val === 'undefined') {
    return true
  }
  return false
}
