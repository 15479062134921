import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'

function content(props) {
  return <div className="general-table-defaults">
    <Helmet>
      <title>Bidder Approval</title>
      <link rel="canonical" href={props.location.pathname} />
    </Helmet>
    <div className="titlebar heading heading-v1 text-center">
      <h2>Bidder Approval</h2>
    </div>

    <p>In order to bid in this auction, we need to verify your information.  Please submit the following documentation at least 24 hours in advance of the live auction <span className="text-bold">(October 10th, 2024)</span> to <a href={"mailto:bid@oldworldauctions.com"}>bid@oldworldauctions.com</a>:</p>
    <ul>
      <li>A copy (.jpg or .pdf file) of your government-issued ID (driver&apos;s license, passport or ID card).  Proof of address will also be required if not shown on the government ID (utility bill, county tax bill, bank statement, etc.).</li>
      <li>At least one of the following as proof of funds: most recent bank statement, brokerage account statement, or bank verification letter.  A deposit may be required to bid.</li>
      <li>If you have not already done so, you will also need to <Link to="/register" style={{ color: '#a10f2b' }}>create an account</Link> on our website.  This last step will take only a minute.</li>
    </ul>
    <p>You will be notified by email within one business day with either an approval or request for additional information.  Thank you.</p>
  </div>
}

export default content
