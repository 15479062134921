import React from 'react'
import PropTypes from 'prop-types'

import StatementLineItems from '../statement/StatementLineItems'

import FormRow from '../shared/FormRow'

import AddressBox from '../shared/AddressBox'

StatementContentUser.propTypes = {
  statement: PropTypes.object.isRequired,
}

function StatementContentUser({ statement }) {
  const data = statement.content.main
  const address = {
    firstname: data.user_firstname,
    lastname: data.user_lastname,
    company: data.primary_company,
    address_line1: data.primary_address_line1,
    address_line2: data.primary_address_line2,
    city: data.primary_city,
    state: data.primary_state,
    postalcode: data.primary_postalcode,
    country: data.primary_country,
    phone: data.primary_phone,
  }

  const user_section = <FormRow style={{ marginBottom: '5px' }}>
    <div className='col-lg-12 margin-bottom-10'>
      <div className="clearfix" style={{ padding: '5px', border: '1px solid #ddd' }}>
        <div className="fixed-gutter-5" style={{ display: 'inline-flex', marginBottom: '15px', width: '100%' }}>
          <div className="col-sm-6">
            <AddressBox
              locked={true}
              showHeader={false}
              address={address}
            />
          </div>
          <div className="col-sm-6">
            <div className="form-general" style={{ height: '100%', fontWeight: 'bold' }}>
              <div style={{ paddingBottom: '5px', borderBottom: '1px solid #ddd' }}>
                Commission: {data.commission_scheme}
              </div>
              <div>
                {data.st_notes}
              </div>
            </div>
          </div>
        </div>
        <StatementLineItems mode="user" statement={statement} />
      </div>
    </div>
  </FormRow>

  return <div className="page-base page-user">
    {user_section}
  </div>
}

export default StatementContentUser