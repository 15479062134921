import React from 'react'
import PropTypes from 'prop-types'

import FieldBase from './FieldBase'

FieldSelect.propTypes = {
  options: PropTypes.array.isRequired,
}

function FieldSelect(props) {
  return <FieldBase {...props} type={'selectWithOptions'} options={this.props.options} />
}

export default FieldSelect