import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { toggleMenu } from 'rdx/modules/menus'

import Menu from './Menu/Menu'

import { menu_content_site } from 'lib/constants'

MobileMenuSite.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  credentials: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
}

MobileMenuSite.defaultProps = {

}

const id = "mobile-menu-site"

function MobileMenuSite({ menuOpen, toggleMenu, credentials, logout }) {
  return <Menu
    id={id}
    type="mobile"
    position="right"
    title="Old World Auctions"
    titleImg={<img className="logo-globe-small" src="/static/img/globe_small_8_transparent.png" />}
    titleTarget="/"
    titleStyle={{ padding: '8px 10px 8px 10px', fontSize: '18px', backgroundColor: '#373a47' }}
    menuOpen={menuOpen}
    toggleMenu={toggleMenu}
    data={menu_content_site}
    context={{credentials}}
    actions={{
      logout: logout,
    }}
  />
}

function mapStateToProps(state) {
  return {
    menuOpen: state.menus[id],
  }
}

const mapDispatchToProps = { toggleMenu }

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuSite)
