import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'
import classNames from 'classnames'

MovableItem.propTypes = {
  containerClass: PropTypes.string,
}

MovableItem.defaultProps = {
  containerClass: '',
}

function MovableItem({ children, id, index, moveItem, containerClass }) {
    const dragRef = useRef(null)
    const previewRef = useRef(null)

    const resolvedContainerClass = classNames('flex-generic-container hover-container', containerClass)

    const [{ handlerId }, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!previewRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = previewRef.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveItem(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag, preview] = useDrag({
        type: 'card',
        item: () => {
          return { id, index }
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        end: () => {
          // end action
        },
    })
    const opacity = isDragging ? 0 : 1
    drag(dragRef)
    drop(preview(previewRef))
    return (<div ref={previewRef} className={resolvedContainerClass} style={{ opacity }} data-handler-id={handlerId}>
      <div ref={dragRef} style={{ flex: '0 0 15px', cursor: 'move', padding: '2px 5px 2px 0' }}>
        <div className="movable-handle" />
      </div>
      <div style={{ flex: 1, minWidth: 0 }}>
        {children}
      </div>
		</div>)
}

export default MovableItem
