import { CountryRegionData } from 'react-country-region-selector'

import { round } from './utility'
import { user_options, bid_extension_time } from './constants'

// Statement Helpers

// not needed for now - the calculation is currently done at the database level
export function calculateCommission(amount, commission_scheme_id) {
  let minimum = 0
  let calc = 0

  switch (commission_scheme_id) {
    case 5:
      // no commission
      return 0
    case -18617049:
      // 7.5% with no minimum
      calc = amount * 0.075
      break;
    case -18617048:
      // 10% with no minimum
      calc = amount * 0.1
      break;
    case -18617050:
      // 12% with no minimum
      calc = amount * 0.12
      break;
    case 1104346766:
      // 12% with $25 min
      calc = amount * 0.12
      minimum = 25
      break;
    case -18617053:
      // 15% with $10 min
      calc = amount * 0.15
      minimum = 10
      break;
    case 1092246053:
      // 15% with $25 min
      calc = amount * 0.15
      minimum = 25
      break;
    default:
      return 5000
  }

  calc = Math.max(round(calc, 2), minimum)
  if (!isNaN(calc)) {
    return calc
  } else {
    return 0
  }
}

export function getCountryName(country) {
  // console.warn('getCountryName:', country)
  const result = country ? CountryRegionData.find((item) => item[1] === country) : null
  return result ? result[0] : null
}

export function resolveLotDisplayInfo({ catalog_mode='current', auction_mode=0, liveAuctionInProgress=false, data, elapsedSeconds }) {
  // mode determination
  let display_mode
  let lot_active = true
  if (catalog_mode === 'archive' || auction_mode === 5) {
    // archive mode (only shows either sold or unsold -- no bidding or buying activity allowed)
    display_mode = 0
  } else if (data.status_withdrawn) {
    display_mode = 4
  } else if (auction_mode === 1) {
    // live mode (auction is live)
    display_mode = 1
  } else if (auction_mode === 2) {
    if (liveAuctionInProgress) {
      display_mode = 3
    } else if (data.status_closed === 1 || data.time_since_lastbid === null || data.time_since_lastbid >= bid_extension_time) {
      // actually closed based on server data
      display_mode = data.reserve_met === 1 ? 0 : 2
    } else {
      display_mode = 1
      lot_active = Math.max(0, bid_extension_time - (data.time_since_lastbid + elapsedSeconds)) > 0
    }
  } else {
    // post-auction phase (either show in closed/archive mode if sold, or in buy it now mode)
    display_mode = data.reserve_met === 1 ? 0 : 2
  }
  return {
    display_mode,
    lot_active,
  }
}
