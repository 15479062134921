import { get, abortWithError } from 'lib/api'
import { verify, validatePassword } from 'lib/validation'
import { status_defaults, validation_defaults, defineAction, buildErrorNew } from 'lib/state_defaults'

const base = 'owa/auth/process/'

const VERIFY_TOKEN = defineAction(base, 'VERIFY_TOKEN', 'async')
const PASSWORD_RESET = defineAction(base, 'PASSWORD_RESET', 'async')

const initialState = {
  actions: {
    verifyToken: {
      mode: '',
      meta: status_defaults,
    },
    resetPassword: {
      meta: status_defaults,
      validation: validation_defaults,
    }
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case VERIFY_TOKEN.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          verifyToken: {
            meta: {
              ...status_defaults,
              processing: true,
            },
          }
        }
      }
    case VERIFY_TOKEN.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          verifyToken: {
            meta: {
              ...status_defaults,
              ...action.meta,
            },
          }
        }
      }
    case VERIFY_TOKEN.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          verifyToken: {
            meta: {
              ...status_defaults,
              ...action.meta,
            },
          }
        }
      }
    case PASSWORD_RESET.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          resetPassword: {
            meta: {
              ...status_defaults,
              processing: true,
            },
            validation: validation_defaults,
          }
        }
      }
    case PASSWORD_RESET.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          resetPassword: {
            meta: {
              ...status_defaults,
              ...action.meta,
            },
            validation: validation_defaults,
          }
        }
      }
    case PASSWORD_RESET.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
         resetPassword: {
            meta: {
              ...status_defaults,
              ...action.meta,
            },
            validation: action.validation,
          }
        }
      }
    default:
      return state
  }
}

export function verifyToken(mode, token) {
  return dispatch => {
    if (!['confirm-registration', 'reset-password'].includes(mode)) {
      return abortWithError('Invalid Token Mode: '+ mode, { source: base })
    }
    const endpoint = '/api/auth/verify-token/' + mode + '/' + token

    return dispatch(
      get({
        types: VERIFY_TOKEN,
        endpoint: endpoint,
        foundation: { mode },
      })
    )
  }
}

export function resetPassword(token, fields) {
  return async (dispatch) => {
    const required_fields = ['user_password_new', 'user_password_new_confirm'];

    const errors_present = verify({
      required: required_fields,
    }, fields)

    // ZZZ - need to refactor verify function to handle other validation functions (like confirmation field matching, password requirements, etc)
    const errors_special = []
    if (!validatePassword(fields.user_password_new)) {
      errors_special.push('Password doesn\'t meet the requirements')
    }
    if (fields.user_password_new !== fields.user_password_new_confirm) {
      errors_special.push('Passwords do not match')
    }

    if (errors_present || errors_special.length > 0) {
      console.log('resetPassword-error:', errors_present, errors_special)
      return dispatch(
        // temporary function until buildError can be refactored
        buildErrorNew(PASSWORD_RESET.fail, { statusText: 'Please review the below issues and try again:', validation: { required: errors_present, other: errors_special } })
      )
    }

    const endpoint = '/api/auth/reset-password/' + token
    return dispatch(
      get({
        types: PASSWORD_RESET,
        endpoint,
        config: {
          method: 'post',
          body: { fields },
        },
      })
    )
  }
}
