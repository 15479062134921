import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import classNames from 'classnames'

MenuLink.propTypes = {
  children: PropTypes.any.isRequired,
  newLocation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]),
  closeMenu: PropTypes.func,
  action: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  contentClassName: PropTypes.string,
  contentStyle: PropTypes.object,
}

MenuLink.defaultProps = {
  style: {},
  contentClassName: 'menu-link-content',
  contentStyle: {},
}

export default function MenuLink({ children, newLocation, closeMenu, action, className, style, contentClassName, contentStyle }) {
  const resolvedClassName = classNames({
    'menu-link': true,
    [`${className}`]: className ? true : false,
  })

  const clickHandler = () => {
    if (action) {
      action()
    }
    if (closeMenu) {
      closeMenu()
    }
  }

  const content = <div className={contentClassName} style={contentStyle}>{children}</div>
  if (action) {
    return <div tabIndex="0" onClick={clickHandler} className={resolvedClassName} style={style}>{content}</div>
  } else {
    const newPath = typeof newLocation === 'string' ? { pathname: newLocation } : newLocation
    return <Link to={ location => ({ ...location, ...newPath }) } tabIndex="0" onClick={clickHandler} className={resolvedClassName} style={style}>{content}</Link>
  }
}
