import { get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

const base = 'owa/admin/lookupData/'

const LOOKUP_DATA = defineAction(base, 'LOOKUP_DATA', 'async', { customActions: ['resolve'] })
const DATA = defineAction(base, 'DATA', 'async')

const initialState = {
  data: {
    item_regions: [],
    item_creators: [],
    item_description_headers: [],
    item_categories: [],
    consignors: [],
    consignor_commission_schemes: [],
    users: [],
  },
  meta: {
    all: status_defaults,
    parts: {
      item_regions: status_defaults,
      item_creators: status_defaults,
      item_description_headers: status_defaults,
      item_categories: status_defaults,
      consignors: status_defaults,
      consignor_commission_schemes: status_defaults,
      users: status_defaults,
    }
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        meta: {
          ...state.meta,
          parts: {
            ...state.meta.parts,
            [action.foundation.entity]: {
              ...status_defaults,
              processing: true,
            }
          }
        }
      }
    case DATA.fail:
      return {
        ...state,
        meta: {
          ...state.meta,
          parts: {
            ...state.meta.parts,
            [action.foundation.entity]: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
    case DATA.success:
      return {
        ...state,
        data: {
          ...state.data,
          [action.foundation.entity]: action.payload.data,
        },
        meta: {
          ...state.meta,
          parts: {
            ...state.meta.parts,
            [action.foundation.entity]: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
    case LOOKUP_DATA.request:
      return {
        ...state,
        meta: {
          ...state.meta,
          all: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case LOOKUP_DATA.resolve:
    {
      const success = Object.entries(state.meta.parts).every(([k, v], i) => (v.status === 1))
      const result = success ? { status: 1 } : { status: 2, statusText: 'One or more lookup tables failed to load', statusCategory: 'Server/Database Problem', statusDirective: 'Please reload the page' }
      return {
        ...state,
        meta: {
          ...state.meta,
          all: {
            ...status_defaults,
            ...result,
          }
        }
      }
    }
    default:
      return state
  }
}

export function getLookupData() {
  return (dispatch) => {
    dispatch({ type: LOOKUP_DATA.request })
    return Promise.all([
      dispatch(get({ types: DATA, endpoint: '/api/admin/regions', foundation: { entity: 'item_regions' } })),
      dispatch(get({ types: DATA, endpoint: '/api/admin/creators', foundation: { entity: 'item_creators' } })),
      dispatch(get({ types: DATA, endpoint: '/api/admin/headers', foundation: { entity: 'item_description_headers' } })),
      dispatch(get({ types: DATA, endpoint: '/api/admin/categories', foundation: { entity: 'item_categories' } })),
      dispatch(get({ types: DATA, endpoint: '/api/admin/consignors', foundation: { entity: 'consignors' } })),
      dispatch(get({ types: DATA, endpoint: '/api/admin/consignor_commission_schemes', foundation: { entity: 'consignor_commission_schemes' } })),
      dispatch(get({ types: DATA, endpoint: '/api/admin/userlist', foundation: { entity: 'users' } })),
    ]).then(() => {
      return dispatch({ type: LOOKUP_DATA.resolve })
    })
  }
}

