import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

class RecentArticles extends Component {
  static propTypes = {
    sitemode: PropTypes.string,
  }

  static defaultProps = {
    sitemode: 'production',
  }

  render() {
    const { articles } = this.props
    let items = <li><p>Retrieving Articles...</p></li>
    try {
      if (articles.content.meta.status === 1) {
        items = articles.content.data.map((item, i) => {
          return <li key={i}>
            <a href={'/info/article/' + item.url_id} target="_blank" rel="noopener noreferrer">{item.title}</a>
            <small>{item.mc_social_card_title}</small>
          </li>
        })
      } else if (articles.content.meta.status > 1) {
        items = <li><p>Currently unavailable...<br />Check again later</p></li>
      }
    } catch (error) {
      items = <li><p>Unable to retrieve articles...<br />Try refreshing the page</p></li>
    }

    return (
      <div className="posts">
        <div className="headline"><h2>Recent Articles</h2></div>
        <ul className="list-unstyled latest-list">
          {items}
        </ul>
        <Link to="/info/articles"><i className="fa fa-chevron-circle-right"></i> More...</Link>
      </div>
    )
  }
}

export default RecentArticles
