import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { toggleClipboardItem, clearClipboardValues, enableAllClipboardItems, disableAllClipboardItems, inputChange } from 'rdx/modules/admin/clipboard'

// import ClipboardItem from './clipboard-item'
import FieldSmartSelect from './FieldSmartSelect'
import FusedFields from '../shared/fused-fields'

class ClipboardPanel extends Component {
  constructor(props) {
    super(props)
    this.state = { formErrors: null }
  }

  _handleToggleClipboardItem = (fieldname, event) => {
    this.props.toggleClipboardItem(fieldname)
  }

  _handleClearClipboardValues = (event) => {
    this.props.clearClipboardValues()
  }

  _handleActivateAllClipboardItems = (event) => {
    this.props.enableAllClipboardItems()
  }

  _handleDeactivateAllClipboardItems = (event) => {
    this.props.disableAllClipboardItems()
  }

  render() {
    return (
      <div className="owa-clipboard">
        <FusedFields data={this.props.clipboard.data} errors={this.state.formErrors} onUpdate={this.props.inputChange}>
        {this.props.clipboard.order.map((item, i) => {
          const info = this.props.clipboard.info[item]

          let fieldelement
          switch(info.displaytype) {
            case 'input':
              fieldelement = <input type="text" name={item} className="form-control" />
              break
            case 'select':
              fieldelement = <FieldSmartSelect
                name={item}
                options={this.props.lookupData[info.linkeddata]}
                valueField={info.linkedid}
                displayField={info.linkedval}
                standalone={true}
              />
              break
            case 'textarea':
              fieldelement = <textarea name={item} className="form-control" style={{height: '48px'}} />
              break
            default:
              fieldelement = <span>unspecified</span>
          }
          return (
            <div key={item} id={info.id} className={"input-group input-group-sm owa-clipboard-row owa-clipboard-item " + (info.active ? 'success' : 'danger')}>
              <span className="input-group-addon" onClick={this._handleToggleClipboardItem.bind(null, item)}>
                <i className={"fa fa-fw " + (info.active ? 'fa-check' : 'fa-ban')} />
              </span>
              <p className="form-control-static input-group-addon" style={{minWidth: '120px', textAlign: 'right'}}>
                {info.displayname}
              </p>
              {fieldelement}
            </div>
          )
        })}
        </FusedFields>
        <div className="input-group owa-clipboard-row owa-clipboard-options owa-info">
          <span className="input-group-addon"><i className="fa fa-fw fa-clipboard fa-2x" /></span>
          <button className="btn btn-default btn-u" type="button" onClick={this._handleActivateAllClipboardItems}><span className="fa fa-check" /> Activate All</button>
          <button className="btn btn-default btn-u" type="button" onClick={this._handleDeactivateAllClipboardItems}><span className="fa fa-ban" /> Deactivate All</button>
          <button className="btn btn-default btn-u" type="button" onClick={this._handleClearClipboardValues}><span className="fa fa-trash-o" /> Clear Clipboard</button>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    clipboard: state.admin.clipboard,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      toggleClipboardItem,
      clearClipboardValues,
      enableAllClipboardItems,
      disableAllClipboardItems,
      inputChange,
    }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClipboardPanel)