import { get } from 'lib/api'
import { status_defaults, validation_defaults, defineAction, buildError } from 'lib/state_defaults'
import { verify } from 'lib/validation'
import { meta_fields_auction } from 'lib/constants'

const base = 'owa/admin/auction/'

const DATA = defineAction(base, 'DATA', 'async')
const UPDATE_AUCTION = defineAction(base, 'UPDATE_AUCTION', 'async')

const FIELD_SET_VALUE = base.concat('FIELD_SET_VALUE')

const content_defaults = {
  originalData: {},
  data: {},
  prev: null,
  next: null,
  invalidInputs: [],
  validation: validation_defaults,
}

const actions_defaults = {
  update: status_defaults,
}

const initialState = {
  meta: status_defaults,
  content: content_defaults,
  actions: actions_defaults,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        meta: {
          ...status_defaults,
          processing: true,
        }
      }
    case DATA.fail:
      return {
        ...state,
        content: content_defaults,
        meta: {
          ...status_defaults,
          ...action.meta,
        }
      }
    case DATA.success:
    {
      return {
        ...state,
        content: {
          ...content_defaults,
          originalData: action.payload.data,
          data: action.payload.data,
          prev: action.payload.prev && action.payload.prev.id ? action.payload.prev.id : null,
          next: action.payload.next && action.payload.next.id ? action.payload.next.id : null,
        },
        meta: {
          ...status_defaults,
          ...action.meta,
        },
        ...(action.reset && { actions: actions_defaults }),
      }
    }
    case UPDATE_AUCTION.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          update: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case UPDATE_AUCTION.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          update: {
            ...status_defaults,
            ...action.meta,
          }
        },
        content: {
          ...state.content,
          validation: validation_defaults,
        }
      }
    case UPDATE_AUCTION.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          update: {
            ...status_defaults,
            ...action.meta,
          }
        },
        content: {
          ...state.content,
          validation: action.validation,
        }
      }
      case FIELD_SET_VALUE:
      {
        const invalidInputs = [...state.content.invalidInputs.filter(item => item !== action.field), ...(action.invalidElementValue ? [action.field] : [])]
        return {
          ...state,
          content: {
            ...state.content,
            data: {
              ...state.content.data,
              [action.field]: action.value,
            },
            invalidInputs: invalidInputs,
          }
        }
      }
    default:
      return state
  }
}

export function setFieldValue(field, value, options) {
  const invalidElementValue = (field === 'date_post_ending' && (options.valid === false)) ? true : false

  return {
    type: FIELD_SET_VALUE,
    field: field,
    value: value,
    invalidElementValue: invalidElementValue,
  }
}

export function getData(auction_id) {
  return (dispatch) => {
    return dispatch(
      get({
        types: DATA,
        endpoint: '/api/admin/auction/' + auction_id,
      })
    )
  }
}

export function updateAuction(auction_id) {
  return (dispatch, getState) => {
    const state = getState().admin.auction

    const data = state.content.data
    const originalData = state.content.originalData

    const errors_present = verify(meta_fields_auction, data, { invalidInputs: state.content.invalidInputs })

    if (errors_present) {
      return dispatch(
        buildError(UPDATE_AUCTION.fail, { errors_present: errors_present, foundation: { auction_id: auction_id } })
      )
    } else {
      return dispatch(
        get({
          types: UPDATE_AUCTION,
          endpoint: '/api/admin/auction/' + auction_id,
          config: {
            method: 'post',
            body: { new_data: data, original_data: originalData },
          },
        })
      )
    }
  }
}
