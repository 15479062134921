import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { getPurchase } from 'rdx/modules/purchase'

import StatusBox from '../shared/StatusBox'
import ContentPanel from '../shared/ContentPanel'

import { round } from 'lib/utility'
import { company_address_line1, company_address_line2 } from 'lib/constants'

CertAuth.propTypes = {
  params: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  getPurchase: PropTypes.func.isRequired,
}

CertAuth.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getPurchase(params.lot_id)),
  ])
}

function CertAuth({ params, data, meta, getPurchase }) {
  useEffect(() => {
    getPurchase(params.lot_id)
  }, [params.lot_id, getPurchase])

  let content = null

  if (meta.status !== 1) {
    content = <StatusBox data={meta} />
  } else {
    content = <div className="certauth-container">
      <div className="certauth-header">
        <div className="certauth-title">Certificate of Authenticity</div>
        <div className="certauth-subtitle">Old World Auctions</div>
      </div>
      <table className="certauth-table">
        <colgroup>
          <col style={{ width: '100px' }} />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td className="text-bold">Title:</td>
            <td className="text-bold">&ldquo;{data.desc_title}&rdquo;</td>
          </tr>
          <tr>
            <td colSpan="2" style={{height: '20px'}}></td>
          </tr>
          <tr>
            <td colSpan="2">
              <div className="certauth-image-container pull-right" style={{paddingRight: '20px'}}>
                <img src={__DYNAMIC_IMAGES_RELATIVE_BASE_URL__ + 'auctions/' + data.auction_id + '/lots/' + data.auction_id + '-' + String(data.lot_number).padStart(3, '0') + 'A.jpg'} alt="" />
              </div>
              <table className="certauth-attrib-table">
                <colgroup>
                  <col style={{ width: '100px' }} />
                  <col />
                </colgroup>
                <tbody>
                  {!!data.desc_subject && <tr>
                    <td className="text-bold">Subject:</td>
                    <td>{data.desc_subject}</td>
                  </tr>}
                  {!!data.creator && <tr>
                    <td className="text-bold">Creator:</td>
                    <td>{data.creator}</td>
                  </tr>}
                  {!!data.desc_period && <tr>
                    <td className="text-bold">Date:</td>
                    <td>{data.desc_period} {data.desc_datetype}</td>
                  </tr>}
                  {!!data.desc_color && <tr>
                    <td className="text-bold">Color:</td>
                    <td>{data.desc_color}</td>
                  </tr>}
                  {data.desc_width > 0 && <tr>
                    <td className="text-bold">Size:</td>
                    <td>{data.desc_width} x {data.desc_height} inches ({round(data.desc_width * 2.54, 1)} x {round(data.desc_height * 2.54, 1)} cm)</td>
                  </tr>}
                  {!!data.desc_publication && <tr>
                    <td className="text-bold">Source:</td>
                    <td>{data.desc_publication}</td>
                  </tr>}
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan="2" style={{height: '20px'}}></td>
          </tr>
          <tr>
            <td className="text-bold">Description:</td>
            <td style={{fontSize: '12px'}} dangerouslySetInnerHTML={{__html: data.desc_text}}></td>
          </tr>
          <tr>
            <td colSpan="2" style={{height: '20px'}}></td>
          </tr>
          {!!data.desc_condition && <tr>
            <td className="text-bold">Condition:</td>
            <td>{data.desc_condition}{!!data.desc_conditiontext && ' (' + data.desc_conditiontext + ')'}</td>
          </tr>}
          {!!data.desc_references && <tr>
            <td className="text-bold">References:</td>
            <td>{data.desc_references}</td>
          </tr>}
          <tr>
            <td className="text-bold">Auction/Lot:</td>
            <td>{data.auction_id}-{data.lot_number}</td>
          </tr>
        </tbody>
      </table>
      <div className="text-center margin-top-10">
        <div className="text-bold">Original Antique Certified by Old World Auctions</div>
        <div className="contact-info-bar margin-top-10">{"Old World Auctions, " + company_address_line1 + ', ' + company_address_line2} <span className="bullet">&bull;</span> 1-804-290-8090</div>
      </div>
    </div>
  }

  let container = <ContentPanel
    title="Certificate of Authenticity"
    icon="fa fa-certificate fa-fw"
    auxClassHeader="hidden-in-printview"
  >
    <div>
      {content}
    </div>
  </ContentPanel>

  return container
}

function mapStateToProps(state) {
  return {
    meta: state.purchase.content.meta,
    data: state.purchase.content.data,
  }
}

export default connect(mapStateToProps, {
  getPurchase,
})(CertAuth)