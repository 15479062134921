import React from 'react'
import PropTypes from 'prop-types'

Root.propTypes = {
  helmet: PropTypes.object.isRequired,
  content: PropTypes.string,
  initialState: PropTypes.object,
}

Root.defaultProps = {
  content: '',
}

function Root({ helmet, content, initialState }) {
  return <html>
    <head>
      {helmet.title.toComponent()}
      {helmet.meta.toComponent()}
      {/* Web Fonts */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link rel='preload' href='https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap' as='style' />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap" />

      {/* CSS Global Compulsory */}
      <link rel="stylesheet" type='text/css' href="/static/unify/plugins/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" type='text/css' href="/static/unify/css/style.css" />

      {/* CSS Header and Footer */}
      <link rel="stylesheet" type='text/css' href="/static/unify/css/headers/header-default.css" />
      <link rel="stylesheet" type='text/css' href="/static/unify/css/footers/footer-v1.css" />

      {/* CSS Implementing Plugins */}
      <link rel="stylesheet" type='text/css' href="/static/unify/plugins/animate.css" />
      <link rel="stylesheet" type='text/css' href="/static/unify/plugins/line-icons/line-icons.css" />
      <link rel="stylesheet" type='text/css' href="/static/unify/plugins/font-awesome/css/font-awesome.css" />

      {/* CSS Theme */}
      <link rel="stylesheet" type='text/css' href="/static/unify/css/theme-colors/dark-red.css" />

      {/* Slick-Carousel */}
      <link rel="stylesheet" type='text/css' href="/static/slick-carousel/slick.css" />
      <link rel="stylesheet" type='text/css' href="/static/slick-carousel/slick-theme.css" />
      {/* swiper */}
      <link rel="stylesheet" type='text/css' href="/static/swiper/swiper-bundle.min.css" />

      {/* CSS Customization */}
      <link rel="stylesheet" type='text/css' href="/static/css/menus.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/override.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/components.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/base.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/sidebar.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/dashboard.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/colors.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/animation.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/react-credit-cards-2.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/override-components.css" />

      {/* User Root Styles */}
      <link rel="stylesheet" type='text/css' href="/static/css/smartselect.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/tag-element.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/filters.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/modal.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/admin/admin.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/admin/filter-panel.css" />
      <link rel="stylesheet" type='text/css' href="/static/css/admin/clipboard-panel.css" />

      {/* Development-specific Styling */}
      {__GENERAL_SETTINGS__.indicateTestMode && <link rel="stylesheet" type='text/css' href="/static/css/testmode.css" />}
      {helmet.link.toComponent()}
    </head>
    <body>
      <div id='root' dangerouslySetInnerHTML={{ __html: content }} />
      {/* {this.renderEnvironment()} */}
      <script dangerouslySetInnerHTML={{ __html: `window.__ENVIRONMENT__ = '${__ENVIRONMENT__}'; window.__SITEMODE__ = '${__SITEMODE__}'` }} />
      {/* {this.renderInitialState()} */}
      {initialState && <script dangerouslySetInnerHTML={{ __html: `window.__INITIAL_STATE__ = ${JSON.stringify(initialState)}` }} />}
      {helmet.script.toComponent()}
      <script src={__WEBPACK_ASSETS__['frameworks'].js}></script>
      <script src={__WEBPACK_ASSETS__['common'].js}></script>
      <script src={__WEBPACK_ASSETS__['main'].js}></script>
      {__ENVIRONMENT__ === 'production' ? <script src={__WEBPACK_ASSETS__['runtime'].js}></script> : null}
    </body>
  </html>
}

export default Root
