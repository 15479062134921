import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import Modal from 'react-modal'
import classNames from 'classnames'

import { getSiteInfo } from 'rdx/modules/siteinfo'
import { getApplicableSlides } from 'rdx/modules/slides'
import { logout } from 'rdx/modules/auth/login'
import { getArticlesRecent } from 'rdx/modules/info/articles/recent'
import { toggleMenu } from 'rdx/modules/menus'

import {SiteView, modes} from './components/siteview-context'
import Header from "./components/Header"
import Footer from "./components/Footer"
import PageTitle from "./components/PageTitle"
import HeaderFixed from "./components/HeaderFixed"
import MobileMenuSite from "./components/MobileMenuSite"
import MobileMenuCatalog from "./components/MobileMenuCatalog"
import MobileMenuUser from "./components/MobileMenuUser"
import MobileMenuAdmin from "./components/MobileMenuAdmin"

import ConfirmContextProvider from "context/ConfirmContextProvider"

import Unify_App from '../../../resources/remote/static/unify/js/app'

import useSiteInfoRefresher from '../shared/hooks/useSiteInfoRefresher'

import { isClient } from 'lib/exenv'
import { filterObjectKeys } from 'lib/utility'

// import DiagnosticsPane from './components/diagnostics/DiagnosticsPane'

if (isClient) {
  Modal.setAppElement('#root')
}


App.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.object,
  routes: PropTypes.array.isRequired,
  siteinfo: PropTypes.object.isRequired,
  siteinfo_meta: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  articles: PropTypes.object.isRequired,
  getSiteInfo: PropTypes.func.isRequired,
  getApplicableSlides: PropTypes.func.isRequired,
  getArticlesRecent: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
}

App.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getSiteInfo()),
    dispatch(getApplicableSlides()),
    dispatch(getArticlesRecent())
  ])
}

function App({ children, siteinfo, siteinfo_meta, routes, location, params, credentials, articles, isAdmin, getSiteInfo, getApplicableSlides, getArticlesRecent, logout, toggleMenu }) {
  // we disable react-hooks/exhaustive-deps eslint rule because we only care about checking for referral parameter on initial app mount
  // we don't want this effect to re-run when the location changes
  useEffect(() => {
    console.log("App-mounted-effect")
    Unify_App.init()
    if (typeof location.query.referral !== 'undefined') {
      browserHistory.replace({ ...location, query: filterObjectKeys(location.query, ['referral']) })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useSiteInfoRefresher({ runOnInit: true })

  const getPageTitle = (routeID) => {
    if (routes[routeID].title && routes[routeID].title !== '') {
      return routes[routeID].title
    } else {
      if (routeID === 0) {
        return 'None'
      } else {
        return getPageTitle(routeID - 1)
      }
    }
  }

  if (siteinfo.timestamp === 0) {
    return <div>Loading App...</div>
  }

  const sitemode = isClient ? window.__SITEMODE__ : __SITEMODE__

  const title = getPageTitle(routes.length - 1)
  const show_big_header = Object.prototype.hasOwnProperty.call(routes[routes.length - 1], 'big_header') === true ? routes[routes.length - 1].big_header : true
  //const show_title = Object.prototype.hasOwnProperty.call(routes[routes.length - 1], 'show_title') === true ? routes[routes.length - 1].show_title : true
  const show_big_footer = Object.prototype.hasOwnProperty.call(routes[routes.length - 1], 'big_footer') === true ? routes[routes.length - 1].big_footer : true

  const sitemode_setting = typeof location.query.printview !== 'undefined' ? 'printview' : 'normal'
  const mode = modes[sitemode_setting]

  const page_wrap_classes = classNames('wrapper', {
    'no-header': !mode.show_header,
    'printview': sitemode_setting === 'printview',
  })

  return <SiteView.Provider value={mode}>
    <ConfirmContextProvider>
      <div id="outer-container">
        {mode.show_header && <HeaderFixed siteinfo={siteinfo} siteinfo_meta={siteinfo_meta} credentials={credentials} logout={logout} getSiteInfo={getSiteInfo} location={location} toggleMenu={toggleMenu} />}
        <MobileMenuSite credentials={credentials} logout={logout} />
        <MobileMenuCatalog siteinfo={siteinfo} location={location} params={params} logout={logout} />
        <MobileMenuUser credentials={credentials} />
        {isAdmin && <MobileMenuAdmin siteinfo={siteinfo} location={location} credentials={credentials} />}
        <div id="page-wrap" className={page_wrap_classes}>
          <Helmet titleTemplate='Old World Auctions - %s'>
            <meta charSet="utf-8" />
            <meta name="description" content="Featuring original antique maps, charts, atlases, engravings, prints and reference books" />
            <meta name="google" content="notranslate" />
            {/* <meta name="viewport" content="user-scalable=yes" /> */}
            {/* <meta name="viewport" content="width=640, initial-scale=1" /> */}
            {/* <meta name="viewport" content="width=device-width, initial-scale=1" /> */}
            {/* <meta name="viewport" content="width=device-width, initial-scale=0.86, maximum-scale=3.0, minimum-scale=0.86"></meta> */}
            <meta name="viewport" content="width=device-width, maximum-scale=3.0, minimum-scale=1"></meta>
            <title>Old World Auctions</title>
          </Helmet>
          {mode.show_header && <Header mode_big={show_big_header} siteinfo={siteinfo} siteinfo_meta={siteinfo_meta} credentials={credentials} sitemode={sitemode} logout={logout} getSiteInfo={getSiteInfo} />}

          {/* <DiagnosticsPane siteinfo={siteinfo} credentials={credentials} routes={routes} location={location} params={params} /> */}

          {/* {'base_canonical: ' + base_canonical}<br/>
          <Link to={location.pathname}>Test Canonical Link</Link> */}

          {/* {show_title === true ? <PageTitle title={title} /> : ''} */}
          {/*{children && React.cloneElement(this.props.children, {setTitle: this.handleTitleUpdate})}*/}
          <div>
            {children}
          </div>
          {mode.show_footer && <Footer mode_big={show_big_footer} articles={articles} />}
        </div>
      </div>
    </ConfirmContextProvider>
  </SiteView.Provider>
}

function mapStateToProps(state) {
  return {
    siteinfo: state.siteinfo.data,
    siteinfo_meta: state.siteinfo.meta,
    credentials: state.auth.login.data,
    isConsignor: state.auth.login.data.userInfo.consignor,
    isAdmin: state.auth.login.data.userInfo.role === 9,
    articles: state.info.articles.recent,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getSiteInfo, getApplicableSlides, getArticlesRecent, logout, toggleMenu }, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App)