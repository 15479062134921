import qs from 'qs'

import { processQuery, get } from 'lib/api'
import { defineAction, status_defaults, validation_defaults, buildError } from 'lib/state_defaults'
import { verify } from 'lib/validation'

const base = 'owa/admin/users/'

const DATA = defineAction(base, 'DATA', 'async')

const DATA_USER = defineAction(base, 'DATA_USER', 'async', { customActions: ['close', 'makeConsignor'] })
const GENERATE_CONSIGNOR_CODE = defineAction(base, 'GENERATE_CONSIGNOR_CODE', 'async')
const ADD_USER = defineAction(base, 'ADD', 'async')
const UPDATE_USER = defineAction(base, 'UPDATE', 'async')

const SELECT_TAB =   base.concat('SELECT_TAB')
const FIELD_UPDATE =   base.concat('FIELD_UPDATE')
const LOCATION_SAVE = base.concat('LOCATION_SAVE')

const info_defaults = {
  pageSize: 20,
  count: 0,
}

const user_detail_defaults = {
  originalData: {},
  data: {},
  meta: status_defaults,
  makingConsignor: false,
  update: status_defaults,
  generateConsignorCode: status_defaults,
  validation: validation_defaults,
}

const initialState = {
  list: {
    data: [],
    info: info_defaults,
    meta: status_defaults,
    location: null,
  },
  user: {
    new: user_detail_defaults,
  },
  user_order: [],
  selectedTab: "list",
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case DATA.success:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          info: {
            ...state.list.info,
            count: action.payload.count,
          },
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA.fail:
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA_USER.request:
      return {
        ...state,
        user: {
          ...state.user,
          [action.foundation.id]: {
            ...user_detail_defaults,
            ...state.user[action.foundation.id],
            data: {
              id: action.foundation.id,
              user_firstname: action.foundation.user_firstname,
              user_lastname: action.foundation.user_lastname,
              ...(state.user[action.foundation.id] && { ...state.user[action.foundation.id].data }),
            },
            originalData: {
              id: action.foundation.id,
              user_firstname: action.foundation.user_firstname,
              user_lastname: action.foundation.user_lastname,
              ...(state.user[action.foundation.id] && { ...state.user[action.foundation.id].originalData }),
            },
            meta: {
              ...status_defaults,
              processing: true,
            },
            ...action.foundation.newborn && {
              update: {
                ...status_defaults,
                status: 1,
                statusText: 'User added successfully',
              }
            }
          }
        },
        user_order: [ ...state.user_order, action.foundation.id ],
      }
    case DATA_USER.success:
      return {
        ...state,
        user: {
          ...state.user,
          [action.foundation.id]: {
            ...state.user[action.foundation.id],
            originalData: action.payload.data,
            data: action.payload.data,
            meta: {
              ...status_defaults,
              ...action.meta,
            }
          }
        },
        selectedTab: action.foundation.id,
      }
    case DATA_USER.fail:
      return {
        ...state,
        user: {
          ...state.user,
          [action.foundation.id]: {
            ...state.user[action.foundation.id],
            meta: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
    case DATA_USER.close:
    {
      const { [action.id]: _, ...remaining } = state.user
      return {
        ...state,
        user: remaining,
        user_order: state.user_order.filter(item => item !== action.id),
        selectedTab: state.selectedTab === action.id ? "list" : state.selectedTab,
      }
    }
    case DATA_USER.makeConsignor:
      return {
        ...state,
        user: {
          ...state.user,
          [action.id]: {
            ...state.user[action.id],
            data: {
              ...state.user[action.id].data,
              consignor_status: 1,
            },
            makingConsignor: true,
          }
        }
      }
    case ADD_USER.request:
      return {
        ...state,
        user: {
          ...state.user,
          new: {
            ...state.user.new,
            update: {
              ...status_defaults,
              processing: true,
            },
            validation: validation_defaults,
          }
        }
      }
    case ADD_USER.success:
      return {
        ...state,
        user: {
          ...state.user,
          new: {
            ...state.user.new,
            update: {
              ...status_defaults,
              ...action.meta,
            },
            makingConsignor: false,
          }
        }
      }
    case ADD_USER.fail:
      return {
        ...state,
        user: {
          ...state.user,
          new: {
            ...state.user.new,
            update: {
              ...status_defaults,
              ...action.meta,
            },
            validation: action.validation ? action.validation : validation_defaults,
          }
        }
      }
    case ADD_USER.reset:
      return {
        ...state,
        user: {
          ...state.user,
          new: user_detail_defaults,
        }
      }
    case UPDATE_USER.request:
      return {
        ...state,
        user: {
          ...state.user,
          [action.foundation.id]: {
            ...state.user[action.foundation.id],
            update: {
              ...status_defaults,
              processing: true,
            },
            validation: validation_defaults,
          }
        }
      }
    case UPDATE_USER.success:
      return {
        ...state,
        user: {
          ...state.user,
          [action.foundation.id]: {
            ...state.user[action.foundation.id],
            originalData: state.user[action.foundation.id].data,
            update: {
              ...status_defaults,
              ...action.meta,
            },
            makingConsignor: false,
          }
        }
      }
    case UPDATE_USER.fail:
      return {
        ...state,
        user: {
          ...state.user,
          [action.foundation.id]: {
            ...state.user[action.foundation.id],
            update: {
              ...status_defaults,
              ...action.meta,
            },
            validation: action.validation,
          }
        }
      }
    case GENERATE_CONSIGNOR_CODE.request:
      return {
        ...state,
        user: {
          ...state.user,
          [action.foundation.id]: {
            ...state.user[action.foundation.id],
            generateConsignorCode: {
              ...status_defaults,
              processing: true,
            }
          }
        }
      }
    case GENERATE_CONSIGNOR_CODE.success:
      return {
        ...state,
        user: {
          ...state.user,
          [action.foundation.id]: {
            ...state.user[action.foundation.id],
            data: {
              ...state.user[action.foundation.id].data,
              consignor_code: action.payload.consignor_code,
            },
            generateConsignorCode: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
    case GENERATE_CONSIGNOR_CODE.fail:
      return {
        ...state,
        user: {
          ...state.user,
          [action.foundation.id]: {
            ...state.user[action.foundation.id],
            generateConsignorCode: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
    case SELECT_TAB:
      return {
        ...state,
        selectedTab: action.key,
      }
    case FIELD_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          [action.key]: {
            ...state.user[action.key],
            data: {
              ...state.user[action.key].data,
              [action.field]: action.value,
            },
          }
        }
      }
    case LOCATION_SAVE:
      return {
        ...state,
        list: {
          ...state.list,
          location: action.location,
        }
      }
    default:
      return state
  }
}

export function fieldUpdate(field, value, key='new') {
  return { type: FIELD_UPDATE, key, field, value }
}

export function selectTab(key) {
  return {
    type: SELECT_TAB,
    key: key,
  }
}

export function closeUser(id) {
  return {
    type: DATA_USER.close,
    id: id,
  }
}

export function makeConsignor(id) {
  return {
    type: DATA_USER.makeConsignor,
    id: id ? id : 'new',
  }
}

export function getData(location) {
  return (dispatch, getState) => {
    const state = getState()
    const { zops={}, ...remainingQuery } = location.query

    dispatch({ type: LOCATION_SAVE, location: location })

    const querydata = processQuery(remainingQuery, { whitelist: [], pageSize: state.admin.users.list.info.pageSize })
    const endpoint = '/api/admin/users' + querydata

    const o = qs.parse(zops)

    return dispatch(
      get({
        types: DATA,
        endpoint: endpoint,
        config: {
          method: 'post',
          body: o,
        },
      })
    )
  }
}

export function getUser(id, user_firstname, user_lastname, { newborn=false }={}) {
  return dispatch => {
    return dispatch(
      get({
        types: DATA_USER,
        endpoint: '/api/admin/users/' + id,
        foundation: { id, user_firstname, user_lastname, newborn },
      })
    )
  }
}

export function saveUser(id, { isNew=false }={}) {
  return (dispatch, getState) => {
    const state = getState()

    const data = isNew ? state.admin.users.user.new.data : state.admin.users.user[id].data
    const originalData = isNew ? {} : state.admin.users.user[id].originalData
    const endpoint = isNew ? '/api/admin/users/add' : '/api/admin/users/' + id
    const types = isNew ? ADD_USER : UPDATE_USER

    const required_fields = ['user_firstname', 'user_lastname', 'user_username', 'user_password', 'user_email']
    if (data.consignor_status) { required_fields.push('consignor_code') }

    const errors_present = verify({
      required: required_fields,
      format: {},
    }, data)

    if (errors_present) {
      return dispatch(
        buildError(types.fail, { errors_present: errors_present, foundation: { id: id } })
      )
    } else {
      return dispatch(
        get({
          types: types,
          endpoint: endpoint,
          config: {
            method: 'post',
            body: { new_data: data, original_data: originalData },
          },
          foundation: { id: id },
          ...isNew && { postSuccess: (json) => {
            dispatch(getUser(json.payload.id, data.user_firstname, data.user_lastname, { newborn: true }))
            dispatch({ type: ADD_USER.reset })
            dispatch(getData(state.admin.users.list.location))
          } },
          ...!isNew && { postSuccess: (json) => {
            dispatch(getData(state.admin.users.list.location))
          } }
        })
      )
    }
  }
}

export function generateConsignorCode(id) {
  return (dispatch, getState) => {
    const actual_id = id ? id : 'new'
    const data = getState().admin.users.user[actual_id].data
    const lname = data.user_lastname.trim()
    const fname = data.user_firstname.trim()
    if (lname && fname) {
      const user_initials = lname.charAt(0) + fname.charAt(0)

      return dispatch(
        get({
          types: GENERATE_CONSIGNOR_CODE,
          endpoint: '/api/admin/consignor-code-available/' + user_initials,
          foundation: { id: actual_id },
        })
      )
    } else {
      return dispatch({
        type: GENERATE_CONSIGNOR_CODE.fail,
        meta: { statusText: 'Missing first/last name' }
      })
    }
  }
}
