import {createContext} from 'react'

export const modes = {
  normal: {
    id: 'normal',
    classPrefix: 'basic',
    show_header: true,
    show_footer: true,
    show_sidebar: true,
    wrap_content: true,
  },
  printview: {
    id: 'printview',
    classPrefix: 'printview',
    show_header: false,
    show_footer: false,
    show_sidebar: false,
    wrap_content: false,
  },
}

export const SiteView = createContext(
  modes.normal // default value
)