import { processQuery, get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

const base = 'owa/info/articles/archive/'

const DATA = defineAction(base, 'DATA', 'async')
const LOCATION_SAVE = base.concat('LOCATION_SAVE')

const initialState = {
  content: {
    data: [],
    meta: status_defaults,
    info: {
      location: {},
      params: {},
      pageSize: 20,
      count: 0,
    },
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        content: {
          ...state.content,
          meta: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case DATA.success:
      return {
        ...state,
        content: {
          ...state.content,
          data: action.payload.data,
          meta: {
            ...status_defaults,
            ...action.meta,
          },
          info: {
            ...state.content.info,
            count: action.payload.count,
          },
        }
      }
    case DATA.fail:
      return {
        ...state,
        content: {
          ...state.content,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
      case LOCATION_SAVE:
      {
        return {
          ...state,
          content: {
            ...state.content,
            info: {
              ...state.content.info,
              location: action.location,
              params: action.params,
            }
          }
        }
      }
    default:
      return state
  }
}

export function getArticles(location, params) {
  return (dispatch, getState) => {
    const state = getState()
    const query = location.query
    const category = params.category ? params.category : null

    dispatch({ type: LOCATION_SAVE, location: location, params: params })

    // add category data to query so we can pass all parameters to api via get request
    const combined_query = { ...query, ...category && {category} }

    const querydata = processQuery(combined_query, { whitelist: ['universal_search', 'category'], pageSize: state.info.articles.archive.content.info.pageSize })
    console.log('getArticles-querydata:', querydata)
    const endpoint = '/api/public/info/articles/archive'

    return dispatch(
      get({
        types: DATA,
        endpoint: endpoint + querydata,
      })
    )
  }
}
