import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { Link } from 'react-router'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { inputChange, placeBid, bidConfirm, getUserData, toggleWatch } from 'rdx/modules/auction'

import DataListContainer from '../shared/DataListContainer'
import DataListBody from '../shared/DataListBody'

import StatusItem from '../shared/StatusItem'
import BidTotals from './components/BidTotals'
import BidResult from '../shared/BidResult'
import LotCard from '../shared/LotCard'
import ContentPanel from '../shared/ContentPanel'
import useRefreshTimer from '../shared/hooks/useRefreshTimer'
import useMedia from '../shared/hooks/useMedia'

import { auctionStatusRefreshInterval, bid_extension_time, query_mobile_view, modalStyles, user_bidstatus_table_definitions, user_watchlist_table_definitions } from 'lib/constants'

StatusUser.propTypes = {
  location: PropTypes.object.isRequired,
  siteinfo: PropTypes.object.isRequired,
  auctionData: PropTypes.object.isRequired,
  auctionAuthorizationLevel: PropTypes.number.isRequired,
  inputChange: PropTypes.func.isRequired,
  placeBid: PropTypes.func.isRequired,
  bidConfirm: PropTypes.func.isRequired,
  getUserData: PropTypes.func.isRequired,
  toggleWatch: PropTypes.func.isRequired,
}

StatusUser.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getUserData(location.query)),
  ])
}

function StatusUser({ location, siteinfo, auctionData, auctionAuthorizationLevel, inputChange, placeBid, bidConfirm, getUserData, toggleWatch }) {
  const [showPopup, setShowPopup] = useState(false)
  const mobileView = useMedia(query_mobile_view)
  const biddingDisabled = typeof auctionAuthorizationLevel === 'undefined' || (auctionAuthorizationLevel < siteinfo.authorization_level_required)

  const refreshData = () => {
    getUserData(location.query)
  }

  const { elapsedSeconds, secondsTillRefresh } = useRefreshTimer(refreshData, auctionData.bids.meta, { deps: [location.query], runOnInit: true, refreshInterval: auctionStatusRefreshInterval, accelerate: auctionData.watchlist_promoting })

  const _placeBid = (data) => {
    placeBid(data, location.query)
    setShowPopup(true)
  }

  // ZZZ - look into below form to potentially prevent recreating event functions every render
  // const _placeBid = useCallback((data) => {
  //   placeBid(data, location.query)
  //   setShowPopup(true)
  // }, [location.query])

  const _bidConfirm = () => {
    bidConfirm(location.query)
  }

  const _unwatchLot = (data) => {
    toggleWatch({ ...data, watch: 0, source: 0, query: location.query })
  }

  const view = mobileView ? 'mobile' : 'standard'

  if (auctionData.bidorder == []) {
    return <div>Loading...</div>
  }

  const auction_mode = siteinfo.auction_mode
  const buyerPremiumInfo = siteinfo.buyerPremiumInfo

  const bidstatus_color = '#555'
  const watchlist_color = '#3498db'

  const lots_active = []
  const lots_unsold = []
  const lots_closed = []
  auctionData.bidorder.map((lot_number, i) => {
    const lot = auctionData.bids.data[lot_number]
    if (auction_mode === 1 || (auction_mode === 2 && lot.time_since_lastbid !== null && lot.time_since_lastbid < bid_extension_time && lot.status_closed !== 1)) {
      lots_active.push(lot_number)
    } else if (!lot.reserve_met) {
      lots_unsold.push(lot_number)
    } else {
      lots_closed.push(lot_number)
    }
  })

  const watchlist_active = []
  const watchlist_unsold = []
  const watchlist_closed = []
  auctionData.watchlistorder.map((lot_number, i) => {
    const lot = auctionData.watchlist.data[lot_number]
    if (lot && lot.visible && typeof auctionData.bids.data[lot_number] === "undefined") {
      if (auction_mode === 1 || (auction_mode === 2 && lot.time_since_lastbid !== null && lot.time_since_lastbid < bid_extension_time && lot.status_closed !== 1)) {
        watchlist_active.push(lot_number)
      } else if (!lot.reserve_met || lot.promoting) {
        watchlist_unsold.push(lot_number)
      } else {
        watchlist_closed.push(lot_number)
      }
    }
  })

  const refresh_text = secondsTillRefresh > 0 ? "(refreshing in " + secondsTillRefresh + " seconds)" : auctionData.bids.meta.status <= 1 ? "(attempting to refresh...)" : null
  const problem_bids = (elapsedSeconds > (auctionStatusRefreshInterval + 5) && auctionData.bids.meta.status !== 1) || auctionData.bids.meta.status > 1 ? <span style={{color: 'orange', paddingLeft: '5px'}}><i className="fa fa-fw fa-exclamation-circle"></i>There is a problem.  Try reloading the page ({elapsedSeconds} seconds since last refresh)</span> : null
  const problem_watchlist = (elapsedSeconds > (auctionStatusRefreshInterval + 5) && auctionData.watchlist.meta.status !== 1) || auctionData.watchlist.meta.status > 1 ? <span style={{color: 'orange', paddingLeft: '5px'}}><i className="fa fa-fw fa-exclamation-circle"></i></span> : null

  //used for testing when polled refresh is disabled
  //const button_refresh = <button className="btn btn-warning btn-xs" onClick={() => refreshData()}><i className="fa fa-bolt"></i> Refresh</button>

  const bid_legend = <div className="bidstatus-legend">
    <div className="bidstatus-legend-item"><span className="highbidder"><span className="bullet">&bull;</span> High Bidder Reserve Met</span></div>
    <div className="bidstatus-legend-item"><span className="reservenotmet"><span className="bullet">&bull;</span> High Bidder Under Reserve</span></div>
    <div className="bidstatus-legend-item"><span className="nothighbidder"><span className="bullet">&bull;</span> Not High Bidder</span></div>
    <div className="bidstatus-legend-item"><span className="">*Reserve Not Met</span></div>
  </div>

  const watchlist_legend = <div className="bidstatus-legend">
    <div className="bidstatus-legend-item"><span className="">*Reserve Not Met</span></div>
  </div>

  const header_unsold_lots_text = <div><i className="fa fa-fw fa-minus-square margin-right-5" style={{ color: 'orange' }} aria-hidden="true"></i>Unsold Lots</div>
  const header_closed_lots_text = <div><i className="fa fa-fw fa-circle margin-right-5" style={{ color: 'red' }} aria-hidden="true"></i>Closed Lots</div>

  const header_unsold_lots_bids = mobileView ? <div className="base-header section-header">{header_unsold_lots_text}</div> : <tbody><tr><td colSpan={user_bidstatus_table_definitions.info.column_count[auction_mode]} style={{ backgroundColor: bidstatus_color, color: '#FFF' }}>{header_unsold_lots_text}</td></tr></tbody>
  const header_closed_lots_bids = mobileView ? <div className="base-header section-header">{header_closed_lots_text}</div> : <tbody><tr><td colSpan={user_bidstatus_table_definitions.info.column_count[auction_mode]} style={{ backgroundColor: bidstatus_color, color: '#FFF' }}>{header_closed_lots_text}</td></tr></tbody>
  const header_unsold_lots_watchlist = mobileView ? <div className="base-header background-blue section-header">{header_unsold_lots_text}</div> : <tbody><tr><td colSpan={user_watchlist_table_definitions.info.column_count[auction_mode]} style={{ backgroundColor: watchlist_color, color: '#FFF' }}>{header_unsold_lots_text}</td></tr></tbody>
  const header_closed_lots_watchlist = mobileView ? <div className="base-header background-blue section-header">{header_closed_lots_text}</div> : <tbody><tr><td colSpan={user_watchlist_table_definitions.info.column_count[auction_mode]} style={{ backgroundColor: watchlist_color, color: '#FFF' }}>{header_closed_lots_text}</td></tr></tbody>

  const bids = <ContentPanel
    title={'Bid Status'}
    titleSuffix={refresh_text}
    titleAuxElement={problem_bids}
    icon="fa fa-star fa-fw"
    auxClassContent="zero-padding no-mobile-background"
    footerElement={!mobileView ? <BidTotals mode="user" order={auctionData.bidorder} data={auctionData.bids.data} /> : null}
  >
    <div className="base-section">
      {!mobileView && bid_legend}
      <DataListContainer
        key={location.query.sort_direction_bids} // ZZZ - hack to force all css animation highlights on every sort change
        useTable={!mobileView}
        tableClass='table table-striped datalist-table-status table-text-vertical-center'
        showHeader={true}
        headerColumnVisibleKey={{auction_mode, biddingDisabled}}
        showTopPagingControl={false}
        showBottomPagingControl={false}
        metadata={user_bidstatus_table_definitions}
        totalItemCount={auctionData.bidorder.length}
        pageSize={0}
        location={location}
        sortKey='bids'
      >
        <DataListBody
          dataIndex={lots_active}
          data={auctionData.bids.data}
          datakey='lot_number'
          useTable={!mobileView}
          className="margin-top-10"
        >
          {<LotCard nature={'bid_status'} view={view} auction_mode={auction_mode} biddingDisabled={biddingDisabled} buyerPremiumInfo={buyerPremiumInfo} elapsedSeconds={elapsedSeconds} bid_extension_time={bid_extension_time} inputChange={inputChange} placeBid={_placeBid} />}
        </DataListBody>
        {lots_unsold.length > 0 ? header_unsold_lots_bids : null}
        {lots_unsold.length > 0 ?
          <DataListBody
            dataIndex={lots_unsold}
            data={auctionData.bids.data}
            datakey='lot_number'
            useTable={!mobileView}
            className="margin-top-10"
          >
            {<LotCard nature={'bid_status'} view={view} auction_mode={auction_mode} biddingDisabled={biddingDisabled} buyerPremiumInfo={buyerPremiumInfo} elapsedSeconds={elapsedSeconds} bid_extension_time={bid_extension_time} inputChange={inputChange} placeBid={_placeBid} />}
          </DataListBody>
        : null}
        {lots_closed.length > 0 ? header_closed_lots_bids : null}
        {lots_closed.length > 0 ?
          <DataListBody
            dataIndex={lots_closed}
            data={auctionData.bids.data}
            datakey='lot_number'
            useTable={!mobileView}
            className="margin-top-10 margin-bottom-20"
          >
            {<LotCard nature={'bid_status'} view={view} auction_mode={auction_mode} biddingDisabled={biddingDisabled} buyerPremiumInfo={buyerPremiumInfo} elapsedSeconds={elapsedSeconds} bid_extension_time={bid_extension_time} inputChange={inputChange} placeBid={_placeBid} />}
          </DataListBody>
        : null}
      </DataListContainer>
    </div>
  </ContentPanel>

  const watchlist = <ContentPanel
    title={'Watch List'}
    titleAuxElement={problem_watchlist}
    icon="fa fa-eye fa-fw"
    auxClassHeader="background-blue"
    auxClassContent="zero-padding no-mobile-background"
    showPrintView={false}
  >
    <div className="base-section">
      {!mobileView && watchlist_legend}
      <DataListContainer
        key={location.query.sort_direction_watchlist} // ZZZ - hack to force all css animation highlights on every sort change
        useTable={!mobileView}
        tableClass='table table-striped datalist-table-status table-text-vertical-center'
        showHeader={true}
        headerColumnVisibleKey={{auction_mode, biddingDisabled}}
        showTopPagingControl={false}
        showBottomPagingControl={false}
        metadata={user_watchlist_table_definitions}
        totalItemCount={auctionData.watchlistorder.length}
        pageSize={0}
        location={location}
        sortKey='watchlist'
      >
        <DataListBody
          dataIndex={watchlist_active}
          data={auctionData.watchlist.data}
          datakey='lot_number'
          useTable={!mobileView}
          className="margin-top-10"
        >
          {<LotCard nature={'watchlist'} view={view} auction_mode={auction_mode} biddingDisabled={biddingDisabled} buyerPremiumInfo={buyerPremiumInfo} elapsedSeconds={elapsedSeconds} bid_extension_time={bid_extension_time} inputChange={inputChange} placeBid={_placeBid} unwatch={_unwatchLot} />}
        </DataListBody>
        {watchlist_unsold.length > 0 ? header_unsold_lots_watchlist : null}
        {watchlist_unsold.length > 0 ?
          <DataListBody
            dataIndex={watchlist_unsold}
            data={auctionData.watchlist.data}
            datakey='lot_number'
            useTable={!mobileView}
            className="margin-top-10"
          >
            {<LotCard nature={'watchlist'} view={view} auction_mode={auction_mode} biddingDisabled={biddingDisabled} buyerPremiumInfo={buyerPremiumInfo} elapsedSeconds={elapsedSeconds} bid_extension_time={bid_extension_time} inputChange={inputChange} placeBid={_placeBid} unwatch={_unwatchLot} />}
          </DataListBody>
        : null}
        {watchlist_closed.length > 0 ? header_closed_lots_watchlist : null}
        {watchlist_closed.length > 0 ?
          <DataListBody
            dataIndex={watchlist_closed}
            data={auctionData.watchlist.data}
            datakey='lot_number'
            useTable={!mobileView}
            className="margin-top-10"
          >
            {<LotCard nature={'watchlist'} view={view} auction_mode={auction_mode} biddingDisabled={biddingDisabled} buyerPremiumInfo={buyerPremiumInfo} elapsedSeconds={elapsedSeconds} bid_extension_time={bid_extension_time} inputChange={inputChange} placeBid={_placeBid} unwatch={_unwatchLot} />}
          </DataListBody>
        : null}
      </DataListContainer>
    </div>
  </ContentPanel>

  let container = <div className="owa-content">
    {biddingDisabled && <StatusItem data={{ status: 4, statusText: 'This auction requires authorization to participate'}} childContent={<Link to={'/resources/bidder-approval'}>Get approval to bid in this auction <i className="fa-fw fa fa-arrow-right" aria-hidden="true"></i></Link>} className="margin-bottom-20" persist={true} />}
    {(auction_mode === 0) && <div style={{paddingTop: '30px', paddingBottom: '100px', fontSize: '16px', textAlign: 'center'}}>
      Auction {siteinfo.current_auction} will be starting soon
      <br />
      To review past purchases, view your <Link to={'/user/purchase-history'}>Purchase History</Link> page
      <br /><br />
      In the meantime, you may <Link to={'/catalog/archive'}>browse the auction archive</Link> to see past auction lots
    </div>}
    {siteinfo.liveAuctionInProgress && <div style={{paddingTop: '30px', paddingBottom: '0', fontSize: '16px', textAlign: 'center'}}>
      Auction {siteinfo.current_auction} is closing now
      <br />
      <Link to={'/live-auction'}>Enter the Live Auction</Link>
      <br /><br />
      Once the auction closes you can return to this page to see your personal bidding info
    </div>}
    {(auction_mode > 0 && !siteinfo.liveAuctionInProgress) && bids}
    {(auction_mode > 0 && !siteinfo.liveAuctionInProgress) && watchlist}
    {/* siteinfo.authorization_level_required: {siteinfo.authorization_level_required}<br />
    auctionAuthorizationLevel: {auctionAuthorizationLevel} */}
    <Modal
      closeTimeoutMS={150}
      isOpen={showPopup}
      onRequestClose={() => {}}
      style={modalStyles}
      contentLabel="Bid Window" >
      <BidResult
        potentialBid={auctionData.potentialBid}
        confirmAction={_bidConfirm}
        closeAction={() => setShowPopup(false)}
      />
    </Modal>
  </div>

  return <div>
    {container}
  </div>
}

function mapStateToProps(state) {
  return {
    siteinfo: state.siteinfo.data,
    auctionData: state.auction,
    auctionAuthorizationLevel: state.auth.login.data.userInfo.auctionAuthorizationLevel,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ inputChange, placeBid, bidConfirm, getUserData, toggleWatch }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusUser)
