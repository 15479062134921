import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import StatusItem from './StatusItem'

// valid response format options are:
// header: displays primary result first, then any child results
// footer: displays any child results first, then the primary result
// wrapper: displays any child results inside the primary result
// children: displays only child results, but also shows the primary processing message
// hidden: displays only child results data (primary result not shown)

StatusBox.propTypes = {
  data: PropTypes.object.isRequired,
  format: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.string,
  mainClassName: PropTypes.string,
  mainStyle: PropTypes.object,
  childSize: PropTypes.string,
  childClassName: PropTypes.string,
  childStyle: PropTypes.object,
  hideUntilDefined: PropTypes.bool,
  hideOnSuccess: PropTypes.bool,
  hideUntilError: PropTypes.bool,
  persist: PropTypes.bool,
  allowClose: PropTypes.bool,
  animationTime: PropTypes.number,
  displayTime: PropTypes.number,
  auxContentProcessing: PropTypes.element,
  auxContentSuccess: PropTypes.element,
  auxContentFail: PropTypes.element,
}

StatusBox.defaultProps = {
  format: 'header',
  className: '',
  style: {},
  size: 'small',
  mainClassName: '',
  mainStyle: {},
  childSize: 'small',
  childClassName: '',
  childStyle: {},
  hideUntilDefined: true,
  hideOnSuccess: false,
  hideUntilError: false,
  persist: false,
  allowClose: false,
  animationTime: 500,
  displayTime: 10000,
}

function StatusBox({ data, format, className, style, size, mainClassName, mainStyle, childSize, childClassName, childStyle, hideUntilDefined, hideOnSuccess, hideUntilError, persist, allowClose, animationTime, displayTime, auxContentProcessing, auxContentSuccess, auxContentFail }) {
  let content
  const { results, ...main } = data
  const defaultSettings = { hideUntilDefined, hideOnSuccess, hideUntilError, persist, allowClose, animationTime, displayTime }
  const childContent = results ? results.map((result) => {
    return <StatusItem key={result.id} data={result} size={childSize} className={childClassName} style={childStyle} {...defaultSettings} />
  }) : null
  const mainContent = format !== 'hidden' ? <StatusItem data={main} size={size} className={mainClassName} style={mainStyle} childContent={ format === 'wrapper' ? childContent : null } {...defaultSettings} /> : null
  switch (format) {
    case 'wrapper':
      content = mainContent
      break
    case 'header':
      content = <>
        {mainContent}
        {childContent}
      </>
      break
    case 'footer':
      content = <>
        {childContent}
        {mainContent}
      </>
      break
    case 'children':
      content = <>
        {main.processing && mainContent}
        {childContent}
      </>
      break
    default: //hidden main
      content = childContent
  }

  const auxContent = main.processing ? auxContentProcessing : (main.status === 1 && auxContentSuccess ? auxContentSuccess : (main.status === 2 && auxContentFail ? auxContentFail : null))

  return <div className={classNames('status-container', className)} style={style} >
    {content}
    {auxContent}
  </div>
}

export default StatusBox