import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getLotIndex } from 'rdx/modules/info/sitemap'

import DataList from '../../shared/DataList'
import DataListRow from '../../shared/DataListRow'
import StatusBox from '../../shared/StatusBox'

import { lotIndex_specs } from 'lib/list_specifications'

import connectDataList from '../../shared/composers/connectDataList'

const DataListBound = connectDataList(DataList, state => ({ statebranch: state.info.sitemap.content.lots }))

LotIndex.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  lots: PropTypes.object.isRequired,
  getLotIndex: PropTypes.func.isRequired,
}

LotIndex.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getLotIndex(params)),
  ])
}

function LotIndex({ location, params, lots, getLotIndex }) {
  useEffect(() => {
    getLotIndex(params)
  }, [params, getLotIndex])

  let content = null

  const title = 'Index of Lots for Auction ' + params.auctionNumber

  if (lots.meta.status > 1) {
    content = <StatusBox data={lots.meta} />
  } else {
    content = <DataListBound
      useTable={true}
      showHeader={true}
      showTopPagingControl={false}
      showBottomPagingControl={false}
      metadata={lotIndex_specs}
      datakey='id'
      anchorTemplate='/catalog/lot/<param1>/<param2>'
      anchorParam1='auction_id'
      anchorParam2='lot_number'
      params={params}
      location={location}
    >
      <DataListRow />
    </DataListBound>
  }

  return <div>
    <Helmet>
      <title>{title}</title>
      {/* <link rel="canonical" href={"/catalog/archive?auction_id=" + params.auctionNumber} /> */}
    </Helmet>
    <div className="titlebar heading heading-v1 text-center">
      <h2><i className="fa fa-calendar fa-fw"></i> {title}</h2>
    </div>
    <div className="text-center" style={{ marginBottom: '20px', borderTop: '0px solid #ccc' }}>
      <h4 className="">This index is a static list of all lots in Auction {params.auctionNumber}</h4>
      <h4 className="">For a more interactive experience, <Link to={"/catalog/archive?auction_id=" + params.auctionNumber}>click here to view these lots in the searchable catalog</Link></h4>
    </div>
    {content}
  </div>
}

function mapStateToProps(state) {
  return {
    lots: state.info.sitemap.content.lots,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getLotIndex }, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LotIndex)