import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import classNames from 'classnames'
import Media from "react-media"

import Navbar from './Navbar'
import AuctionStatus from './AuctionStatus'

import { query_mobile_view } from 'lib/constants'

Header.propTypes = {
  siteinfo: PropTypes.object.isRequired,
  siteinfo_meta: PropTypes.object.isRequired,
  mode_big: PropTypes.bool,
  sitemode: PropTypes.string,
  credentials: PropTypes.object.isRequired,
  logout: PropTypes.func,
  getSiteInfo: PropTypes.func.isRequired,
}

Header.defaultProps = {
  mode_big: true,
  sitemode: 'production',
}

function Header({ siteinfo, siteinfo_meta, mode_big, credentials, getSiteInfo }) {
  const wrapper_css = classNames({
    'header': true,
    'header-box': mode_big,
    'header-box-small': !mode_big,
  })

  return <div className={wrapper_css}>
    <div className="logo-container-mobile" style={{ textAlign: 'center' }}>
      <Link to="/">
        <img className="logo-globe logo-globe-small" src="/static/img/globe_small_8_transparent.png" />
        <img className="logo-text logo-text-small" src="/static/img/logo_large_24b.png" />
      </Link>
    </div>
    <Media query={query_mobile_view} defaultMatches={false}>
      {matches =>
        matches ? (
          <AuctionStatus siteinfo={siteinfo} siteinfo_meta={siteinfo_meta} getSiteInfo={getSiteInfo} credentials={credentials} />
        ) : (
          <div className="auction-status-placeholder" />
        )
      }
    </Media>
    <div className="owa-container" style={{marginLeft: '5px', marginRight: '5px'}}>
      <div className="logo-text-container">
        <Link to="/"><img className="logo-text" src="/static/img/logo_large_24b.png" /></Link>
      </div>
    </div>
    <Navbar authenticated={credentials.isAuthenticated} role={credentials.userInfo.role} />
  </div>
}

export default Header