import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { buildPath } from 'lib/utility'

BackToList.propTypes = {
  catalog_mode: PropTypes.string.isRequired,
  listInfo: PropTypes.object.isRequired,
}

function BackToList({ catalog_mode, listInfo }) {
  const destination = {
    pathname: buildPath('/catalog/' + catalog_mode, listInfo.params.category),
    query: listInfo.location && listInfo.location.query ? listInfo.location.query : {},
  }
  const button_backToList = <Link className="btn btn-u rounded btn-u-darkred btn-u-contrast-border" style={{width: '100%', fontSize: '14px', lineHeight: '19px', marginBottom: '5px'}} to={ location => ({ ...location, pathname: destination.pathname, query: destination.query }) }><span><i className="fa fa-table"></i> <i className="fa fa-arrow-left"></i> Back to List</span></Link>

  return <div>
    {button_backToList}
  </div>
}

export default BackToList