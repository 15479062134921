import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Helmet } from 'react-helmet'

import { connect } from 'react-redux'
import { login, logout } from 'rdx/modules/auth/login'

import FusedFields from '../shared/fused-fields'
import FormRow from '../shared/FormRow'
import FieldText from '../shared/FieldText'
import FieldBase from '../shared/FieldBase'
import StatusBox from '../shared/StatusBox'

const fields_initialstate = {
  user_email: '',
  user_password: '',
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
}

function Login({ location, data, meta, validation, login, logout }) {
  const [fields, setFields] = useState(fields_initialstate)

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _handleKeyDown = (key, keyCode) => {
    if (key === 'Enter' || keyCode === 13) {
      login(fields, location.query.redirect)
    }
  }

  const _login = () => {
    login(fields, location.query.redirect)
  }

  const logged_in = <div>
    <div className="reg-header">
      <h2>You&apos;re already logged in!</h2>
    </div>
    <div className="row">
      <div className="col-md-12 text-center">
        <button className="btn-u" type="submit" onClick={ logout }>Logout</button>
      </div>
    </div>
  </div>

  const formContainer = <form className="reg-page">
    <div className="reg-header">
      <h2>{'Login to your account'}</h2>
    </div>
    <div>
      <FusedFields data={fields} errors={validation.required} onUpdate={_handleUpdate} onKeyDown={_handleKeyDown}>
        <FormRow>
          <FieldText name="user_email" addonBefore={<i className="fa fa-fw fa-envelope-o" />} placeholder="Email (Username)" groupClassName="col-lg-12" />
          <FieldBase type="password" name="user_password" addonBefore={<i className="fa fa-fw fa-lock" />} placeholder="Password" groupClassName="col-lg-12" />
        </FormRow>
      </FusedFields>
      <StatusBox data={meta} size="small" className='margin-bottom-20' />
      <div className="row">
        <div className="col-md-12">
          <button type="button" className="btn-u pull-right" onClick={_login}>{'Login '}<span className='fa fa-angle-double-right' /></button>
        </div>
      </div>
    </div>
    <hr />
    <h5><Link to="/forgot-password">Forgot Password?</Link></h5>
    <h5><Link to="/register">Create an account here</Link> if you haven&apos;t registered yet.</h5>
  </form>

  return <div className="container content">
    <Helmet>
      <title>Login</title>
      <link rel="canonical" href={location.pathname} />
    </Helmet>
    <div className="row">
      <div className="col-sm-6 col-sm-offset-3">
        {data.isAuthenticated ? logged_in : formContainer}
      </div>
    </div>
  </div>
}

function mapStateToProps(state) {
  return {
    data: state.auth.login.data,
    meta: state.auth.login.actions.login.meta,
    validation: state.auth.login.actions.login.validation,
  }
}

export default connect(mapStateToProps, { login, logout })(Login)
