import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'

function content(props) {
  return (
    <div className="general-table-defaults">
      <Helmet>
        <title>How to Consign</title>
        <link rel="canonical" href={props.location.pathname} />
      </Helmet>
      <div className="titlebar heading heading-v1 text-center">
        <h2>How to Consign</h2>
      </div>

      <div className="headline">
        <h4>Why You Should Consign with Us</h4>
      </div>
      <p>Since 1978 Old World Auctions has sold over 50 thousand items for consignors throughout the world. As a specialist auctioneer we provide a powerful selling format and the ability to reach a large and diverse audience of collectors, institutions, and dealers.  Because we are a leader in the cartography category and regularly achieve 70-75% sell-through in our auctions, we are able to ensure the best possible results for our consignors.</p>
      <p>Through the years we have fine-tuned our marketing plan, thereby bringing the greatest awareness and highest prices for your items. We advertise our auctions nationally and internationally in a variety of online, email, and print media. We also send auction announcements to our customer base via both email and printed mailers.</p>
      <p>Perhaps what makes us stand out the most from other auction houses is our focus on customer service.  We regularly receive feedback from our consignors that we are easy to work with, well organized, thorough in our communications, prompt in paying, and we work hard for our commission.  See testimonials from our consignors <Link to={'/resources/testimonials'}>here</Link>.</p>

      <div className="headline">
        <h4>What We Accept on Consignment</h4>
      </div>
      <p>Quality consignments of maps, atlases, globes, books related to cartography, books with plates and engravings, and fine and historical prints are some of the items we can help you sell. Generally, we do not feature 20th century material later than World War II with the exception of very unique items. We also do not accept material that is in poor condition; some minor flaws, foxing or marginal tears are acceptable. Condition may be less critical on significant and rare material.  Framed items must be removed from the frames prior to consigning them with us.</p>

      <div className="headline">
        <h4>Consignment Process</h4>
      </div>
      <p>We require our consignors to send us a list of items they would like to consign, and have a minimum consignment value of $2500 due to administrative costs.  When sending your list of consignment items, please include the exact title, maker, and circa date (if known), as well as an image of each item.</p>
      <p>Once we have reviewed your list of items, we will let you know when we can accommodate your consignment.  If you have a large collection or duplicates within your collection, we may split your consignment into multiple auctions.  Old World Auctions holds four to five auctions each year - see our <Link to={'/info/auctions/upcoming'}>auction calendar</Link> for a list of upcoming auctions.</p>
      <p>All consignment items must be sent to Old World Auctions so that we can accurately describe condition and confirm authenticity.  You are responsible for shipment of the material to us, and in-transit insurance.  In the event an item does not sell you are also responsible for return postage and insurance.  We provide insurance on all items in our possession.  The deadline for receipt of materials is typically two months in advance of each auction.</p>
      <p>Once we have received your consignment, we will catalog your items and send you a Pre-Publication Report prior to the auction.  This report will include all of the information that will appear in the auction catalog, including auction estimates.  Most items in our auction will have a reserve, which is set between 50% - 75% of the low estimate.  The reserve is the lowest amount for which an item will sell during the auction and is kept confidential with the consignor until the auction has concluded.  After each auction has concluded, we offer a post-auction sale for all remaining unsold items.  This gives our consignors another opportunity to sell their material.  We send our consignor payments exactly 30 days after the close of each auction.</p>

      <div className="headline">
        <h4>Start Your Consignment Today</h4>
      </div>
      <p>Whether you have a single valuable piece or a major collection, please contact us to discuss your material. You may reach us by email at info(at)oldworldauctions.com or by phone at (804) 290-8090.</p>
    </div>
  )
}

export default content
