import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { getOperationsInfo, setSiteStatus, resetOperationsStatus } from 'rdx/modules/admin/operations'

import ContentPanel from '../shared/ContentPanel'
import StatusBox from '../shared/StatusBox'
import FieldText from '../shared/FieldText'
import FieldBase from '../shared/FieldBase'
import FusedFields from '../shared/fused-fields'

import { auction_modes_select_values } from 'lib/constants'
import { makeOptions } from 'lib/utility'

const fields_initialstate = {
  current_auction: 0,
  auction_mode: 0,
  status: 0,
}

SiteDiagnostics.propTypes = {
  operations: PropTypes.object.isRequired,
  getOperationsInfo: PropTypes.func.isRequired,
  setSiteStatus: PropTypes.func.isRequired,
  resetOperationsStatus: PropTypes.func.isRequired,
}

function SiteDiagnostics({ operations, getOperationsInfo, resetOperationsStatus, setSiteStatus }) {
  const [fields, setFields] = useState(fields_initialstate)
  const { data } = operations.operationsInfo
  useEffect(() => {
    getOperationsInfo()
    resetOperationsStatus()
  }, [getOperationsInfo, resetOperationsStatus])

  useEffect(() => {
    if (operations.operationsInfo.meta.status === 1) {
      setFields({ current_auction: data.current_auction, auction_mode: data.auction_mode, status: 1 })
    }
  }, [operations.operationsInfo.meta.status, data])

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _setSiteStatus = () => {
    setSiteStatus(fields)
  }

  let content

  content = <form className='page-base'>
    <div className="tag-box tag-box-v3">
      <div className="section-summary" style={{ fontSize: '18px' }}>
        <span className='fa fa-wrench fa-fw' /> Update Auction Number/Mode {operations.operationsInfo.meta.status === 1 ? <span>(presently Auction {data.current_auction}, Mode {data.auction_mode})</span> : <span>(Refreshing...)</span>}
      </div>
      <div className="section-description clearfix">
        *Note that these actions should only be used in testing/non-production scenarios unless an emergency requires specific action.
        Any of these actions may have unintended side-effects if not used in conjunction with other required manual updates to the database/site settings
      </div>
      {operations.operationsInfo.meta.status < 2 && <FusedFields data={fields} errors={null} onUpdate={_handleUpdate}>
        <div className="flex-standard-container form-action-container standard-border all-rounded">
          <div className="flex-group flex-group-left">
            <FieldText name='current_auction' addonBefore='Current Auction' />
            <FieldBase type="select" name='auction_mode' addonBefore='Auction Mode'>
              {makeOptions(auction_modes_select_values, { showVal: true })}
            </FieldBase>
          </div>
          <div className="flex-group flex-group-right">
            <button type='button' className='btn btn-u btn-u-dark-red' onClick={_setSiteStatus} { ...((operations.operationsInfo.meta.status !== 1 || fields.status !== 1) && { disabled: true } ) }>
              Update Auction/Mode
              <span className='fa fa-angle-double-right fa-fw' />
            </button>
          </div>
        </div>
      </FusedFields>}
      <StatusBox data={operations.actions.setSiteStatus} style={{ marginTop: '10px' }} />
    </div>
  </form>

  return <ContentPanel
    title="Site Diagnostics/Emergency Actions"
    icon="fa fa-star fa-fw"
  >
    <div>
      <StatusBox data={operations.operationsInfo.meta} hideUntilError={true} className="margin-bottom-10" />
      {content}
    </div>
  </ContentPanel>
}

function mapStateToProps(state) {
  return {
    operations: state.admin.operations,
  }
}

export default connect(mapStateToProps, {
  getOperationsInfo,
  setSiteStatus,
  resetOperationsStatus,
})(SiteDiagnostics)