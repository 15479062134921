import { processQuery, get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

const base = 'owa/admin/consignor_summary/'

const DATA = defineAction(base, 'DATA', 'async')

const initialState = {
  content: {
    data: [],
    info: {
      pageSize: 20,
      count: 0,
    },
    meta: status_defaults,
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        content: {
          ...state.content,
          meta: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case DATA.fail:
      return {
        ...state,
        content: {
          ...state.content,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA.success:
      return {
        ...state,
        content: {
          ...state.content,
          data: action.payload.data,
          info: {
            ...state.content.info,
            count: action.payload.count,
          },
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    default:
      return state
  }
}

export function getConsignorSummary(location) {
  return (dispatch, getState) => {
    const state = getState()
    const query = location.query

    const querydata = processQuery(query, { whitelist: ['auction_id'], pageSize: state.admin.auction_summary_history.content.info.pageSize })
    const endpoint = '/api/admin/stats/consignor-summary' + querydata
    return dispatch(
      get({
        types: DATA,
        endpoint: endpoint,
      })
    )
  }
}
