import React from 'react'
import PropTypes from 'prop-types'

import DataList from '../shared/DataList'
import DataListRow from '../shared/DataListRow'

import { formatMoney } from 'lib/utility'
import { calcInvoiceCosts } from 'lib/utility_invoice'
import { invoiceLots_specs } from 'lib/list_specifications'

InvoiceLots.propTypes = {
  mode: PropTypes.string.isRequired,
  invoice: PropTypes.object.isRequired,
}

function InvoiceLots({ mode, invoice }) {
  const data = mode === 'admin' ? invoice.content.admin_info.data : invoice.content.main
  const options = mode === 'admin' ? invoice.content.admin_info.data : invoice.content.other_options.data
  const primarydata = invoice.content.main
  const shipping = invoice.content.address.shipping.data
  const lots = invoice.content.lots

  const calculatedCosts = calcInvoiceCosts({ primaryData: primarydata, auxData: data, options, carrier: invoice.content.shipping_options.data.ship_carrier_preference, foreign: shipping.country !== 'US', state: shipping.state, mode })

  const lot_data = <DataList
    useTable={true}
    tableStyle={{ fontSize: '13px' }}
    showHeader={true}
    errorCondition={lots.conditionFail}
    errorMessage={lots.errorMessage}
    isLoading={lots.isFetching}
    showTopPagingControl={false}
    showBottomPagingControl={false}
    data={lots}
    totalItemCount={invoice.content.lot_count}
    metadata={invoiceLots_specs[mode]}
    datakey='id'
    clickTargetTemplate='/catalog/lot/<param1>/<param2>'
    clickTargetParam1='auction_id'
    clickTargetParam2='lot_number'
    pageSize={400}
  >
    <DataListRow />
  </DataList>

  const total_data = <table style={{ width: '100%', marginTop: '0' }}>
    <colgroup>
      <col style={{ width: '50%' }} />
      <col style={{ width: '50%' }} />
    </colgroup>
    <tbody>
      <tr style={{ borderTop: '1px solid #717984' }}>
        <td />
        <td>
          <table style={{ width: '100%' }}>
            <colgroup>
              <col />
              <col style={{ width: '100px' }} />
            </colgroup>
            <tbody>
              <tr>
                <td style={{ textAlign: 'right' }}>Total Bids:</td>
                <td style={{ textAlign: 'right', fontWeight: '900', paddingRight: '4px' }}>{formatMoney(primarydata.bids_total)}</td>
              </tr>
              <tr>
                <td style={{ textAlign: 'right' }}>{"Buyer's Premium:"}</td>
                <td style={{ textAlign: 'right', fontWeight: '900', paddingRight: '4px' }}>{formatMoney(primarydata.buyer_premium)}</td>
              </tr>
              {options.inv_options_digital_images === 1 ? <tr>
                <td style={{ textAlign: 'right' }}>Digital Images Add-on:</td>
                <td style={{ textAlign: 'right', fontWeight: '900', paddingRight: '4px' }}>{formatMoney(calculatedCosts.digital_images_cost_total)}</td>
              </tr> : null }
            </tbody>
          </table>
        </td>
      </tr>
      <tr style={{ borderTop: '1px solid #717984' }}>
        <td />
        <td>
          <table style={{ width: '100%' }}>
            <colgroup>
              <col />
              <col style={{ width: '100px' }} />
            </colgroup>
            <tbody>
              {/* <tr>
                <td style={{ textAlign: 'right' }}>Shipping &amp; Insurance:</td>
                <td style={{ textAlign: 'right', fontWeight: '900', paddingRight: '4px' }}>{!invoice.content.ready ? '<pending>' : formatMoney(data.amount_shipping)}</td>
              </tr> */}
              <tr>
                <td style={{ textAlign: 'right' }}>Shipping:</td>
                <td style={{ textAlign: 'right', fontWeight: '900', paddingRight: '4px' }}>{!invoice.content.ready ? '<pending>' : formatMoney(data.amount_shipping)}</td>
              </tr>
              <tr>
                <td style={{ textAlign: 'right' }}>Insurance:</td>
                <td style={{ textAlign: 'right', fontWeight: '900', paddingRight: '4px' }}>{!invoice.content.ready ? '<pending>' : formatMoney(calculatedCosts.options_insurance_cost)}</td>
              </tr>
              {calculatedCosts.options_signature_cost > 0 && <tr>
                <td style={{ textAlign: 'right' }}>Delivery Signature:</td>
                <td style={{ textAlign: 'right', fontWeight: '900', paddingRight: '4px' }}>{!invoice.content.ready ? '<pending>' : formatMoney(calculatedCosts.options_signature_cost)}</td>
              </tr>}
              {shipping.state === 'VA' && primarydata.user_taxable === 1 ? <tr>
                <td style={{ textAlign: 'right' }}>Sales Tax:</td>
                <td style={{ textAlign: 'right', fontWeight: '900', paddingRight: '4px' }}>{!invoice.content.ready ? '<pending>' : formatMoney(calculatedCosts.tax)}</td>
              </tr> : <tr><td colSpan='2' style={{ lineHeight: '14px' }} /></tr>}
              {data.amount_misc > 0 ? <tr>
                <td style={{ textAlign: 'right' }}>{data.amount_misc_desc}:</td>
                <td style={{ textAlign: 'right', fontWeight: '900', paddingRight: '4px' }}>{formatMoney(data.amount_misc)}</td>
              </tr> : null}
              <tr>
                <td style={{ textAlign: 'right' }}>Deposit:</td>
                <td style={{ textAlign: 'right', fontWeight: '900', paddingRight: '4px' }}>({formatMoney(data.amount_deposit)})</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr style={{ borderTop: '1px solid #717984' }}>
        <td />
        <td>
          <table style={{ width: '100%' }}>
            <colgroup>
              <col />
              <col style={{ width: '100px' }} />
            </colgroup>
            <tbody>
              <tr>
                <td style={{ textAlign: 'right', fontWeight: '900', fontSize: '16px' }}>{!invoice.content.approved ? 'Total Due:' : 'Total Amount:'}</td>
                <td style={{ textAlign: 'right', fontWeight: '900', fontSize: '16px', paddingRight: '4px' }}>{!invoice.content.ready ? '<pending>' : formatMoney(calculatedCosts.total_due)}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>

  return <div style={{ border: '1px solid #AAA', borderRadius: '10px', padding: '10px' }}>
    {lot_data}
    {total_data}
  </div>
}

export default InvoiceLots