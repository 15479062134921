import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getData, actionStatusReset, approveAdjustment } from 'rdx/modules/admin/resolution'

import DataListContainer from '../shared/DataListContainer'
import DataListBody from '../shared/DataListBody'

import FieldBase from '../shared/FieldBase'
import FieldText from '../shared/FieldText'
import FieldSmartSelect from '../shared/FieldSmartSelect'
import FormContainer from '../shared/FormContainer'
import FormRow from '../shared/FormRow'
import FusedFields from '../shared/fused-fields'
import LotCard from '../shared/LotCard'
import ConfirmBox from '../shared/ConfirmBox'
import ContentPanel from '../shared/ContentPanel'
import LotActionsAdmin from './components/LotActionsAdmin'

import useRefreshTimer from '../shared/hooks/useRefreshTimer'
import useMedia from '../shared/hooks/useMedia'

import { makeOptions, formatQueryParams, hydrateQueryFields } from 'lib/utility'
import { auctionStatusRefreshInterval, bid_extension_time, query_mobile_view, modalStyles, admin_resolution_table_definitions } from 'lib/constants'

const fields_initialstate = {
  consignor_id: '',
  status: '',
  lot_number: '',
  changes: '',
}

ResolutionList.propTypes = {
  location: PropTypes.object.isRequired,
  siteinfo: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  resolution: PropTypes.object.isRequired,
  lookupData: PropTypes.object.isRequired, // injected via DashboardAdmin
  getData: PropTypes.func.isRequired,
  actionStatusReset: PropTypes.func.isRequired,
  approveAdjustment: PropTypes.func.isRequired,
}

ResolutionList.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getData(location)),
  ])
}

function ResolutionList({ location, siteinfo, router, resolution, lookupData, getData, actionStatusReset, approveAdjustment }) {
  const [fields, setFields] = useState({ ...fields_initialstate, ...(hydrateQueryFields(location.query, fields_initialstate))})
  const [showPopup, setShowPopup] = useState(false)
  const [selectedLotID, setSelectedLotID] = useState(null)
  const mobileView = useMedia(query_mobile_view)

  const refreshData = () => {
    getData(location, selectedLotID)
  }

  const { elapsedSeconds, secondsTillRefresh } = useRefreshTimer(refreshData, resolution.lots.meta, { deps: [location.query], runOnInit: true, refreshInterval: auctionStatusRefreshInterval })

  const closeModal = () => {
    setShowPopup(false)
    actionStatusReset()
  }

  const popupAfterClose = () => {
    setSelectedLotID(null)
  }

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _handleFilter = () => {
    let new_query = {}
    Object.keys(fields).map(key => {
      if (typeof fields[key] !== 'undefined' && fields[key] !== ''){ new_query[key] = fields[key] }
    })
    router.push({ ...location, query: new_query })
  }

  const _clearFilter = () => {
    router.push({ ...location, query: {} })
    setFields(fields_initialstate)
  }

  const promptAdvancedAdjustment = (lot_id) => {
    setSelectedLotID(lot_id)
    setShowPopup(true)
  }

  const refresh_text = secondsTillRefresh > 0 ? "(" + secondsTillRefresh + ")" : resolution.lots.meta.status <= 1 ? "(attempting to refresh...)" : null
  const problem_lots = (elapsedSeconds > (auctionStatusRefreshInterval + 5) && resolution.lots.meta.status !== 1) || resolution.lots.meta.status > 1 ? <span style={{color: 'orange', paddingLeft: '5px'}}><i className="fa fa-fw fa-exclamation-circle"></i>There is a problem.  Try reloading the page ({elapsedSeconds} seconds since last refresh)</span> : null

  let content = <DataListContainer
    useTable={!mobileView}
    tableClass='table table-striped datalist-table-status table-text-vertical-center'
    showHeader={true}
    headerColumnVisibleKey={siteinfo.auction_mode}
    showTopPagingControl={true}
    showBottomPagingControl={true}
    pagingControlSize='slim'
    metadata={admin_resolution_table_definitions}
    totalItemCount={resolution.lots.info.count}
    pageSize={resolution.lots.info.pageSize}
    location={location}
  >
    <DataListBody
      dataIndex={resolution.lots.order}
      data={resolution.lots.data}
      datakey='lot_number'
      useTable={!mobileView}
    >
      <LotCard nature={'resolution'} view={mobileView ? 'mobile' : 'standard'} auction_mode={siteinfo.auction_mode} elapsedSeconds={elapsedSeconds} bid_extension_time={bid_extension_time} approveAdjustment={approveAdjustment} promptAdvancedAdjustment={promptAdvancedAdjustment} />
    </DataListBody>
  </DataListContainer>

  const search_fields = <FusedFields data={fields} onUpdate={_handleUpdate}>
    <FormContainer rowClassName="fixed-gutter-5 margin-bottom-5" size='small' noMargins={true} addonBeforeStyle={{ textAlign: 'left', flexShrink: '0' }}>
      <FormRow>
        <FieldSmartSelect
          name='consignor_id'
          addonBefore='Consignor'
          groupClassName='col-lg-4'
          options={lookupData.consignors}
          valueField='id'
          displayField='user_fullname'
        />
        <FieldBase type="select" name='status' addonBefore='Status' groupClassName='col-lg-4'>
          {makeOptions([{ value: '', text: 'All (Default)' },{ value: 1, text: 'No Bids' }, { value: 2, text: 'Under Reserve' }, { value: 3, text: 'Reserve Met (Sold)' },{ value: 4, text: 'Unsold' }])}
        </FieldBase>
        <FieldText name='lot_number' addonBefore='Lot' groupClassName='col-lg-4' />
      </FormRow>
      <FormRow>
        <FieldBase type="select" name='changes' addonBefore='Changes' groupClassName='col-lg-4'>
          {makeOptions([{ value: '', text: '' },{ value: 0, text: 'Pending Requests' }, { value: 1, text: 'Resolved Requests' }, { value: 2, text: 'Pending or Resolved Requests' }])}
        </FieldBase>
        <div className='col-lg-8'>
          <button type='submit' className='btn btn-u btn-u-dark-blue pull-right' onClick={_handleFilter} style={{ marginLeft: '10px' }}>
            <span className='fa fa-filter fa-fw' />
            {' Filter'}
          </button>
          <button type='submit' className='btn btn-u btn-u-red pull-right' onClick={_clearFilter}>
            <span className='fa fa-times fa-fw' />
            {' Clear Filter'}
          </button>
        </div>
      </FormRow>
    </FormContainer>
  </FusedFields>

  const actionBox = <ConfirmBox
    title={'Lot Actions'}
    style={{maxWidth: '800px'}}
    confirm_button_show={false}
    close_button_text="Close"
    close_button_action={closeModal}
  >
    <LotActionsAdmin view={mobileView ? 'mobile' : 'standard'} lot={resolution.lots.data[selectedLotID]} auction_mode={siteinfo.auction_mode} users={lookupData.users} lotCard={<LotCard nature={'lot_actions'} view={mobileView ? 'mobile' : 'standard'} auction_mode={siteinfo.auction_mode} elapsedSeconds={elapsedSeconds} bid_extension_time={bid_extension_time} />} />
  </ConfirmBox>

  const filteredText = formatQueryParams(location.query)

  const container = <ContentPanel
    title={'Auction Resolution'}
    titleSuffix={refresh_text}
    titleAuxElement={problem_lots}
    icon="fa fa-star fa-fw"
    filteredText={filteredText}
    auxClassContent="zero-padding"
  >
    <>
      <div className="clearfix" style={{ paddingTop: "5px", paddingLeft: "5px", paddingRight: "5px", backgroundColor: "#fff"}}>
        {search_fields}
      </div>
      {content}
      <Modal
        closeTimeoutMS={150}
        isOpen={showPopup}
        onRequestClose={() => {}}
        onAfterClose={popupAfterClose}
        style={modalStyles}
        contentLabel="Lot Actions" >
        {actionBox}
      </Modal>
    </>
  </ContentPanel>

  return <div className="owa-content">
    {container}
  </div>
}

function mapStateToProps(state) {
  return {
    siteinfo: state.siteinfo.data,
    resolution: state.admin.resolution,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getData, actionStatusReset, approveAdjustment }, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResolutionList)