import React from 'react'
import PropTypes from 'prop-types'

ClickElement.propTypes = {
  children: PropTypes.any.isRequired,
  handler: PropTypes.func.isRequired,
  type: PropTypes.string,
  options: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
}

ClickElement.defaultProps = {
  type: 'button',
  options: {},
  className: '',
  style: {},
}

function ClickElement({ children, handler, type, options, className, style }) {
  const clickHandler = () => {
    handler(options)
  }

  return type === 'button' ? <button className={className} style={style} onClick={clickHandler}>{children}</button> : <a role="button" tabIndex="0" className={className} style={style} onClick={clickHandler}>{children}</a>
}

export default ClickElement