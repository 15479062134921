import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class FilterTag extends Component {
  static propTypes = {
    type: PropTypes.string,
    filtertagstring: PropTypes.string,
    onRemoveFilter: PropTypes.func,
  }

  static defaultProps = {
    type: 'filter',
  }

  removeFilter = () => {
    this.props.onRemoveFilter(this.props.id)
  }

  clearFilters = () => {
    this.props.onRemoveFilter()
  }

  render() {
    const { type, filtertagstring } = this.props
    if (type === 'clear') {
      return <div className="tag label md clearAction" onClick={this.clearFilters}>
        <i className="fa fa-times-circle-o"></i>
        <span>Clear All Filters</span>
      </div>
    } else {
      return <div className="tag label md">
        <span>{filtertagstring}</span>
        <a onClick={this.removeFilter}>
          <i className="fa fa-times-circle"></i>
        </a>
      </div>
    }
  }
}