import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

Slide.propTypes = {
  customContent: PropTypes.node,
  data: PropTypes.object,
  preview: PropTypes.bool,
}

Slide.defaultProps = {
  data: null,
  preview: false,
}

function Slide({ customContent, data, preview, ...other }) {
  if (!data || (!data.image_filename && !data.static_image_path)) return <div>Loading...</div>
  const { header, title, subtitle, link1_text, link1_url, link1_new_window, link2_text, link2_url, link2_new_window, image_filename, static_image_path } = data
  let content_block = null

  switch (data.layout) {
    case 0:
    {
      const text_block = <div className="home-slider-info-content wrapped padded topleft">
        {header && <div className="slider-text-large slider-line-height-slim slider-hover-dim-color" dangerouslySetInnerHTML={{ __html: header }} />}
        {title && <div className="slider-text-small slider-line-height-slim" dangerouslySetInnerHTML={{ __html: title }} />}
        {subtitle && <div className="slider-text-small slider-line-height-slim" dangerouslySetInnerHTML={{ __html: subtitle }} />}
        {link1_url && <button className="home-slider-info-button btn-u btn-u-darkred" type="button">{link1_text}</button>}
      </div>

      content_block = link1_url ? <a href={link1_url} tabIndex="0" {...((link1_new_window || preview) && { target: "_blank", rel: "noopener noreferrer" })}>{text_block}</a> : text_block
      break;
    }
    case 1:
      content_block = <div>
        <div className="home-slider-info-content wrapped centered">
          <div className="slider-text-extra-large text-center" dangerouslySetInnerHTML={{ __html: header }} />
          <div className="slider-text-medium text-center" dangerouslySetInnerHTML={{ __html: title }} />
          <div className="slider-text-medium text-center" dangerouslySetInnerHTML={{ __html: subtitle }} />
        </div>
        <div className="home-slider-info-content bottomleft">
          {/* <Link to="/register" tabIndex="0"><button className="home-slider-info-button btn-u btn-u-darkred bigbutton" type="button">Register to Bid</button></Link> */}
          <Link to={link1_url} tabIndex="0" {...((link1_new_window || preview) && { target: "_blank", rel: "noopener noreferrer" })}><button className="home-slider-info-button btn-u btn-u-darkred bigbutton" type="button">{link1_text}</button></Link>
        </div>
        <div className="home-slider-info-content bottomright">
          {/* <Link to="/resources/how-to-consign" tabIndex="0"><button className="home-slider-info-button btn-u btn-u-darkred bigbutton" type="button">Consign with Us</button></Link> */}
          <Link to={link2_url} tabIndex="0" {...((link2_new_window || preview) && { target: "_blank", rel: "noopener noreferrer" })}><button className="home-slider-info-button btn-u btn-u-darkred bigbutton" type="button">{link2_text}</button></Link>
        </div>
      </div>
      break;
    case 'custom':
      content_block = {customContent}
      break;
    default:
      break;
  }

  return (
    <div {...other}>
      <div className="home-slider-info-container">
        {content_block}
      </div>
      <div className="home-slider-image-container"><img src={static_image_path ? static_image_path : `${__DYNAMIC_IMAGES_RELATIVE_BASE_URL__}slides/${image_filename}`} style={{maxWidth: '100%'}} draggable={false} /></div>
    </div>
  )
}

export default Slide