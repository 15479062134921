import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { toggleMenu } from 'rdx/modules/menus'

import Menu from './Menu/Menu'

import { catalog_sidebar_groups } from 'lib/constants'

MobileMenuCatalog.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  siteinfo: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  listInfo: PropTypes.object,
}

MobileMenuCatalog.defaultProps = {
  listInfo: {},
}

const id = "mobile-menu-catalog"

function MobileMenuCatalog({ menuOpen, toggleMenu, siteinfo, location, params, listInfo, logout }) {
  const catalog_mode = siteinfo.auction_mode === 5 ? 'archive' : (params.auctionNumber ? (+params.auctionNumber === siteinfo.current_auction ? 'current' : 'archive') : location.pathname.includes('/archive') ? 'archive' : 'current')
  return <Menu
    id={id}
    type="mobile"
    position="left"
    title="Catalog Categories"
    titleIcon={<span className='fa fa-list fa-fw' />}
    hideBurgerIcon={true}
    menuOpen={menuOpen}
    toggleMenu={toggleMenu}
    data={catalog_sidebar_groups}
    context={{catalog_mode, siteinfo}}
    storedQuery={listInfo.location.query}
    storedParams={listInfo.params}
    actions={{
      logout: logout,
    }}
  />
}

function mapStateToProps(state) {
  return {
    menuOpen: state.menus[id],
    listInfo: state.lots.content.info,
  }
}

const mapDispatchToProps = { toggleMenu }

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuCatalog)
