import { useState, useRef, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getLot } from 'rdx/modules/lot'
import { getUserData } from 'rdx/modules/auction'

import usePrevious from './usePrevious'
import { requestInterval } from 'lib/timers'
import { bid_extension_time } from 'lib/constants'

const refreshIntervalBase = 30
const refreshIntervalMedium = 10
const refreshIntervalFast = 4

const useLotRefresher = ({ runOnInit=false, params={} }={}) => {
  const siteinfo = useSelector(state => state.siteinfo.data)
  const timestamp = useSelector(state => state.lot.content.meta.timestamp)
  const status = useSelector(state => state.lot.content.meta.status)
  const time_since_lastbid = useSelector(state => state.lot.content.data.time_since_lastbid)
  const closed = useSelector(state => state.lot.content.data.status_closed)
  const isAuthenticated = useSelector(state => state.auth.login.data.isAuthenticated)
  const dispatch = useDispatch()

  const [count, setCount] = useState(0)
  const prevCount = usePrevious(count)

  const catalog_mode = +params.auctionNumber === siteinfo.current_auction && siteinfo.auction_mode !== 5 ? 'current' : 'archive'

  const auction_server_seconds_left = Math.trunc((siteinfo.auction_end_date - timestamp)/1000)

  const _refreshData = useCallback(
    (tag) => {
      // console.log('lot -> refreshdata-' + tag)
      dispatch(getLot(params.auctionNumber, params.lotNumber))
      if (isAuthenticated) {
        // console.log('auth -> refresh user data')
        dispatch(getUserData())
      }
    },[dispatch, isAuthenticated, params.auctionNumber, params.lotNumber])

  const isInitialMount = useRef(true)

  // handles refresh on initial mount and/or on dependency changes
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      // console.log('*lot refresh (initial)')
      runOnInit && _refreshData('initial')
    } else {
      // refresh on dependency change (except on initial mount)
      // console.log('*lot refresh (dep-update)')
      _refreshData('dep-update')
    }
  }, [catalog_mode, siteinfo.auction_mode, siteinfo.auction_end_date, runOnInit, _refreshData])

  // handles refresh on interval
  useEffect(() => {
    const time_remaining = time_since_lastbid !== null ? Math.max(auction_server_seconds_left - count, bid_extension_time - (time_since_lastbid + count)) : auction_server_seconds_left - count
    // console.log('count:', count, 'time_remaining:', time_remaining)
    if (count !== prevCount && count > 0 && ((time_remaining > 34 || time_remaining < -34) ? (count % refreshIntervalBase === 0) : ((time_remaining > 9 || time_remaining < -9) ? count % refreshIntervalMedium === 0 : count % refreshIntervalFast === 0))) {
      _refreshData('standard')
    }
  }, [_refreshData, count, prevCount, auction_server_seconds_left, time_since_lastbid])

  useEffect(() => {
    // only start (or restart) timer if catalog is current and lot is not closed
    if (catalog_mode === 'current' && closed === 0) {
      if (status === 1) {
        setCount(0)
      }
      const interval = new requestInterval(() => setCount(c => c + 1), 1000)
      return () => interval.clear()
    }
  }, [catalog_mode, status, closed])

  return { elapsedSeconds: count }
}

export default useLotRefresher
