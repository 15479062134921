import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

LoadingOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

LoadingOverlay.defaultProps = {
  loading: false,
  className: '',
  style: {},
}

function LoadingOverlay({ children, meta, className, style }) {
  const resolvedClassName = classNames('loading-overlay-outer-wrapper', className)
  return <div className={resolvedClassName} style={style}>
    {meta.processing && <div className="loading-overlay-container">
      <div className="loading-overlay-dimmer"></div>
      <div className="loading-dots" />
      <div className="loading-message" />
    </div>}
    {meta.status !== 2 && children}
  </div>
}

export default LoadingOverlay