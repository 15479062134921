import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getConsignments } from 'rdx/modules/consignments'

import ContentPanel from '../shared/ContentPanel'
import DataList from '../shared/DataList'
import DataListRow from '../shared/DataListRow'

import FieldText from '../shared/FieldText'
import FormRow from '../shared/FormRow'
import FusedFields from '../shared/fused-fields'
import StatusBox from '../shared/StatusBox'

import { formatQueryParams, hydrateQueryFields } from 'lib/utility'
import { consignmentHistory_specs } from 'lib/list_specifications'

import connectDataList from '../shared/composers/connectDataList'

const DataListBound = connectDataList(DataList, state => ({ statebranch: state.consignments.content }))

const fields_initialstate = {
  auction_id: '',
}

ConsignmentHistory.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  consignments: PropTypes.object.isRequired,
  getConsignments: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
}

ConsignmentHistory.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getConsignments(location)),
  ])
}

function ConsignmentHistory({ location, params, consignments, getConsignments, router }) {
  const [fields, setFields] = useState({ ...fields_initialstate, ...(hydrateQueryFields(location.query, fields_initialstate))})
  useEffect(() => {
    getConsignments(location)
  }, [location, getConsignments])

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _handleFilter = () => {
    let new_query = {}
    Object.keys(fields).map(key => {
      if (typeof fields[key] !== 'undefined' && fields[key] !== ''){ new_query[key] = fields[key] }
    })
    router.push({ ...location, query: new_query })
  }

  const _clearFilter = () => {
    router.push({ ...location, query: {} })
    setFields(fields_initialstate)
  }

  let content = null

  if (consignments.content.meta.status > 1) {
    content = <StatusBox data={consignments.content.meta} />
  } else {
    content = <DataListBound
      useTable={true}
      showHeader={true}
      showTopPagingControl={false}
      pagingControlSize='slim'
      metadata={consignmentHistory_specs}
      datakey='id'
      clickTargetTemplate='/catalog/lot/<param1>/<param2>'
      clickTargetParam1='auction_id'
      clickTargetParam2='lot_number'
      params={params}
      location={location}
    >
      <DataListRow />
    </DataListBound>
  }

  return <ContentPanel
    title="Consignment History"
    icon="fa fa-history fa-fw"
    filteredText={formatQueryParams(location.query)}
  >
    <div>
      <div className='filter-container'>
        <FusedFields data={fields} onUpdate={_handleUpdate}>
          <FormRow>
            <FieldText name='auction_id' addonBefore='Auction #' groupClassName='col-lg-3' data-type='unsigned-int' />
            <button type='submit' className='btn btn-u btn-u-dark-blue' onClick={_handleFilter} style={{ marginRight: '10px' }}>
              <span className='fa fa-filter fa-fw' />
              {' Filter'}
            </button>
            <button type='submit' className='btn btn-u btn-u-red' onClick={_clearFilter}>
              <span className='fa fa-times fa-fw' />
              {' Clear Filter'}
            </button>
          </FormRow>
        </FusedFields>
      </div>
      {content}
    </div>
  </ContentPanel>

}

function mapStateToProps(state) {
  return {
    consignments: state.consignments,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getConsignments }, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsignmentHistory)