import React, { Component } from 'react'
import qs from 'qs'

import FilterTag from './filter-tag'

export default class FilterTagsContainer extends Component {
  render(){
    const o = qs.parse(this.props.location.query.zops)
    const filters = o.f ? o.f : []
    return (
      <div className="filter-tags clearfix" id="filter-tag-container">
        {filters.map(({ f, o, v }, i) => {
          return <FilterTag
            key={i}
            id={i}
            filtertagstring={f + ' ' + o + ' ' + v}
            onRemoveFilter={this.props.onRemoveFilter}
          />
        })}
        {filters.length > 0 && <FilterTag type="clear" onRemoveFilter={this.props.onRemoveFilter} />}
      </div>
    )
  }
}