import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getLotPerformance } from 'rdx/modules/admin/lot_performance'

import ContentPanel from '../shared/ContentPanel'
import DataList from '../shared/DataList'
import DataListRow from '../shared/DataListRow'

import FieldText from '../shared/FieldText'
import FormRow from '../shared/FormRow'
import FusedFields from '../shared/fused-fields'
import StatusBox from '../shared/StatusBox'
import LoadingOverlay from '../shared/LoadingOverlay'

import { formatQueryParams, hydrateQueryFields } from 'lib/utility'
import { admin_lot_performance_table_definitions } from 'lib/constants'

import connectDataList from '../shared/composers/connectDataList'

const DataListBound = connectDataList(DataList, state => ({ statebranch: state.admin.lot_performance.content }))

const fields_initialstate = {
  auction_id: '',
}

LotPerformance.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  siteinfo: PropTypes.object.isRequired,
  lot_performance: PropTypes.object.isRequired,
  getLotPerformance: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
}

LotPerformance.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getLotPerformance(location)),
  ])
}

function LotPerformance({ location, params, siteinfo, lot_performance, getLotPerformance, router }) {
  const [fields, setFields] = useState({ ...fields_initialstate, ...(hydrateQueryFields(location.query, fields_initialstate))})
  useEffect(() => {
    getLotPerformance(location)
  }, [location, getLotPerformance])

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _handleFilter = () => {
    let new_query = {}
    Object.keys(fields).map(key => {
      if (typeof fields[key] !== 'undefined' && fields[key] !== ''){ new_query[key] = fields[key] }
    })
    router.push({ ...location, query: new_query })
  }

  const _clearFilter = () => {
    router.push({ ...location, query: {} })
    setFields(fields_initialstate)
  }

  const performance_legend = <div className="legend-general legend-border-bottom margin-bottom-5">
    <div className="legend-general-item"><span className="tier1"><span className="bullet">&bull;</span>{ ' No Bids' }</span></div>
    <div className="legend-general-item"><span className="tier2"><span className="bullet">&bull;</span>{ ' <=60%' }</span></div>
    <div className="legend-general-item"><span className="tier3"><span className="bullet">&bull;</span><span className="legend-general-item-text">{ ' 60-70%' }</span></span></div>
    <div className="legend-general-item"><span className="tier4"><span className="bullet">&bull;</span><span className="legend-general-item-text">{ ' 70-80%' }</span></span></div>
    <div className="legend-general-item"><span className="tier5"><span className="bullet">&bull;</span>{ ' >80%' }</span></div>
    <div className="legend-general-item"><span className="tier6"><span className="bullet">&bull;</span>{ ' >=100% Reserve' }</span></div>
  </div>

  const content = <DataListBound
    useTable={true}
    showHeader={true}
    showTopPagingControl={false}
    pagingControlSize='slim'
    metadata={admin_lot_performance_table_definitions}
    rowStyleFunction={admin_lot_performance_table_definitions.helpers.rowStyleFunction}
    rowStyleDataKey='percent_reserve'
    datakey='id'
    clickTargetTemplate='/catalog/lot/<param1>/<param2>'
    clickTargetParam1='auction_id'
    clickTargetParam2='lot_number'
    params={params}
    location={location}
  >
    <DataListRow />
  </DataListBound>

  return <ContentPanel
    title="Lot Performance"
    icon="fa fa-line-chart fa-fw"
    titleSuffix={' - Auction ' + (typeof location.query.auction_id !== 'undefined' ? location.query.auction_id : siteinfo.current_auction)}
    filteredText={formatQueryParams(location.query)}
  >
    <div>
      {performance_legend}
      <div className='filter-container'>
        <FusedFields data={fields} onUpdate={_handleUpdate}>
          <FormRow>
            <FieldText name='auction_id' addonBefore='Auction #' groupClassName='col-lg-3' data-type='unsigned-int' />
            <button type='submit' className='btn btn-u btn-u-dark-blue' onClick={_handleFilter} style={{ marginRight: '10px' }}>
              <span className='fa fa-filter fa-fw' />
              {' Filter'}
            </button>
            <button type='submit' className='btn btn-u btn-u-red' onClick={_clearFilter}>
              <span className='fa fa-times fa-fw' />
              {' Clear Filter'}
            </button>
          </FormRow>
        </FusedFields>
      </div>
      <StatusBox data={lot_performance.content.meta} hideUntilError={true} />
      <LoadingOverlay meta={lot_performance.content.meta}>{content}</LoadingOverlay>
    </div>
  </ContentPanel>
}

function mapStateToProps(state) {
  return {
    siteinfo: state.siteinfo.data,
    lot_performance: state.admin.lot_performance,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getLotPerformance }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LotPerformance)