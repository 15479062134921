import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Modal from 'react-modal'
import { browserHistory, Link } from 'react-router'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getLot } from 'rdx/modules/lot'
import { placeBid, bidConfirm, toggleWatch, getUserData } from 'rdx/modules/auction'

import { SiteView } from '../base/components/siteview-context'

import FusedFields from '../shared/fused-fields'
import ContentPanel from '../shared/ContentPanel'
import BidResult from '../shared/BidResult'
import ZoomViewer from '../shared/ZoomViewer'
import HiresDownloader from '../shared/HiresDownloader'
import StatusBox from '../shared/StatusBox'
import LotImageSlider from './LotImageSlider'
import LotTimeRemaining from './components/LotTimeRemaining'
import WatchListToggle from './components/WatchListToggle'
import BidIncrementInfo from './components/BidIncrementInfo'

import useLotRefresher from '../shared/hooks/useLotRefresher'

import { forceInteger, round, formatMoneyInteger, loginWithMemory } from 'lib/utility'
import { modalStyles } from 'lib/constants'
import { resolveLotDisplayInfo } from 'lib/helpers'
import { next_higher_bid } from 'lib/helper_auction'

const fields_initialstate = {
  bid_value: '',
}

LotDetail.propTypes = {
  data: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  auctionData: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  auctionAuthorizationLevel: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  siteinfo: PropTypes.object.isRequired,
  catalog_mode: PropTypes.string.isRequired,
  getLot: PropTypes.func.isRequired,
  placeBid: PropTypes.func.isRequired,
  bidConfirm: PropTypes.func.isRequired,
  toggleWatch: PropTypes.func.isRequired,
  getUserData: PropTypes.func.isRequired,
}

LotDetail.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getLot(params.auctionNumber, params.lotNumber)),
    dispatch(getUserData()),
  ])
}

// ZZZ - implement refresh after lot appears to close on client side to make sure it reflects any extensions due to other bids that occurred

function LotDetail({ data, meta, auctionData, isAuthenticated, auctionAuthorizationLevel, location, params, siteinfo, catalog_mode, placeBid, bidConfirm, toggleWatch }) {
  const [fields, setFields] = useState(fields_initialstate)
  const [showBidPopup, setShowBidPopup] = useState(false)
  const [showZoomImagesPopup, setShowZoomImagesPopup] = useState(false)
  const [showHiresDownloader, setShowHiresDownloader] = useState(false)
  const [initialZoomImage, setInitialZoomImage] = useState(0)

  const { elapsedSeconds } = useLotRefresher({ runOnInit: true, params })

  const _login = () => {
    loginWithMemory(location.pathname)
  }

  const _goToApprovalPage = () => {
    browserHistory.push('/resources/bidder-approval')
  }

  const _closeBidPopup = () => {
    const resolved_bid_value = auctionData.potentialBid.bidresult === 1 ? null : auctionData.potentialBid.resolved_bid
    setFields(prev => ({
      ...prev,
      bid_value: resolved_bid_value,
    }))
    setShowBidPopup(false)
  }

  const _openZoomImages = (index=0) => {
    setInitialZoomImage(index)
    setShowZoomImagesPopup(true)
  }

  const _closeZoomImages = () => {
    setShowZoomImagesPopup(false)
  }

  const _openHiresDownloader = () => {
    setShowHiresDownloader(true)
  }

  const _closeHiresDownloader = () => {
    setShowHiresDownloader(false)
  }

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _placeBid = () => {
    console.log('_placeBid:', fields.bid_value, data)
    if (fields.bid_value > 0) {
      placeBid({ bid_type: 0, bid_source: 0, lot_id: data.id, lot_number: data.lot_number, lot_title: data.desc_title, visible_high_bid: data.price_highbid, resolved_bid: fields.bid_value })
      setShowBidPopup(true)
    }
  }

  const _placeBuy = () => {
    console.log('_placeBuy:', data.calc_price_reserve, data)
    placeBid({ bid_type: 2, bid_source: 0, lot_id: data.id, lot_number: data.lot_number, lot_title: data.desc_title, visible_high_bid: data.price_highbid, resolved_bid: data.calc_price_reserve, buyerPremiumInfo: siteinfo.buyerPremiumInfo })
    setShowBidPopup(true)
  }

  const _nextLot = (event) => {
    event.preventDefault()
    console.log('nextLot', siteinfo.current_auction)
    browserHistory.push('/catalog/lot/' + siteinfo.current_auction + '/' + (parseInt(params.lotNumber, 10) + 1))
  }

  const _previousLot = (event) => {
    event.preventDefault()
    console.log('previousLot', siteinfo.current_auction)
    browserHistory.push('/catalog/lot/' + siteinfo.current_auction + '/' + (parseInt(params.lotNumber, 10) - 1))
  }

  // ZZZ - implement resultset-based prev and next actions instead of just going to next/prev lot number

  let title = "Lot Information"
  let faultNotification = ''
  let content
  const biddingDisabled = typeof auctionAuthorizationLevel === 'undefined' || (auctionAuthorizationLevel < siteinfo.authorization_level_required)

  if (!data.id) {
    if (!forceInteger(params.auctionNumber) || !forceInteger(params.lotNumber)) {
      faultNotification = "Something is wrong with the data requested for this page.  The auction and/or lot number is not valid.  Please contact the Old World Auctions staff if you are seeing this message and haven't modified the address bar parameters"
    } else if (params.lotNumber > siteinfo.lot_count || params.lotNumber < 0) {
      faultNotification = "There is no lot " + params.lotNumber + " in auction " + params.auctionNumber + "."
    }
    content = <div>
      <StatusBox data={meta} />
      {meta.status > 1 && <div>{faultNotification}</div>}
    </div>
  } else {
    title = (catalog_mode === 'archive' ? ('Auction ' + params.auctionNumber + ',') : '') + ' Lot ' + params.lotNumber
    const user_bid = auctionData.bids.data[data.lot_number]
    const user_watch = auctionData.watchlist.data[data.lot_number]
    const userDataInit= auctionData.bids.meta.status === 1 && auctionData.watchlist.meta.status === 1

    const is_high_bidder = user_bid && user_bid.is_high_bidder === 1 ? true : false

    const { display_mode, lot_active } = resolveLotDisplayInfo({ catalog_mode, auction_mode: siteinfo.auction_mode, liveAuctionInProgress: siteinfo.liveAuctionInProgress, data, elapsedSeconds })
    const button_buynow = <button className="btn btn-u btn-u-darkred btn-block" style={{borderRadius: 0, fontWeight: 'bold'}} onClick={_placeBuy}><span>Buy Now </span><span className="fa-fw fa fa-arrow-right"/></button>
    const button_login = <button className='btn btn-u btn-u-darkred btn-block' style={{borderRadius: 0, fontWeight: 'bold'}} onClick={_login}>{'Login to ' + (display_mode > 1 ? 'Buy' : 'Bid')}</button>
    const button_register = <button className='btn btn-u btn-u-darkred btn-block' style={{borderRadius: 0, fontWeight: 'bold'}} onClick={_goToApprovalPage}>{'Register for this auction'}</button>
    const button_unauthenticated = siteinfo.auction_type === 0 ? button_login : button_register

    const bid_block =
      <FusedFields data={fields} onUpdate={_handleUpdate}>
        <div style={{backgroundColor: '#888', fontWeight: 'bold', fontSize: '18px', lineHeight: '20px', textAlign: 'center', padding: '4px'}}>
          <div className="input-group" style={{marginBottom: '4px', width: '100%'}}>
            <input name="bid_value" className="form-control" placeholder="Enter Bid..." data-type="unsigned-int" autoComplete="off" />
          </div>
          <div className="input-group">
            <span className="input-group-btn">
              <button className='btn btn-u btn-u-darkred btn-block' style={{borderRadius: 0, fontWeight: 'bold'}} onClick={_placeBid}>Place Bid</button>
            </span>
          </div>
        </div>
      </FusedFields>

    let side_box_content = null
    let side_box_status = null
    if (display_mode === 0) {
      // archive or closed mode (only shows either sold or unsold -- no bidding or buying activity allowed)
      side_box_content = <>
        <h4 className="text-bold">{data.reserve_met === 1 ? 'Sold for: ' + formatMoneyInteger(data.price_highbid) : 'Unsold'}</h4>
        <h5 style={{fontWeight: 'bold'}}>Closed on {data.date_closed}</h5>
        <h2><div className="block-label color-background-darkred" style={{borderRadius: 0, fontWeight: 'bold'}}><span className="fa fa-fw fa-asterisk"/><span>{catalog_mode === 'archive' ? ' Archived' : ' Closed'}</span></div></h2>
      </>
      side_box_status = <>
        {catalog_mode === 'current' && is_high_bidder && data.reserve_met ? <h5 className="color-green1"><strong>You are the winner</strong></h5> : null}
      </>
    } else if (display_mode === 1) {
      // live mode (either auction is live, or it is in closing mode and this lot has a bid within the extension time period)
      const bid_status_color = is_high_bidder ? (data.reserve_met ? 'color-green1' : 'color-orange') : 'color-red'
      const bid_status_icon = is_high_bidder ? (data.reserve_met ? <span className="fa-fw fa fa-check-circle"/> : <span className="fa-fw fa fa-exclamation-circle"/>) : <span className="fa-fw fa fa-exclamation-circle"/>
      const bid_status_message = is_high_bidder ? (data.reserve_met ? 'High Bidder' : 'High Bidder Under Reserve') : 'Not High Bidder'
      side_box_content = <>
        <h4 className="text-bold">Current High Bid:<br />{ formatMoneyInteger(data.price_highbid || 0) }</h4>
        <LotTimeRemaining auction_mode={siteinfo.auction_mode} display_mode={display_mode} elapsedSeconds={elapsedSeconds} time_since_lastbid={data.time_since_lastbid} />
        <h5>Reserve: <span className="text-bold">{data.reserve_met ? <span className="color-green1">Reserve Met</span> : <span className="color-red">Reserve Not Met</span>}</span></h5>
        <h5>{ 'Next Bid: ' + formatMoneyInteger(next_higher_bid(data.price_highbid || 0, data.price_minimumbid || 0)) }</h5>
        {lot_active && <h2>{isAuthenticated ? (biddingDisabled ? button_register : bid_block) : button_unauthenticated}</h2>}
        <BidIncrementInfo minimumBid={data.price_minimumbid} highBid={data.price_highbid} />
      </>
      side_box_status = <>
        {user_bid && <h5 className={bid_status_color}><span className="text-bold">{bid_status_icon}{bid_status_message}</span></h5>}
        {user_bid && <h5 className={bid_status_color}><span className="text-bold">Your Max Bid: {formatMoneyInteger(user_bid.current_max_bid)}</span></h5>}
      </>
    } else if (display_mode === 3) {
      side_box_content = <>
        <Link className="btn btn-u rounded btn-u-darkred btn-u-contrast-border" style={{width: '100%', fontSize: '14px', lineHeight: '19px', marginBottom: '5px'}} to={'/live-auction'}><span>View Live Auction</span></Link>
      </>
    } else if (display_mode === 4) {
      side_box_content = <>
        <h2><div className="block-label color-background-darkyellow" style={{ borderRadius: 0, fontWeight: 'bold' }}><span>WITHDRAWN</span></div></h2>
      </>
    } else {
      // post-auction mode (no bids meet reserve; available to buy at the reserve price)
      side_box_content = <>
        <h4 className="text-bold">{'Price: ' + (data.calc_price_reserve === 0 ? 'Pending...' : formatMoneyInteger(data.calc_price_reserve))}</h4>
        <h2>{isAuthenticated ? (biddingDisabled ? button_register : (data.calc_price_reserve === 0 ? 'Waiting for Refresh...' : button_buynow)) : button_unauthenticated}</h2>
        <BidIncrementInfo minimumBid={data.price_minimumbid} highBid={data.price_highbid} />
      </>
    }

    // const aux_box = <div>
    //   <div>
    //     {/* <h5>closed_actual: {closed_actual ? 'true' : 'false'}</h5> */}
    //     <h5>bid_extension_time: {bid_extension_time}</h5>
    //     <h5>ms_left: {ms_left}</h5>
    //     <h5>time_since_lastbid: {data.time_since_lastbid}</h5>
    //     <h5>elapsedSeconds: {elapsedSeconds}</h5>
    //     {/* <h5>secondsTillRefresh: {secondsTillRefresh}</h5> */}
    //     <h5>rawTimer: {bid_extension_time + 2 - (data.time_since_lastbid + elapsedSeconds)}</h5>
    //     <h5>resolved_auction_seconds_left: {resolved_auction_seconds_left}</h5>
    //   </div>
    // </div>

    content = <div style={{marginTop: '5px'}}>
      <Helmet>
        <title>{'Auction ' + data.auction_id + ' - Lot ' + data.lot_number + ' - ' + data.desc_title}</title>
      </Helmet>
      <div className="row item-detail-box fixed-gutter-10">
        <div className="col-sm-8 sm-margin-bottom-20 col-md-8 col-lg-9">
          <div className="funny-boxes box-shadow shadow-effect-1">
            <div className="lot-detail-container">
              <div className='lot-detail-header'>
                {data.featured_text && <h2 className="color-red" style={{fontWeight: 'bold'}}>{data.featured_text}</h2>}
                <h3 style={{fontWeight: 'bold'}}>&quot;{data.desc_title}&quot;{data.creator && (', ' + data.creator)}</h3>
              </div>
              <div className="lot-detail-images">
                <LotImageSlider zoomView={_openZoomImages} baseImagePath={__DYNAMIC_IMAGES_RELATIVE_BASE_URL__ + "auctions/" + data.auction_id + "/lots/" + data.auction_id + "-" + String(data.lot_number).padStart(3, '0')} numImages={data.meta_image_count} />
              </div>
              <div className="lot-detail-attributes">
                <h4 style={{fontWeight: 'bold'}}>Subject: {data.desc_subject}</h4>
                <h4 style={{fontWeight: 'bold'}}>Period: {data.desc_period} {data.desc_datetype}</h4>
                <h4 style={{fontWeight: 'bold'}}>Publication: {data.desc_publication}</h4>
                <h4 style={{fontWeight: 'bold'}}>Color: {data.desc_color}</h4>
                <div className="clearfix">
                  <div className="pull-left margin-right-10">
                    <h5 style={{fontWeight: 'bold'}}>Size:</h5>
                  </div>
                  <div className="pull-left">
                    {data.desc_width ? <h5 style={{fontWeight: 'bold'}}>{data.desc_width} x {data.desc_height} inches<br />{round(data.desc_width * 2.54, 1)} x {round(data.desc_height * 2.54, 1)} cm</h5> : <h5 style={{fontWeight: 'bold'}}>See Description</h5>}
                  </div>
                </div>
                <div>
                  <a className="basic-link" onClick={_openHiresDownloader}>Download High Resolution Image</a><br />(or just click on image to launch the Zoom viewer)
                </div>
              </div>
              <div className="lot-detail-description">
                {data.headertext && <h4 dangerouslySetInnerHTML={{__html: data.headertext}}></h4>}
                <h4 dangerouslySetInnerHTML={{__html: data.desc_text}}></h4>
                <h4 style={{fontWeight: 'bold'}}>References: {data.desc_references}</h4>
                <h4 style={{fontWeight: 'bold'}}>Condition: {data.desc_condition}</h4>
                <h4 style={{fontWeight: 'bold'}}>{data.desc_conditiontext}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4 col-md-4 col-lg-3">
          <div className="funny-boxes funny-boxes-top-blue box-shadow shadow-effect-1 text-center">
            {meta.status === 2 && <div>
              <h4 className="text-bold color-red">Unable to contact server!</h4>
              <h6 className="text-bold color-red">Your internet connection<br />may be down</h6>
              <h6 className="text-bold color-red">Please try refreshing the page</h6>
            </div>}
            <h5>Estimate: {formatMoneyInteger(data.price_estimate_low)} - {formatMoneyInteger(data.price_estimate_high)}</h5>
            {side_box_content}
            <WatchListToggle page_mode="detail" lot_id={data.id} lot_number={data.lot_number} hide={catalog_mode !== 'current' || !userDataInit || !!user_bid || !isAuthenticated || data.status_withdrawn === 1 || siteinfo.liveAuctionInProgress} user_watch={user_watch} toggleWatch={toggleWatch} />
            {side_box_status}
          </div>
        </div>
      </div>
      <Modal
        closeTimeoutMS={150}
        isOpen={showBidPopup}
        onRequestClose={() => {}}
        style={modalStyles}
        contentLabel="Bid Window" >
        <BidResult
          potentialBid={auctionData.potentialBid}
          confirmAction={bidConfirm}
          closeAction={_closeBidPopup}
        />
      </Modal>
      <Modal
        closeTimeoutMS={150}
        isOpen={showZoomImagesPopup}
        onRequestClose={_closeZoomImages}
        style={modalStyles}
        htmlOpenClassName="zoomviewer--open"
        contentLabel="View Images" >
        <ZoomViewer auctionNumber={params.auctionNumber} lotNumber={params.lotNumber} catalog_mode={catalog_mode} numImages={data.meta_image_count} initialZoomImage={initialZoomImage} closeAction={_closeZoomImages} />
      </Modal>
      <Modal
        closeTimeoutMS={150}
        isOpen={showHiresDownloader}
        onRequestClose={_closeHiresDownloader}
        style={modalStyles}
        htmlOpenClassName="hiresdownloader--open"
        contentLabel="Download Hi-Res Imagery" >
        <HiresDownloader auctionNumber={params.auctionNumber} lotNumber={params.lotNumber} catalog_mode={catalog_mode} numImages={data.meta_image_count} closeAction={_closeHiresDownloader} />
      </Modal>
    </div>
  }

  const container = <ContentPanel
    title={title}
    icon="fa fa-file-text-o fa-fw"
    prevAction={params.lotNumber > 1 ? _previousLot : null}
    nextAction={params.lotNumber < siteinfo.lot_count ? _nextLot : null}
    beanColor='#e67e22'
    beanIcon='fa fa-asterisk fa-fw'
    beanText={catalog_mode === 'current' ? 'Current Auction' : null}
    showPagingControls={catalog_mode === 'current'}
    auxClassContent="zero-padding"
    styleContent={{ background: 'transparent' }}
  >
    {content}
  </ContentPanel>

  return <SiteView.Consumer>
    {mode => (
      <React.Fragment>
        {container}
      </React.Fragment>
    )}
  </SiteView.Consumer>
}

function mapStateToProps(state) {
  return {
    data: state.lot.content.data,
    meta: state.lot.content.meta,
    auctionData: state.auction,
    isAuthenticated: state.auth.login.data.isAuthenticated,
    auctionAuthorizationLevel: state.auth.login.data.userInfo.auctionAuthorizationLevel,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      getLot,
      placeBid,
      bidConfirm,
      toggleWatch,
      getUserData,
     }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LotDetail)
