import { Component } from 'react'

class User extends Component {
  constructor(props) {
    super(props)
    this.state = { breadcrumb_text: "" };
  }

  render() {
    const { children } = this.props
    return (
      children
    )
  }
}

export default User
