import { processQuery, get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

const base = 'owa/admin/auction_summary/'

const DATA = defineAction(base, 'DATA', 'async')
const WRITE = defineAction(base, 'WRITE', 'async')

const initialState = {
  content: {
    data: [],
    meta: status_defaults,
  },
  write: status_defaults,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        content: {
          ...state.content,
          meta: {
            ...status_defaults,
            processing: true,
          }
        },
        write: status_defaults,
      }
    case DATA.fail:
      return {
        ...state,
        content: {
          ...state.content,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA.success:
      return {
        ...state,
        content: {
          ...state.content,
          data: action.payload.data,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case WRITE.request:
      return {
        ...state,
        write: {
          ...status_defaults,
          processing: true,
        }
      }
    case WRITE.success:
      return {
        ...state,
        write: {
          ...status_defaults,
          ...action.meta,
        }
      }
    case WRITE.fail:
      return {
        ...state,
        write: {
          ...status_defaults,
          ...action.meta,
        }
      }
    default:
      return state
  }
}

export function getAuctionSummary(location) {
  return (dispatch) => {
    const query = location.query

    const querydata = processQuery(query, { whitelist: ['auction_id', 'percent_reserve'] })
    const endpoint = '/api/admin/stats/auction-summary' + querydata
    return dispatch(
      get({
        types: DATA,
        endpoint: endpoint,
      })
    )
  }
}

export function writeAuctionSummary() {
  return (dispatch, getState) => {
    const state = getState()
    const endpoint = '/api/admin/stats/auction-summary-write'

    return dispatch(
      get({
        types: WRITE,
        endpoint: endpoint,
        config: {
          method: 'post',
          body: { summary: state.admin.auction_summary.content.data },
        },
      })
    )
  }
}
