import { get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

import { getSiteInfo } from '../siteinfo'

const base = 'owa/admin/operations/'

const ADVANCE_AUCTION_PHASE = defineAction(base, 'ADVANCE_AUCTION_PHASE', 'async')
const EXPIRE_ARTICLE_CACHE = defineAction(base, 'EXPIRE_ARTICLE_CACHE', 'async')
const TEST_AUCTION_CLOSED_EMAIL = defineAction(base, 'TEST_AUCTION_CLOSED_EMAIL', 'async')
const RESEND_AUCTION_CLOSED_EMAIL = defineAction(base, 'RESEND_AUCTION_CLOSED_EMAIL', 'async')
const GET_OPERATIONS_INFO = defineAction(base, 'GET_OPERATIONS_INFO', 'async')
const GET_REMOTE_CONTACTS = defineAction(base, 'GET_REMOTE_CONTACTS', 'async')
// const UPDATE_REMOTE_CONTACT = defineAction(base, 'UPDATE_REMOTE_CONTACT', 'async')
const UPDATE_ALL_REMOTE_CONTACTS = defineAction(base, 'UPDATE_ALL_REMOTE_CONTACTS', 'async')
const RESOLVE_CONTACTS = defineAction(base, 'RESOLVE_CONTACTS', 'async')
const PERFORM_DIAGNOSTIC_ACTION = defineAction(base, 'PERFORM_DIAGNOSTIC_ACTION', 'async')

const SET_SITE_STATUS = defineAction(base, 'SET_SITE_STATUS', 'async')

const RESET_OPERATIONS_STATUS = base.concat('RESET_OPERATIONS_STATUS')
const POPULATE_FIELDS = base.concat('POPULATE_FIELDS')
const FIELD_SET_VALUE = base.concat('FIELD_SET_VALUE')

const actions_default = {
  advanceAuctionPhase: status_defaults,
  expireArticleCache: status_defaults,
  testAuctionClosedEmail: status_defaults,
  resendAuctionClosedEmail: status_defaults,
  getRemoteContacts: status_defaults,
  // updateRemoteContact: status_defaults,
  updateAllRemoteContacts: status_defaults,
  resolveContacts: status_defaults,
  performDiagnosticAction: status_defaults,
  setSiteStatus: status_defaults,
}

const initialState = {
  operationsInfo: {
    data: {
      current_auction: 0,
      auction_mode: 0,
      advance: {
        target_auction_id: 0,
        target_mode: 0,
      }
    },
    meta: status_defaults,
  },
  actions: actions_default,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_OPERATIONS_STATUS:
      return {
        ...state,
        actions: actions_default,
      }
    case ADVANCE_AUCTION_PHASE.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          advanceAuctionPhase: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case ADVANCE_AUCTION_PHASE.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          advanceAuctionPhase: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case ADVANCE_AUCTION_PHASE.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          advanceAuctionPhase: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case ADVANCE_AUCTION_PHASE.reset:
      return {
        ...state,
        actions: {
          ...state.actions,
          advanceAuctionPhase: status_defaults,
        }
      }
    case EXPIRE_ARTICLE_CACHE.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          expireArticleCache: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case EXPIRE_ARTICLE_CACHE.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          expireArticleCache: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case EXPIRE_ARTICLE_CACHE.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          expireArticleCache: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case TEST_AUCTION_CLOSED_EMAIL.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          testAuctionClosedEmail: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case TEST_AUCTION_CLOSED_EMAIL.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          testAuctionClosedEmail: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case TEST_AUCTION_CLOSED_EMAIL.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          testAuctionClosedEmail: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case RESEND_AUCTION_CLOSED_EMAIL.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          resendAuctionClosedEmail: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case RESEND_AUCTION_CLOSED_EMAIL.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          resendAuctionClosedEmail: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case RESEND_AUCTION_CLOSED_EMAIL.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          resendAuctionClosedEmail: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case GET_OPERATIONS_INFO.request:
      return {
        ...state,
        operationsInfo: {
          ...state.operationsInfo,
          meta: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case GET_OPERATIONS_INFO.success:
      return {
        ...state,
        operationsInfo: {
          data: {
            ...action.payload,
          },
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        },
        fields: {
          ...action.payload,
        }
      }
    case GET_OPERATIONS_INFO.fail:
      return {
        ...state,
        operationsInfo: {
          ...state.operationsInfo,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case SET_SITE_STATUS.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          setSiteStatus: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case SET_SITE_STATUS.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          setSiteStatus: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case SET_SITE_STATUS.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          setSiteStatus: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case GET_REMOTE_CONTACTS.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          getRemoteContacts: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case GET_REMOTE_CONTACTS.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          getRemoteContacts: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case GET_REMOTE_CONTACTS.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          getRemoteContacts: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    // case UPDATE_REMOTE_CONTACT.request:
    //   return {
    //     ...state,
    //     actions: {
    //       ...state.actions,
    //       updateRemoteContact: {
    //         ...status_defaults,
    //         processing: true,
    //       }
    //     }
    //   }
    // case UPDATE_REMOTE_CONTACT.success:
    //   return {
    //     ...state,
    //     actions: {
    //       ...state.actions,
    //       updateRemoteContact: {
    //         ...status_defaults,
    //         ...action.meta,
    //       }
    //     }
    //   }
    // case UPDATE_REMOTE_CONTACT.fail:
    //   return {
    //     ...state,
    //     actions: {
    //       ...state.actions,
    //       updateRemoteContact: {
    //         ...status_defaults,
    //         ...action.meta,
    //       }
    //     }
    //   }
    case UPDATE_ALL_REMOTE_CONTACTS.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          updateAllRemoteContacts: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case UPDATE_ALL_REMOTE_CONTACTS.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          updateAllRemoteContacts: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case UPDATE_ALL_REMOTE_CONTACTS.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          updateAllRemoteContacts: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case RESOLVE_CONTACTS.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          resolveContacts: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case RESOLVE_CONTACTS.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          resolveContacts: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case RESOLVE_CONTACTS.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          resolveContacts: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
      case PERFORM_DIAGNOSTIC_ACTION.request:
        return {
          ...state,
          actions: {
            ...state.actions,
            performDiagnosticAction: {
              ...status_defaults,
              processing: true,
            }
          }
        }
      case PERFORM_DIAGNOSTIC_ACTION.success:
        return {
          ...state,
          actions: {
            ...state.actions,
            performDiagnosticAction: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      case PERFORM_DIAGNOSTIC_ACTION.fail:
        return {
          ...state,
          actions: {
            ...state.actions,
            performDiagnosticAction: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
    case POPULATE_FIELDS:
      return {
        ...state,
        fields: {
          ...action.data,
        }
      }
    case FIELD_SET_VALUE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.field]: action.value,
        }
      }
    default:
      return state
  }
}

export function resetOperationsStatus() {
  return {
    type: RESET_OPERATIONS_STATUS,
  }
}

export function populateFields(data) {
  return {
    type: POPULATE_FIELDS,
    data: data,
  }
}

export function setFieldValue(field, value) {
  return {
    type: FIELD_SET_VALUE,
    field: field,
    value: value,
  }
}

export function resetAdvanceStatus() {
  return {
    type: ADVANCE_AUCTION_PHASE.reset,
  }
}

export function advanceAuctionPhase(expectedAdvanceResult) {
  return (dispatch) => {
    return dispatch(
      get({
        types: ADVANCE_AUCTION_PHASE,
        endpoint: '/api/admin/operations/advance-auction-phase',
        config: {
          method: 'post',
          body: expectedAdvanceResult,
        },
        postSuccess: () => {
          dispatch(getOperationsInfo())
          dispatch(getSiteInfo())
        }
      })
    )
  }
}

export function expireArticleCache() {
  return (dispatch) => {
    return dispatch(
      get({
        types: EXPIRE_ARTICLE_CACHE,
        endpoint: '/api/admin/operations/expire-article-cache',
        config: {
          method: 'post',
        },
        postSuccess: () => {
          dispatch(getOperationsInfo())
          dispatch(getSiteInfo())
        }
      })
    )
  }
}

export function testAuctionClosedEmail() {
  return (dispatch) => {
    return dispatch(
      get({
        types: TEST_AUCTION_CLOSED_EMAIL,
        endpoint: '/api/admin/operations/test-auction-closed-email',
        config: {
          method: 'post',
        },
      })
    )
  }
}

export function resendAuctionClosedEmail() {
  return (dispatch) => {
    return dispatch(
      get({
        types: RESEND_AUCTION_CLOSED_EMAIL,
        endpoint: '/api/admin/operations/resend-auction-closed-email',
        config: {
          method: 'post',
        },
      })
    )
  }
}

export function getRemoteContacts() {
  return (dispatch) => {
    return dispatch(
      get({
        types: GET_REMOTE_CONTACTS,
        endpoint: '/api/admin/operations/get-remote-contacts',
        config: {
          method: 'get',
        },
      })
    )
  }
}

// export function updateRemoteContact() {
//   return (dispatch) => {
//     return dispatch(
//       get({
//         types: UPDATE_REMOTE_CONTACT,
//         endpoint: '/api/admin/operations/update-remote-contact',
//         config: {
//           method: 'post',
//           body: {
//             // original_data: {
//             //   user_email: 'davisontest2@nextgentech.com',
//             //   user_firstname: 'Harry',
//             //   user_lastname: 'Long',
//             //   primary_city: 'Bristol',
//             // },
//             // original_data: {
//             //   user_email: 'davisontest@nextgentech.com',
//             //   user_firstname: 'Davison',
//             //   user_lastname: 'Long',
//             //   primary_city: 'Richmond',
//             //   id_pref_monthly_newsletter: true,
//             //   id_pref_standard_auction_alerts: false,
//             // },
//             original_data: {
//               user_email: 'davisontestb@nextgentech.com',
//               mc_status: 'subscribed',
//               user_firstname: 'Davison',
//               user_lastname: 'TesterB',
//               primary_address_line1: '13713 Queensgate Road',
//               primary_address_line2: '',
//               primary_city: 'Midlothian',
//               primary_state: '',
//               primary_postalcode: '',
//               primary_country: 'GB',
//               id_pref_monthly_newsletter: true,
//               id_pref_standard_auction_alerts: false,
//             },
//             data: {
//               user_email: 'davisontestb@nextgentech.com',
//               mc_status: 'subscribed',
//               user_firstname: 'Davison',
//               user_lastname: 'TesterB',
//               primary_address_line1: '13713 Queensgate Road',
//               primary_address_line2: '',
//               primary_city: 'Midlothian',
//               primary_state: 'VA',
//               primary_postalcode: '23113',
//               primary_country: 'US',
//               id_pref_monthly_newsletter: true,
//               id_pref_standard_auction_alerts: false,
//             }
//           }
//         },
//       })
//     )
//   }
// }

export function updateAllRemoteContacts() {
  return (dispatch) => {
    return dispatch(
      get({
        types: UPDATE_ALL_REMOTE_CONTACTS,
        endpoint: '/api/admin/operations/update-all-remote-contacts',
        config: {
          method: 'post',
        },
      })
    )
  }
}

export function resolveContacts() {
  return (dispatch) => {
    return dispatch(
      get({
        types: RESOLVE_CONTACTS,
        endpoint: '/api/admin/operations/resolve-contacts',
        config: {
          method: 'get',
        },
      })
    )
  }
}

export function performDiagnosticAction() {
  return (dispatch) => {
    return dispatch(
      get({
        types: PERFORM_DIAGNOSTIC_ACTION,
        endpoint: '/api/admin/operations/perform-diagnostic-action',
        config: {
          method: 'post',
        },
      })
    )
  }
}

// only should be used with Diagnostics/Emergency Actions
export function setSiteStatus(data) {
  return (dispatch) => {
    return dispatch(
      get({
        types: SET_SITE_STATUS,
        endpoint: '/api/admin/operations/set-site-status',
        config: {
          method: 'post',
          body: data,
        },
        postSuccess: () => {
          dispatch(getSiteInfo())
          dispatch(getOperationsInfo())
        }
      })
    )
  }
}

export function getOperationsInfo() {
  return (dispatch) => {
    return dispatch(
      get({
        types: GET_OPERATIONS_INFO,
        endpoint: '/api/admin/operations/info',
      })
    )
  }
}
