const base = 'owa/cookie/'

const CLEAR_LOCAL_COOKIE_DATA = base.concat('CLEAR_LOCAL_COOKIE_DATA')

const initialState = {
  value: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_LOCAL_COOKIE_DATA:
      return {
        ...state,
        value: null,
      }
    default:
      return state
  }
}
