import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getAuctionSummary, writeAuctionSummary } from 'rdx/modules/admin/auction_summary'

import ContentPanel from '../shared/ContentPanel'

import FieldText from '../shared/FieldText'
import FormRow from '../shared/FormRow'
import FusedFields from '../shared/fused-fields'
import StatusBox from '../shared/StatusBox'
import LoadingOverlay from '../shared/LoadingOverlay'

import { formatQueryParams, formatServerValue, formatValue, hydrateQueryFields } from 'lib/utility'

const fields_initialstate = {
  auction_id: '',
  percent_reserve: '',
}

AuctionSummary.propTypes = {
  location: PropTypes.object.isRequired,
  siteinfo: PropTypes.object.isRequired,
  auction_summary: PropTypes.object.isRequired,
  getAuctionSummary: PropTypes.func.isRequired,
  writeAuctionSummary: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
}

AuctionSummary.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getAuctionSummary(location)),
  ])
}

function AuctionSummary({ location, siteinfo, auction_summary, getAuctionSummary, writeAuctionSummary, router }) {
  const [fields, setFields] = useState({ ...fields_initialstate, ...(hydrateQueryFields(location.query, fields_initialstate))})
  useEffect(() => {
    getAuctionSummary(location)
  }, [location, getAuctionSummary])

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _refresh = () => {
    router.push({ ...location })
  }

  const _handleFilter = () => {
    let new_query = {}
    Object.keys(fields).map(key => {
      if (typeof fields[key] !== 'undefined' && fields[key] !== ''){ new_query[key] = fields[key] }
    })
    router.push({ ...location, query: new_query })
  }

  const _clearFilter = () => {
    router.push({ ...location, query: {} })
    setFields(fields_initialstate)
  }

  const data = auction_summary.content.data

  const content = <div className="detail-list">
    <div className="detail-list-header">Summary Details</div>
    <div className="margin-bottom-10">
      <div className="row">
        <div className="col-lg-3">
          <span className="text-bold">Server Time:</span><br /><span>{formatValue(data.formatted_server_time)}</span>
        </div>
        <div className="col-lg-3">
          <span className="text-bold">Auction:</span><br /><span>{data.auction_id}</span>
        </div>
        <div className="col-lg-3">
          <span className="text-bold">Percent of Reserve:</span><br /><span>{formatValue(data.reserve_factor * 100, 'percent')}</span>
        </div>
      </div>
    </div>
    <div className="detail-list-header">User Activity</div>
    <div className="margin-bottom-10">
      <div className="row">
        <div className="col-lg-3">
          <span className="text-bold">Number of Bidders:</span><br /><span>{formatServerValue(data.num_bidders)}</span>
        </div>
        <div className="col-lg-3">
          <span className="text-bold">Number of Bids:</span><br /><span>{formatServerValue(data.num_bids)}</span>
        </div>
        <div className="col-lg-3">
          <span className="text-bold">Number of Watchers*:</span><br /><span>{formatServerValue(data.num_watchers)}</span>
        </div>
        <div className="col-lg-3">
          <span className="text-bold">Number of Watches*:</span><br /><span>{formatServerValue(data.num_watches)}</span>
        </div>
      </div>
    </div>
    <div className="detail-list-header">Lot Activity</div>
    <div className="margin-bottom-10">
      <div className="row">
        <div className="col-lg-3">
          <span className="text-bold">Number of Lots:</span><br /><span>{formatServerValue(data.num_lots)}</span>
        </div>
        <div className="col-lg-3">
          <span className="text-bold">Lots with Bids:</span><br /><span>{formatServerValue(data.num_lots_bid_on, { showPercentage: true, totalInfo: data.num_lots })}</span>
        </div>
        <div className="col-lg-3">
          <span className="text-bold">Lots meeting Reserve:</span><br /><span>{formatServerValue(data.num_lots_meeting_reserve, { showPercentage: true, totalInfo: data.num_lots })}</span>
        </div>
        <div className="col-lg-3">
          <span className="text-bold">Lot last bid on:</span><br /><span>{formatServerValue(data.lot_last_bid_on)}</span>
        </div>
      </div>
    </div>
    <div className="detail-list-header">Sales Information</div>
    <div className="margin-bottom-10">
      <div className="row">
        <div className="col-lg-3">
          <span className="text-bold">Sales:</span><br /><span>{formatServerValue(data.sales, { format: 'currency-integer', showPercentage: true, totalInfo: data.low_estimate})}</span>
        </div>
        <div className="col-lg-3">
          <span className="text-bold">Low Estimate:</span><br /><span>{formatServerValue(data.low_estimate, { format: 'currency-integer' })}</span>
        </div>
        <div className="col-lg-3">
          <span className="text-bold">Total Bid Value:</span><br /><span>{formatServerValue(data.total_bid_value, { format: 'currency-integer' })}</span>
        </div>
      </div>
    </div>
    <div className="warningbox">
      *Watch activity is reset after each auction, so only the last auction will have accurate live watch data
    </div>
  </div>

  return <ContentPanel
    title="Auction Summary"
    icon="fa fa-pie-chart fa-fw"
    titleSuffix={' - Auction ' + (typeof location.query.auction_id !== 'undefined' ? location.query.auction_id : siteinfo.current_auction)}
    filteredText={formatQueryParams(location.query)}
  >
    <div>
      <div className='filter-container'>
        <FusedFields data={fields} onUpdate={_handleUpdate}>
          <FormRow>
            <button type='submit' className='btn btn-u btn-u-sea pull-left' onClick={_refresh} style={{ marginLeft: '15px' }}>
              <span className='fa fa-refresh fa-fw' />
            </button>
            <FieldText name='auction_id' addonBefore='Auction #' groupClassName='col-lg-3' data-type="unsigned-int" />
            <FieldText name='percent_reserve' addonBefore='% of Reserve' groupClassName='col-lg-3' data-type="unsigned-int" />
            <button type='submit' className='btn btn-u btn-u-dark-blue' onClick={_handleFilter} style={{ marginRight: '10px' }}>
              <span className='fa fa-filter fa-fw' />
              {' Set Criteria'}
            </button>
            <button type='submit' className='btn btn-u btn-u-red' onClick={_clearFilter} style={{ marginRight: '10px' }}>
              <span className='fa fa-times fa-fw' />
              {' Clear Criteria'}
            </button>
            <button type='submit' className='btn btn-u btn-u-green' onClick={writeAuctionSummary}>
              <span className='fa fa-plus fa-fw' />
              {' Write to Database'}
            </button>
          </FormRow>
        </FusedFields>
      </div>
      <StatusBox data={auction_summary.content.meta} hideUntilError={true} />
      <StatusBox data={auction_summary.write} className="margin-bottom-10" />
      <LoadingOverlay meta={auction_summary.content.meta}>{content}</LoadingOverlay>
    </div>
  </ContentPanel>
}

function mapStateToProps(state) {
  return {
    siteinfo: state.siteinfo.data,
    auction_summary: state.admin.auction_summary,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getAuctionSummary, writeAuctionSummary }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionSummary)