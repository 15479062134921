// Use requestAnimationFrame to emulate setInterval and setTimeout for better browser performance (at least in Chrome)
// scrolling with mousewheel (and maybe other scenarios) can cause indefinite delays with setInterval and setTimeout

export class requestInterval {
  constructor(callback, delay) {
    this.delay = delay
    this.start = Date.now()
    this.cancel = false
    this.callback = callback
    this.raf = requestAnimationFrame(this.intervalFunc)
  }

  intervalFunc = () => {
    Date.now() - this.start < this.delay || (this.start += this.delay, this.callback())
    this.cancel || (this.raf = requestAnimationFrame(this.intervalFunc))
  }

  clear = () => {
    this.cancel = true
    cancelAnimationFrame(this.raf)
  }
}

export class requestTimeout {
  constructor(callback, delay) {
    this.delay = delay
    this.start = Date.now()
    this.cancel = false
    this.callback = callback
    this.raf = requestAnimationFrame(this.timeoutFunc)
  }

  timeoutFunc = () => {
    Date.now() - this.start < this.delay ? this.cancel || requestAnimationFrame(this.timeoutFunc) : this.callback()
  }

  clear = () => {
    this.cancel = true
    cancelAnimationFrame(this.raf)
  }
}