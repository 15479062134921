import React from 'react';
import { Route, IndexRoute, IndexRedirect } from 'react-router';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
//import locationHelperBuilder from 'redux-auth-wrapper/history3/locationHelper'
import { connectedRouterRedirect, createOnEnter } from 'redux-auth-wrapper/history3/redirect';
//import { routerActions } from 'react-router-redux'

// import general app components
import App from './app/base/App';
import NoMatch from './app/base/components/NoMatch';
import NotAuthorized from './app/base/components/NotAuthorized';
import ComingSoon from './app/base/components/ComingSoon';
import BaseContainer from './app/base/components/BaseContainer';

// import Auth and Registration components
import Login from './app/auth/Login';
import ForgotPassword from './app/auth/ForgotPassword';
import Register from './app/auth/Register';
import ProcessAccountAction from './app/auth/ProcessAccountAction';

// import Public components
import Home from './app/public/Home';
import Catalog from './app/public/Catalog';
import CatalogList from './app/public/CatalogList';
import LotDetail from './app/public/LotDetail';

import Info from './app/public/Info';
import Auctions from './app/public/info/Auctions';
import ArticleBase from './app/public/info/ArticleBase';
import ArticleList from './app/public/info/ArticleList';
import ArticleDetail from './app/public/info/ArticleDetail';

import Resources from './app/public/Resources';
import about_us from './app/public/resources/about-us';
import bidder_approval from './app/public/resources/bidder-approval';
import contact_us from './app/public/resources/contact-us';
import copyright from './app/public/resources/copyright';
import faq from './app/public/resources/faq';
import guide from './app/public/resources/guide';
import how_to_consign from './app/public/resources/how-to-consign';
import newsletter_subscribe from './app/public/resources/newsletter-subscribe';
import privacy_policy from './app/public/resources/privacy-policy';
import references_cited from './app/public/resources/references-cited';
import terms_and_conditions from './app/public/resources/terms-and-conditions';
import Testimonials from './app/public/resources/Testimonials';

// import User components
import User from './app/user/User';
import DashboardUser from './app/user/DashboardUser';
import ProfileUser from './app/user/ProfileUser';
import StatusUser from './app/user/StatusUser';
import PurchaseHistory from './app/user/PurchaseHistory';
import CertAuth from './app/user/CertAuth';
import BidHistory from './app/user/BidHistory';
import ConsignmentHistory from './app/user/ConsignmentHistory';
import StatusConsignor from './app/user/StatusConsignor';

// import Admin components
import Admin from './app/admin/Admin';
import DashboardAdmin from './app/admin/DashboardAdmin';
import TabGroupItem from './app/admin/tab-group-item';
import TabGroupUser from './app/admin/tab-group-user';
import SlideGroups from './app/admin/SlideGroups';
import AuctionMode from './app/admin/AuctionMode';
import AuctionOperations from './app/admin/AuctionOperations';
import ResolutionList from './app/admin/ResolutionList';
import AdminReports from './app/admin/admin-reports';
import AdminStats from './app/admin/admin-stats';
import AuctionSummary from './app/admin/AuctionSummary';
import AuctionSummaryHistory from './app/admin/AuctionSummaryHistory';
import BiddingActivity from './app/admin/BiddingActivity';
import LotPerformance from './app/admin/LotPerformance';
import ConsignorSummary from './app/admin/ConsignorSummary';
import SiteDiagnostics from './app/admin/SiteDiagnostics';
import AuctionList from './app/admin/AuctionList';
import AuctionDetail from './app/admin/AuctionDetail';

// import LiveAuction components
import LiveAuction from './app/liveauction/LiveAuction';

// import components used by both admin and user routes
import InvoiceList from './app/invoice/InvoiceList';
import InvoiceDetail from './app/invoice/InvoiceDetail';
import StatementList from './app/statement/StatementList';
import StatementDetail from './app/statement/StatementDetail';

// Sitemap components
import SitemapContainer from './app/public/sitemap/SitemapContainer';
import AuctionIndex from './app/public/sitemap/AuctionIndex';
import LotIndex from './app/public/sitemap/LotIndex';

// Diagnostic components - commented in production
// import date_testing from './app/public/diagnostics/date-testing'

//const locationHelper = locationHelperBuilder({})

const onEnterAuth = createOnEnter({
  redirectPath: '/login',
  authenticatedSelector: state => state.auth.login.data.isAuthenticated,
})

const UserIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: state => state.auth.login.data.isAuthenticated,
  //authenticatingSelector: state => state.auth.login.actions.login.meta.processing,
  //AuthenticatingComponent: Loading,
  //redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsAuthenticated'
})

// const userIsNotAuthenticated = connectedRouterRedirect({
//   redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/foo',
//   allowRedirectBack: false,
//   // Want to redirect the user when they are done loading and authenticated
//   authenticatedSelector: state => state.auth.login.data.isAuthenticated === false && state.auth.login.actions.login.meta.processing === false,
//   redirectAction: routerActions.replace,
//   wrapperDisplayName: 'UserIsNotAuthenticated'
// })

// We don't currently need an onEnter for admin checks because we don't redirect (a failure component is displayed instead)
// const onEnterAdmin = createOnEnter({
//   redirectPath: '/login',
//   authenticatedSelector: state => state.auth.login.data.userInfo.role === 9,
// })

const UserIsAdmin = connectedAuthWrapper({
  authenticatedSelector: state => state.auth.login.data.userInfo.role === 9,
  FailureComponent: NotAuthorized,
  wrapperDisplayName: 'UserIsAdmin'
})

export default (store) => {
  const connect = (fn) => (nextState, replaceState) => fn(store, nextState, replaceState)

  // onEnterChain needed if there are nested redux-auth-wrapper checks with redirection, but we currently don't have any nested checks requiring redirection
  // const onEnterChain = (...listOfOnEnters) => (store, nextState, replace) => {
  //   let redirected = false
  //   const wrappedReplace = (...args) => {
  //     replace(...args)
  //     redirected = true
  //   };
  //   listOfOnEnters.forEach((onEnter) => {
  //     if (!redirected) {
  //       console.log('onEnter: ', onEnter)
  //       onEnter(store, nextState, wrappedReplace)
  //     }
  //   })
  // }

  return (
    <Route path="/" component={App}>
      <IndexRoute name="home" component={Home} show_title={false} />
      <Route name="Login" path="/login" component={Login} show_title={false} />
      <Route name="forgot-password" path="/forgot-password" component={ForgotPassword} show_title={false} />
      <Route name="Register" path="/register" component={Register} show_title={false} />
      <Route name="ProcessAction" path="/process-action/:mode/:token" component={ProcessAccountAction} show_title={false} />
      <Route name="Admin" path="/admin" component={UserIsAuthenticated(UserIsAdmin(Admin))} onEnter={connect(onEnterAuth)} title="Admin Dashboard">
        <Route name="dashboard-admin" component={DashboardAdmin}>
          <IndexRedirect to="mode" />
          <Route name="manage-items" path="/admin/manage-items" component={TabGroupItem} show_title={false} hide_sidebar={true} />
          <Route name="manage-users" path="/admin/manage-users" component={TabGroupUser} show_title={false} hide_sidebar={true} />
          <Route name="admin-slider" path="/admin/slider" component={SlideGroups} />
          <Route name="admin-mode" path="/admin/mode" component={AuctionMode} big_header={true} />
          <Route name="admin-operations" path="/admin/operations" component={AuctionOperations} big_header={true} />
          <Route name="admin-auctions" path="/admin/auctions" component={AuctionList} big_header={true} />
          <Route name="admin-auction" path="/admin/auction/:auction_id" component={AuctionDetail} big_header={true} />
          <Route name="admin-invoices" path="/admin/invoices" component={InvoiceList} big_header={true} />
          <Route name="admin-invoice" path="/admin/invoice/:invoice_id" component={InvoiceDetail} big_header={true} />
          <Route name="admin-statements" path="/admin/statements" component={StatementList} big_header={true} />
          <Route name="admin-statement" path="/admin/statement/:statement_id" component={StatementDetail} big_header={true} />
          <Route name="admin-resolution" path="/admin/resolution" component={ResolutionList} big_header={true} />
          <Route name="admin-reports" path="/admin/runreport/:reportname" component={AdminReports} />
          <Route name="admin-stats-basic" path="/admin/stats/basic/:statsname" component={AdminStats} big_header={true} />
          <Route name="admin-stats-auction-summary" path="/admin/stats/auction-summary" component={AuctionSummary} big_header={true} />
          <Route name="admin-stats-auction-summary-history" path="/admin/stats/auction-summary-history" component={AuctionSummaryHistory} big_header={true} />
          <Route name="admin-stats-bidding-activity" path="/admin/stats/bidding-activity" component={BiddingActivity} big_header={true} />
          <Route name="admin-stats-lot-performance" path="/admin/stats/lot-performance" component={LotPerformance} big_header={true} />
          <Route name="admin-stats-consignor-summary" path="/admin/stats/consignor-summary" component={ConsignorSummary} big_header={true} />
          <Route name="admin-site-diagnostics" path="/admin/site-diagnostics" component={SiteDiagnostics} big_header={true} />
          <Route name="admin-live-auction" path="/admin/live-auction" component={LiveAuction} hide_sidebar={true} />
        </Route>
      </Route>
      <Route name="user" path="/user" component={UserIsAuthenticated(User)} onEnter={connect(onEnterAuth)} title="User Dashboard">
        <Route name="dashboard-user" component={DashboardUser}>
          <IndexRedirect to="invoices" />
          <Route name="profile-user" path="/user/profile" component={ProfileUser} />
          <Route name="auction-status-user" path="/user/status" component={StatusUser} />
          <Route name="user-purchase-history" path="/user/purchase-history" component={PurchaseHistory} />
          <Route name="user-purchase" path="/user/purchase/:lot_id" component={CertAuth} />
          <Route name="user-bid-history" path="/user/bid-history" component={BidHistory} />
          <Route name="user-invoices" path="/user/invoices" component={InvoiceList} />
          <Route name="user-invoice" path="/user/invoice/:invoice_id" component={InvoiceDetail} />
          <Route name="user-consignment-history" path="/user/consignment-history" component={ConsignmentHistory} />
          <Route name="user-statements" path="/user/statements" component={StatementList} />
          <Route name="user-statement" path="/user/statement/:statement_id" component={StatementDetail} />
          <Route name="auction-status-consignor" path="/user/consignor-status" component={StatusConsignor} />
        </Route>
      </Route>
      <Route name="catalog" path="/catalog" component={Catalog} title="Interactive Auction Catalog">
        <IndexRedirect to="current" />
        <Route name="catalog-current-list" path="current" component={CatalogList} title="Auction Catalog (Current)" />
        <Route name="catalog-current-list-category" path="current/:category" component={CatalogList} title="Auction Catalog (Current)" />
        <Route name="catalog-archive-list" path="archive" component={CatalogList} title="Auction Catalog (Archive)" />
        <Route name="catalog-archive-list-category" path="archive/:category" component={CatalogList} title="Auction Catalog (Archive)" />
        <Route name="catalog-lot-detail" path="lot/:auctionNumber/:lotNumber" component={LotDetail} show_title={false} />
      </Route>
      <Route name="info" path="/info" component={Info} title="Info">
        <IndexRedirect to="auctions/past" />
        <Route name="info-auctions" path="auctions/:auctionType" component={Auctions} />
        <Route name="info-articles-base" path="articles" component={ArticleBase} title="Articles">
          <IndexRoute component={ArticleList}/>
          <Route name="info-article" path="/info/article/:url_id" component={ArticleDetail} />
        </Route>
        <Route name="info-articles-base-category" path="articles/:category" component={ArticleBase} title="Articles">
          <IndexRoute component={ArticleList}/>
        </Route>
      </Route>
      <Route name="base-container" component={BaseContainer}>
        <Route name="live-auction" path="/live-auction" component={LiveAuction} title="Live Auction Dashboard" />
      </Route>

      <Route name="contact-us" path="/resources/contact-us" component={contact_us} show_title={false} />
      <Route name="resources" path="/resources" component={Resources} title="Resources">
        <IndexRedirect to="about-us" />
        <Route name="about-us" path="about-us" component={about_us} />
        <Route name="bidder-approval" path="bidder-approval" component={bidder_approval} />
        <Route name="copyright" path="copyright" component={copyright} />
        <Route name="faq" path="faq" component={faq} />
        <Route name="guide" path="guide" component={guide} />
        <Route name="how-to-consign" path="how-to-consign" component={how_to_consign} />
        <Route name="newsletter-subscribe" path="newsletter-subscribe" component={newsletter_subscribe} />
        <Route name="privacy-policy" path="privacy-policy" component={privacy_policy} />
        <Route name="references-cited" path="references-cited" component={references_cited} />
        <Route name="terms-and-conditions" path="terms-and-conditions" component={terms_and_conditions} />
        <Route name="testimonials" path="testimonials" component={Testimonials} />
      </Route>

      <Route name="sitemap" path="/sitemap" component={SitemapContainer} title="Sitemap">
        <Route name="sitemap-auction-list" path="auctions" component={AuctionIndex} />
        <Route name="sitemap-auction-lots" path="auction/:auctionNumber" component={LotIndex} />
      </Route>

      {/* <Route name="date-testing" path="/diagnostics/date-testing" component={date_testing} title="Date Testing" /> */}
      <Route path="*" component={NoMatch} show_title="false" />
    </Route>
  )
}
