import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { SiteView } from '../base/components/siteview-context'
import Sidebar from '../shared/Sidebar'
import Menu from '../base/components/Menu/Menu'

import { user_sidebar_groups } from 'lib/constants'

DashboardUser.propTypes = {
  children: PropTypes.node.isRequired,
  credentials: PropTypes.object.isRequired,
}

function DashboardUser({ children, credentials }) {
  return <SiteView.Consumer>
    {mode => (
      <div className="owa-row">
        {mode.show_sidebar && <Sidebar>
          <Menu
            id="standard-menu-user"
            hideOnMobile={true}
            data={user_sidebar_groups}
            context={{ credentials }}
          />
        </Sidebar>}
        <div className="owa-column owa-content-margins">
          {children}
        </div>
      </div>
    )}
  </SiteView.Consumer>
}

function mapStateToProps(state) {
  return {
    credentials: state.auth.login.data,
  }
}

export default connect(mapStateToProps)(DashboardUser)