import { status_defaults, validation_defaults, defineAction, buildError } from 'lib/state_defaults'
import { verify, processRecaptcha } from 'lib/validation'

const base = 'owa/auth/contact/'

const CONTACT = defineAction(base, 'CONTACT', 'async')

const initialState = {
  actions: {
    processContactForm: {
      meta: status_defaults,
      validation: validation_defaults,
    }
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CONTACT.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          processContactForm: {
            meta: {
              ...status_defaults,
              processing: true,
            },
            validation: validation_defaults,
          }
        }
      }
    case CONTACT.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          processContactForm: {
            meta: {
              ...status_defaults,
              ...action.meta,
            },
            validation: validation_defaults,
          }
        }
      }
    case CONTACT.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          processContactForm: {
            meta: {
              ...status_defaults,
              ...action.meta,
            },
            validation: {
              ...validation_defaults,
              ...action.validation,
            }
          }
        }
      }
    default:
      return state
  }
}

export function processContactForm(fields, recaptchaInstance) {
  return async (dispatch) => {
    const required_fields = ['firstname', 'lastname', 'email', 'message']

    const errors_present = verify({
      required: required_fields,
    }, fields)

    if (errors_present) {
      return dispatch(
        buildError(CONTACT.fail, { statusText: 'One or more required fields are empty!', errors_present: errors_present })
      )
    }

    return processRecaptcha(recaptchaInstance, dispatch, CONTACT, '/api/auth/contact-us', fields)
  }
}