import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

Navbar.propTypes = {
  role: PropTypes.number.isRequired,
  authenticated: PropTypes.bool.isRequired,
}

Navbar.defaultProps = {
  role: 0,
}

function Navbar({ role, authenticated }) {
  const isAdmin = role >= 9
  const isLoggedIn = authenticated
  return (
    <div className="collapse navbar-collapse mega-menu navbar-responsive-collapse">
      <div className="menu-container">
        <ul className="nav navbar-nav" style={{paddingTop: '3px'}}>
          {/* Auctions */}
          <li className="dropdown mega-menu-fullwidth">
          <a className="dropdown-toggle" data-toggle="dropdown">
              Auctions
            </a>
            <ul className="dropdown-menu menu-pane-3-column">
              <li>
                <div className="mega-menu-content">
                  <div className="">
                    <div className="row equal-height">
                      <div className="col-md-4 equal-height-in">
                        <ul className="list-unstyled equal-height-list">
                          <li><h3>Current Auction</h3></li>
                          {/* Current Auction */}
                          {!isLoggedIn && <li><a href="/register">Create Account</a></li>}
                          {!isLoggedIn && <li><a href="/login">Login</a></li>}
                          <li><a href="/catalog/current">Search Auction</a></li>
                          <li><a href="/catalog/current">Browse Auction</a></li>
                          <li><a href="/catalog/current?featured=true">Featured Lots</a></li>
                          {/* End Current Auction */}
                        </ul>
                      </div>
                      <div className="col-md-4 equal-height-in">
                        <ul className="list-unstyled equal-height-list">
                          <li><h3>Past and Future</h3></li>
                          <li><a href="/catalog/archive">Browse Auction Archives</a></li>
                          <li><a href="/info/auctions/upcoming">Auction Calendar</a></li>
                          <li><a href="/info/auctions/past">List of Past Auctions</a></li>
                        </ul>
                      </div>
                      <div className="col-md-4 equal-height-in">
                        <ul className="list-unstyled equal-height-list">
                          <li><h3>Info</h3></li>
                          <li><a href="/resources/guide">Auction Guide</a></li>
                          <li><a href="/resources/how-to-consign">How to Consign</a></li>
                          <li><a href="/resources/faq">FAQ</a></li>
                          <li><a href="/resources/terms-and-conditions">Terms and Conditions</a></li>
                          <li><a href="/resources/testimonials">Client Testimonials</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>
          {/* End Auctions */}
          {/* Portfolio */}
          <li className="dropdown mega-menu-fullwidth">
            <a className="dropdown-toggle" data-toggle="dropdown">
              MY ACCOUNT
            </a>
            <ul className="dropdown-menu menu-pane-3-column">
              <li>
                <div className="mega-menu-content">
                  <div className="">
                    <div className="row equal-height">
                      <div className="col-md-4 equal-height-in">
                        <ul className="list-unstyled equal-height-list">
                          <li><h3>Account Info</h3></li>
                          {/* 4 Columns */}
                          {!isLoggedIn && <li><a href="/register">Create Account</a></li>}
                          {!isLoggedIn && <li><a href="/login">Login</a></li>}
                          <li><a href="/user/profile">Modify Account Info</a></li>
                          {/* End 4 Columns */}
                        </ul>
                      </div>
                      <div className="col-md-4 equal-height-in">
                        <ul className="list-unstyled equal-height-list">
                          <li><h3>For Bidders</h3></li>
                          {/* Default */}
                          <li><a href="/user/status">Bid Status &amp; Watch List</a></li>
                          <li><a href="/user/purchase-history">Purchase History &amp; Certificates of Authenticity</a></li>
                          <li><a href="/user/invoices">View Invoices</a></li>
                          {/* End Default */}
                        </ul>
                      </div>
                      <div className="col-md-4 equal-height-in">
                        <ul className="list-unstyled equal-height-list">
                          <li><h3>For Consignors</h3></li>
                          <li><a href="/user/consignor-status">Consignment Status</a></li>
                          <li><a href="/user/consignment-history">Consignment History</a></li>
                          <li><a href="/user/statements">View Statements</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>
          {/* Ens Portfolio */}
          {/* Contacts */}
          <li className="dropdown mega-menu-fullwidth">
            <a className="dropdown-toggle" data-toggle="dropdown">
              Contact
            </a>
            <ul className="dropdown-menu menu-pane-1-column">
              <li>
                <div className="mega-menu-content">
                  <div className="row equal-height">
                    <div className="col-md-12 equal-height-in">
                      <ul className="list-unstyled equal-height-list">
                        <li><a href="/resources/contact-us">Send us a message</a></li>
                        <li><a href="/resources/contact-us">Phone and Email Info</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>
          {/* End Contacts */}
        </ul>
      </div>{/*/end container*/}
    </div>
  )
}

export default Navbar