import { get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

const base = 'owa/liveAuction/'

const NOTIFICATION = base.concat('NOTIFICATION')
const SET_LIVE_AUCTION_INFO = base.concat('SET_LIVE_AUCTION_INFO')
const BID_REQUESTED = base.concat('BID_REQUESTED')
const BID_ACCEPTED = base.concat('BID_ACCEPTED')
const LOT_ADVANCED = base.concat('LOT_ADVANCED')
const UNKNOWN_ACTION = base.concat('UNKNOWN_ACTION')

const actions_defaults = {
  advanceLot: status_defaults,
}

// const fields_defaults = {
//   customMessage: null,
// }

const initialState = {
  base: {
    current_lot_number: 0,
    closed: 0,
    bidder_id: 0,
    reserve_met: false,
    is_high_bidder: false,
    is_pending_bidder: false,
    current_auction: 0,
    bidRequestPending: false,
  },
  admin: {},
  messages: [],
  meta: status_defaults,
  actions: actions_defaults,
  // fields: fields_defaults,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_LIVE_AUCTION_INFO:
      return {
        ...state,
        base: action.data.base,
        admin: action.data.admin,
      }
    case NOTIFICATION:
      return {
        ...state,
        messages: [
          ...state.messages,
          ...action.messages,
        ],
      }
      case BID_REQUESTED:
        return {
          ...state,
          base: {
            ...state.base,
            ...action.data.base,
            bidRequestPending: true,
          },
          messages: [
            ...state.messages,
            ...action.messages,
          ],
          admin: {
            ...state.admin,
            ...(action.data.admin && { ...action.data.admin }),
          }
        }
      case BID_ACCEPTED:
        return {
          ...state,
          base: {
            ...state.base,
            ...action.data.base,
            bidRequestPending: false,
          },
          messages: [
            ...state.messages,
            ...action.messages,
          ],
          admin: {
            ...state.admin,
            bidRequest: null,
          }
        }
      case LOT_ADVANCED:
        return {
          ...state,
          base: {
            ...state.base,
            ...action.data.base,
          },
          messages: [
            ...state.messages,
            ...action.messages,
          ],
        }
    default:
      return state
  }
}

export function processIncomingMessage(rawMessage) {
  const parsedMessage = JSON.parse(rawMessage)
  console.log('processIncomingMessage:', parsedMessage)

  switch (parsedMessage.action) {
    case 'initialstate':
      return setLiveAuctionInfo(parsedMessage.payload)
    case 'notification':
      return {
        type: NOTIFICATION,
        messages: parsedMessage.payload.messages,
      }
    case 'bid_requested':
      return {
        type: BID_REQUESTED,
        data: parsedMessage.payload.resolvedData,
        messages: parsedMessage.payload.messages,
      }
    case 'bid_accepted':
      return {
        type: BID_ACCEPTED,
        data: parsedMessage.payload.resolvedData,
        messages: parsedMessage.payload.messages,
      }
    case 'lot_advanced':
      return {
        type: LOT_ADVANCED,
        data: parsedMessage.payload.resolvedData,
        messages: parsedMessage.payload.messages,
      }
    default:
      return {
        type: UNKNOWN_ACTION,
      }
  }
}

export function setLiveAuctionInfo(data) {
  return {
    type: SET_LIVE_AUCTION_INFO,
    data: data.payload,
  }
}
