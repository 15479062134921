import { get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

const base = 'owa/lot/'

const DATA = defineAction(base, 'DATA', 'async')

const initialState = {
  content: {
    data: {},
    meta: status_defaults,
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        content: {
          ...state.content,
          meta: {
            ...status_defaults,
            // initialized: state.content.meta.initialized,
            processing: true,
            timestamp: state.content.meta.timestamp,
          }
        }
      }
    case DATA.success:
      return {
        ...state,
        content: {
          ...state.content,
          data: action.payload.data,
          meta: {
            ...status_defaults,
            // initialized: true,
            ...action.meta,
          }
        }
      }
    case DATA.fail:
      return {
        ...state,
        content: {
          ...state.content,
          meta: {
            ...status_defaults,
            // initialized: state.content.meta.initialized,
            ...action.meta,
          }
        }
      }
    default:
      return state
  }
}

export function getLot(auction_id, lot_number) {
  return (dispatch) => {
    return dispatch(
      get({
        types: DATA,
        endpoint: '/api/public/catalog/lot/' + auction_id + '/' + lot_number,
      })
    )
  }
}
