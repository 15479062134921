import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { toggleMenu } from 'rdx/modules/menus'

import Menu from './Menu/Menu'

import { user_sidebar_groups } from 'lib/constants'

MobileMenuUser.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  credentials: PropTypes.object.isRequired,
}

MobileMenuUser.defaultProps = {

}

const id = "mobile-menu-user"

function MobileMenuUser({ menuOpen, toggleMenu, credentials }) {
  return <Menu
    id={id}
    type="mobile"
    position="left"
    title="Account Info"
    titleIcon={<span className='fa fa-list fa-fw' />}
    hideBurgerIcon={true}
    menuOpen={menuOpen}
    toggleMenu={toggleMenu}
    data={user_sidebar_groups}
    context={{credentials}}
  />
}

function mapStateToProps(state) {
  return {
    menuOpen: state.menus[id],
  }
}

const mapDispatchToProps = { toggleMenu }

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuUser)
