import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class NoMatch extends Component {

  render() {
    return (
      <div>
        <Helmet>
          <title>Page Not Found</title>
        </Helmet>
        <div style={{padding: '20px'}}>
          <div className="tag-box tag-box-v3 margin-bottom-40">
            <h2>Page not Found</h2>
            <p>This content appears to be missing...</p>
          </div>
        </div>
      </div>
    )
  }
}

export default NoMatch;
