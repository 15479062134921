import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'

import { connect } from 'react-redux'

import {SiteView} from '../../base/components/siteview-context'
import Menu from '../../base/components/Menu/Menu'
import ArticleFilterPanel from './ArticleFilterPanel'
import Sidebar from '../../shared/Sidebar'

import { menu_content_articles } from 'lib/constants'
import { getLinkCanonical, buildPath } from 'lib/utility'

ArticleBase.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  siteinfo: PropTypes.object.isRequired,
  articlesInfo: PropTypes.object.isRequired,
}

function ArticleBase({ children, location, params, siteinfo, articlesInfo }) {
  let content

  const backToList_destination = {
    pathname: buildPath('/info/articles', articlesInfo.params.category),
    query: articlesInfo.location && articlesInfo.location.query ? articlesInfo.location.query : {},
  }
  const button_backToList = <Link className="btn btn-u rounded btn-u-darkred btn-u-contrast-border" style={{width: '100%', fontSize: '14px', lineHeight: '19px', marginBottom: '5px'}} to={ location => ({ ...location, pathname: backToList_destination.pathname, query: backToList_destination.query }) }><span><i className="fa fa-table"></i> <i className="fa fa-arrow-left"></i> Back to List</span></Link>

  content = <div className="owa-row">
    <Helmet>
      <title>{'Articles Archive'}</title>
      <link rel="canonical" href={getLinkCanonical(location, ['printview', 'referral'])} />
    </Helmet>
    <SiteView.Consumer>
      {mode => (
        <div className="owa-row">
          {mode.show_sidebar && <Sidebar>
            {params.url_id && button_backToList}
            <Menu
              id="standard-menu-articles"
              hideOnMobile={true}
              data={menu_content_articles}
              siteinfo={siteinfo}
              location={location}
              params={params}
              context={{siteinfo}}
              storedQuery={articlesInfo.location.query}
            />
          </Sidebar>}
          <div className="owa-column owa-content-margins">
            <div className="dashboard-element">
              {(!params.url_id || mode.wrap_content) && <ArticleFilterPanel location={location} listInfo={articlesInfo} />}
            </div>
            {!params.url_id && <div className="dashboard-element">
              <div className="page-title text-center">Articles</div>
            </div>}
            {React.Children.only(React.cloneElement(children, { siteinfo }))}
          </div>
        </div>
      )}
    </SiteView.Consumer>
  </div>

  return <>{content}</>
}

function mapStateToProps(state) {
  return {
    siteinfo: state.siteinfo.data,
    articlesInfo: state.info.articles.archive.content.info,
  }
}

export default connect(mapStateToProps)(ArticleBase)
