import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import { useLayoutEffect } from './hooks/useIsomorphicLayoutEffect'
import { requestTimeout } from 'lib/timers'
import { contact_email } from 'lib/constants'

function resolveContactEmail(s) {
  if (s && s.includes('@@contact-email@@')) {
    const p = s.split('@@contact-email@@')
    return <span>{p[0]}<a href={"mailto:" + contact_email}>{contact_email}</a>{p[1]}</span>
  } else {
    return s
  }
}

StatusItem.propTypes = {
  data: PropTypes.object.isRequired,
  childContent: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.string,
  hideUntilDefined: PropTypes.bool,
  hideOnSuccess: PropTypes.bool,
  hideUntilError: PropTypes.bool,
  hidePrimaryOnSuccess: PropTypes.bool,
  persist: PropTypes.bool,
  allowClose: PropTypes.bool,
  animationTime: PropTypes.number,
  displayTime: PropTypes.number,
}

StatusItem.defaultProps = {
  childContent: null,
  // className: '',
  // style: {},
  // size: 'small',
  // hideUntilDefined: true,
  // hideOnSuccess: false,
  // hidePrimaryOnSuccess: false,
  hideUntilError: false,
  // persist: false,
  // allowClose: false,
  // animationTime: 500,
  // displayTime: 10000,
}

function refreshPage() {
  window.location.reload()
}

function StatusItem({ data, size, className, style, childContent, hideUntilDefined, hideOnSuccess, hideUntilError, persist, allowClose, displayTime, animationTime }={}) {
  const [age, setAge] = useState(data.timestamp ? Math.trunc((Date.now() - data.timestamp)/1000) : 0)
  const [visible, setVisibility] = useState(false)

  useEffect(() => {
    // console.log('setAge:', (data.timestamp ? Math.trunc((Date.now() - data.timestamp)/1000) : 0))
    setAge(data.timestamp ? Math.trunc((Date.now() - data.timestamp)/1000) : 0)
  }, [data.timestamp])

  useLayoutEffect(() => {
    function timeout() {
      // console.error('StatusItem-visibility-timeout')
      setVisibility(false)
    }

    if (data.processing || persist || (data.status !== 0 && (age * 1000 < (displayTime + animationTime)))) {
      // console.log('StatusItem-make-visible')
      setVisibility(true)
      if (data.status === 1 && !persist) {
        const atimer = new requestTimeout(timeout, (displayTime + animationTime - (age * 1000)))
        return () => {
          // console.log('StatusItem-cleartimeout')
          atimer.clear()
        }
      }
    }
  }, [data.id, data.status, data.processing, animationTime, displayTime, persist, age])


  let info = { text: 'No Result Available', mode: 'info', icon: 'fa-info-circle' }
  if (data.processing && !hideUntilError) {
    info = { ...info, icon: 'fa-circle-o-notch fa-spin', text: 'Processing...' }
  } else {
    switch (data.status) {
      case 0:
        if (hideUntilDefined || hideUntilError) { return null }
        // info = { text: 'No Result Available', mode: 'info', icon: 'fa-info-circle' }
        break
      case 1:
        if (hideOnSuccess || hideUntilError) { return null }
        info = { ...info, text: 'Success!', mode: 'success', icon: 'fa-check-circle' }
        break
      case 2:
        info = { ...info, text: 'Error!', mode: 'danger', icon: 'fa-times-circle' }
        break
      case 3:
        info = { ...info, text: 'Warning!', mode: 'warning', icon: 'fa-exclamation-triangle' }
        break
      case 4:
        info = { ...info, text: 'Info', mode: 'info', icon: 'fa-info-circle' }
        break
      default:
        info = { ...info, text: 'Exception!', mode: 'danger', icon: 'fa-exclamation-triangle' }
    }
  }

  const modeClass = 'alert-' + info.mode
  const iconBoxModeClass = 'icon-box-' + modeClass
  const finalContainerClass = classNames('notification-container', className)
  const finalNotificationIconBoxClass = classNames('alert', modeClass, 'fade', 'in', 'notification-component', iconBoxModeClass)
  const finalNotificationMessageClass = classNames('alert', modeClass, 'fade', 'in', 'notification-component', 'notification-message')

  let componentPaddingVertical = '15px'
  let componentPaddingHorizontal = '15px'
  let iconSize = 'fa-3x'

  switch (size) {
    case 'small':
      componentPaddingVertical = '5px'
      componentPaddingHorizontal = '5px'
      iconSize = 'fa-2x'
      break
    case 'micro':
      componentPaddingVertical = '2px'
      componentPaddingHorizontal = '5px'
      iconSize = 'fa-2x'
      break
  }

  // ZZZ - override icon if custom icon or iconSize passed?

  // ZZZ - check for statusText array usage?  Does that work with new responder library?

  const baseComponentStyle = { padding: componentPaddingVertical + ' ' + componentPaddingHorizontal }

  const closeable = allowClose && data.status !== 0 && !data.processing

  const resolvedStatusDirective = resolveContactEmail(data.statusDirective)

  const item = <div key={data.id} className={finalContainerClass}>
    {closeable && <div className="notification-closer" onClick={() => setVisibility(false)}><i className={'fa fa-fw fa-times'} /></div>}
    <div className={finalNotificationIconBoxClass} style={{...baseComponentStyle, ...style}}>
      <div>
        <i className={classNames('fa', 'fa-fw', info.icon, iconSize)} />
      </div>
    </div>
    <div className={finalNotificationMessageClass} style={{...baseComponentStyle, ...style}}>
      <div>
        <div>
          {info.raw && info.raw}
          {data.statusCategory && <div className="text-bold">{data.statusCategory}</div>}
          <div>{Array.isArray(data.statusText) && data.statusText.length ? data.statusText.map((m, i) => <div key={i}>{m}</div>) : (data.statusText && data.statusText !== '' ? data.statusText : info.text)}</div>
          {data.statusExtendedText && <div>{data.statusExtendedText}</div>}
          {resolvedStatusDirective && <div className="text-bold">{resolvedStatusDirective}</div>}
          {data.displayRefresh && <div className="text-bold"><a style={{ cursor: 'pointer' }} onClick={refreshPage}>Click here to refresh the page...</a></div>}
        </div>
        {childContent}
      </div>
    </div>
  </div>

  return <CSSTransition
    in={visible}
    timeout={animationTime}
    appear={true}
    classNames="message"
    // mountOnEnter
    unmountOnExit
    // onEnter={this.handleEnter}
    // onEntered={this.handleEntered}
    // onExit={this.handleExit}
    // onExiting={this.handleExiting}
    // onExited={this.handleExited}
  >
    {item}
  </CSSTransition>
}

export default StatusItem