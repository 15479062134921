import React from 'react'
import PropTypes from 'prop-types'

FilterDropdownItem.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  displayvalue: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

function FilterDropdownItem({ item, displayvalue, onClick }) {
  const _onClick = () => {
    onClick(item)
  }

  return <li data-value={displayvalue}>
    <a onClick={_onClick}>{displayvalue}</a>
  </li>
}

export default FilterDropdownItem