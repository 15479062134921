import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getData } from 'rdx/modules/invoices'

import ContentPanel from '../shared/ContentPanel'
import DataList from '../shared/DataList'
import DataListRow from '../shared/DataListRow'

import FieldBase from '../shared/FieldBase'
import FieldText from '../shared/FieldText'
import FormContainer from '../shared/FormContainer'
import FormRow from '../shared/FormRow'
import FusedFields from '../shared/fused-fields'

import { formatQueryParams, hydrateQueryFields, makeOptions, makeOptionsBoolean } from 'lib/utility'
import { invoiceList_specs } from 'lib/list_specifications'

import connectDataList from '../shared/composers/connectDataList'

const DataListBound = connectDataList(DataList, state => ({ statebranch: state.invoices.content }))

const fields_initialstate = {
  auction_id: '',
  user_lastname: '',
  country_type: '',
  ready: '',
  changed: '',
  approved: '',
  paid: '',
}

InvoiceList.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
}

InvoiceList.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getData(location)),
  ])
}

function InvoiceList({ location, params, router, getData }) {
  const [fields, setFields] = useState({ ...fields_initialstate, ...(hydrateQueryFields(location.query, fields_initialstate))})
  useEffect(() => {
    getData(location)
  }, [location, getData])

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _handleFilter = () => {
    let new_query = {}
    Object.keys(fields).map(key => {
      if (typeof fields[key] !== 'undefined' && fields[key] !== ''){ new_query[key] = fields[key] }
    })
    router.push({ ...location, query: new_query })
  }

  const _clearFilter = () => {
    router.push({ ...location, query: {} })
    setFields(fields_initialstate)
  }

  const mode = location.pathname.includes('/admin/') ? 'admin' : 'user'

  let content = <DataListBound
    useTable={true}
    showHeader={true}
    showTopPagingControl={false}
    pagingControlSize='slim'
    metadata={invoiceList_specs[mode]}
    datakey='id'
    clickTargetTemplate={location.pathname === '/admin/invoices' ? '/admin/invoice/<param1>' : '/user/invoice/<param1>'}
    clickTargetParam1='id'
    clickTargetQueryParams={location.query}
    params={params}
    location={location}
  >
    <DataListRow />
  </DataListBound>

  const search_fields_admin = <FusedFields data={fields} onUpdate={_handleUpdate}>
    <FormContainer rowClassName="fixed-gutter-5 margin-bottom-5" size='small' noMargins={true} addonBeforeStyle={{ textAlign: 'left', flexShrink: '0' }}>
      <FormRow>
        <FieldText name='auction_id' addonBefore='Auction #' groupClassName='col-lg-4' data-type='unsigned-int' />
        <FieldText name='user_lastname' addonBefore='Last Name' groupClassName='col-lg-4' />
        <FieldBase type="select" name='country_type' addonBefore='Country' groupClassName='col-lg-4'>
          {makeOptions([{ value: 'United States', text: 'United States' },{ value: 'Non-US', text: 'Non-US' }], { emptyOption: true })}
        </FieldBase>
      </FormRow>
      <FormRow>
        <FieldBase type="select" name='ready' addonBefore='Ready' groupClassName='col-lg-2'>
          {makeOptionsBoolean({ emptyOption: true })}
        </FieldBase>
        <FieldBase type="select" name='changed' addonBefore='Changed' groupClassName='col-lg-2'>
          {makeOptionsBoolean({ emptyOption: true })}
        </FieldBase>
        <FieldBase type="select" name='approved' addonBefore='Approved' groupClassName='col-lg-2'>
          {makeOptionsBoolean({ emptyOption: true })}
        </FieldBase>
        <FieldBase type="select" name='paid' addonBefore='Paid' groupClassName='col-lg-2'>
          {makeOptionsBoolean({ emptyOption: true })}
        </FieldBase>
        <div className='col-lg-4'>
          <button type='submit' className='btn btn-u btn-u-dark-blue pull-right' onClick={_handleFilter} style={{ marginLeft: '10px' }}>
            <span className='fa fa-filter fa-fw' />
            {' Filter'}
          </button>
          <button type='submit' className='btn btn-u btn-u-red pull-right' onClick={_clearFilter}>
            <span className='fa fa-times fa-fw' />
            {' Clear Filter'}
          </button>
        </div>
      </FormRow>
    </FormContainer>
  </FusedFields>

  const search_fields_user = <FusedFields data={fields} onUpdate={_handleUpdate}>
    <FormContainer rowClassName="fixed-gutter-5 margin-bottom-5" size='small' noMargins={true} addonBeforeStyle={{ textAlign: 'left', flexShrink: '0' }}>
      <FormRow>
        <FieldText name='auction_id' addonBefore='Auction #' groupClassName='col-lg-4' data-type='unsigned-int' />
        <div className='col-lg-8'>
          <button type='submit' className='btn btn-u btn-u-dark-blue pull-right' onClick={_handleFilter} style={{ marginLeft: '10px' }}>
            <span className='fa fa-filter fa-fw' />
            {' Filter'}
          </button>
          <button type='submit' className='btn btn-u btn-u-red pull-right' onClick={_clearFilter}>
            <span className='fa fa-times fa-fw' />
            {' Clear Filter'}
          </button>
        </div>
      </FormRow>
    </FormContainer>
  </FusedFields>

  const search_fields = mode === 'admin' ? search_fields_admin : null

  const guide_message = <div>
    <div className="text-bold text-center" style={{fontSize: '18px'}}>
      Click on an invoice below to view, approve, and/or pay a balance.
    </div>
    <div className="text-center">
      Currently only invoices for auction 167 and later are listed here, but we are working on adding invoices for previous auctions as well.
    </div>
  </div>

  const legend = <div className="legend-general legend-border-top text-center">
    <div className="legend-general-item"><span className="caution"><i className="fa fa-exclamation-circle"></i> Pending Review</span></div>
    <div className="legend-general-item"><span className="ready"><i className="fa fa-flag"></i> Ready</span></div>
    <div className="legend-general-item"><span className="resolved"><i className="fa fa-check"></i> Approved</span></div>
    <div className="legend-general-item"><span className="paid"><i className="fa fa-usd"></i> Paid</span></div>
  </div>

  return <ContentPanel
    title="Invoices"
    icon="fa fa-file-text-o fa-fw"
    filteredText={formatQueryParams(location.query)}
  >
    <div>
      <div className="clearfix">
        {search_fields}
      </div>
      {mode === 'user' && guide_message}
      {legend}
      {content}
    </div>
  </ContentPanel>
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getData }, dispatch),
    dispatch,
  }
}

export default connect(null, mapDispatchToProps)(InvoiceList)