import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'

import SliderManager from '../shared/SliderManager'

Home.propTypes = {
  location: PropTypes.object.isRequired,
}

function Home({ location }) {
  return <div className="home-container">
    <Helmet>
      <title>Home</title>
      <link rel="canonical" href={location.pathname} />
    </Helmet>
    <SliderManager />
    <div className="home-page-section clearfix">
      <div className="home-section-title">Bid With Confidence</div>
      <div className="home-confidence-container">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <div className="confidence-content">
            <div><img src="/static/img/g_guarantee.png" /></div>
            <div className="confidence-summary">Money-Back Guarantee</div>
            <div className="confidence-description">We guarantee the condition report of every item</div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <div className="confidence-content">
            <div><img src="/static/img/g_ribbon.png" /></div>
            <div className="confidence-summary">Certificates of Authenticity</div>
            <div className="confidence-description">We ensure the authenticity of everything we sell</div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <div className="confidence-content">
            <div><img src="/static/img/g_support.png" /></div>
            <div className="confidence-summary">Superior<br />Customer Service</div>
            <div className="confidence-description">Highly rated on <a href="https://www.google.com/search?q=old+world+auctions&ie=utf-8&oe=utf-8&client=firefox-b-1#lrd=0x89b1401d7d2df80b:0xc01045efdd6875c0,1" target="_blank" rel="noopener noreferrer">Google</a> and <a href="https://www.facebook.com/pg/OldWorldAuctions/reviews/?ref=page_internal" target="_blank" rel="noopener noreferrer">Facebook</a></div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4 col-md-offset-2">
          <div className="confidence-content">
            <div><img src="/static/img/g_dollarsign.png" /></div>
            <div className="confidence-summary">Pricing<br />Guide</div>
            <div className="confidence-description">See what our maps have sold for over the last 20 years in our Archive</div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-0">
          <div className="confidence-content">
            <div><img src="/static/img/g_knowledge.png" /></div>
            <div className="confidence-summary">Specialized Expertise</div>
            <div className="confidence-description">Over 100,000 maps researched and listed in our auctions since 1977</div>
          </div>
        </div>
      </div>
    </div>
    <div className="horizontal-divider" />
    <div className="home-section-title">What our Customers Say</div>
    <div className="home-page-section clearfix">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <div className="message-bubble message-bubble-left">I have learned to value your descriptions of auction lots as very accurate and they can be relied on without hesitation. The bidding process is easy and the bid notifications are very timely. I think you have the “system” down pat! A+</div>
        <div className="message-attribution">
          <span className="message-user">John B.</span>
          <span>Bidder, USA</span>
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <div className="message-bubble message-bubble-left">In all the years that I have been following and purchasing maps and books from Old World Auctions, I have always felt that I can trust and count on your professional service.</div>
        <div className="message-attribution">
          <span className="message-user">Marios N.</span>
          <span>Bidder, Cyprus</span>
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-0">
        <div className="message-bubble message-bubble-left">Wow, you and your staff certainly brought some serious bidders to our consignment. Great job, so glad we decided to consign with Old World Auctions. Thanks so much for your professional help.</div>
        <div className="message-attribution">
          <span className="message-user">Richard T.</span>
          <span>Consignor, USA</span>
        </div>
      </div>
    </div>
    <div className="horizontal-divider" />
    <div className="home-page-section clearfix">
      <div className="home-section-title">Get Started Bidding</div>
      <div className="home-get-started-container">
        <ol>
          <li><Link to="/register">Register to bid</Link></li>
          <li>Read our <Link to="/resources/guide">Guide to the Auction</Link> and <Link to="/resources/terms-and-conditions">Terms &amp; Conditions</Link></li>
          <li>Check our <Link to="/info/auctions/upcoming">Auction Calendar</Link></li>
          <li>View our bidder <a href="https://www.youtube.com/channel/UCMBnRZd9iQ5GlPZJlXvhHEQ?disable_polymer=true" target="_blank" rel="noopener noreferrer">video tutorials</a> on YouTube</li>
          <li>When the auction catalog goes online, <Link to="/catalog/current">Browse</Link> and <Link to="/catalog/current">Search</Link> the auction</li>
          <li>Add items to your <Link to="/user/status">Watch List</Link>, and view your active bids on your <Link to="/user/status">Bid Status</Link> page</li>
        </ol>
      </div>
    </div>
    <div className="horizontal-divider" />
    <div className="home-page-section clearfix">
      <div className="home-section-title">Learn From the Experts</div>
      <div className="home-learn-container">
        <div className="col-xs-12 col-sm-6 col-md-6">
          <div className="learn-content" style={{backgroundImage: 'url("/static/img/learn_ship.png")'}}>
            <a className="btn-u btn-u-darkred" href="/static/docs/11_secrets_to_map_collecting.pdf" target="_blank">11 Secrets to<br />Map Collecting</a>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <div className="learn-content" style={{backgroundImage: 'url("/static/img/learn_compass.png")'}}>
            <Link to="/info/articles"><button className="btn-u btn-u-darkred">Read our<br />Articles</button></Link>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <div className="learn-content" style={{backgroundImage: 'url("/static/img/learn_monster.png")'}}>
            <a className="btn-u btn-u-darkred" href="https://www.facebook.com/OldWorldAuctions" target="_blank" rel="noopener noreferrer">Daily Tips on Facebook</a>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <div className="learn-content" style={{backgroundImage: 'url("/static/img/learn_franklin.png")'}}>
            <a className="btn-u btn-u-darkred" href="/static/docs/state_of_franklin_maps_v12.pdf" target="_blank" rel="noopener noreferrer">State of Franklin<br />Carto-Bibliography</a>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default Home
