import { processQuery, get } from 'lib/api'
import { defineAction, status_defaults } from 'lib/state_defaults'
import { validDateOrNull } from 'lib/validation'
import { formatDate } from 'lib/utility'

const base = 'owa/statement/'

const DATA_STATEMENT = defineAction(base, 'DATA_STATEMENT', 'async')
const ADMIN_UPDATE_STATEMENT = defineAction(base, 'ADMIN_UPDATE_STATEMENT', 'async')
const LINEITEM_ADD = defineAction(base, 'LINEITEM_ADD', 'async')
const LINEITEM_REMOVE = defineAction(base, 'LINEITEM_REMOVE', 'async')

const FIELD_SET_VALUE = base.concat('FIELD_SET_VALUE')

const content_defaults = {
  init: false,
  init_date: null,
  ready: false,
  ready_date: null,
  changed: false,
  paid: false,
  main: {},
  lots: [],
  lineitems: [],
  lot_count: 0,
  lineitem_count: 0,
  admin_info: {
    data: {
      consignor_commission_structure_id: '',
      commission_scheme: '',
      st_notes: '',
    },
    original: {
      consignor_commission_structure_id: '',
      commission_scheme: '',
      st_notes: '',
    },
    meta: {
      complete: false,
      notable_change: false,
      loaded: false,
    },
  },
}

const actions_defaults = {
  adminUpdate: status_defaults,
  lineitemAdd: status_defaults,
  lineitemRemove: status_defaults,
}

const initialState = {
  meta: status_defaults,
  content: content_defaults,
  actions: actions_defaults,
}

function buildUserInfo(data) {
  return {
    st_paid: data.st_paid,
    st_notes: data.st_notes,
    commission_scheme: data.commission_scheme,
  }
}

function buildAdminInfo(data) {
  return {
    st_paid: data.st_paid,
    st_notes: data.st_notes,
    consignor_commission_structure_id: data.consignor_commission_structure_id,
    commission_scheme: data.commission_scheme,
  }
}

function mapStatementData(payload, mode) {
  const main = payload.main
  const init = main.st_init === 1 ? true : false
  const user_info = buildUserInfo(main)
  const admin_info = mode === 'admin' ? buildAdminInfo(main) : {}

  const lotmap = payload.lots.reduce((o, lot, i) => {
    o[lot.id] = i
    return o
  }, {})

  return {
    mode: mode,
    statement_id: main.id,
    init: init,
    init_date: validDateOrNull(main.st_init_date),
    ready: main.st_ready === 1 ? true : false,
    ready_date: validDateOrNull(main.st_ready_date),
    paid: main.st_paid === 1 ? true : false,
    paid_date: validDateOrNull(main.st_paid_date),
    prev: payload.prev && payload.prev.id ? payload.prev.id : null,
    next: payload.next && payload.next.id ? payload.next.id : null,
    main: main,
    lots: payload.lots,
    lot_count: payload.lot_count,
    lotmap: lotmap,
    lineitems: payload.lineitems,
    lineitem_count: payload.lineitem_count,
    user_info: {
      data: user_info,
    },
    admin_info: {
      data: admin_info,
      original: admin_info,
      meta: {
        complete: true,
        notable_change: false,
        loaded: mode === 'admin',
      },
    },
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA_STATEMENT.request:
      return {
        ...state,
        meta: {
          ...status_defaults,
          processing: true,
        }
      }
    case DATA_STATEMENT.fail:
      return {
        ...state,
        content: content_defaults,
        meta: {
          ...status_defaults,
          ...action.meta,
        }
      }
    case DATA_STATEMENT.success:
      return {
        ...state,
        content: mapStatementData(action.payload, action.mode),
        meta: {
          ...status_defaults,
          ...action.meta,
        },
        ...(action.reset && { actions: actions_defaults }),
      }
    case FIELD_SET_VALUE:
      if (action.options.group) {
        if (action.options.group === 'lots') {
          return {
            ...state,
            content: {
              ...state.content,
              lots: state.content.lots.map(lot => lot.id === +action.options.id ? { ...lot, [action.field]: action.value } : lot),
            }
          }
        } else {
          const fields_obj = action.options.fields ? action.options.fields : 'data'
          return {
            ...state,
            content: {
              ...state.content,
              [action.options.group]: {
                ...state.content[action.options.group],
                [fields_obj]: {
                  ...state.content[action.options.group][fields_obj],
                  [action.field]: action.value,
                }
              }
            }
          }
        }
      } else {
        return {
          ...state,
          fields: {
            ...state.fields,
            [action.field]: action.value,
          }
        }
      }
    case LINEITEM_ADD.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          lineitemAdd: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case LINEITEM_ADD.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          lineitemAdd: {
            ...status_defaults,
            ...action.meta,
          }
        },
        // we don't need to update content because statement will be refreshed?
      }
    case LINEITEM_ADD.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          lineitemAdd: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case LINEITEM_REMOVE.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          lineitemRemove: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case LINEITEM_REMOVE.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          lineitemRemove: {
            ...status_defaults,
            ...action.meta,
          }
        },
        // we don't need to update content because statement will be refreshed?
      }
    case LINEITEM_REMOVE.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          lineitemRemove: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case ADMIN_UPDATE_STATEMENT.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          adminUpdate: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case ADMIN_UPDATE_STATEMENT.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          adminUpdate: {
            ...status_defaults,
            ...action.meta,
          }
        },
        content: {
          ...state.content,
          init: true,
          init_date: state.content.init ? state.content.init_date : formatDate(),
          ready: action.payload.mode === 1 ? true : action.payload.mode === 2 ? false : state.content.ready,
          ready_date: action.payload.mode === 1 ? formatDate() : action.payload.mode === 2 ? null : state.content.ready_date,
          ...(action.payload.mode === 1 || action.payload.mode === 3 ? { paid: true } : (action.payload.mode === 2 || action.payload.mode === 4 ? { paid: false } : {})),
          ...(action.payload.mode === 1 || action.payload.mode === 3 ? ({ paid_date: (state.content.paid_date ? state.content.paid_date : formatDate()) }) : { paid_date: null }),
        }
      }
    case ADMIN_UPDATE_STATEMENT.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          adminUpdate: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    default:
      return state
  }
}

export function setFieldValue(field, value, options) {
  return {
    type: FIELD_SET_VALUE,
    field: field,
    value: value,
    options: options,
  }
}

export function getData(mode, statement_id, reset=true) {
  return (dispatch, getState) => {
    const state = getState()
    const query = state.statements.content.info.location[mode] && state.statements.content.info.location[mode].query
    const querydata = processQuery(query, { whitelist: ['auction_id', 'user_lastname', 'ready', 'fulfilled', 'paid'] })
    const endpoint = (mode === 'admin' ? '/api/admin/statement/' : '/api/user/statement/') + statement_id + querydata
    return dispatch(
      get({
        types: DATA_STATEMENT,
        endpoint: endpoint,
        inject: { mode, reset },
      })
    )
  }
}

export function lineitemAdd(lineitem, statement_id) {
  return (dispatch, getState) => {
    const state = getState().statement

    return dispatch(
      get({
        types: LINEITEM_ADD,
        endpoint: '/api/admin/statement/lineitem/add',
        config: {
          method: 'post',
          body: { lineitem: lineitem, statement_id: statement_id },
        },
        postSuccess: () => {
          return dispatch(getData('admin', state.content.statement_id))
        }
      })
    )
  }
}

export function lineitemRemove(statement_lineitem_id) {
  return (dispatch, getState) => {
    const state = getState().statement

    return dispatch(
      get({
        types: LINEITEM_REMOVE,
        endpoint: '/api/admin/statement/lineitem/remove',
        config: {
          method: 'post',
          body: { statement_lineitem_id: statement_lineitem_id },
        },
        postSuccess: () => {
          return dispatch(getData('admin', state.content.statement_id))
        }
      })
    )
  }
}

export function adminUpdateStatement(calculated_data, mode=0) {
  return (dispatch, getState) => {
    const state = getState().statement

    const lot_adjustments = state.content.lots.map(lot => {
      return { id: lot.id, adjustment: lot.adjustment }
    })

    return dispatch(
      get({
        types: ADMIN_UPDATE_STATEMENT,
        endpoint: '/api/admin/statement/' + state.content.statement_id,
        config: {
          method: 'post',
          body: { mode: mode, main: state.content.main, admin_info: state.content.admin_info, lot_adjustments: lot_adjustments, calculated_data: calculated_data },
        },
      })
    )
  }
}
