import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import {useDropzone} from 'react-dropzone'

function blobToBase64(blob) {
  return new Promise( resolve=>{
    let reader = new FileReader()
    reader.onload = function() {
      let dataUrl = reader.result
      resolve(dataUrl)
    }
    reader.readAsDataURL(blob)
  })
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '264px',
  cursor: 'pointer',
}

const activeStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

FieldImage.propTypes = {
  handleImageChange: PropTypes.func.isRequired,
  server_url: PropTypes.string,
}

function FieldImage({ handleImageChange }) {
  const [preview, setPreview] = useState('')
  const [showWarning, setShowWarning] = useState(false)

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': [],
    },
    multiple: false,
    onDrop: async (acceptedFiles, fileRejections) => {
      console.log('acceptedFiles:', acceptedFiles)
      setShowWarning(false)
      if (acceptedFiles.length) {
        setPreview(URL.createObjectURL(acceptedFiles[0]))
        handleImageChange(await blobToBase64(acceptedFiles[0]))
      }
      if (fileRejections.length) {
        setPreview('')
        setShowWarning(true)
      }
    }
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ])

  // const files = acceptedFiles.map(file => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ))

  // const _handleChange = async (e) => {
  //   console.log('e.target.files[0]:', e.target.files[0])
  //   setShowWarning(false)
  //   if (e.target.files.length) {
  //     const image = e.target.files[0]
  //     if (image.type === 'image/jpeg' || image.type === 'image/png') {
  //       setPreview(URL.createObjectURL(e.target.files[0]))
  //       handleImageChange(await blobToBase64(e.target.files[0]))
  //     } else {
  //       setShowWarning(true)
  //     }
  //   }
  // }

  return <div>
    <div {...getRootProps({ style, tabIndex: '-1' })}>
      <input {...getInputProps()} />
      {
        preview ? <img src={preview} alt="dummy" className="img-responsive" style={{ maxHeight: '250px' }} />
      :
        <div className="file-drop-message">
          <div className="file-upload-icon"></div>
          <div>Drag image here or click to select</div>
          {showWarning && <div>Image must be a JPEG or PNG</div>}
        </div>
      }
    </div>
    {/* <label htmlFor="upload-button">
      {preview ? (
        <img src={preview} alt="dummy" width="300" height="300" />
      ) : (
        <>
          <span className="fa-stack fa-2x mt-3 mb-2">
            <i className="fa fa-circle fa-stack-2x" />
            <i className="fa fa-store fa-stack-1x fa-inverse" />
          </span>
          <h5 className="text-center">Upload your photo</h5>
        </>
      )}
    </label>
    {showWarning && <div>Image must be a JPEG or PNG</div>}
    <input
      type="file"
      id="upload-button"
      style={{ display: "none" }}
      accept=".png, .jpg, .jpeg"
      onChange={_handleChange}
    /> */}
  </div>
}

export default FieldImage