import { get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

const base = 'owa/info/testimonials/'

const DATA = defineAction(base, 'DATA', 'async')

const initialState = {
  content: {
    data: [],
    info: {
      //pageSize: 20,
      count: 0,
    },
    meta: status_defaults,
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        content: {
          ...state.content,
          meta: {
            ...status_defaults,
            initialized: state.content.meta.initialized,
            processing: true,
          }
        }
      }
    case DATA.success:
      return {
        ...state,
        content: {
          ...state.content,
          data: action.payload.data,
          info: {
            ...state.content.info,
            count: action.payload.count,
          },
          meta: {
            ...status_defaults,
            initialized: true,
            status: 1,
          }
        }
      }
    case DATA.fail:
      return {
        ...state,
        content: {
          ...state.content,
          meta: {
            ...status_defaults,
            ...action.data.result,
            initialized: state.content.meta.initialized,
          }
        }
      }
    default:
      return state
  }
}

export function getTestimonials(params) {
  return (dispatch) => {
    const endpoint = '/api/public/info/testimonials'
    return dispatch(
      get({
        types: DATA,
        endpoint: endpoint,
      })
    )
  }
}
