import React from 'react'
import PropTypes from 'prop-types'

import StatementLineItems from '../statement/StatementLineItems'

import FormContainer from '../shared/FormContainer'
import FormRow from '../shared/FormRow'
import FieldBase from '../shared/FieldBase'
import FusedFields from '../shared/fused-fields'

import NotificationBean from '../shared/NotificationBean'
import StatusBox from '../shared/StatusBox'
import AddressBox from '../shared/AddressBox'

import { makeOptions, numOrZero, round } from 'lib/utility'

StatementContentAdmin.propTypes = {
  statement: PropTypes.object.isRequired,
  consignor_commission_schemes: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  adminUpdateStatement: PropTypes.func.isRequired,
  lineitemAdd: PropTypes.func.isRequired,
  lineitemRemove: PropTypes.func.isRequired,
}

function StatementContentAdmin({ statement, consignor_commission_schemes, setFieldValue, adminUpdateStatement, lineitemAdd, lineitemRemove }) {
  const _adminUpdateStatement = (mode) => {
    const { lineitems, lots, admin_info } = statement.content

    let calc_sales = 0
    let calc_commission = 0
    let calc_misc = 0

    const commission_scheme = consignor_commission_schemes.filter(o => o.value === +admin_info.data.consignor_commission_structure_id)[0]

    lots.map(function(item, i) {
      calc_sales += item.winning_bid ? numOrZero(item.winning_bid) : 0
      const amount_base_commission = numOrZero(Math.max(round(item.winning_bid * commission_scheme.com_percent, 2), commission_scheme.com_minimum))
      calc_commission += amount_base_commission + numOrZero(item.adjustment)
    })

    lineitems.map(function(line, i) {
      calc_misc += line.amount ? +line.amount : 0
    })

    adminUpdateStatement({ calc_sales: calc_sales, calc_commission: calc_commission, calc_misc: calc_misc }, mode)
  }

  const data = statement.content.main
  const admin_info = statement.content.admin_info
  const address = {
    firstname: data.user_firstname,
    lastname: data.user_lastname,
    company: data.primary_company,
    address_line1: data.primary_address_line1,
    address_line2: data.primary_address_line2,
    city: data.primary_city,
    state: data.primary_state,
    postalcode: data.primary_postalcode,
    country: data.primary_country,
    phone: data.primary_phone,
  }

  const admin_section = <FormRow style={{ marginBottom: '5px' }}>
    <div className='col-lg-12 margin-bottom-5'>
      <div className="clearfix">
        <NotificationBean flag={statement.content.ready} message={'Ready - ' + statement.content.ready_date} messageFail="Not Ready" type="success" typeFail="warning" position="left" />
        {/* <NotificationBean flag={statement.content.fulfilled} message={'Fulfilled'} messageFail="Unpaid Lots" type="success" typeFail="warning" position="left" /> */}
        <NotificationBean flag={statement.content.paid} message={'Paid - ' + statement.content.paid_date} messageFail="Unpaid" type="success" typeFail="warning" position="left" />
      </div>
    </div>
    <div className='col-lg-12 margin-bottom-10'>
      <div className="clearfix" style={{ padding: '5px', border: '1px solid #ddd' }}>
        <div className="fixed-gutter-5" style={{ display: 'inline-flex', marginBottom: '15px', width: '100%' }}>
          <div className="col-md-4">
            <AddressBox
              locked={true}
              showHeader={false}
              address={address}
            />
          </div>
          <div className="col-md-4">
            <FusedFields data={admin_info.data} onUpdate={setFieldValue} options={{ group: 'admin_info' }}>
              <FormContainer rowClassName="fixed-gutter-5" size='small' noMargins={true} addonBeforeStyle={{ width: '100px', textAlign: 'left', flexShrink: '0' }}>
                <FormRow>
                  {/* <FieldText name='commission_scheme' addonBefore='Commission' groupClassName='col-lg-12' disabled={false} /> */}
                  <FieldBase type="select" name="consignor_commission_structure_id" addonBefore="Commission Structure" groupClassName="col-lg-12" standalone={ true }>
                    { makeOptions(consignor_commission_schemes) }
                  </FieldBase>
                </FormRow>
                <FormRow>
                  <FieldBase type="textarea" name='st_notes' groupClassName='col-lg-12' style={{height: '125px'}} maxLength={700} />
                </FormRow>
              </FormContainer>
            </FusedFields>
          </div>
          <div className="col-md-4">
            <FormContainer rowClassName="fixed-gutter-5" size='small' noMargins={true} addonBeforeStyle={{ width: '100px', textAlign: 'left', flexShrink: '0' }} rowStyle={{ marginBottom: '5px' }}>
              <FormRow>
                {!statement.content.ready && <button className="btn btn-u btn-u-sm rounded btn-u-success pull-right" onClick={()=>_adminUpdateStatement(1)} style={{ lineHeight: '24px', marginLeft: '5px' }}><span style={{fontWeight: 'bold'}}><span className="fa fa-fw fa-check-square" /> Mark as Ready/Paid</span></button>}
                {statement.content.ready && <button className="btn btn-u btn-u-sm rounded btn-u-danger pull-right" onClick={()=>_adminUpdateStatement(2)} style={{ lineHeight: '24px', marginLeft: '5px' }}><span style={{fontWeight: 'bold'}}><span className="fa fa-fw fa-exclamation-circle" /> Mark as Not Ready</span></button>}
                <button className="btn btn-u btn-u-sm rounded btn-u-primary pull-right" onClick={()=>_adminUpdateStatement(0)} style={{ lineHeight: '24px', marginLeft: '5px' }}><span style={{fontWeight: 'bold'}}><span className="fa fa-fw fa-check-square" /> Update</span></button>
              </FormRow>
              {statement.content.ready && <FormRow>
                {!statement.content.paid && <button className="btn btn-u btn-u-sm rounded btn-u-light-grey pull-right" onClick={()=>_adminUpdateStatement(3)} style={{ lineHeight: '24px', marginLeft: '5px' }}><span style={{fontWeight: 'bold'}}><span className="fa fa-fw fa-check-square" /> Mark as Paid</span></button>}
                {statement.content.paid && <button className="btn btn-u btn-u-sm rounded btn-u-light-grey pull-right" onClick={()=>_adminUpdateStatement(4)} style={{ lineHeight: '24px', marginLeft: '5px' }}><span style={{fontWeight: 'bold'}}><span className="fa fa-fw fa-exclamation-circle" /> Mark as Unpaid</span></button>}
              </FormRow>}
            </FormContainer>
          </div>
        </div>
        <StatementLineItems
          mode="admin"
          statement={statement}
          commission_scheme={consignor_commission_schemes.filter(o => o.value === +admin_info.data.consignor_commission_structure_id)[0]}
          setFieldValue={setFieldValue}
          lineitemAdd={lineitemAdd}
          lineitemRemove={lineitemRemove}
        />
        <StatusBox data={statement.actions.adminUpdate} className="margin-top-5" />
      </div>
    </div>
  </FormRow>

  return <div className="page-base page-admin">
    {admin_section}
  </div>
}

export default StatementContentAdmin