import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

export default function connectDataList(PassedComponent, stateToPropsFunc, listOfActions) {
  class ComposedComponent extends PureComponent {
    static displayName = PassedComponent.displayName + '-HOCconnectDataList'

    static propTypes = {
      statebranch: PropTypes.object.isRequired,
    }

    render() {
      const { statebranch, ...other } = this.props
      return <PassedComponent
        {...other}
        errorCondition={statebranch.meta.status > 1}
        errorMessage={statebranch.meta.errorMessage}
        isLoading={statebranch.meta.processing}
        data={statebranch.data}
        totalItemCount={statebranch.info.count}
        pageSize={statebranch.info.pageSize}
      />
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators(listOfActions, dispatch)
  }

  if (listOfActions) {
    return connect(stateToPropsFunc, mapDispatchToProps)(ComposedComponent)
  } else {
    return connect(stateToPropsFunc)(ComposedComponent)
  }
}

