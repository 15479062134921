import { isValid } from 'date-fns'

import { generateUUID, resolveDate, formatDate } from 'lib/utility'
import { get } from 'lib/api'
import { buildError } from 'lib/state_defaults'

// Basic validation based on rules given
export function verify(spec, data, { invalidInputs=null }={}) {
  var errors = {}

  if(spec.required) {
    spec.required.forEach(name => {
      if (typeof data[name] === 'undefined' || data[name] === null || (typeof data[name] === 'string' && data[name].trim() === '')) {
        errors[name] = 'required'
      }
    })
  }

  if(spec.dates) {
    spec.dates.forEach(name => {
      if(data[name]) {
        if (!isValidDate(data[name])) {
          errors[name] = 'invalid-date'
        }
      }
    })
  }

  // use browser-based input validation on these inputs (only for client-side obviously)
  if (invalidInputs) {
    invalidInputs.forEach(name => {
      errors[name] = 'invalid-input'
    })
  }

  // don't use this currently, but this allows for validation of specific regex patterns for specific fields (date format validation is handled within dates validation code)
  if(spec.format) {
    var names = Object.keys(spec.format)
    names.forEach(name => {
      if(data[name]) {
        if(!data[name].match(spec.format[name])) {
          errors[name] = 'format'
        }
      }
    })
  }

  return Object.keys(errors).length > 0 ? errors : null
}

export function isValidDate(i, { format='autodetect' }={}) {
  return isValid(resolveDate(i, { format }))
}

export function validDateOrNull(i, { sourceFormat='autodetect', destinationFormat="M/d/yyyy" }={}) {
  const r = resolveDate(i, { format: sourceFormat })
  return isValid(r) ? formatDate(r, {format: destinationFormat }) : null
}

export function validatePassword(pwd) {
  // at least 2 uppercase characters, at least one special character (from defined list), at least 2 digits, at least 3 lowercase letters, length of at least 8
  //const pwd_validate = /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/

  // at least 1 uppercase character, at least 1 lowercase character, at least 1 number, length of at least 8
  const pwd_validate = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/
  if (pwd_validate.test(pwd)) {
    return true
  } else {
    return false
  }
}

export function validateEmail(email) {
  // just do an extremely basic check to make sure there is an @ symbol with one or more characters before and after it
  const email_validate = /^\S+@\S+$/
  if (email_validate.test(email)) {
    return true
  } else {
    return false
  }
}

export function generateVerificationString() {
    return generateUUID()
}

export function validCreditCard(info) {
  return info.CCname && info.CCnumber && info.CCnumber.length >= 8 && info.CCexpiration && info.CCexpiration.length >= 4 && info.CCcvv
}

export async function processRecaptcha(instance, dispatch, action, endpoint, data) {
  try {
    const recaptcha_token = await instance.executeAsync()
    // const recaptcha_token = 'no-token'
    console.log('Human detected: ' + recaptcha_token)
    instance.reset()
    return dispatch(
      get({
        types: action,
        endpoint,
        config: {
          method: 'post',
          body: { recaptcha_token, data: data },
        },
      })
    )
  } catch(e) {
    console.log('e:', e)
    console.log('processRecaptcha-failed-[' + action.id + ']:' , e.message)
    return dispatch(
      buildError(action.fail, { statusText: 'Something went wrong; please try again' })
    )
  }
}