import { format as dateFNSformat, parseISO, addDays } from 'date-fns'
import { formatValue } from './utility'

function get_bid_increment(bid) {
  if (bid <= 50) { return 1 }
  else if (bid <= 100) { return 5 }
  else if (bid <= 250) { return 10 }
  else if (bid <= 500) { return 25 }
  else if (bid <= 1000) { return 50 }
  else if (bid <= 2500) { return 100 }
  else if (bid <= 5000) { return 250 }
  else if (bid <= 10000) { return 500 }
  else if (bid <= 25000) { return 1000 }
  else if (bid <= 50000) { return 2500 }
  else if (bid <= 100000) { return 5000 }
  else { return 10000 }
}

function get_future_bid_increment(high_bid) {
  if (high_bid < 50) { return 1 }
  else if (high_bid < 100) { return 5 }
  else if (high_bid < 250) { return 10 }
  else if (high_bid < 500) { return 25 }
  else if (high_bid < 1000) { return 50 }
  else if (high_bid < 2500) { return 100 }
  else if (high_bid < 5000) { return 250 }
  else if (high_bid < 10000) { return 500 }
  else if (high_bid < 25000) { return 1000 }
  else if (high_bid < 50000) { return 2500 }
  else if (high_bid < 100000) { return 5000 }
  else { return 10000 }
}

export function sanitize_bid(max_bid) {
  let result = max_bid % get_bid_increment(max_bid)
  return result !== 0 ? max_bid - result : max_bid
}

export function next_higher_bid(existing_bid, minimum_bid, { unconstrained=false }={}) {
  if (unconstrained) {
    return +Math.max(minimum_bid, (existing_bid+1))
  } else {
    const sanitized_bid = sanitize_bid(existing_bid)
    return existing_bid < minimum_bid ? minimum_bid : sanitized_bid + get_future_bid_increment(sanitized_bid)
  }
}

export function resolve_high_bid({ new_bid, current_max_bid, reserve_price, ignore_reserve=false }) {
  // ZZZ - still need to handle issue with existing high bidder having bid placed after there has been an unconstrained bid as that can produce a mis-aligned bid based on an increment being applied to a non-aligned bid
  // might impact diff bidder as well
  if (ignore_reserve && new_bid < reserve_price) {
    return new_bid
  } else {
    const potential_high_bid = new_bid === current_max_bid ? new_bid : (new_bid < current_max_bid ? new_bid + get_future_bid_increment(new_bid) : current_max_bid + get_future_bid_increment(current_max_bid))
    return new_bid <= reserve_price ? new_bid : Math.max(reserve_price, potential_high_bid)
  }
}

export function recalculate_high_bid(reserve_price, top_max_bid, next_max_bid) {
  if (top_max_bid === next_max_bid || top_max_bid <= reserve_price) {
    return top_max_bid
  } else {
    const potential_high_bid = next_max_bid + get_future_bid_increment(next_max_bid)
    return Math.max(reserve_price, potential_high_bid)
  }
}

export function resolveBuyerPremiumPercentage({ buyerPremiumInfo, amount }) {
  try {
    const premiumPercentage = amount >= buyerPremiumInfo.tierA_threshold ? buyerPremiumInfo.tierA : buyerPremiumInfo.base
    return formatValue(premiumPercentage * 100, 'percent')
  } catch(error) {
    return 'error'
  }
}

export function resolveAuctionDates({ datetime_ending=null, date_post_ending=null }={}) {
  let text_date_under_reserve_offer_process = null
  let text_date_post_sale_end = null
  if (datetime_ending) {
    const auction_end_date = parseISO(datetime_ending)
    const raw_date_under_reserve_offer_process = addDays(auction_end_date, 2)
    const raw_date_post_sale_end = date_post_ending ? parseISO(date_post_ending) : addDays(auction_end_date, 7)
    const timezone_date_post_sale_end = dateFNSformat(raw_date_post_sale_end, "z") === 'GMT-4' ? 'EDT' : 'EST'
    text_date_under_reserve_offer_process = dateFNSformat(raw_date_under_reserve_offer_process, "EEEE, MMMM do")
    text_date_post_sale_end = dateFNSformat(raw_date_post_sale_end, "MMMM do") + ' at 12pm ' + timezone_date_post_sale_end
  }

  return {
    text_date_under_reserve_offer_process,
    text_date_post_sale_end,
  }
}
