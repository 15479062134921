import { nanoid } from 'nanoid/non-secure'

// State Definition Defaults

export const status_defaults = {
  initialized: false,
  invalidated: true,
  processing: false,
  status: 0,
  statusCategory: '',
  statusText: '',
  statusExtendedText: '',
  statusDirective: '',
  timestamp: 0,
  initialQueryTimestamp: 0,
  x_status_code: 0,
}

export const view_defaults = {
  mode: 'processing',
}

export const status_success_defaults = {
  initialized: true,
  status: 1,
}

export const validation_defaults = {
  required: null,
  other: [],
}


// State Helper Functions

export function defineAction(base, a, type='default', { customActions=[] }={}) {
  if (type === 'default') {
    return base + a
  } else if (type === 'async') {
    const extraActions = customActions.reduce((o, item) => {
      o[item] = base + a + '_' + item.toUpperCase()
      return o
    }, {})
    return {
      base: base,
      id: base + a,
      request: base + a + '_REQUEST',
      success: base + a + '_SUCCESS',
      fail: base + a + '_FAIL',
      reset: base + a + '_RESET',
      // end: base + a + '_END',
      ...extraActions,
    }
  } else {
    throw new Error('Bad Action Type')
  }
}

// ZZZ - is this the right file to locate buildError, or should it be in the api?
// ZZZ - enhance buildError with more flexibility in handling different client-side error scenarios and add more details
export function buildError(failAction, { statusText='Validation Error: Check for required fields and/or invalid values', errors_present=null, foundation }) {
  return {
    type: failAction,
    validation: { ...validation_defaults, required: errors_present },
    meta: {
      id: nanoid(),
      status: 2,
      statusText: statusText,
    },
    ...(foundation && { foundation: foundation }),
  }
}

// ZZZ - this replaces the above buildError function - need to update code to use this after refactoring verify validation function
export function buildErrorNew(failAction, { statusText='Validation Error: Check for required fields and/or invalid values', validation=null, foundation }) {
  return {
    type: failAction,
    meta: {
      id: nanoid(),
      status: 2,
      statusText: statusText,
    },
    ...(foundation && { foundation: foundation }),
    validation: { ...validation_defaults, ...validation },
  }
}