import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

ArticleSummary.propTypes = {
  data: PropTypes.object.isRequired,
}

ArticleSummary.defaultProps = {
  data: {},
}

function ArticleSummary({ data }) {
  return <div className="row">
    <div key={data.id} className="col-sm-12 margin-bottom-30">
      <div className="funny-boxes funny-boxes-left-green box-shadow shadow-effect-1 lot-summary">
        <div className="row">
          <div className="col-sm-3 sm-margin-bottom-10 funny-boxes-img">
          <Link to={'/info/article/' + data.url_id}><img className="img-responsive" src={data.headline_image_url ? data.headline_image_url : '/static/img/globe_small_8_transparent.png'} alt={data.title + ' headline image'}/></Link>
          </div>
          <div className="col-sm-9">
            <div className="row">
              <div className="margin-right-20">
                <h2 style={{fontWeight: 'bold', borderBottom: '1px solid #AAA', lineHeight: '23px'}}><Link to={'/info/article/' + data.url_id}>{data.title}</Link></h2>
                <h5>{data.description}</h5>
                <Link to={'/info/article/' + data.url_id}><button className='btn btn-u btn-u-darkred' style={{fontWeight: 'bold'}}>Read More <span className="fa-fw fa fa-arrow-right"/></button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default ArticleSummary
