import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'

import BidIncrementInfo from '../components/BidIncrementInfo'

function content(props) {
  return (
    <div className="general-table-defaults">
      <Helmet>
        <title>Terms and Conditions</title>
        <link rel="canonical" href={props.location.pathname} />
      </Helmet>
      <div className="titlebar heading heading-v1 text-center">
        <h2>Terms and Conditions</h2>
      </div>

      <div className="terms-container">
        <blockquote>
          <p>
            <span className="dropcap text-bold">1</span>
            <span>All lots are offered for sale in USD ($) by Old World Auctions, either as owner or as agent for various Consignors. These CONDITIONS OF SALE, as set forth in this catalog, are the complete, and only, terms and conditions on which all property is offered for sale. By bidding at auction, the buyer agrees to be bound by these Conditions of Sale.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">2</span>
            <span>For live online auctions, new bidders must register at least 24 hours prior to the start of a live auction and must provide a copy of their government-issued ID (driver&apos;s license, passport or ID card).  Proof of address will also be required if not shown on the government ID (utility bill, county bill, bank statement, etc.).  Proof of funds will be required; acceptable forms of documentation include bank statements, brokerage account statements, and bank verification letters.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">3</span>
            <div>In a timed online auction, in which all lots close at the same time, bids are accepted in real-time and processed by our website according to time stamp.  In the event of identical high bids, preference goes to the first received. In a live online auction, in which lots close in order sequentially, the auctioneer has sole discretion to accept or refuse any bid, change the lot order, move the bidding forward or backward, or to withdraw a lot.  As a general rule, if bids are received simultaneously on both Old World Auctions and a third-party platform, the auctioneer will accept bids placed on Old World Auctions first.  The bid increment table, which is followed in both timed and live auctions events, is as follows:</div>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6">
                <BidIncrementInfo showTerms={false} showAllIncrements={true} />
              </div>
            </div>
            <div>During a timed online auction, bids may be changed by the bidder at any time up to the closing time, but cannot be cancelled after the close.  For live online auctions, absentee bids may be changed by the bidder at any time up to one hour before the start of the live auction.</div>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">4</span>
            <span>The estimated price shown for each lot should be used only as a guide. Lots may end up selling for more or less than the estimated price. Bids must be placed in fixed bidding increments. Bids received, which are not in accordance with bidding increments, will be reduced to the next lower increment.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">5</span>
            <span>There is no obligation to sell items at less than the reserve price. Lots without a reserve are marked as &quot;No Reserve&quot; lots. Where reserves exist, they are no more than 75% of the low estimate. Reserves are kept confidential with the consignor, until the reserve has been met. Once a reserve has been met, it will be indicated on the Lot Detail page.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">6</span>
            <span>High bids will be reduced to the reserve, or to the increment above the next highest bid by a competing bidder, which ever is greater.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">7</span>
            <span>A buyer&apos;s premium will be applied to the hammer price of all items.  For bids placed on the Old World Auctions&apos; website, a 20% buyer&apos;s premium will be applied to each lot with a hammer price below $5,000.  For lots with hammer prices equal to and above $5,000, a 15% buyer&apos;s premium will be added.  For bids placed on third-party auction sites, including Invaluable and LiveAuctioneers, an additional 5% will be added to the aforementioned buyer&apos;s premium.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">8</span>
            <span>Invoices will be prepared within three business days following the auction close and you will receive email notification when your invoice is ready.  Payment is due within 72 hours of invoice receipt in U.S. Dollars only.  Acceptable forms of payment for live auctions include bank wire, ACH transfer, cashier check and personal check.  Please note that credit cards will not be accepted for the October 10, 2024 live auction.  Checks should be made out to Old World Auctions.  Items will not ship until checks have cleared and funds have been received.  We reserve the right to take any and all legal actions to collect outstanding payments.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">9</span>
            <span>With prior arrangement, items may be stored at Old World Auctions&apos; office for a period of up to one year free of charge.  After this one-year period, items still held will be considered abandoned property unless alternative arrangements have been made.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">10</span>
            <span>Virginia residents will be charged sales tax at the rate of 6%, unless we are provided a copy of a reseller&apos;s permit.  Bidders outside of Virginia are solely responsible for determining any appropriate sales and use tax within his/her respective state of residence. For bids placed on third-party auction sites, including Invaluable and LiveAuctioneers, state sales tax will be applied to invoices of all U.S. residents.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">11</span>
            <span>We warranty the authenticity and condition of each lot described in this catalog. Defects in lots offered for sale have been carefully noted. If a lot is found to be substantially non-conforming to its catalog description, thereby significantly reducing its value, the purchaser must notify Old World Auctions within one week of receipt. Please call for a Return Authorization prior to returning. No returns will be accepted for any reason after 30 days following the auction close date.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">12</span>
            <span>You must open and inspect your package immediately upon receipt. Any discrepancies, damage or missing items must be reported to us within 2 business days of receipt by you. All packaging materials must be retained until the discrepancy has been resolved. Failure to notify us within the two-day limit will nullify any claim by you.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">13</span>
            <span>Successful overseas bidders shall provide specific shipping and customs instructions when completing their invoice, including specific customs declarations, for any deliveries outside of the United States. You are solely responsible for, and agree to pay all local fees including, but not limited to, duty and customs charges, and brokerage fees. These fees are not collected by Old World Auctions and are in addition to the amounts on your invoice.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">14</span>
            <span>The internet being the fragile environment that it is, Old World Auctions cannot be held responsible for server down time, or your inability to access our site. Notifications sent by email may possibly take longer than expected, and cannot be counted on to inform you of an item&apos;s status. In the event of technical problems, we reserve the right to cancel, restart, or extend an auction as the circumstances may dictate.</span>
          </p>
        </blockquote>
        <blockquote>
          <p>
            <span className="dropcap text-bold">15</span>
            <span>These terms and conditions are binding upon you and may be amended by us at any time. You will be notified of such changes in writing or via email.</span>
          </p>
        </blockquote>
      </div>
    </div>
  )
}

export default content
