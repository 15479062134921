import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

class Logo extends Component {
  static propTypes = {
    mode_big: PropTypes.bool,
    sitemode: PropTypes.string,
    link_active: PropTypes.bool,
  }

  static defaultProps = {
    mode_big: true,
    sitemode: 'production',
    link_active: true,
  }

  render() {
    const small_logo_image = <span><img className="logo-text" src="/static/img/logo_small_8.png" /></span>
    const large_logo_image = <span><img className="logo-globe" src="/static/img/globe_small_8_transparent.png" /><img className="logo-text" src="/static/img/logo_large_24b.png" style={{paddingLeft: '5px'}} /></span>

    const logo_image = this.props.mode_big ? large_logo_image : small_logo_image

    return (
      <div>
        {this.props.sitemode === 'test' ? <div className="sitemode-indicator">***TEST SITE***</div> : null}
        <div className="logo-container">
          {this.props.link_active ? <Link className="logo" style={{display: 'inline'}} to="/">
            {logo_image}
          </Link> : logo_image}
        </div>
      </div>
    )
  }
}

export default Logo
