import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

class Topbar extends Component {
  static propTypes = {
    toggleMenu: PropTypes.func.isRequired,
    siteinfo: PropTypes.object.isRequired,
    authenticated: PropTypes.bool,
    role: PropTypes.number,
    logout: PropTypes.func,
    facet: PropTypes.string,
  }

  static defaultProps = {
    authenticated: false,
    role: 0,
    facet: 'default',
  }

  toggleMenuSite = () => {
    this.props.toggleMenu('mobile-menu-site')
  }

  toggleMenuCatalog = () => {
    this.props.toggleMenu('mobile-menu-catalog')
  }

  toggleMenuUser = () => {
    this.props.toggleMenu('mobile-menu-user')
  }

  toggleMenuAdmin = () => {
    this.props.toggleMenu('mobile-menu-admin')
  }

  render() {
    const { siteinfo, facet } = this.props
    const isAdmin = this.props.role >= 9
    const isLoggedIn = this.props.authenticated
    const auctionActive = siteinfo.auction_mode > 0 && siteinfo.auction_mode < 5

    const admin_content = isAdmin ? <React.Fragment>
      <li className="show-only-in-desktop"><Link to="/admin">Admin Dashboard</Link></li>
      <li className="divider show-only-in-desktop" />
      <li className="show-only-in-desktop"><Link to="/admin/manage-items">Manage Lots</Link></li>
      <i className='fa fa-star margin-left-10 margin-right-10 show-only-in-desktop' />
    </React.Fragment> : null

    const catalog_content = <React.Fragment>
      { auctionActive ? <li className="show-only-in-desktop"><Link to="/catalog/current">Browse Auction</Link></li>
      : <React.Fragment>
        <li className="show-only-in-desktop"><Link to="/info/auctions/upcoming">Calendar</Link></li>
        <li className="divider show-only-in-desktop" />
        <li className="show-only-in-desktop"><Link to="/catalog/archive">Archive</Link></li>
      </React.Fragment> }
    </React.Fragment>

    const bidstatus_content = auctionActive && isLoggedIn && <React.Fragment>
      <li className="divider" />
      <li><Link to="/user/status">Bid Status</Link></li>
    </React.Fragment>

    const liveAuction_content = auctionActive && isLoggedIn && <React.Fragment>
      <li className="divider" />
      <li><Link to="/live-auction">Live Auction</Link></li>
    </React.Fragment>

    const auth_content = <React.Fragment>
      <li className="divider" />
      { isLoggedIn ? <li><a style={{ cursor: 'pointer' }} onClick={this.props.logout}>Logout</a></li>
      : <React.Fragment>
        <li className="show-only-in-desktop"><Link to="/register">Create Account</Link></li>
        <li className="divider show-only-in-desktop" />
        <li><Link to="/login">Login</Link></li>
      </React.Fragment> }
    </React.Fragment>

    let facet_content = null
    let show_bidstatus_content = false
    switch (facet) {
      case 'catalog':
        facet_content = <li className="show-only-in-mobile"><a style={{ cursor: 'pointer' }} onClick={this.toggleMenuCatalog}>Categories</a></li>
        show_bidstatus_content = !siteinfo.liveAuctionInProgress
        break
      case 'user':
        facet_content = <li className="show-only-in-mobile"><a style={{ cursor: 'pointer' }} onClick={this.toggleMenuUser}>Account Info</a></li>
        break
      case 'admin':
        facet_content = <React.Fragment>
          <li className="show-only-in-mobile"><a style={{ cursor: 'pointer' }} onClick={this.toggleMenuAdmin}>Dashboard</a></li>
          <li className="divider show-only-in-mobile" />
          <li className="show-only-in-mobile"><Link to="/admin/manage-items">Manage Lots</Link></li>
        </React.Fragment>
        break
      default:
        facet_content = <React.Fragment>
          { auctionActive ? <li className="show-only-in-mobile"><Link to="/catalog/current">Browse Auction</Link></li>
          : <React.Fragment>
            <li className="show-only-in-mobile"><Link to="/info/auctions/upcoming"><i className='fa fa-calendar fa-lg margin-left-10 margin-right-5' /></Link></li>
            <li className="divider show-only-in-mobile" />
            <li className="show-only-in-mobile"><Link to="/catalog/archive">Archive</Link></li>
          </React.Fragment> }
        </React.Fragment>
        show_bidstatus_content = !siteinfo.liveAuctionInProgress
    }

    return (
      <div className="topbar-container">
        <ul className="topbar">
          {facet_content}
          {admin_content}
          {catalog_content}
          {show_bidstatus_content && bidstatus_content}
          {siteinfo.liveAuctionInProgress && liveAuction_content}
          {auth_content}
        </ul>
      </div>
    )
  }
}

export default Topbar
