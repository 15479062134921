import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory, Link } from 'react-router'
import classNames from 'classnames'

// metadata: object with defaults and column data
// metadata.defaults: object with default options for columns
// metadata.columns: array of column fields with options
// field_name: 'field_name_from_db'
// display_name: 'text displayed in column header'
// format: 'currency, yesno'
// style: { width: '85px' } // style of th element (in DataListHeader)
// tdStyle: { textAlign: 'right', paddingRight: '4px' } // style of td element

import FusedFields from './fused-fields'
import { formatMoney, formatMoneyInteger } from 'lib/utility'

DataListRow.propTypes = {
  data: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
  useTable: PropTypes.bool,
  rowClass: PropTypes.string,
  rowStyle: PropTypes.object,
  rowkey: PropTypes.string,
  clickTargetFunction: PropTypes.func,
  clickTargetURL: PropTypes.string,
  clickTargetQueryParams: PropTypes.object,
  anchorHREF: PropTypes.string,
  globalCalcData: PropTypes.object,
  inputHandleUpdate: PropTypes.func,
  inputHandleLeave: PropTypes.func,
  removeFunction: PropTypes.func,
  updateFunction: PropTypes.func,
  allowUpdateFunction: PropTypes.func,
  headerColumnVisibleKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
}

DataListRow.defaultProps = {
  data: {},
  metadata: {},
  showCount: true,
  errorCondition: false,
  errorMessage: '',
  useTable: false,
  rowClass: '',
  rowStyle: {},
  clickTargetFunction: null,
  clickTargetURL: null,
  clickTargetQueryParams: {},
  anchorHREF: null,
  allowUpdateFunction: null,
}

function DataListRow({ data, metadata, useTable, rowkey, rowClass, rowStyle, allowUpdateFunction, anchorHREF, globalCalcData, clickTargetFunction, clickTargetURL, clickTargetQueryParams, headerColumnVisibleKey, removeFunction, updateFunction, inputHandleUpdate, inputHandleLeave }) {
  const _handleClick = () => {
    if (clickTargetFunction) {
      clickTargetFunction(data)
    } else if (clickTargetURL) {
      const location = { pathname: clickTargetURL, query: clickTargetQueryParams}
      browserHistory.push(location);
    }
  }

  const _handleRemove = () => {
    if (removeFunction) {
      removeFunction(rowkey)
    }
  }

  const _handleUpdate = () => {
    if (updateFunction) {
      updateFunction(rowkey)
    }
  }

  if (!data) { return null}
  if (!metadata || !metadata.columns) {
    return 'No column data specified'
  }

  const final_rowClass = classNames(
    rowClass, {
    'clickable': metadata.defaults.row_clickable === true,
  });

  const rowdata = metadata.columns.map((column, i) => {
    let element = null
    let value = data[column.field_name]
    const col = {...metadata.defaults, ...column}

    if (typeof col.visibleFunc !== 'undefined') {
      if (typeof headerColumnVisibleKey === 'undefined' || !col.visibleFunc(headerColumnVisibleKey)) {
        return null
      }
    }

    if (col.calculated) {
      if (globalCalcData) {
        value = col.calculation(data, globalCalcData)
      } else {
        value = col.calculation(data)
      }
    }

    if (col.format === 'yesno') {
      value = value === 1 ? 'Yes' : 'No'
    } else if (col.format === 'int_currency') {
      value = formatMoneyInteger(value)
    } else if (col.format === 'currency') {
      value = formatMoney(value)
    } else if (col.format === 'percent') {
      if (value !== null) {
        value = value + '%'
      } else {
        value = '-'
      }
    }

    if (col.type === 'input') {
      element = <FusedFields data={data} onUpdate={inputHandleUpdate} onLeave={inputHandleLeave} options={col.options}>
        <input data-id={rowkey} data-type={col.dataType} name={column.field_name} {...(!col.autoComplete && { autoComplete: 'off' })} />
      </FusedFields>
    } else if (col.type === 'remove') {
      element = <div className="remove" style={{margin: '4px'}}><span className="fa fa-close fa-border fa-hover text-danger" style={{cursor: 'pointer'}} onClick={_handleRemove} /></div>
    } else if (col.type === 'update') {
      if (allowUpdateFunction !== null && allowUpdateFunction(data)) {
        element = <div className="update" style={{marginLeft: '4px', marginRight: '4px'}}><button className="btn btn-warning btn-xs" onClick={_handleUpdate}><i className="fa fa-thumbs-up"></i> Update</button></div>
      }
    // } else if (col.type === 'component') {
    //   const Component = col.component
    //   element = <Component data={data} />
    } else if (col.type === 'custom') {
      element = col.generator ? col.generator(data) : null
    } else if (col.type === 'static') {
      element = anchorHREF ? <span><Link to={anchorHREF}>{col.staticValue}</Link></span> : <span className={col.clickable ? "clickable" : null} onClick={col.clickable ? _handleClick : null}>{col.staticValue}</span>
    } else {
      element = anchorHREF ? <span><Link to={anchorHREF}>{value}</Link></span> : <span className={col.clickable ? "clickable" : null} onClick={col.clickable ? _handleClick : null}>{value}</span>
    }
    if (useTable) {
      return <td key={rowkey + '-' + col.field_name} className={col.tdClass} style={col.tdStyle}>{element}</td>
    } else {
      return <div key={rowkey + '-' + col.field_name}>{value}</div>
    }
  })

  let content

  if (useTable) {
    content = <tr className={final_rowClass} style={rowStyle} onClick={metadata.defaults.row_clickable ? _handleClick : null}>{rowdata}</tr>
  } else {
    content = <div style={rowStyle}>{rowdata}</div>
  }

  return content
}

export default DataListRow
