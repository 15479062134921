import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getArticle } from 'rdx/modules/info/articles/article'

import { SiteView } from '../../base/components/siteview-context'

import SimpleContainer from '../../shared/SimpleContainer'
import ContentPanel from '../../shared/ContentPanel'
import StatusBox from '../../shared/StatusBox'

ArticleDetail.propTypes = {
  data: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  getArticle: PropTypes.func.isRequired,
}

ArticleDetail.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getArticle(params)),
  ])
}

function ArticleDetail({ data, meta, params, getArticle }) {
  useEffect(() => {
    getArticle(params)
  }, [params, getArticle])

  let title = "Article Detail"
  let content

  if (meta.status !== 1) {
    content = <div>
      <StatusBox data={meta} />
    </div>
  } else {
    title = data.title

    content = <SimpleContainer format='full-width-container-shadowed'>
      <Helmet>
        <title>{data.title}</title>
        <link rel="stylesheet" href="/static/css/article.css" />
      </Helmet>
      <div className="article-container" dangerouslySetInnerHTML={ { __html: data.owa_html } } />
    </SimpleContainer>
  }

  const container = <ContentPanel
    title={title}
    icon="fa fa-file-text-o fa-fw"
    auxClassContent="zero-padding"
    styleContent={{ background: 'transparent' }}
  >
    {content}
  </ContentPanel>

  return <SiteView.Consumer>
    {mode => (
      <React.Fragment>
        {container}
      </React.Fragment>
    )}
  </SiteView.Consumer>
}

function mapStateToProps(state) {
  return {
    data: state.info.articles.article.content.data,
    meta: state.info.articles.article.content.meta,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      getArticle,
     }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleDetail)
