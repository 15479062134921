import { get } from 'lib/api'
import { status_defaults, validation_defaults, defineAction, buildError } from 'lib/state_defaults'
import { verify } from 'lib/validation'
import { meta_fields_user } from 'lib/constants'

const base = 'owa/userData/'

const DATA = defineAction(base, 'DATA', 'async')
const UPDATE = defineAction(base, 'UPDATE', 'async')

const FIELD_UPDATE = base.concat('FIELD_UPDATE')

const initialState = {
  content: {
    originalData: {},
    data: {},
    meta: status_defaults,
  },
  actions: {
    update: {
      meta: status_defaults,
      validation: validation_defaults,
    }
  },
  fields: {},
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        content: {
          ...state.content,
          meta: {
            ...status_defaults,
            initialized: state.content.meta.initialized,
            processing: true,
          }
        }
      }
    case DATA.success:
      return {
        ...state,
        content: {
          ...state.content,
          originalData: action.payload.data,
          data: action.payload.data,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA.fail:
      return {
        ...state,
        content: {
          ...state.content,
          meta: {
            ...status_defaults,
            ...action.meta,
            initialized: state.content.meta.initialized,
          }
        }
      }
    case UPDATE.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          update: {
            ...state.actions.update,
            meta: {
              ...status_defaults,
              processing: true,
            }
          }
        }
      }
    case UPDATE.success:
      return {
        ...state,
        content: {
          ...state.content,
          originalData: state.content.data,
        },
        actions: {
          ...state.actions,
          update: {
            ...state.actions.update,
            meta: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
    case UPDATE.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          update: {
            ...state.actions.update,
            meta: {
              ...status_defaults,
              ...action.meta,
            },
            validation: action.validation,
          }
        }
      }
    case FIELD_UPDATE:
      return {
        ...state,
        content: {
          ...state.content,
          data: {
            ...state.content.data,
            [action.field]: action.value,
          }
        }
      }
    default:
      return state
  }
}

export function fieldUpdate(field, value) {
  return { type: FIELD_UPDATE, field, value }
}

export function getUser() {
  return dispatch => {
    const endpoint = '/api/user/profile'
    return dispatch(
      get({
        types: DATA,
        endpoint: endpoint,
      })
    )
  }
}

export function updateUser(id) {
  return (dispatch, getState) => {
    const state = getState().userData

    const data = state.content.data
    const originalData = state.content.originalData

    const errors_present = verify(meta_fields_user, data)

    if (errors_present) {
      return dispatch(
        buildError(UPDATE.fail, { errors_present: errors_present, foundation: { id: id } })
      )
    } else {
      return dispatch(
        get({
          types: UPDATE,
          endpoint: '/api/user/profile/' + id,
          config: {
            method: 'post',
            body: { new_data: data, original_data: originalData },
          },
          foundation: { id: id },
        })
      )
    }
  }
}