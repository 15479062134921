import { defineAction } from 'lib/state_defaults'

const base = 'owa/menus/'

const TOGGLE_MENU = defineAction(base, 'TOGGLE_MENU')

const initialState = {
  "mobile-menu-site": false,
  "mobile-menu-catalog": false,
  "mobile-menu-user": false,
  "mobile-menu-admin": false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        [action.target]: action.value,
      }
    default:
      return state
  }
}

export function toggleMenu(target, { isOpen=null }={}) {
  return (dispatch, getState) => {
    const state = getState().menus

    return dispatch({
      type: TOGGLE_MENU,
      value: isOpen !== null ? isOpen : !state[target],
      target: target,
    })
  }
}