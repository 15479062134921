import React from 'react'
import PropTypes from 'prop-types'
import { Panel } from 'react-bootstrap'

import FieldBase from '../shared/FieldBase'
import FieldText from '../shared/FieldText'
import FieldSmartSelect from '../shared/FieldSmartSelect'
import FieldCheckbox from '../shared/FieldCheckbox'
import InputGroupMultiple from '../shared/InputGroupMultiple'
import FormRow from '../shared/FormRow'
import FusedFields from '../shared/fused-fields'

import { makeOptions, getKeyBySubValue } from 'lib/utility'
import { admin_lot_form_options as op } from 'lib/constants'
import StatusBox from '../shared/StatusBox'

FormItem.propTypes = {
  lot: PropTypes.object.isRequired,
  isNew: PropTypes.bool.isRequired,
  lookupData: PropTypes.object.isRequired,
  fieldUpdate: PropTypes.func.isRequired,
  fieldLeave: PropTypes.func.isRequired,
  saveLot: PropTypes.func.isRequired,
  copyToClipboard: PropTypes.func.isRequired,
  pasteFromClipboard: PropTypes.func.isRequired,
  refreshSerial: PropTypes.func.isRequired,
}

function FormItem({ lot, isNew, lookupData, fieldUpdate, fieldLeave, saveLot, copyToClipboard, pasteFromClipboard, refreshSerial }) {
  const _handleUpdate = (field, value) => {
    fieldUpdate(field, value, lot.data.id)
  }

  const _handleLeaveField = (field, value) => {
    fieldLeave(field, value, lot.data.id)
  }

  const _saveLot = () => {
    saveLot(lot.data.id, { isNew })
  }

  const _handleCopy = () => {
    copyToClipboard(lot.data)
  }

  const _handlePaste = () => {
    pasteFromClipboard(lot.data.id)
  }

  const _handleRefreshSerial = () => {
    refreshSerial(lot.data.id)
  }

  const { data, validation } = lot
  const categories = lookupData.item_categories
  let category_1 = null
  let category_2 = null
  let category_userdef = null

  if (categories.length) {
    category_1 = data.desc_category_id_1 ? (<span className='margin-right-10'><i className='fa fa-star' />{ ' ' + categories[getKeyBySubValue(categories,data.desc_category_id_1)].text }</span>) : null;
    category_2 = data.desc_category_id_2 ? (<span className='margin-right-10'><i className='fa fa-star' />{ ' ' + categories[getKeyBySubValue(categories,data.desc_category_id_2)].text }</span>) : null;
    category_userdef = data.desc_category_id_userdef ? (<span className='margin-right-10'><i className='fa fa-star' />{ ' ' + categories[getKeyBySubValue(categories,data.desc_category_id_userdef)].text }</span>) : null;
  }

  let f_Consignor = '';
  if (isNew) {
    f_Consignor =
      <FieldSmartSelect
        name='consignor_id'
        addonBefore='Consignor'
        groupClassName='col-lg-4'
        options={lookupData.consignors}
        valueField='id'
        displayField='user_fullname'
      />
  } else {
    f_Consignor = <FieldText name='user_fullname' addonBefore='Consignor' groupClassName='col-lg-4' disabled={true} />
  }

  const form = <form className="basic-form">
    <FusedFields data={data} errors={validation.required} onUpdate={_handleUpdate} onLeave={_handleLeaveField} classes='basic-form-contents'>
      <FormRow>
        { f_Consignor }
        <FieldText name="auction_id" addonBefore="Auction #" groupClassName="col-lg-2" />
        <FieldText name="auction_lotnumber" addonBefore="Lot #" groupClassName="col-lg-2" />
        <FieldText name="date_received" addonBefore="Received" groupClassName="col-lg-2" />
        <FieldText name="date_returned" addonBefore="Returned" groupClassName="col-lg-2" />
      </FormRow>
      <FormRow>
        <InputGroupMultiple className="col-lg-2">
          <FieldBase type="rawinput" className="form-control" name="consignor_code" style={ { width: '50%' } } disabled={ true } />
          <FieldBase type="rawinput" className="form-control" name="consignor_serial" style={ { width: '50%' } } disabled={ true } />
        </InputGroupMultiple>
        <FieldBase type="select" name="ship_exception" addonBefore="Ship" groupClassName="col-lg-2">
          { makeOptions(op.item_shipexceptions) }
        </FieldBase>
        <InputGroupMultiple className="col-lg-4" addonBefore="Weight, L x W x H">
          <FieldBase type="rawinput" className="form-control" name="ship_weight" placeholder="weight" maxLength={ 11 } style={{ width: '25%' }} />
          <FieldBase type="rawinput" className="form-control" name="ship_length" placeholder="length" maxLength={ 11 } style={{ width: '25%' }} />
          <FieldBase type="rawinput" className="form-control" name="ship_width" placeholder="width" maxLength={ 11 } style={{ width: '25%' }} />
          <FieldBase type="rawinput" className="form-control" name="ship_height" placeholder="height" maxLength={ 11 } style={{ width: '25%' }} />
        </InputGroupMultiple>
        <FieldText name="ship_exceptiontext" groupClassName="col-lg-4" standalone={ true } maxLength={ 100 } />
      </FormRow>
      <hr />
      <FormRow>
        <InputGroupMultiple className="col-lg-2" addonBefore="W x H">
          <FieldBase type="rawinput" className="form-control" name="desc_width" style={ { width: '50%' } } maxLength={ 11 } />
          <FieldBase type="rawinput" className="form-control" name="desc_height" style={ { width: '50%' } } maxLength={ 11 } />
        </InputGroupMultiple>
        <FieldBase type="select" name="desc_color" addonBefore="Color" groupClassName="col-lg-2">
          { makeOptions(op.item_colors, { showVal: true }) }
        </FieldBase>
        <FieldBase type="select" name="desc_condition" addonBefore="Condition" groupClassName="col-lg-2">
          { makeOptions(op.item_conditions, { showVal: true }) }
        </FieldBase>
        <FieldBase type="textarea" name="desc_conditiontext" groupClassName="col-lg-6" maxLength={ 1300 } />
      </FormRow>
      <FormRow>
        <FieldText name="desc_title" addonBefore="Item Title" groupClassName="col-lg-12" maxLength={ 255 } />
      </FormRow>
      <FormRow>
        <FieldSmartSelect name="desc_region" addonBefore="Region" groupClassName="col-lg-3"  options={lookupData.item_regions} valueField='code' displayField='description' />
        <FieldSmartSelect name="desc_creator_id" addonBefore="Creator" groupClassName="col-lg-3" options={lookupData.item_creators} valueField='id' displayField='creator' />
        <FieldText name="desc_subject" addonBefore="Subject" groupClassName="col-lg-6" maxLength={ 255 } spellCheck={ true } />
      </FormRow>
      <FormRow>
        <FieldText name="desc_publication" addonBefore="Publication" groupClassName="col-lg-6" maxLength={ 255 } spellCheck={ true } />
        <FieldSmartSelect name="desc_header_id" addonBefore="Header" groupClassName="col-lg-6" options={lookupData.item_description_headers} valueField='id' displayField='title' />
      </FormRow>
      <FormRow>
        <FieldText name="desc_period" addonBefore="Period" groupClassName="col-lg-2" maxLength={ 80 } />
        <FieldBase type="select" name="desc_datetype" groupClassName="col-lg-2" standalone={ true }>
          { makeOptions(op.item_datetypes, { showVal: true }) }
        </FieldBase>
        <FieldText name="desc_references" addonBefore="References" groupClassName="col-lg-8" maxLength={ 255 } spellCheck={ true } />
      </FormRow>
      <FormRow>
        <FieldBase type="textarea" name="desc_text" groupClassName="col-lg-12" style={{ height: '180px' }} maxLength={ 12000 } />
      </FormRow>
      <FormRow>
        <FieldCheckbox name='featured' groupClassName='col-lg-1' checked={ data && data.featured == 1 } label='Featured' />
        <FieldText name="featured_text" height="80px" groupClassName="col-lg-11" maxLength={ 200 } spellCheck={ true } standalone={ true } />
      </FormRow>
      <FormRow>
        <FieldBase type="select" name="desc_category_id_userdef" addonBefore="Extra Category" groupClassName="col-lg-4" data-type="int">
          { makeOptions(op.item_categories.concat(lookupData.item_categories)) }
        </FieldBase>
        <div className="form-group col-lg-8">
          <p className="form-control-static">
            Tagged in:
            {category_1}
            {category_2}
            {category_userdef}
          </p>
        </div>
      </FormRow>
      <hr />
      <FormRow sizeClass="col-lg-2">
        <FieldText name="price_estimate_low" addonBefore="Est. Low" maxLength={ 14 } />
        <FieldText name="price_estimate_high" addonBefore="High" maxLength={ 14 } />
        <FieldText name="price_initialreserve" addonBefore="Initial Reserve" maxLength={ 14 } />
        <FieldText name="price_reserve" addonBefore="Reserve" maxLength={ 14 } />
        <FieldText name="price_highbid" addonBefore="High Bid" maxLength={ 14 } />
        <FieldCheckbox name='status_sold' groupClassName='col-lg-2' checked={ data && data.status_sold == 1 } label='Sold' />
      </FormRow>
      <hr />
      <FormRow sizeClass="col-lg-6">
        <FieldBase type="textarea" name="notes_user" label="User Notes" maxLength={ 700 } />
        <FieldBase type="textarea" name="notes_internal" label="Internal Notes" maxLength={ 255 } />
      </FormRow>
      <FormRow>
        <FieldCheckbox name='status_complete_all' groupClassName='col-lg-2' checked={ data && data.status_complete_all == 1 } label='Completed' />
        <FieldCheckbox name='status_complete_image' groupClassName='col-lg-2' checked={ data && data.status_complete_image == 1 } label='Image Verified' />
        <FieldText name="meta_date_modified" addonBefore="Last Modified" groupClassName="col-lg-3 pull-right" disabled={ true } />
      </FormRow>
    </FusedFields>
    <div className="flex-standard-container form-action-container top-separator-border">
      <div className="flex-group flex-group-left">
        <button type="button" className="btn btn-u btn-u-dark-blue" onClick={ _saveLot }><span className={ isNew ? 'fa fa-plus' : 'fa fa-upload' } />{ isNew ? ' Add' : ' Update' }</button>
      </div>
      <div className="flex-group flex-group-center">
        <StatusBox data={lot.update} size="micro" />
        <StatusBox data={lot.serial} size="micro" />
      </div>
      <div className="flex-group flex-group-right">
        {isNew && <button type="button" className="btn-u btn-u-dark-blue btn btn-default" onClick={ _handleRefreshSerial }>Refresh Serial</button>}
        <button type="button" className="btn-u btn-u-dark-blue btn btn-default" onClick={ _handleCopy }>Copy</button>
        <button type="button" className="btn-u btn-u-dark-blue btn btn-default" onClick={ _handlePaste }>Paste</button>
      </div>
    </div>
  </form>

  return <Panel className="panel-basic panel-basic-admin rounded-bottom border-top-none">
    <Panel.Body>
      { form }
    </Panel.Body>
  </Panel>
}

export default FormItem
