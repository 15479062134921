import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ControlLabel } from 'react-bootstrap'

import FieldCheckbox from '../shared/FieldCheckbox'

import usePrevious from '../shared/hooks/usePrevious'

function loadBits(numBits, { fieldValue=0 }={}) {
  return Array.from({ length: numBits }, (_, i) => i + 1).reduce((o, val, i) => {
    o[i] = (fieldValue & (1 << (numBits - 1 - i))) !== 0 ? 1 : 0
    return o
  }, {})
}

function replaceBits(numBits, { bitValue=0 }={}) {
  return Array.from(Array(numBits), (_, i) => i + 1).reduce((o, val, i) => {
    o[i] = bitValue
    return o
  }, {})
}

FieldBitButton.propTypes = {
  name: PropTypes.string.isRequired,
  numBits: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  bitLabels: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.number,
}

FieldBitButton.defaultProps = {
  bitLabels: null,
  label: null,
  value: 0,
}

function FieldBitButton({ name, numBits, onChange, bitLabels, label, value }) {
  const [bits, setBits] = useState(loadBits(numBits, { fieldValue: value }))
  const bitFieldValue = useMemo(() => {
    return Object.entries(bits).reduce((b, val, i) => {
      return b + (bits[i] * (1 << (Object.keys(bits).length - 1 - i)))
    }, 0)
  }, [bits])
  const prevValue = usePrevious(value)
  const prevBitFieldValue = usePrevious(bitFieldValue)

  useEffect(() => {
    if (bitFieldValue !== prevBitFieldValue && bitFieldValue !== value) {
      // local change needs to populate to external state
      onChange({ target: { name, value: bitFieldValue } })
    } else if (value !== prevValue && value !== bitFieldValue) {
      // external change needs to populate to local state
      setBits(loadBits(numBits, { fieldValue: value }))
    }
  }, [bits, name, value, onChange, bitFieldValue, prevBitFieldValue, prevValue, numBits])

  const _handleClick = (e) => {
    if (e.target.name === 'all') {
      setBits(replaceBits(numBits, { bitValue: 1 }))
    } else if (e.target.name === 'none') {
      setBits(replaceBits(numBits))
    } else {
      setBits(prev => ({
        ...prev,
        [e.target.name]: prev[e.target.name] === 1 ? 0 : 1,
      }))
    }
  }

  return <>
    <ControlLabel>{label}</ControlLabel>
    <div className="btn-group" role="group" aria-label="..." style={{ display: 'flex', fontFamily: 'monospace' }}>
      <FieldCheckbox mode="button-group" name="all" label={"All"} onClick={_handleClick} />
      {Array.from(Array(numBits), (_, i) => i + 1).map((val, i) => {
        return <FieldCheckbox key={'bit' + i} mode="button-group" name={i} value={bits[i]} label={Array.isArray(bitLabels) ? bitLabels[i] : i} onClick={_handleClick} />
      })}
      <FieldCheckbox mode="button-group" name="none" label={"None"} onClick={_handleClick} />
    </div>
  </>
}

export default React.memo(FieldBitButton)
