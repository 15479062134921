import { get } from 'lib/api'
import { defineAction, status_defaults, validation_defaults, buildError } from 'lib/state_defaults'
import { verify } from 'lib/validation'

import { getSiteInfo } from '../siteinfo'
import { getOperationsInfo } from './operations'

const base = 'owa/admin/slidegroups/'

const DATA = defineAction(base, 'DATA', 'async')
const SAVE_SLIDEGROUPS = defineAction(base, 'SAVE_SLIDEGROUPS', 'async')

const DATA_SLIDEGROUP = defineAction(base, 'DATA_SLIDEGROUP', 'async', { customActions: ['close'] })
const ADD_SLIDEGROUP = defineAction(base, 'ADD_SLIDEGROUP', 'async')
const UPDATE_SLIDEGROUP = defineAction(base, 'UPDATE_SLIDEGROUP', 'async')
const REMOVE_SLIDEGROUP = defineAction(base, 'REMOVE_SLIDEGROUP', 'async')
const DATA_SLIDE = defineAction(base, 'DATA_SLIDE', 'async', { customActions: ['new'] })
const ADD_SLIDE = defineAction(base, 'ADD_SLIDE', 'async')
const UPDATE_SLIDE = defineAction(base, 'UPDATE_SLIDE', 'async')
const REMOVE_SLIDE = defineAction(base, 'REMOVE_SLIDE', 'async')
const PUBLISH_SLIDES = defineAction(base, 'PUBLISH_SLIDES', 'async')

const SELECT_TAB = base.concat('SELECT_TAB')
const FIELD_UPDATE_SLIDEGROUP = base.concat('FIELD_UPDATE_SLIDEGROUP')
const FIELD_UPDATE_SLIDE = base.concat('FIELD_UPDATE_SLIDE')

const REORDER_SLIDEGROUPS = base.concat('REORDER_SLIDEGROUPS')
const REORDER_SLIDES = base.concat('REORDER_SLIDES')
const TOGGLE_SLIDEGROUP_ACTIVE = base.concat('TOGGLE_SLIDEGROUP_ACTIVE')
const TOGGLE_SLIDE_ACTIVE = base.concat('TOGGLE_SLIDE_ACTIVE')

const info_defaults = {
  pageSize: 20,
  count: 0,
}

const slide_data_defaults = {
  show_in_modes: 31,
}

const slidegroup_data_defaults = {
  type: 0,
  auction_id: '',
}

const slide_detail_defaults = {
  originalData: slide_data_defaults,
  data: slide_data_defaults,
  meta: status_defaults,
  update: status_defaults,
  validation: validation_defaults,
  actions: {

  }
}

const slidegroup_detail_defaults = {
  originalData: slidegroup_data_defaults,
  data: slidegroup_data_defaults,
  unsaved_changes: false,
  meta: status_defaults,
  validation: validation_defaults,
  slidelist: [],
  slide: {
    new: slide_detail_defaults,
  },
  templateData: {},
  actions: {
    update: status_defaults,
    removeSlide: status_defaults,
  }
}

const initialState = {
  list: {
    data: [],
    info: info_defaults,
    meta: status_defaults,
  },
  unsaved_changes: false,
  slidegroup: {
    new: slidegroup_detail_defaults,
  },
  slidegroup_tab_order: [],
  selectedTab: "list",
  actions: {
    saveSlideGroups: status_defaults,
    getSlideGroup: status_defaults,
    removeSlideGroup: status_defaults,
    publishSlides: status_defaults,
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case DATA.success:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          info: {
            ...state.list.info,
            count: action.payload.count,
          },
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA.fail:
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case SAVE_SLIDEGROUPS.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          saveSlideGroups: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case SAVE_SLIDEGROUPS.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          saveSlideGroups: {
            ...status_defaults,
            ...action.meta,
          }
        },
        unsaved_changes: false,
      }
    case SAVE_SLIDEGROUPS.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          saveSlideGroups: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA_SLIDEGROUP.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          getSlideGroup: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case DATA_SLIDEGROUP.success:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.id]: {
            ...slidegroup_detail_defaults,
            ...state.slidegroup[action.foundation.id],
            originalData: action.payload.main,
            data: action.payload.main,
            unsaved_changes: false,
            slidelist: action.payload.slides,
            templateData: action.payload.templateData,
            meta: {
              ...status_defaults,
              ...action.meta,
            }
          }
        },
        ...!state.slidegroup_tab_order.includes(action.foundation.id) && { slidegroup_tab_order: [ ...state.slidegroup_tab_order, action.foundation.id ] },
        selectedTab: action.foundation.id,
        actions: {
          ...state.actions,
          getSlideGroup: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA_SLIDEGROUP.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          getSlideGroup: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA_SLIDEGROUP.close:
    {
      const { [action.id]: _, ...remaining } = state.slidegroup
      return {
        ...state,
        slidegroup: remaining,
        slidegroup_tab_order: state.slidegroup_tab_order.filter(item => item !== action.id),
        selectedTab: state.selectedTab === action.id ? "list" : state.selectedTab,
      }
    }
    case ADD_SLIDEGROUP.request:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          new: {
            ...state.slidegroup.new,
            update: {
              ...status_defaults,
              processing: true,
            },
            validation: validation_defaults,
          }
        }
      }
    case ADD_SLIDEGROUP.success:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          new: {
            ...state.slidegroup.new,
            update: {
              ...status_defaults,
              ...action.meta,
            },
          }
        }
      }
    case ADD_SLIDEGROUP.fail:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          new: {
            ...state.slidegroup.new,
            update: {
              ...status_defaults,
              ...action.meta,
            },
            validation: action.validation ? action.validation : validation_defaults,
          }
        }
      }
    case ADD_SLIDEGROUP.reset:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          new: slidegroup_detail_defaults,
        }
      }
    case UPDATE_SLIDEGROUP.request:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.id]: {
            ...state.slidegroup[action.foundation.id],
            validation: validation_defaults,
            actions: {
              ...state.slidegroup[action.foundation.id].actions,
              update: {
                ...status_defaults,
                processing: true,
              },
            },
          }
        }
      }
    case UPDATE_SLIDEGROUP.success:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.id]: {
            ...state.slidegroup[action.foundation.id],
            originalData: state.slidegroup[action.foundation.id].data,
            unsaved_changes: false,
            actions: {
              ...state.slidegroup[action.foundation.id].actions,
              update: {
                ...status_defaults,
                ...action.meta,
              },
            },
          }
        }
      }
    case UPDATE_SLIDEGROUP.fail:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.id]: {
            ...state.slidegroup[action.foundation.id],
            validation: action.validation,
            actions: {
              ...state.slidegroup[action.foundation.id].actions,
              update: {
                ...status_defaults,
                ...action.meta,
              },
            },
          }
        }
      }
    case REMOVE_SLIDEGROUP.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          removeSlideGroup: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case REMOVE_SLIDEGROUP.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          removeSlideGroup: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case REMOVE_SLIDEGROUP.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          removeSlideGroup: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case PUBLISH_SLIDES.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          publishSlides: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case PUBLISH_SLIDES.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          publishSlides: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case PUBLISH_SLIDES.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          publishSlides: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case SELECT_TAB:
      return {
        ...state,
        selectedTab: action.key,
      }
    case FIELD_UPDATE_SLIDEGROUP:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.key]: {
            ...state.slidegroup[action.key],
            unsaved_changes: true,
            data: {
              ...state.slidegroup[action.key].data,
              [action.field]: action.value,
            },
          }
        }
      }
    case REORDER_SLIDEGROUPS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.updatedSlideGroupList,
        },
        unsaved_changes: true,
      }
    case DATA_SLIDE.request:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.slidegroupID]: {
            ...state.slidegroup[action.foundation.slidegroupID],
            slide: {
              ...state.slidegroup[action.foundation.slidegroupID].slide,
              [action.foundation.id]: {
                ...slide_detail_defaults,
                data: {
                  id: action.foundation.id,
                },
                meta: {
                  ...status_defaults,
                  processing: true,
                },
              }
            },
          }
        }
      }
    case DATA_SLIDE.success:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.slidegroupID]: {
            ...state.slidegroup[action.foundation.slidegroupID],
            slide: {
              ...state.slidegroup[action.foundation.slidegroupID].slide,
              [action.foundation.id]: {
                ...state.slidegroup[action.foundation.slidegroupID].slide[action.foundation.id],
                data: action.payload.data,
                originalData: action.payload.data,
                meta: {
                  ...status_defaults,
                  ...action.meta,
                },
              }
            }
          }
        }
      }
    case DATA_SLIDE.fail:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.slidegroupID]: {
            ...state.slidegroup[action.foundation.slidegroupID],
            slide: {
              ...state.slidegroup[action.foundation.slidegroupID].slide,
              [action.foundation.id]: {
                ...state.slidegroup[action.foundation.slidegroupID].slide[action.foundation.id],
                meta: {
                  ...status_defaults,
                  ...action.meta,
                },
              }
            },
          }
        }
      }
    case DATA_SLIDE.new:
    {
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.key]: {
            ...state.slidegroup[action.key],
            slide: {
              ...state.slidegroup[action.key].slide,
              new: {
                ...state.slidegroup[action.key].slide.new,
                data: {
                  ...state.slidegroup[action.key].slide.new.data,
                  slidegroup_id: action.key,
                }
              }
            }
          }
        }
      }
    }
    case ADD_SLIDE.request:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.slidegroupID]: {
            ...state.slidegroup[action.foundation.slidegroupID],
            slide: {
              ...state.slidegroup[action.foundation.slidegroupID].slide,
              new: {
                ...state.slidegroup[action.foundation.slidegroupID].slide.new,
                update: {
                  ...status_defaults,
                  processing: true,
                },
                validation: validation_defaults,
              }
            },
          }
        }
      }
    case ADD_SLIDE.success:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.slidegroupID]: {
            ...state.slidegroup[action.foundation.slidegroupID],
            slidelist: [
              ...state.slidegroup[action.foundation.slidegroupID].slidelist,
              {id: action.payload.id, ...action.foundation.slidedata}
            ],
            slide: {
              ...state.slidegroup[action.foundation.slidegroupID].slide,
              new: {
                ...state.slidegroup[action.foundation.slidegroupID].slide.new,
                update: {
                  ...status_defaults,
                  ...action.meta,
                },
              }
            },
          }
        }
      }
    case ADD_SLIDE.fail:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.slidegroupID]: {
            ...state.slidegroup[action.foundation.slidegroupID],
            slide: {
              ...state.slidegroup[action.foundation.slidegroupID].slide,
              new: {
                ...state.slidegroup[action.foundation.slidegroupID].slide.new,
                update: {
                  ...status_defaults,
                  ...action.meta,
                },
                validation: action.validation ? action.validation : validation_defaults,
              }
            },
          }
        }
      }
    case ADD_SLIDE.reset:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.slidegroupID]: {
            ...state.slidegroup[action.foundation.slidegroupID],
            slide: {
              ...state.slidegroup[action.foundation.slidegroupID].slide,
              new: slide_detail_defaults,
            },
          }
        }
      }
    case UPDATE_SLIDE.request:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.slidegroupID]: {
            ...state.slidegroup[action.foundation.slidegroupID],
            slide: {
              ...state.slidegroup[action.foundation.slidegroupID].slide,
              [action.foundation.id]: {
                ...state.slidegroup[action.foundation.slidegroupID].slide[action.foundation.id],
                update: {
                  ...status_defaults,
                  processing: true,
                },
                validation: validation_defaults,
              }
            },
          }
        }
      }
    case UPDATE_SLIDE.success:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.slidegroupID]: {
            ...state.slidegroup[action.foundation.slidegroupID],
            slide: {
              ...state.slidegroup[action.foundation.slidegroupID].slide,
              [action.foundation.id]: {
                ...state.slidegroup[action.foundation.slidegroupID].slide[action.foundation.id],
                update: {
                  ...status_defaults,
                  ...action.meta,
                },
              }
            },
          }
        }
      }
    case UPDATE_SLIDE.fail:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.slidegroupID]: {
            ...state.slidegroup[action.foundation.slidegroupID],
            slide: {
              ...state.slidegroup[action.foundation.slidegroupID].slide,
              [action.foundation.id]: {
                ...state.slidegroup[action.foundation.slidegroupID].slide[action.foundation.id],
                update: {
                  ...status_defaults,
                  ...action.meta,
                },
                validation: action.validation ? action.validation : validation_defaults,
              }
            },
          }
        }
      }
    case UPDATE_SLIDE.reset:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.foundation.slidegroupID]: {
            ...state.slidegroup[action.foundation.slidegroupID],
            slide: {
              ...state.slidegroup[action.foundation.slidegroupID].slide,
              [action.foundation.id]: {
                ...state.slidegroup[action.foundation.slidegroupID].slide[action.foundation.id],
                update: {
                  ...status_defaults,
                },
                validation: validation_defaults,
              }
            },
          }
        }
      }
    case REMOVE_SLIDE.request:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [state.selectedTab]: {
            ...state.slidegroup[state.selectedTab],
            actions: {
              ...state.slidegroup[state.selectedTab].actions,
              removeSlide: {
                ...status_defaults,
                processing: true,
              }
            }
          }
        }
      }
    case REMOVE_SLIDE.success:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [state.selectedTab]: {
            ...state.slidegroup[state.selectedTab],
            actions: {
              ...state.slidegroup[state.selectedTab].actions,
              removeSlide: {
                ...status_defaults,
                ...action.meta,
              }
            }
          }
        }
      }
    case REMOVE_SLIDE.fail:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [state.selectedTab]: {
            ...state.slidegroup[state.selectedTab],
            actions: {
              ...state.slidegroup[state.selectedTab].actions,
              removeSlide: {
                ...status_defaults,
                ...action.meta,
              }
            }
          }
        }
      }
    case FIELD_UPDATE_SLIDE:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.key_slidegroup]: {
            ...state.slidegroup[action.key_slidegroup],
            slide: {
              ...state.slidegroup[action.key_slidegroup].slide,
              [action.key_slide]: {
                ...state.slidegroup[action.key_slidegroup].slide[action.key_slide],
                data: {
                  ...state.slidegroup[action.key_slidegroup].slide[action.key_slide].data,
                  [action.field]: action.value,
                },
              }
            }
          }
        }
      }
    case REORDER_SLIDES:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.slidegroupID]: {
            ...state.slidegroup[action.slidegroupID],
            slidelist: action.updatedSlideList,
            unsaved_changes: true,
          }
        }
      }
    case TOGGLE_SLIDEGROUP_ACTIVE:
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data.map(item => item.id === action.id ? { ...item, active: item.active === 1 ? 0 : 1 } : item),
        },
        unsaved_changes: true,
      }
    case TOGGLE_SLIDE_ACTIVE:
      return {
        ...state,
        slidegroup: {
          ...state.slidegroup,
          [action.slidegroupID]: {
            ...state.slidegroup[action.slidegroupID],
            slidelist: state.slidegroup[action.slidegroupID].slidelist.map(item => item.id === action.id ? { ...item, active: item.active === 1 ? 0 : 1 } : item),
            unsaved_changes: true,
          }
        }
      }
    default:
      return state
  }
}

export function reorderSlideGroups(updatedSlideGroupList) {
  return {
    type: REORDER_SLIDEGROUPS,
    updatedSlideGroupList,
  }
}

export function toggleActive(id, { mode='slidegroup', slidegroupID=null }={}) {
  const type = mode === 'slidegroup' ? TOGGLE_SLIDEGROUP_ACTIVE : TOGGLE_SLIDE_ACTIVE
  return {
    type: type,
    id,
    slidegroupID,
  }
}

export function fieldUpdateSlideGroup(field, value, key='new') {
  return { type: FIELD_UPDATE_SLIDEGROUP, key, field, value }
}

export function fieldUpdateSlide(field, value, key_slidegroup='new', key_slide='new') {
  return { type: FIELD_UPDATE_SLIDE, key_slidegroup, key_slide, field, value }
}

export function selectTab(key) {
  return {
    type: SELECT_TAB,
    key: key,
  }
}

export function reorderSlides(slidegroupID, updatedSlideList) {
  return {
    type: REORDER_SLIDES,
    slidegroupID,
    updatedSlideList,
  }
}

export function closeSlideGroup(id) {
  return {
    type: DATA_SLIDEGROUP.close,
    id: id,
  }
}

export function getData() {
  return (dispatch) => {
    const endpoint = '/api/admin/slidegroups'
    return dispatch(
      get({
        types: DATA,
        endpoint: endpoint,
      })
    )
  }
}

export function saveSlideGroups() {
  console.log('**saveSlideGroups:')
  return (dispatch, getState) => {
    const state = getState()
    return dispatch(
      get({
        types: SAVE_SLIDEGROUPS,
        endpoint: '/api/admin/slidegroups/',
        config: {
          method: 'post',
          body: { list: state.admin.slidegroups.list.data },
        },
        postSuccess: () => {
          dispatch(getData())
        }
      })
    )
  }
}

export function getSlideGroup(id, { newborn=false, name='loading' }={}) {
  return dispatch => {
    return dispatch(
      get({
        types: DATA_SLIDEGROUP,
        endpoint: '/api/admin/slidegroups/' + id,
        foundation: { id, newborn, name },
      })
    )
  }
}

export function saveSlideGroup(id, { isNew=false }={}) {
  return (dispatch, getState) => {
    const state = getState()

    const data = isNew ? state.admin.slidegroups.slidegroup.new.data : state.admin.slidegroups.slidegroup[id].data
    const originalData = isNew ? {} : state.admin.slidegroups.slidegroup[id].originalData
    const slidelist = isNew ? [] : state.admin.slidegroups.slidegroup[id].slidelist
    const endpoint = isNew ? '/api/admin/slidegroups/add' : '/api/admin/slidegroups/' + id
    const types = isNew ? ADD_SLIDEGROUP : UPDATE_SLIDEGROUP

    const required_fields = ['name']

    const errors_present = verify({
      required: required_fields,
      format: {},
    }, data)

    if (errors_present) {
      return dispatch(
        buildError(types.fail, { errors_present: errors_present, foundation: { id: id } })
      )
    } else {
      return dispatch(
        get({
          types: types,
          endpoint: endpoint,
          config: {
            method: 'post',
            body: {
              new_data: {
                ...data,
                ...(data.type === 0 && { auction_id: null }),
              },
              original_data: originalData,
              slidelist
            },
          },
          foundation: { id: id },
          ...isNew && { postSuccess: (json) => {
            dispatch(getSlideGroup(json.payload.id, { newborn: true }))
            dispatch({ type: ADD_SLIDEGROUP.reset })
            dispatch(getData())
          } },
          ...!isNew && { postSuccess: (json) => {
            dispatch(getSlideGroup(id))
            dispatch(getData())
          } }
        })
      )
    }
  }
}

export function removeSlideGroup(id) {
  console.log('**removeSlideGroup:', id)
  return (dispatch) => {

    const endpoint = '/api/admin/slidegroups/remove/' + id
    return dispatch(
      get({
        types: REMOVE_SLIDEGROUP,
        endpoint: endpoint,
        config: {
          method: 'delete',
        },
        postSuccess: () => {
          dispatch(getData())
        }
      })
    )
  }
}

export function newSlide(slidegroup_key) {
  return {
    type: DATA_SLIDE.new,
    key: slidegroup_key,
  }
}

export function getSlide(id, { slidegroupID=null, newborn=false }={}) {
  return dispatch => {
    return dispatch(
      get({
        types: DATA_SLIDE,
        endpoint: '/api/admin/slide/' + id,
        foundation: { id, slidegroupID, newborn },
      })
    )
  }
}

export function saveSlide(id, { isNew=false, slidegroupID=null, image=null }={}) {
  console.log('saveSlide-id:', id)
  return (dispatch, getState) => {
    const state = getState()

    const data = isNew ? state.admin.slidegroups.slidegroup[slidegroupID].slide.new.data : state.admin.slidegroups.slidegroup[slidegroupID].slide[id].data
    const originalData = isNew ? {} : state.admin.slidegroups.slidegroup[slidegroupID].slide[id].originalData
    const endpoint = isNew ? '/api/admin/slide/add' : '/api/admin/slide/' + id
    const types = isNew ? ADD_SLIDE : UPDATE_SLIDE

    const required_fields = []

    const errors_present = verify({
      required: required_fields,
      format: {},
    }, data)

    if (errors_present) {
      return dispatch(
        buildError(types.fail, { errors_present: errors_present, foundation: { id, slidegroupID, slidedata: data } })
      )
    } else {
      return dispatch(
        get({
          types: types,
          endpoint: endpoint,
          config: {
            method: 'post',
            body: { new_data: data, original_data: originalData, image },
          },
          foundation: { id, slidegroupID, slidedata: data },
          postSuccess: (json) => {
            dispatch(getSlideGroup(slidegroupID))
            dispatch(getData())
          },
        })
      )
    }
  }
}

export function removeSlide(id, { slidegroupID }) {
  console.log('**removeSlide:', id)
  return (dispatch) => {

    const endpoint = '/api/admin/slide/remove/' + id
    return dispatch(
      get({
        types: REMOVE_SLIDE,
        endpoint: endpoint,
        config: {
          method: 'delete',
        },
        postSuccess: () => {
          dispatch(getSlideGroup(slidegroupID))
        }
      })
    )
  }
}

export function resetSlideStatus(id, { slidegroupID=null }={}) {
  console.log('resetSlideStatus:', id, slidegroupID)
  if (id === 'new') {
    return {
      type: ADD_SLIDE.reset,
      foundation: { slidegroupID }
    }
  } else {
    return {
      type: UPDATE_SLIDE.reset,
      foundation: { id, slidegroupID }
    }
  }
}

export function publishSlides() {
  console.log('**publishSlides:')
  return (dispatch) => {
    return dispatch(
      get({
        types: PUBLISH_SLIDES,
        endpoint: '/api/admin/slidegroups/publish',
        config: {
          method: 'post',
        },
        postSuccess: () => {
          dispatch(getOperationsInfo())
          dispatch(getSiteInfo())
        }
      })
    )
  }
}