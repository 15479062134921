const base = 'owa/admin/clipboard/'

const TOGGLE_CLIPBOARD_ITEM =             base.concat('TOGGLE_CLIPBOARD_ITEM')
const CLEAR_CLIPBOARD_VALUES =            base.concat('CLEAR_CLIPBOARD_VALUES')
const ENABLE_ALL_CLIPBOARD_ITEMS =        base.concat('ENABLE_ALL_CLIPBOARD_ITEMS')
const DISABLE_ALL_CLIPBOARD_ITEMS =       base.concat('DISABLE_ALL_CLIPBOARD_ITEMS')
const INPUT_CHANGE = base.concat('INPUT_CHANGE')
const COPY = base.concat('COPY')

const clipboard_info_defaults = {
  desc_title: {
    displayname: 'Title',
    linkedval: false,
    linkedid: null,
    linkeddata: null,
    displaytype: 'input',
    active: true,
  },
  desc_subject: {
    displayname: 'Subject',
    linkedval: false,
    linkedid: null,
    linkeddata: null,
    displaytype: 'input',
    active: true,
  },
  desc_text: {
    displayname: 'Description',
    linkedval: false,
    linkedid: null,
    linkeddata: null,
    displaytype: 'textarea',
    active: true,
  },
  desc_region: {
    displayname: 'Region',
    linkedval: 'description',
    linkedid: 'code',
    linkeddata: 'item_regions',
    displaytype: 'select',
    active: true,
  },
  desc_creator_id: {
    displayname: 'Creator',
    linkedval: 'creator',
    linkedid: 'id',
    linkeddata: 'item_creators',
    displaytype: 'select',
    active: true,
  },
  desc_publication: {
    displayname: 'Publication',
    linkedval: false,
    linkedid: null,
    linkeddata: null,
    displaytype: 'input',
    active: true,
  },
  desc_period: {
    displayname: 'Period',
    linkedval: false,
    linkedid: null,
    linkeddata: null,
    displaytype: 'input',
    active: true,
  },
  desc_references: {
    displayname: 'References',
    linkedval: false,
    linkedid: null,
    linkeddata: null,
    displaytype: 'input',
    active: true,
  },
  desc_width: {
    displayname: 'Width',
    linkedval: false,
    linkedid: null,
    linkeddata: null,
    displaytype: 'input',
    active: true,
  },
  desc_height: {
    displayname: 'Height',
    linkedval: false,
    linkedid: null,
    linkeddata: null,
    displaytype: 'input',
    active: true,
  },
  price_estimate_low: {
    displayname: 'Low Estimate',
    linkedval: false,
    linkedid: null,
    linkeddata: null,
    displaytype: 'input',
    active: true,
  },
  price_estimate_high: {
    displayname: 'High Estimate',
    linkedval: false,
    linkedid: null,
    linkeddata: null,
    displaytype: 'input',
    active: true,
  }
}

const clipboard_data_defaults = {
  desc_title: '',
  desc_subject: '',
  desc_text: '',
  desc_region: '',
  desc_creator_id: '',
  desc_publication: '',
  desc_period: '',
  desc_references: '',
  desc_width: '',
  desc_height: '',
  price_estimate_low: '',
  price_estimate_high: '',
}

const initialState = {
  data: clipboard_data_defaults,
  info: clipboard_info_defaults,
  order: [
    "desc_title",
    "desc_subject",
    "desc_text",
    "desc_region",
    "desc_creator_id",
    "desc_publication",
    "desc_period",
    "desc_references",
    "desc_width",
    "desc_height",
    "price_estimate_low",
    "price_estimate_high",
  ]
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_CLIPBOARD_ITEM:
      return {
        ...state,
        info: {
          ...state.info,
          [action.fieldname]: {
            ...state.info[action.fieldname],
            active: !state.info[action.fieldname].active,
          }
        }
      }
    case CLEAR_CLIPBOARD_VALUES:
      return {
        ...state,
        data: clipboard_data_defaults,
      }
    case ENABLE_ALL_CLIPBOARD_ITEMS:
      return {
        ...state,
        info: Object.entries(state.info).reduce((o, [key, value]) => {
          o[key] = {
            ...state.info[key],
            active: true,
          }
          return o
        }, {})
      }
    case DISABLE_ALL_CLIPBOARD_ITEMS:
      return {
        ...state,
        info: Object.entries(state.info).reduce((o, [key, value]) => {
          o[key] = {
            ...state.info[key],
            active: false,
          }
          return o
        }, {})
      }
    case COPY:
      return {
        ...state,
        data: state.order.reduce((o, item) => {
          o[item] = action.data[item] !== null ? action.data[item] : ''
          return o
        }, {})
      }
    case INPUT_CHANGE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.value,
        }
      }
    default:
      return state
  }
}

export function inputChange(field, value, options={}) {
  return {
    type: INPUT_CHANGE,
    field: field,
    value: value,

  }
}

export function toggleClipboardItem(fieldname) {
  return {
    type: TOGGLE_CLIPBOARD_ITEM,
    fieldname: fieldname,
  }
}

export function copyToClipboard(data) {
  return {
    type: COPY,
    data: data,
  }
}

export function clearClipboardValues() {
  return {
    type: CLEAR_CLIPBOARD_VALUES
  }
}

export function enableAllClipboardItems() {
  return {
    type: ENABLE_ALL_CLIPBOARD_ITEMS
  }
}

export function disableAllClipboardItems() {
  return {
    type: DISABLE_ALL_CLIPBOARD_ITEMS
  }
}
