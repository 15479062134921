import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Panel } from 'react-bootstrap'

import { connect } from 'react-redux'
import { getUser, updateUser, fieldUpdate } from 'rdx/modules/userData'

import FieldBase from '../shared/FieldBase'
import FieldText from '../shared/FieldText'
import FieldCheckbox from '../shared/FieldCheckbox'
import FormRow from '../shared/FormRow'
import FusedFields from '../shared/fused-fields'
import StatusBox from '../shared/StatusBox'
import ContentPanel from '../shared/ContentPanel'

import { makeOptions, makeOptionsBoolean } from 'lib/utility'
import { user_options, invoice_options } from 'lib/constants'

ProfileUser.propTypes = {
  userData: PropTypes.object.isRequired,
  fieldUpdate: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
}

function ProfileUser({ userData, getUser, updateUser, fieldUpdate }) {
  useEffect(() => {
    getUser()
  }, [getUser])

  const _handleUpdate = (field, value) => {
    fieldUpdate(field, value)
  }

  const _handleLeaveField = (field, value) => {
    fieldUpdate(field, value)
  }

  const _updateUser = () => {
    updateUser(userData.content.data.id)
  }

  const fields = userData.content.data
  let content = null

  if (userData.content.meta.status > 1) {
    content = <StatusBox data={userData.content.meta} />
  } else {
    const form_user_profile = <div>
      <form action="#" className="basic-form">
        <FusedFields data={fields} errors={userData.actions.update.validation.required} onUpdate={_handleUpdate} onLeave={_handleLeaveField} classes='basic-form-contents'>
          <FormRow>
            <FieldText name="user_firstname" required label="Name" groupClassName="col-md-6" placeholder="First" />
            <FieldText name="user_lastname" label="" groupClassName="col-md-6" placeholder="Last" />
          </FormRow>
          <FormRow>
            <FieldText name="user_email" required label="Email Address" groupClassName="col-md-6" />
            <FieldText name="user_username" label="Username (for legacy accounts)" groupClassName="col-md-6" disabled />
          </FormRow>
          <FormRow>
            <FieldBase type="password" name="user_password" autoComplete="new-password" required label="Password" groupClassName="col-md-6" />
            <div className="col-md-6" style={{ marginTop: '10px' }}>
              <span className="input-group" style={{ display: 'flex', alignItems: 'center' }}><i className="fa fa-fw fa-unlock-alt fa-3x" style={{ marginRight: '10px' }}></i>Passwords must be at least 8 characters long and contain at least 1 of each: uppercase character, lowercase character, number</span>
            </div>
          </FormRow>
          <div style={{ fontSize: '18px' }}>
            Primary Contact Info
          </div>
          <div style={{ border: '1px solid #CCC', backgroundColor: '#f4f9ff', padding: '10px 10px 0 10px', marginBottom: '10px' }}>
            <FormRow>
              <FieldText name="primary_phone" required label="Phone Number" groupClassName="col-md-6" />
              <FieldText name="primary_company" label="Company" groupClassName="col-md-6" maxLength={ 50 } />
            </FormRow>
            <FormRow>
              <FieldText name="primary_address_line1" required label="Address" groupClassName="col-md-6" placeholder="Street and number, PO Box" />
              <FieldText name="primary_address_line2" label="" groupClassName="col-md-6" placeholder="Apartment, suite, unit, floor, etc" />
            </FormRow>
            <FormRow>
              <FieldText name="primary_city" required label="City" groupClassName="col-md-4" />
              <FieldText name="primary_state" required label="State/Province" groupClassName="col-md-4" />
              <FieldText name="primary_postalcode" required label="Zip/Postal Code" groupClassName="col-md-4" />
            </FormRow>
            <FormRow>
              <FieldBase type='countryDropdown' name='primary_country' required label='Country' groupClassName='col-md-12' />
            </FormRow>
          </div>
          <div style={{ fontSize: '18px' }}>
            Shipping Info
          </div>
          <div style={{ border: '1px solid #CCC', backgroundColor: '#fffdf4', padding: '10px 10px 0 10px', marginBottom: '10px' }}>
            <FormRow>
              <FieldText name="ship_firstname" label="Name" groupClassName="col-md-6" placeholder="First" />
              <FieldText name="ship_lastname" label="" groupClassName="col-md-6" placeholder="Last" />
            </FormRow>
            <FormRow>
              <FieldText name="ship_phone" label="Phone Number" groupClassName="col-md-6" />
              <FieldText name="ship_company" label="Company" groupClassName="col-md-6" maxLength={ 50 } />
            </FormRow>
            <FormRow>
              <FieldText name="ship_address_line1" label="Address" groupClassName="col-md-6" placeholder="Street and number, PO Box" />
              <FieldText name="ship_address_line2" label="" groupClassName="col-md-6" placeholder="Apartment, suite, unit, floor, etc" />
            </FormRow>
            <FormRow>
              <FieldText name="ship_city" label="City" groupClassName="col-md-4" />
              <FieldText name="ship_state" label="State/Province" groupClassName="col-md-4" />
              <FieldText name="ship_postalcode" label="Zip/Postal Code" groupClassName="col-md-4" />
            </FormRow>
            <FormRow>
              <FieldBase type='countryDropdown' name='ship_country' label='Country' groupClassName='col-md-12' />
            </FormRow>
            <FormRow>
              <FieldBase type="select" name='ship_carrier_preference' label="Carrier Preference" groupClassName='col-lg-12'>
                {makeOptions(fields.ship_country === 'US' ? invoice_options.options_carrier_us : invoice_options.options_carrier_other, { filterHidden: true })}
              </FieldBase>
            </FormRow>
            <FormRow>
              <FieldBase type="select" name='options_insurance' label='Shipping Insurance' groupClassName='col-lg-12'>
                {makeOptionsBoolean()}
              </FieldBase>
            </FormRow>
            {fields.ship_country === 'US' ?
              <FormRow>
                <FieldBase type="select" name='options_delivery_signature' label='Signature Required' groupClassName='col-lg-12'>
                  {makeOptionsBoolean()}
                </FieldBase>
              </FormRow>
            : null}
            <FormRow>
              <FieldBase type="textarea" name="ship_notes_user" label="Special Shipping Instructions" style={{ height: '50px' }} groupClassName="col-lg-12" maxLength={ 400 } />
            </FormRow>
          </div>
          <div style={{ fontSize: '18px' }}>
            Billing Info <span style={{ fontSize: '14px' }}>(if different than primary contact info)</span>
          </div>
          <div style={{ border: '1px solid #CCC', backgroundColor: '#fffdf4', padding: '10px 10px 0 10px', marginBottom: '10px' }}>
            <FormRow>
              <FieldText name="bill_firstname" label="Name" groupClassName="col-md-6" placeholder="First" />
              <FieldText name="bill_lastname" label="" groupClassName="col-md-6" placeholder="Last" />
            </FormRow>
            <FormRow>
              <FieldText name="bill_phone" label="Phone Number" groupClassName="col-md-6" />
              <FieldText name="bill_company" label="Company" groupClassName="col-md-6" maxLength={ 50 } />
            </FormRow>
            <FormRow>
              <FieldText name="bill_address_line1" label="Address" groupClassName="col-md-6" placeholder="Street and number, PO Box" />
              <FieldText name="bill_address_line2" label="" groupClassName="col-md-6" placeholder="Apartment, suite, unit, floor, etc" />
            </FormRow>
            <FormRow>
              <FieldText name="bill_city" label="City" groupClassName="col-md-4" />
              <FieldText name="bill_state" label="State/Province" groupClassName="col-md-4" />
              <FieldText name="bill_postalcode" label="Zip/Postal Code" groupClassName="col-md-4" />
            </FormRow>
            <FormRow>
              <FieldBase type='countryDropdown' name='bill_country' label='Country' groupClassName='col-md-12' />
            </FormRow>
          </div>
          <div style={{ fontSize: '18px', borderBottom: '1px solid #CCC', marginBottom: '5px' }}>
            What kinds of email do you want to receive?
          </div>
          <FormRow>
            <FieldCheckbox name='user_email_pref_auction_reminders' groupClassName='col-lg-12' checked={fields.user_email_pref_auction_reminders == 1} label='Auction Reminders (4x / auction)' />
          </FormRow>
          <FormRow>
            <FieldCheckbox name='user_email_pref_outbid_notices' groupClassName='col-lg-12' checked={fields.user_email_pref_outbid_notices == 1} label='Outbid Notices (real time)' />
          </FormRow>
          <FormRow>
            <FieldCheckbox name='user_email_pref_price_reductions' groupClassName='col-lg-12' checked={fields.user_email_pref_price_reductions == 1} label='After-Auction Price Reductions (1x / auction)' />
          </FormRow>
          <FormRow>
            <FieldCheckbox name='user_email_pref_newsletter' groupClassName='col-lg-12' checked={fields.user_email_pref_newsletter == 1} label='Newsletters (8x / year) - info on antique maps, collecting tips, and events in the map community' />
          </FormRow>
          <div style={{ fontSize: '18px', borderBottom: '1px solid #CCC', marginBottom: '5px' }}>
            Additional Info
          </div>
          <FormRow>
            <FieldBase type="select" name='user_referralsource' required label='How did you learn about us?' groupClassName='col-md-6'>
              {makeOptions(user_options.user_referral_sources)}
            </FieldBase>
          </FormRow>
          <FormRow>
            <FieldBase type="textarea" name="user_comments" label="Comments/Interests" style={{ height: '50px' }} groupClassName="col-lg-12" maxLength={ 700 } />
          </FormRow>
          <FormRow>
            <div className="form-group col-md-12 clearfix">
              <button type='button' className='pull-right btn btn-u margin-left-10' onClick={_updateUser}>
                <span className='fa fa-check' />
                {' Update'}
              </button>
              <div><StatusBox data={userData.actions.update.meta} /></div>
            </div>
          </FormRow>
        </FusedFields>
      </form>
    </div>

    content = <Panel className="panel-basic rounded-bottom border-top-none">
      <Panel.Body>
        {form_user_profile}
      </Panel.Body>
    </Panel>
  }

  return <ContentPanel
    title="User Account Profile Information"
    icon="fa fa-user fa-fw"
    auxClassContent="zero-padding"
  >
    <div>
      {content}
    </div>
  </ContentPanel>
}

function mapStateToProps(state) {
  return {
    userData: state.userData,
  }
}

export default connect(mapStateToProps, {
  getUser,
  updateUser,
  fieldUpdate,
})(ProfileUser)