import React, { useReducer } from 'react'
import PropTypes from 'prop-types'

import FieldBase from './FieldBase'
import FieldSmartSelect from './FieldSmartSelect'
import FilterDropdownItem from './filter-dropdown-item'
import FusedFields from './fused-fields'

FilterBuilder.propTypes = {
  filterItems: PropTypes.array.isRequired,
  lookupData: PropTypes.object.isRequired,
  onAddFilter: PropTypes.func.isRequired,
}

function FilterBuilder({ filterItems, lookupData, onAddFilter }) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { filterItem: filterItems[0], operator: filterItems[0].operators[0], filterValue: '' }
  )

  const _handleValueUpdate = (field, value) => {
    setState({ filterValue: value })
  }

  const _handleFilterItemClick = (item) => {
    if (item != state.filterItem) {
      if (item.operators.includes(state.operator)) {
        setState({ filterItem: item, filterValue: '' })
      } else {
        setState({ filterItem: item, filterValue: '', operator: item.operators[0] })
      }
    }
  }

  const _handleOperatorClick = (item) => {
    setState({ operator: item })
  }

  const _handleAddFilter = () => {
    onAddFilter(state)
  }

  let inputcomponent
  switch (state.filterItem.inputtype) {
    case 'text':
      inputcomponent = <FieldBase type="text" standalone={ true } placeholder="value" value={ state.filterValue } name="filter_value_holder" />
      break
    case 'select':
      inputcomponent = <FieldSmartSelect
                        standalone={ true }
                        placeholder="value"
                        options={ lookupData[state.filterItem.sourcedata] }
                        displayField={ state.filterItem.fieldname }
                        name="filter_value_holder"
                      />
      break
    default:
      throw new Error("Invalid input component type")
  }
  return <div>
    <div className="input-group filter-input-group">
      <div className="input-group-btn bs-dropdown-to-select-group">
        <button type="button" className="btn btn-default dropdown-toggle as-is bs-dropdown-to-select" data-toggle="dropdown" tabIndex={ -1 }>
          <span data-bind="bs-drp-sel-fieldname" />{ state.filterItem.displayname }
        </button>
        <ul className="dropdown-menu" role="menu">
          {
            filterItems.map(function(filterItem, i) {
              return <FilterDropdownItem key={ filterItem.fieldname } item={ filterItem } displayvalue={ filterItem.displayname } onClick={ _handleFilterItemClick } />
            })
          }
        </ul>
      </div>
      <div className="input-group-btn bs-dropdown-to-select-group">
        <button type="button" className="btn btn-default dropdown-toggle as-is bs-dropdown-to-select" data-toggle="dropdown" tabIndex={ -1 }>
          <span data-bind="bs-drp-sel-operator" />{ state.operator }
        </button>
        <ul className="dropdown-menu" role="menu">
          {
            state.filterItem.operators.map(function(operator, i) {
              return <FilterDropdownItem key={ operator } item={ operator } displayvalue={ operator } onClick={ _handleOperatorClick } />
            })
          }
        </ul>
      </div>
      <FusedFields data={{ filter_value_holder: state.filterValue }} onUpdate={_handleValueUpdate} wrapped={false}>
        { inputcomponent }
      </FusedFields>
      <div className="input-group-btn">
        <button className="btn btn-default btn-u" type="button" onClick={ _handleAddFilter }>
          <span className="fa fa-plus" />{ " Add" }
        </button>
      </div>
    </div>
    <div id='owa-filter-box-message'>{ (state.filterItem.message || '') }</div>
  </div>
}

export default FilterBuilder