import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class Tab extends PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    isActive: PropTypes.bool,
  }

  static defaultProps = {
    isActive: false,
  }

  render() {
    return <div style={{display: (this.props.isActive ? 'block' : 'none')}}>
      {this.props.children}
    </div>
  }
}