import { useState, useRef, useEffect } from 'react'

import { requestInterval, requestTimeout } from 'lib/timers'
import { defaultRefreshInterval } from 'lib/constants'

const useRefreshTimer = (callback, meta, { deps=[], runOnInit=false, refreshInterval=defaultRefreshInterval, accelerate=false, acceleratedRefreshInterval=5 }={}) => {
  const [count, setCount] = useState(0)
  const [acceleratedCount, setAcceleratedCount] = useState(0)
  const latestCallback = useRef(() => {})

  const isInitialMount = useRef(true)

  useEffect(() => {
    latestCallback.current = callback
  })

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      runOnInit && latestCallback.current('initial')
    } else {
      // run callback on any dependency change (except on initial mount)
      latestCallback.current('dep-update')
    }
  }, [...deps, runOnInit])

  useEffect(() => {
    if (count === refreshInterval || (count === refreshInterval * 2)) {
      latestCallback.current('standard')
    }
  }, [count, refreshInterval])


  useEffect(() => {
    if (meta.status === 1) {
      setCount(0)
    }
    const interval = new requestInterval(() => setCount(c => c + 1), 1000)
    return () => interval.clear()
  }, [meta.timestamp, meta.status])

  useEffect(() => {
    if (accelerate) {
      const interval = new requestInterval(() => setAcceleratedCount(c => c + 1), 1000)
      const timeout = new requestTimeout(() => latestCallback.current('accelerated'), acceleratedRefreshInterval * 1000)
      return () => {
        interval.clear()
        timeout.clear()
        setAcceleratedCount(0)
      }
    }
  }, [accelerate, acceleratedRefreshInterval])

  const secondsTillRefresh = accelerate ? Math.min(refreshInterval - count, 5 - acceleratedCount) : refreshInterval - count
  return { elapsedSeconds: count, secondsTillRefresh }
}

export default useRefreshTimer