import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import classNames from 'classnames'
import Media from "react-media"

import Topbar from './Topbar'
import AuctionStatus from './AuctionStatus'

import useHeaderShrinker from '../../shared/hooks/useHeaderShrinker'

HeaderFixed.propTypes = {
  siteinfo: PropTypes.object.isRequired,
  siteinfo_meta: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  location: PropTypes.object,
  getSiteInfo: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  logout: PropTypes.func,
}

function HeaderFixed({ siteinfo, siteinfo_meta, credentials, location, getSiteInfo, toggleMenu, logout }) {
  const shrinkHeader = useHeaderShrinker()
  const facet = location.pathname.includes('/catalog') ? 'catalog' : (location.pathname.includes('/user') ? 'user' : (location.pathname.includes('/admin') && credentials.userInfo.role === 9 ? 'admin' : 'default'))

  const logo_image_css = classNames({
    'logo-globe': true,
    'logo-globe-small': shrinkHeader,
  })

  return <div className="fixedheader">
    <Topbar siteinfo={siteinfo} authenticated={credentials.isAuthenticated} role={credentials.userInfo.role} logout={logout} facet={facet} toggleMenu={toggleMenu} />
    <div className="logo-globe-container">
      <Link to="/">
        <img className={logo_image_css} src="/static/img/globe_small_8_transparent.png" />
      </Link>
    </div>
    <Media
      query="(min-width: 992px)"
      defaultMatches={false}
      render={() => <AuctionStatus siteinfo={siteinfo} siteinfo_meta={siteinfo_meta} getSiteInfo={getSiteInfo} credentials={credentials} />}
    />
  </div>
}

export default HeaderFixed