import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getData, setFieldValue, setSelectedShippingAddress, resolveShippingAddress, setAddressMode, setShippingOptionsMode, approveInvoice, approveInvoiceClear, returnToAddressChooser, confirmShippingChanges, cancelShippingChanges, packageAdd, packageRemove, packagesEmail, adminUpdateInvoice } from 'rdx/modules/invoice'

import ContentPanel from '../shared/ContentPanel'
import StatusBox from '../shared/StatusBox'
import InvoiceContentUser from './InvoiceContentUser'
import InvoiceContentAdmin from './InvoiceContentAdmin'
import { formatQueryParams } from 'lib/utility'

InvoiceDetail.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setSelectedShippingAddress: PropTypes.func.isRequired,
  resolveShippingAddress: PropTypes.func.isRequired,
  setAddressMode: PropTypes.func.isRequired,
  setShippingOptionsMode: PropTypes.func.isRequired,
  approveInvoice: PropTypes.func.isRequired,
  approveInvoiceClear: PropTypes.func.isRequired,
  returnToAddressChooser: PropTypes.func.isRequired,
  confirmShippingChanges: PropTypes.func.isRequired,
  cancelShippingChanges: PropTypes.func.isRequired,
  packageAdd: PropTypes.func.isRequired,
  packageRemove: PropTypes.func.isRequired,
  packagesEmail: PropTypes.func.isRequired,
  adminUpdateInvoice: PropTypes.func.isRequired,
}

InvoiceDetail.readyOnActions = (dispatch, params, location) => {
  const invoice_id = params && params.invoice_id ? parseInt(params.invoice_id) : 0
  return Promise.all([
    dispatch(getData(location.pathname.includes('/admin/') ? 'admin' : 'user', invoice_id))
  ])
}

function InvoiceDetail({ location, params, invoice, router, getData, setFieldValue, setSelectedShippingAddress, resolveShippingAddress, setAddressMode, setShippingOptionsMode, approveInvoice, approveInvoiceClear, returnToAddressChooser, confirmShippingChanges, cancelShippingChanges, packageAdd, packageRemove, packagesEmail, adminUpdateInvoice }) {
  useEffect(() => {
    const invoice_id = params && params.invoice_id ? parseInt(params.invoice_id) : 0
    getData(location.pathname.includes('/admin/') ? 'admin' : 'user', invoice_id)
  }, [location, params, getData])

  const mode = location.pathname.includes('/admin/') ? 'admin' : 'user'

  const _backToList = () => {
    let new_query = Object.assign({}, location.query)
    router.push({ ...location, pathname: '/' + mode + '/invoices', query: new_query })
  }

  const _prev = () => {
    let new_query = Object.assign({}, location.query)
    router.push({ ...location, pathname: '/' + mode + '/invoice/' + invoice.content.prev, query: new_query })
  }

  const _next = () => {
    let new_query = Object.assign({}, location.query)
    router.push({ ...location, pathname: '/' + mode + '/invoice/' + invoice.content.next, query: new_query })
  }

  const adminMode = mode === 'admin'
  const title = 'Invoice #' + (invoice.meta.status === 1 ? invoice.content.main.auction_id + '-' + invoice.content.main.id : params.invoice_id)
  if (invoice.meta.status !== 1) {
    return <StatusBox data={invoice.meta} />
  }
  const status = invoice.content.main.inv_paid ? 'Paid on ' + invoice.content.paid_date : (invoice.content.main.inv_approved ? 'Approved' : (invoice.content.main.inv_ready ? 'Ready' : 'Not Ready'))

  const content = adminMode && typeof location.query.printview === 'undefined' ?
    <InvoiceContentAdmin
      invoice={invoice}
      setFieldValue={setFieldValue}
      packageAdd={packageAdd}
      packageRemove={packageRemove}
      packagesEmail={packagesEmail}
      adminUpdateInvoice={adminUpdateInvoice}
    />
  :
    <InvoiceContentUser
      invoice={invoice}
      setFieldValue={setFieldValue}
      setSelectedShippingAddress={setSelectedShippingAddress}
      resolveShippingAddress={resolveShippingAddress}
      setAddressMode={setAddressMode}
      setShippingOptionsMode={setShippingOptionsMode}
      returnToAddressChooser={returnToAddressChooser}
      cancelShippingChanges={cancelShippingChanges}
      confirmShippingChanges={confirmShippingChanges}
      approveInvoice={approveInvoice}
      approveInvoiceClear={approveInvoiceClear}
    />

  return <div>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <ContentPanel
      icon="fa fa-file-text-o fa-fw"
      title={title}
      titleSuffix={'(' + (typeof location.query.printview === 'undefined' ? status : invoice.content.main.fullname) + ')'}
      titleRight={status}
      showBackToList={adminMode}
      backToListAction={adminMode ? _backToList : null}
      prevAction={adminMode && invoice.content.prev ? _prev : null}
      nextAction={adminMode && invoice.content.next ? _next : null}
      filteredText={adminMode ? formatQueryParams(location.query) : null}
      showPagingControls={adminMode}
    >
      {content}
    </ContentPanel>
  </div>
}

function mapStateToProps(state) {
  return {
    invoice: state.invoice,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getData, setFieldValue, setSelectedShippingAddress, resolveShippingAddress, setAddressMode, setShippingOptionsMode, approveInvoice, approveInvoiceClear, returnToAddressChooser, confirmShippingChanges, cancelShippingChanges, packageAdd, packageRemove, packagesEmail, adminUpdateInvoice }, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail)