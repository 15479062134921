import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { getStat } from 'rdx/modules/admin/statsbasic'

import FieldText from '../shared/FieldText'
import FormRow from '../shared/FormRow'
import FusedFields from '../shared/fused-fields'
import StatusBox from '../shared/StatusBox'
import ContentPanel from '../shared/ContentPanel'

import { formatTimestamp } from 'lib/utility'

AdminStats.propTypes = {
  stats: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.shape({
    statsname: PropTypes.string.isRequired,
  }),
  getStat: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
}

function AdminStats({ location, params, stats, getStat, router }) {
  useEffect(() => {
    getStat(params.statsname)
  }, [getStat, params])

  const _refresh = () => {
    router.push({ ...location })
  }

  if (!stats.content[params.statsname]) {
    return <div>Invalid Stats Parameter</div>
  }

  const meta = stats.content[params.statsname].meta
  const data = stats.content[params.statsname].data
  let fields = null
  let content = null
  let content_title = 'Unknown'
  const last_updated = meta.timestamp === 0 ? 'NEVER' : formatTimestamp(meta.timestamp)
  const processing_block = <FormRow><div className='form-group col-lg-8'>Loading...</div></FormRow>

  switch (params.statsname) {
    case 'next_auction_info':
      content_title = 'Upcoming Auction Statistics'
      fields = <FusedFields data={data} classes='basic-form-contents'>
        {meta.status === 1 ? <FormRow>
          <FieldText name="itemcount" addonBefore='# Items' groupClassName='col-lg-4' value={data.itemcount} readOnly={true} />
          <FieldText name="low_estimate_sum" addonBefore='Low Estimate Sum' groupClassName='col-lg-4' displayFormat="currency-integer" readOnly={true} />
          <FieldText name="high_estimate_sum" addonBefore='High Estimate Sum' groupClassName='col-lg-4' displayFormat="currency-integer" readOnly={true} />
        </FormRow> : processing_block}
      </FusedFields>
      content = <form className="basic-form">
        {fields}
        <div className="flex-standard-container form-action-container standard-border top-separator-border bottom-rounded">
          <div className="flex-group flex-group-left">
            <button type="button" className="btn btn-u btn-u-dark-blue" onClick={ _refresh }><span className='fa fa-fw fa-refresh' />{ ' Refresh' }</button>
          </div>
          <div className="flex-group flex-group-center">
            <StatusBox data={meta} size="micro" hideOnSuccess={true} />
          </div>
          <div className="flex-group flex-group-right">
            <div className="text-block"><span className="text-bold">Last Updated: </span>{last_updated}</div>
          </div>
        </div>
      </form>
      break
  }

  return <ContentPanel
    title={'Stats: ' + content_title}
    icon="fa fa-question-circle-o fa-fw"
    auxClassContent="zero-padding"
  >
    {content}
  </ContentPanel>
}

function mapStateToProps(state) {
  return {
    stats: state.admin.statsbasic,
  }
}

export default connect(mapStateToProps, {
  getStat,
})(AdminStats)