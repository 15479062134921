import React from 'react'
import PropTypes from 'prop-types'

import DataListRow from './DataListRow'

DataListBody.propTypes = {
  children: PropTypes.element.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  dataIndex: PropTypes.array,
  datakey: PropTypes.string,
  auxDataKey: PropTypes.string,
  metadata: PropTypes.object,
  useTable: PropTypes.bool,
  auxData1: PropTypes.object,
  auxData2: PropTypes.object,
  clickTargetFunction: PropTypes.func,
  clickTargetTemplate: PropTypes.string,
  clickTargetParam1: PropTypes.string,
  clickTargetParam2: PropTypes.string,
  anchorTemplate: PropTypes.string,
  anchorParam1: PropTypes.string,
  anchorParam2: PropTypes.string,
  removeFunction: PropTypes.func,
  updateFunction: PropTypes.func,
  allowUpdateFunction: PropTypes.func,
  headerColumnVisibleKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  inputHandleUpdate: PropTypes.func,
  inputHandleLeave: PropTypes.func,
  rowStyleFunction: PropTypes.func,
  rowStyleDataKey: PropTypes.string,
  clickTargetQueryParams: PropTypes.object,
  className: PropTypes.string,
}

DataListBody.defaultProps = {
  data: [],
  metadata: {},
  useTable: false,
}

function DataListBody({ children, dataIndex, data, datakey, auxDataKey, metadata, useTable, auxData1, auxData2, rowStyleFunction, rowStyleDataKey, clickTargetTemplate, clickTargetParam1, clickTargetParam2, clickTargetFunction, clickTargetQueryParams, anchorTemplate, anchorParam1, anchorParam2, inputHandleUpdate, inputHandleLeave, removeFunction, updateFunction, allowUpdateFunction, className, ...other }) {
  const index = dataIndex ? dataIndex : data

  if (index.length <= 0) {
    return null
  }

  const rowData = index.map((row, i) => {
    const item = dataIndex ? data[row] : row
    const key = datakey ? item[datakey] : i
    const auxKey = auxDataKey ? item[auxDataKey] : null
    const finalAuxData1 = auxData1 && auxKey ? (auxData1[auxKey] ? auxData1[auxKey] : null) : null
    const finalAuxData2 = auxData2 && auxKey ? (auxData2[auxKey] ? auxData2[auxKey] : null) : null
    if (children.type === DataListRow) {
      const rowStyle = rowStyleFunction && rowStyleDataKey ? rowStyleFunction(item[rowStyleDataKey]) : null
      let clickTargetURL = null
      let anchorHREF = null
      if (clickTargetTemplate) {
        clickTargetURL = clickTargetParam1 ? clickTargetTemplate.replace(/<param1>/i, item[clickTargetParam1]) : clickTargetTemplate;
        clickTargetURL = clickTargetParam2 ? clickTargetURL.replace(/<param2>/i, item[clickTargetParam2]) : clickTargetURL;
        clickTargetURL = clickTargetURL ? clickTargetURL.replace(/<param1>|<param2>/gi, '') : null;
      } else if (anchorTemplate) {
        anchorHREF = anchorParam1 ? anchorTemplate.replace(/<param1>/i, item[anchorParam1]) : anchorTemplate;
        anchorHREF = anchorParam2 ? anchorHREF.replace(/<param2>/i, item[anchorParam2]) : anchorHREF;
        anchorHREF = anchorHREF ? anchorHREF.replace(/<param1>|<param2>/gi, '') : null;
      }
      return React.cloneElement( React.Children.only( children ), { ...other, key: key, rowkey: String(key), data: item, metadata: metadata, useTable: useTable, auxData1: finalAuxData1, auxData2: finalAuxData2, rowStyle: rowStyle, clickTargetFunction: clickTargetFunction, clickTargetURL: clickTargetURL, clickTargetQueryParams: clickTargetQueryParams, anchorHREF: anchorHREF, inputHandleUpdate: inputHandleUpdate, inputHandleLeave: inputHandleLeave, removeFunction: removeFunction, updateFunction: updateFunction, allowUpdateFunction: allowUpdateFunction } )
    } else {
      return React.cloneElement( React.Children.only( children ), { ...other, key: key, rowkey: String(key), data: item, metadata: metadata, useTable: useTable, auxData1: finalAuxData1, auxData2: finalAuxData2 } )
    }
  })

  if (useTable) {
    return <tbody>{rowData}</tbody>
  } else {
    return <div className={className}>{rowData}</div>
  }
}

export default DataListBody