import React from 'react'

function printAction() {
  window.print()
}

function closePage() {
  window.close()
}

function PrintButton() {
  return <div>
    <div className="text-center print_button">
      <button className="btn btn-u btn-u-xs btn-u-darkred btn-u-contrast-border margin-right-20" style={{fontSize: '14px', lineHeight: '19px'}} onClick={printAction}><i className="fa fa-fw fa-print rounded"></i> Click here to print this page</button>
      <button className="btn btn-u btn-u-xs btn-u-darkred btn-u-contrast-border" style={{fontSize: '14px', lineHeight: '19px'}} onClick={closePage}><i className="fa fa-fw fa-times-circle rounded"></i> Close page and go back</button>
    </div>
    <div className="margin-bottom-10" style={{ textAlign: 'center' }}>
      <img className="logo-globe logo-globe-small" src="/static/img/globe_small_8_transparent.png" />
      <img className="logo-text logo-text-small" src="/static/img/logo_large_24b.png" />
    </div>
  </div>
}

export default PrintButton