import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import objectFitImages from 'object-fit-images'

import { Swiper, SwiperSlide } from 'swiper/react'

// import Swiper core and required modules
import SwiperCore, { Navigation, Thumbs, Pagination, Keyboard } from 'swiper/core'

// install Swiper modules
SwiperCore.use([Navigation, Thumbs, Pagination, Keyboard])

const pagination = {
  "clickable": true,
}

LotImageSlider.propTypes = {
  baseImagePath: PropTypes.string.isRequired,
  numImages: PropTypes.number.isRequired,
  zoomView: PropTypes.func.isRequired,
}

function LotImageSlider({ baseImagePath, numImages, zoomView }) {
  const [swiper, setSwiper] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    objectFitImages()
  },[])

  useEffect(() => {
    // reset to first slide on baseImagePath change
    if (swiper && !swiper.destroyed && swiper.activeIndex !== 0) {
      swiper.slideTo(0, 0, false)
    }
  },[baseImagePath, swiper])

  const _clickSwiperImage = (index) => {
    zoomView(index)
  }

  const _clickRawImage = () => {
    zoomView()
  }

  if (!numImages || numImages < 1) {
    return null
  } else if (numImages == 1) {
    return <div className='lot-images-container'>
      <div className="lot-images-standard-container">
        <div className="lot-image-standard"><img src={baseImagePath + "A.jpg"} onClick={_clickRawImage} alt="" /></div>
      </div>
    </div>
  } else {
    const images = Array.apply(0, Array(numImages)).map((a, i) => {
      const suffix = String.fromCharCode(i+65)
      return <SwiperSlide key={i}>
        <div className="lot-image-standard">
          <img src={baseImagePath + suffix + ".jpg"} alt="" style={{ }} />
        </div>
      </SwiperSlide>
    })
    const thumbnails = Array.apply(0, Array(numImages)).map((a, i) => {
      const suffix = String.fromCharCode(i+65)
      return <SwiperSlide key={i}>
        <span className="lot-image-thumbnail">
          <img src={baseImagePath + suffix + ".jpg"} alt="" style={{ }} />
        </span>
      </SwiperSlide>
    })
    return <div className='lot-images-container'>
      <div className="lot-images-standard-container">
        <Swiper style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff' }}
          className="lot-images-standard-swiper"
          onSwiper={setSwiper}
          threshold={4}
          pagination={pagination}
          loop={false}
          spaceBetween={0}
          navigation={true}
          thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
          keyboard={{ enabled: true, onlyInViewport: true, pageUpDown: false }}
          onSlideChange={(swiper) => {
            let thumbIndex = thumbsSwiper.activeIndex
            let slidesPerView = thumbsSwiper.params.slidesPerView
            if (swiper.previousIndex < swiper.activeIndex) {
              // moving to right
              if (swiper.activeIndex >= (slidesPerView - 1)) {
                thumbIndex = swiper.activeIndex - (slidesPerView - 2)
              } else {
                thumbIndex = 0
              }
            } else {
              // moving to left
              if (swiper.activeIndex <= (numImages - (slidesPerView - 1))) {
                thumbIndex = swiper.activeIndex - 1
              } else {
                thumbIndex = numImages - (slidesPerView - 1)
              }
            }
            thumbsSwiper.slideTo(thumbIndex, 200, false)
          }}
          onClick={(swiper, event) => {
            if (typeof swiper.clickedIndex !== 'undefined') {
              _clickSwiperImage(swiper.clickedIndex)
            }
          }}
        >
          {images}
        </Swiper>
      </div>

      <div className="lot-images-thumbnails-container">
        <Swiper
          className="lot-images-thumbnails-swiper"
          onSwiper={setThumbsSwiper}
          threshold={4}
          loop={false}
          spaceBetween={10}
          slidesPerView={2}
          watchSlidesVisibility={true}
          watchSlidesProgress={true}
          breakpoints={{
            300: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            400: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            500: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
            600: {
              slidesPerView: 6,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1400: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
        >
          {thumbnails}
        </Swiper>
      </div>
    </div>
  }
}

function propsAreEqual(prevProps, nextProps) {
  if (prevProps !== nextProps) {
    return prevProps.baseImagePath === nextProps.baseImagePath && prevProps.numImages === nextProps.numImages
  } else {
    return true
  }
}

export default React.memo(LotImageSlider, propsAreEqual)
