import React, { useState } from 'react'
import PropTypes from 'prop-types'

import FieldText from '../shared/FieldText'
import FieldCheckbox from '../shared/FieldCheckbox'
import FieldSmartSelect from '../shared/FieldSmartSelect'
import FormRow from '../shared/FormRow'
import FusedFields from '../shared/fused-fields'
import ContentPanel from '../shared/ContentPanel'

import { hydrateQueryFields, getLinkCanonical } from 'lib/utility'

const fields_initialstate = {
  rpt_consignor_id: '',
  rpt_date_received_after: '',
  rpt_date_received_before: '',
  rpt_auction_id: '',
  rpt_all_unassigned: '',
  rpt_date_returned_after: '',
  rpt_date_returned_before: '',
  formdata: '',
}

AdminReports.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  lookupData: PropTypes.object.isRequired,
}

AdminReports.defaultProps = {
  lookupData: {},
}

function AdminReports({ location, params, lookupData }) {
  const [fields, setFields] = useState({ ...fields_initialstate, ...(hydrateQueryFields(location.query, fields_initialstate))})

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _handleOpenReport = () => {
    let new_query = {}
    Object.keys(fields).map(key => {
      if (typeof fields[key] !== 'undefined' && fields[key] !== ''){ new_query[key] = fields[key] }
    })
    const resolvedURL = getLinkCanonical({ pathname: '/admin/reports/' + params.reportname, query: new_query })
    window.open(resolvedURL, '_blank')
  }

  let criteria_fields = ''
  let content_title = ''

  const consignor_select = <FieldSmartSelect
    name='rpt_consignor_id'
    addonBefore='Consignor'
    groupClassName='col-lg-4'
    options={lookupData.consignors}
    valueField='id'
    displayField='user_fullname'
  />

  switch (params.reportname) {
    case 'inventory_received':
      content_title = 'Inventory Received Report'
      criteria_fields =
        <FormRow>
          {consignor_select}
          <FieldText name='rpt_date_received_after' addonBefore='Received After' groupClassName='col-lg-3' />
          <FieldText name='rpt_date_received_before' addonBefore='Received Before' groupClassName='col-lg-3' />
        </FormRow>
      break
    case 'unassigned':
      content_title = 'Unassigned Report'
      criteria_fields =
        <FormRow>
          {consignor_select}
        </FormRow>
      break
    case 'prepublication':
      content_title = 'Prepublication Report'
      criteria_fields =
        <FormRow>
          {consignor_select}
          <FieldText name='rpt_auction_id' addonBefore='Auction #' groupClassName='col-lg-3' data-type='unsigned-int' />
          <FieldCheckbox name='rpt_all_unassigned' label='All Unassigned' groupClassName='col-lg-2' />
        </FormRow>
      break
    case 'internal':
      content_title = 'Internal Report'
      criteria_fields =
        <FormRow>
          {consignor_select}
        </FormRow>
      break
    case 'return':
      content_title = 'Return Report'
      criteria_fields =
        <FormRow>
          {consignor_select}
          <FieldText name='rpt_date_returned_after' addonBefore='Returned After' groupClassName='col-lg-3' />
          <FieldText name='rpt_date_returned_before' addonBefore='Returned Before' groupClassName='col-lg-3' />
        </FormRow>
      break
    default:
      // should not get here
  }

  const content = <form className='basic-form'>
    <FusedFields data={fields} onUpdate={_handleUpdate} classes='basic-form-contents'>
      {criteria_fields}
    </FusedFields>
    <div className="flex-standard-container form-action-container standard-border top-separator-border bottom-rounded">
      <div className="flex-group flex-group-left">
        <button type='button' className='btn btn-u btn-u-dark-blue' onClick={_handleOpenReport}><span className='fa fa-th-list' />{' Generate Report'}</button>
      </div>
    </div>
  </form>

  return <ContentPanel
    title={content_title}
    icon="fa fa-print fa-fw"
    auxClassContent="zero-padding"
  >
    {content}
  </ContentPanel>
}

export default AdminReports