import React from 'react'
import PropTypes from 'prop-types'

SimpleContainer.propTypes = {
  children: PropTypes.node,
  format: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleBackgroundColor: PropTypes.string,
  titleIcon: PropTypes.string,
}

SimpleContainer.defaultProps = {
  format: 'standard',
  titleColor: '#555',
  titleBackgroundColor: '#f5f5f5',
}

function SimpleContainer({ format, className, style, title, titleColor, titleBackgroundColor, titleIcon, children }) {
  if (format === 'standard') {
    return <div className={className} style={style}>
      {title ? <div style={{ fontWeight: '700', color: titleColor, backgroundColor: titleBackgroundColor, padding: '0 5px', marginBottom: '5px' }}>{titleIcon ? <span className={titleIcon} style={{ marginRight: '3px' }} /> : null}{title}</div> : null}
      {children}
    </div>
  } else if (format === 'center-container-shadowed') {
    return <div className="resource-container resource-locked-max-width-standard shadow-wrapper">
      <div className="tag-box tag-box-v3 box-shadow shadow-effect-2 resource-content">
        {children}
      </div>
    </div>
  } else if (format === 'full-width-container-shadowed') {
    return <div className="shadow-wrapper">
      <div className="tag-box tag-box-v3 box-shadow shadow-effect-2 resource-content">
        {children}
      </div>
    </div>
  } else {
    return children
  }
}

export default SimpleContainer