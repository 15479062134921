import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { toggleMenu } from 'rdx/modules/menus'

import Menu from './Menu/Menu'

import { menu_content_admin } from 'lib/constants'

MobileMenuAdmin.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
}

MobileMenuAdmin.defaultProps = {

}

const id = "mobile-menu-admin"

function MobileMenuAdmin({ menuOpen, toggleMenu }) {
  return <Menu
    id={id}
    type="mobile"
    position="left"
    title="Admin Dashboard"
    titleIcon={<span className='fa fa-list fa-fw' />}
    hideBurgerIcon={true}
    menuOpen={menuOpen}
    toggleMenu={toggleMenu}
    data={menu_content_admin}
  />
}

function mapStateToProps(state) {
  return {
    menuOpen: state.menus[id],
  }
}

const mapDispatchToProps = { toggleMenu }

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuAdmin)
