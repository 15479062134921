import React from 'react'
import PropTypes from 'prop-types'
import sanitizeHtml from 'sanitize-html'

RawHTML.propTypes = {
  stringToSanitize: PropTypes.string,
  surroundWithQuotes: PropTypes.bool,
}

RawHTML.defaultProps = {
  surroundWithQuotes: false,
}

const ParseRawHTML = ( html ) => {
	if( typeof html === 'string' ) {
		return html
			.replace(/<object>/g, '')
			.replace(/<\/object>/g, '');
	}
	else {
		return html;
	}
}

/* We are using the object tag to create a valid HTML component that will then be stripped before returning */
/* Obviously this means we can't use the object tag in the string parameter, but that's OK since it's not an allowed tag when sanitizing */
function RawHTML({stringToSanitize, surroundWithQuotes}) {
  const sanitizedString = sanitizeHtml(stringToSanitize, {
    allowedTags: [ 'b', 'i', 'br' ],
    allowedAttributes: {},
  })
  if (surroundWithQuotes) {
    return <>&quot;{ParseRawHTML(<object dangerouslySetInnerHTML={{__html: sanitizedString}} />)}&quot;</>
  } else {
    return ParseRawHTML(<object dangerouslySetInnerHTML={{__html: sanitizedString}} />)
  }
}

export default RawHTML