import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import { connect } from 'react-redux'
import { getOperationsInfo, advanceAuctionPhase, resetAdvanceStatus } from 'rdx/modules/admin/operations'

import ContentPanel from '../shared/ContentPanel'
import ConfirmBox from '../shared/ConfirmBox'
import StatusBox from '../shared/StatusBox'
import { auction_modes, modalStyles } from 'lib/constants'

AuctionMode.propTypes = {
  operationsInfo: PropTypes.object.isRequired,
  advanceActionInfo: PropTypes.object.isRequired,
  getOperationsInfo: PropTypes.func.isRequired,
  advanceAuctionPhase: PropTypes.func.isRequired,
  resetAdvanceStatus: PropTypes.func.isRequired,
}

AuctionMode.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getOperationsInfo()),
  ])
}

function AuctionMode({ operationsInfo, advanceActionInfo, getOperationsInfo, advanceAuctionPhase, resetAdvanceStatus }) {
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    getOperationsInfo()
  }, [getOperationsInfo])

  const handleConfirm = () => {
    setShowPopup(true)
  }

  const handlePhaseChange = () => {
    advanceAuctionPhase(operationsInfo.data.advance)
  }

  const popupAfterClose = () => {
    if (advanceAuctionPhase.status !== 0) {
      resetAdvanceStatus()
    }
  }

  const { data } = operationsInfo
  const { target_mode, target_auction_id } = data.advance
  let content = null

  const successMessage = typeof auction_modes[data.auction_mode].targetSuccessText !== 'undefined' ? (typeof auction_modes[data.auction_mode].targetSuccessText === 'function' ? auction_modes[data.auction_mode].targetSuccessText(target_auction_id) : auction_modes[data.auction_mode].targetSuccessText) : 'Successfully advanced to Mode ' + data.auction_mode + ' for Auction ' + data.current_auction
  const popupTitle = typeof auction_modes[target_mode].targetConfirmPromptTitle !== 'undefined' ? (typeof auction_modes[target_mode].targetConfirmPromptTitle === 'function' ? auction_modes[target_mode].targetConfirmPromptTitle(target_auction_id) : auction_modes[target_mode].targetConfirmPromptTitle) : 'Confirm advance to Mode ' + target_mode + ' for Auction ' + target_auction_id
  const promptAdvanceText = 'Advance to Mode ' + target_mode + ' for Auction ' + target_auction_id + '?'
  const promptAdvanceDetail = typeof auction_modes[target_mode].targetConfirmPromptText !== 'undefined' ? (typeof auction_modes[target_mode].targetConfirmPromptText === 'function' ? auction_modes[target_mode].targetConfirmPromptText(target_auction_id) : auction_modes[target_mode].targetConfirmPromptText) : 'Advance to Mode ' + target_mode + ' for Auction ' + target_auction_id + '?'

  const modal = <Modal
    closeTimeoutMS={150}
    isOpen={showPopup}
    onRequestClose={() => {}}
    onAfterClose={popupAfterClose}
    style={modalStyles}
    contentLabel="Confirm Advance Auction Mode" >
    <ConfirmBox
      title={advanceActionInfo.status === 1 ? 'Success' : popupTitle}
      style={{ maxWidth: '600px' }}
      confirm_button_text="Confirm"
      close_button_text="Cancel"
      confirm_button_action={handlePhaseChange}
      close_button_action={() => setShowPopup(false)}
      data={advanceActionInfo}
      resultType="persist"
      resultFormat="children"
    >
      <div style={{ paddingTop: '20px' }}>
        {advanceActionInfo.status === 1 ?
          <div style={{ color: '#3c763d', fontWeight: 'bold' }}>{successMessage}</div>
        :
          <>
            <div style={{ fontWeight: 'bold' }}>{promptAdvanceText}</div>
            <div style={{ color: '#337ab7', fontWeight: 'bold' }}>{promptAdvanceDetail}</div>
          </>
        }
      </div>
    </ConfirmBox>
  </Modal>

  if (operationsInfo.meta.status === 1) {
    const next_title = <span> Next Phase: <span style={{ color: '#337ab7' }}>{typeof auction_modes[target_mode].title === 'function' ? auction_modes[target_mode].title(target_auction_id) : auction_modes[target_mode].title}</span> (Mode {target_mode})</span>
    const next_actions_title = target_mode === 1 ? <span>Starting the next auction will also trigger the following actions:</span> : <span>Moving to mode {target_mode} will also trigger the following actions:</span>
    const next_button_text = <span>{typeof auction_modes[target_mode].targetButtonText !== 'undefined' ? (typeof auction_modes[target_mode].targetButtonText === 'function' ? auction_modes[target_mode].targetButtonText(target_auction_id) : auction_modes[target_mode].targetButtonText) : <span>Proceed to Mode {target_mode}</span>}</span>
    const actions = typeof auction_modes[target_mode].actions === 'function' ? auction_modes[target_mode].actions(operationsInfo.data) : auction_modes[target_mode].actions
    const mode_icon = data.auction_mode < 5 ? 'fa-thermometer-' + data.auction_mode : 'fa-archive'
    content = <div>
      <form className='page-base'>
        <div className="tag-box tag-box-v3" style={{ border: '2px solid #666'}}>
          <div className="section-summary" style={{ color: '#a10f2b'}}>
            <span className={'fa ' + mode_icon + ' fa-fw'} /> Auction {data.current_auction} {auction_modes[data.auction_mode].summary} (Mode {data.auction_mode})
          </div>
          <div className="section-description">
            {auction_modes[data.auction_mode].activeDescription}
          </div>
        </div>
        <div className="tag-box tag-box-v2">
          <div className="section-summary">
            <span className='fa fa-chevron-right fa-fw' />{next_title}
          </div>
          <div className="section-description clearfix">
            {auction_modes[target_mode].targetDescription}
            {actions.length > 0 && <div className="tag-box tag-box-v4 margin-bottom-0">
              <span className="text-bold">{next_actions_title}</span>
              <ul>
                {actions.map((item, i) => {
                  return <li key={i}>{item}</li>
                })}
              </ul>
            </div>}
          </div>
          <div className="section-description clearfix margin-top-10">
            <button type='button' className='btn btn-u btn-u-dark-red pull-right' onClick={handleConfirm}>
              {next_button_text}
              <span className='fa fa-angle-double-right fa-fw' />
            </button>
          </div>
        </div>
        <div className="flex-generic-outer-container">
          <div className="flex-generic-container child-flex-grow child-gutter-10">
            <div className="tag-box tag-box-v3">
              <div className="section-summary" style={{ fontSize: '18px' }}>
                <span className='fa fa-info-circle fa-fw' /> Auction {data.current_auction} Info
              </div>
              <div><span className='fa fa-calendar fa-fw' /> Auction End Date/Time:<br /><span className='fa fa-fw' /> <span className="text-bold">{data.datetime_ending}</span></div>
              <div><span className='fa fa-calendar fa-fw' /> Under Reserve Offer Process Date:<br /><span className='fa fa-fw' /> <span className="text-bold">{data.text_date_under_reserve_offer_process}</span></div>
              <div><span className='fa fa-calendar fa-fw' /> Post-Auction Sale End Date/Time:<br /><span className='fa fa-fw' /> <span className="text-bold">{data.text_date_post_sale_end}</span></div>
            </div>
            <div className="tag-box tag-box-v3">
              <div className="section-summary" style={{ fontSize: '18px' }}>
                <span className='fa fa-history fa-fw' /> Auction {data.current_auction} Action History
              </div>
              {data.timestamp_mode0 && <div><span className='fa fa-star-o fa-fw' /> Mode 0 activated: {data.timestamp_mode0}</div>}
              {data.timestamp_auction_item_generate && <div><span className='fa fa-database fa-fw' /> Auction Lots Generated: {data.timestamp_auction_item_generate}</div>}
              {data.timestamp_mode1 && <div><span className='fa fa-star-o fa-fw' /> Mode 1 activated: {data.timestamp_mode1}</div>}
              {data.timestamp_mode2 && <div><span className='fa fa-star-o fa-fw' /> Mode 2 activated: {data.timestamp_mode2}</div>}
              {data.timestamp_mode3 && <div><span className='fa fa-star-o fa-fw' /> Mode 3 activated: {data.timestamp_mode3}</div>}
              {data.timestamp_eoa_email && <div><span className='fa fa-envelope-o fa-fw' /> End of auction email sent: {data.timestamp_eoa_email}</div>}
              {data.timestamp_invoice_generate && <div><span className='fa fa-file-text-o fa-fw' /> Initial Invoices Generated: {data.timestamp_invoice_generate}</div>}
              {data.timestamp_mode4 && <div><span className='fa fa-star-o fa-fw' /> Mode 4 activated: {data.timestamp_mode4}</div>}
              {data.timestamp_mode5 && <div><span className='fa fa-star-o fa-fw' /> Mode 5 activated: {data.timestamp_mode5}</div>}
              {data.timestamp_statement_generate && <div><span className='fa fa-file-text-o fa-fw' /> Statements Generated: {data.timestamp_statement_generate}</div>}
            </div>
          </div>
        </div>
      </form>
    </div>
  }

  return <ContentPanel
    title="Auction Mode"
    icon="fa fa-star fa-fw"
  >
    <div>
      <StatusBox data={operationsInfo.meta} hideOnSuccess={true} className="margin-bottom-10" />
      {content}
      {modal}
    </div>
  </ContentPanel>
}

function mapStateToProps(state) {
  return {
    operationsInfo: state.admin.operations.operationsInfo,
    advanceActionInfo: state.admin.operations.actions.advanceAuctionPhase,
  }
}

export default connect(mapStateToProps, {
  getOperationsInfo,
  advanceAuctionPhase,
  resetAdvanceStatus,
})(AuctionMode)