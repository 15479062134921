import { get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

const base = 'owa/admin/statsbasic/'

const DATA = defineAction(base, 'DATA', 'async')

const initialState = {
  content: {
    next_auction_info: {
      data: {},
      meta: status_defaults,
    },
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        content: {
          ...state.content,
          [action.foundation.statsname]: {
            ...state.content[action.foundation.statsname],
            meta: {
              ...status_defaults,
              processing: true,
            }
          }
        }
      }
    case DATA.fail:
      return {
        ...state,
        content: {
          ...state.content,
          [action.foundation.statsname]: {
            ...state.content[action.foundation.statsname],
            meta: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
    case DATA.success:
      return {
        ...state,
        content: {
          ...state.content,
          [action.foundation.statsname]: {
            ...state.content[action.foundation.statsname],
            data: action.payload.data,
            meta: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
    default:
      return state
  }
}

export function getStat(statsname) {
  return (dispatch, getState) => {
    if (!getState().admin.statsbasic.content[statsname]) {
      return null
    }

    const endpoint = '/api/admin/stats/basic/' + statsname
    return dispatch(
      get({
        types: DATA,
        endpoint: endpoint,
        foundation: { statsname: statsname },
      })
    )
  }
}
