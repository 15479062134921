import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

WatchListToggle.propTypes = {
  toggleWatch: PropTypes.func.isRequired,
  lot_id: PropTypes.number.isRequired,
  lot_number: PropTypes.number.isRequired,
  hide: PropTypes.bool,
  page_mode: PropTypes.string,
  user_watch: PropTypes.object,
}

function WatchListToggle({ toggleWatch, lot_id, lot_number, hide=true, page_mode="summary", user_watch }) {
  if (hide) return null
  const watchLot = () => {
    toggleWatch({ watch: 1, lot_id, lot_number })
  }

  const unwatchLot = () => {
    toggleWatch({ watch: 0, lot_id, lot_number })
  }

  const button_watch_class = classNames('btn', {
    'btn-xs': page_mode === 'summary',
    'pull-right': page_mode === 'summary',
  })
  const button_watch = <button className={button_watch_class + " btn-warning"} disabled={user_watch && user_watch.processing} onClick={watchLot}><span className="fa-fw fa fa-plus"/><span style={{fontWeight: 700}}> Add to Watchlist</span></button>
  const button_unwatch = <button className={button_watch_class + " btn-light"} disabled={user_watch && user_watch.processing} onClick={unwatchLot}><span className="fa-fw fa fa-minus"/><span style={{fontWeight: 700}}> Stop Watching</span></button>
  return !user_watch ? button_watch : button_unwatch
}

export default WatchListToggle
