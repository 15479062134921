import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getTestimonials } from 'rdx/modules/info/testimonials'

import StatusBox from '../../shared/StatusBox'
import RawHTML from '../../shared/RawHTML'

Testimonials.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  testimonials: PropTypes.object.isRequired,
  getTestimonials: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
}

Testimonials.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getTestimonials(params)),
  ])
}

function Testimonials({ location, params, testimonials, getTestimonials, router }) {
  useEffect(() => {
    getTestimonials(params)
  }, [params, getTestimonials])


  let content = null

  if (testimonials.content.meta.status > 1) {
    content = <StatusBox data={testimonials.content.meta} />
  } else {
    content = <div className="row">
      <div className="col-md-6 quote-block testimonial-block">
        <div className="headline text-center">
          <h4>Bidders</h4>
        </div>
        {testimonials.content.data.filter(t => t.source === 0).map(t => <blockquote key={t.id}><RawHTML stringToSanitize={t.text} surroundWithQuotes={true}></RawHTML><div>{t.name} - {t.country} ({t.month_year})</div></blockquote>)}
      </div>
      <div className="col-md-6 quote-block testimonial-block">
        <div className="headline text-center">
          <h4>Consignors</h4>
        </div>
        {/* <blockquote>"We started consigning at Old World Auctions many years ago, starting with Marti and Curt and now continuing with the team of Eliane and Jon. They are great in describing the maps and the system for the consigner is very easy and convenient. 4 weeks after the auction the payment is always on time." <div> HB - Netherlands (3/2023) </div></blockquote> */}
        {testimonials.content.data.filter(t => t.source === 1).map(t => <blockquote key={t.id}><RawHTML stringToSanitize={t.text} surroundWithQuotes={true}></RawHTML><div>{t.name} - {t.country} ({t.month_year})</div></blockquote>)}
      </div>
    </div>
  }

  return <div className="general-table-defaults">
    <Helmet>
      <title>Client Testimonials</title>
      <link rel="canonical" href={location.pathname} />
    </Helmet>
    <div className="titlebar heading heading-v1 text-center">
      <h2>Client Testimonials</h2>
    </div>
    {content}
  </div>
}

function mapStateToProps(state) {
  return {
    testimonials: state.info.testimonials,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getTestimonials }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Testimonials)