import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import update from 'immutability-helper'

import SlideDetails from './SlideDetails'

import MovableItem from '../shared/MovableItem'
import FusedFields from '../shared/fused-fields'
import FieldText from '../shared/FieldText'
import FieldBase from '../shared/FieldBase'
import StatusBox from '../shared/StatusBox'
import ConfirmModal from '../shared/confirmModal'
import ConfirmBox from '../shared/ConfirmBox'
import Slide from '../shared/Slide'

import useConfirm from "../shared/hooks/useConfirm"
import { modalStyles } from 'lib/constants'

const style = {
  border: '1px solid black',
  padding: '4px 4px 0 4px',
}

SlideGroup.propTypes = {
  slidegroup: PropTypes.object.isRequired,
  isNew: PropTypes.bool.isRequired,
  saveSlideGroup: PropTypes.func.isRequired,
  closeSlideGroup: PropTypes.func,
  newSlide: PropTypes.func.isRequired,
  getSlide: PropTypes.func.isRequired,
  saveSlide: PropTypes.func.isRequired,
  removeSlide: PropTypes.func.isRequired,
  toggleActive: PropTypes.func.isRequired,
  resetSlideStatus: PropTypes.func.isRequired,
  fieldUpdateSlideGroup: PropTypes.func.isRequired,
  fieldUpdateSlide: PropTypes.func.isRequired,
  reorderSlides:  PropTypes.func.isRequired,
}

function SlideGroup({ slidegroup, isNew, saveSlideGroup, closeSlideGroup, newSlide, getSlide, saveSlide, removeSlide, toggleActive, resetSlideStatus, fieldUpdateSlideGroup, fieldUpdateSlide, reorderSlides }) {
  const [selectedSlideID, setSelectedSlideID] = useState(null)
  const [showPopup, setShowPopup] = useState(false)
  const [slideGetStatus, setSlideGetStatus] = useState(null)
  const [slideUpdateStatus, setSlideUpdateStatus] = useState(null)
  const [previewSlideID, setPreviewSlideID] = useState(null)
  const [showPreviewPopup, setShowPreviewPopup] = useState(false)
  const { isConfirmed } = useConfirm()
  const { data, validation } = slidegroup

  useEffect(() => {
    if (selectedSlideID) {
      setSlideGetStatus(slidegroup.slide[selectedSlideID].meta)
      if (slidegroup.slide[selectedSlideID].meta.status === 1 && !showPopup) {
        console.log('show-popup (get success)')
        setShowPopup(true)
      }
      if (slidegroup.slide[selectedSlideID].update.status === 1 && showPopup) {
        setSlideUpdateStatus(slidegroup.slide[selectedSlideID].update)
        resetSlideStatus(selectedSlideID, { slidegroupID: slidegroup.slide[selectedSlideID].data.slidegroup_id })
        setSelectedSlideID(null)
        console.log('hide-popup (update success)')
        setShowPopup(false)
      }
    } else if (showPopup) {
      console.log('hide-popup (cancel)')
      setSlideUpdateStatus(null)
      setShowPopup(false)
    }
  },[resetSlideStatus, selectedSlideID, showPopup, slidegroup.slide])

  const _handleUpdate = (field, value) => {
    fieldUpdateSlideGroup(field, value, data.id)
  }

  const _newSlide = () => {
    setSelectedSlideID('new')
    newSlide(data.id)
    setShowPopup(true)
  }

  const _editSlide = (e, item) => {
    setSelectedSlideID(item.id)
    getSlide(item.id, { slidegroupID: data.id })
  }

  const _handleRemoveSlide = async (e, item) => {
    console.log('_handleRemoveSlide:', item)
    const confirmed = await isConfirmed("Confirm removal of slide " + item.description)
    if (confirmed) {
      removeSlide(item.id, { slidegroupID: data.id })
    } else {
      // nothing
    }
  }

  const _previewSlide = (e, item) => {
    setPreviewSlideID(item.id)
    getSlide(item.id, { slidegroupID: data.id })
    setShowPreviewPopup(true)
  }

  const _closePreviewPopup = () => {
    setShowPreviewPopup(false)
  }

  const _toggleActive = async (e, item) => {
    console.log('_toggleActive-slidegroup:', item)
    toggleActive(item.id, { mode: 'slide', slidegroupID: data.id })
  }

  const moveSlide = useCallback((dragIndex, hoverIndex) => {
      const dragCard = slidegroup.slidelist[dragIndex]
      const newlist = update(slidegroup.slidelist, {
          $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
          ],
      })
      reorderSlides(data.id, newlist)
  }, [slidegroup.slidelist, data.id, reorderSlides])

  const renderSlideCard = (card, index) => {
    const displayName = (card.description || card.title || card.subtitle) ? [card.description, card.title, card.subtitle].filter(x => typeof x === 'string' && x.length > 0).join(', ') : [card.header, ('(Slide ID ' + card.id + ')')].join(' ')
    return <MovableItem key={card.id} index={index} id={card.id} moveItem={moveSlide} containerClass={card.active === 0 ? "item-disabled" : ""}>
      <div className="flex-standard-container">
        <div className="flex-group flex-group-left" style={{ minWidth: 0 }}>
          <span className={"fa fa-lg " + (card.active === 1 ? "fa-toggle-on text-primary" : "fa-toggle-off")} style={{ cursor: 'pointer', marginRight: '10px' }} onClick={(e) => _toggleActive(e, card)} />
          <span className="text-bold" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{displayName.replace(/<[^>]*>?/gm, '')}</span>
        </div>
        <div className="flex-group flex-group-right">
          <span className="fa fa-eye fa-border fa-hover text-success" style={{cursor: 'pointer' }} onClick={(e) => _previewSlide(e, card)} />
          {!slidegroup.unsaved_changes && <span className="fa fa-edit fa-border fa-hover text-info" style={{cursor: 'pointer', marginLeft: '10px' }} onClick={(e) => _editSlide(e, card)} />}
          {!slidegroup.unsaved_changes && <span className="fa fa-close fa-border fa-hover text-danger" style={{cursor: 'pointer', marginLeft: '10px' }} onClick={(e) => _handleRemoveSlide(e, card)} />}
        </div>
      </div>
    </MovableItem>
  }

  const _saveSlideGroup = () => {
    saveSlideGroup(data.id, { isNew })
  }

  const _closeSlidePopup = () => {
    resetSlideStatus(selectedSlideID, { slidegroupID: data.id })
    setSelectedSlideID(null)
  }

  const actionBox = <ConfirmBox
    title={(selectedSlideID === 'new' ? 'New ' : 'Edit ') + 'Slide for Group ' + data.name}
    style={{maxWidth: '800px'}}
    hide_action_buttons={true}
  >
    {!isNew && selectedSlideID && <SlideDetails
      slidegroupID={data.id}
      slidegroupType={data.type}
      templateData={slidegroup.templateData}
      slide={slidegroup.slide[selectedSlideID]}
      isNew={selectedSlideID === 'new'}
      getSlide={getSlide}
      saveSlide={saveSlide}
      closeSlide={_closeSlidePopup}
      fieldUpdate={fieldUpdateSlide}
    />}
  </ConfirmBox>

  const templateData = <div>
    <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', paddingBottom: '5px', height: '27px' }}>
      <div style={{ fontSize: '18px', lineHeight: '18px', fontWeight: 'bold', backgroundColor: '#fefefe' }}>Template Data</div>
      <div style={{ fontSize: '12px', lineHeight: '12px', fontWeight: 'bold', backgroundColor: '#fefefe' }}>(based on Auction {slidegroup.originalData.auction_id})</div>
    </div>
    <div style={{ border: '1px solid black', padding: '4px 4px 0 4px' }}>
      <div><span className="text-bold margin-right-10">Auction Year:</span>{slidegroup.templateData.auctionYear}</div>
      <div><span className="text-bold margin-right-10">Auction Month:</span>{slidegroup.templateData.auctionMonth}</div>
      <div><span className="text-bold margin-right-10">Auction End Day:</span>{slidegroup.templateData.auctionEndDay}</div>
      <div><span className="text-bold margin-right-10">Post-Auction End Day:</span>{slidegroup.templateData.postAuctionEndDay}</div>
      <div><span className="text-bold margin-right-10">Next Auction Year:</span>{slidegroup.templateData.nextAuctionYear}</div>
      <div><span className="text-bold margin-right-10">Next Auction Start Day:</span>{slidegroup.templateData.nextAuctionStartDay}</div>
      <div><span className="text-bold margin-right-10">Next Auction End Day:</span>{slidegroup.templateData.nextAuctionEndDay}</div>
    </div>
  </div>

  const content = <div style={{ marginTop: '20px' }}>
    <div className="flex-container-base">
      <div style={{ flex: '1 0 48%', minWidth: 0 }}>
        <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', paddingBottom: '5px' }}>
          <div style={{ fontSize: '18px', lineHeight: '18px', fontWeight: 'bold', backgroundColor: '#fefefe' }}>Slides</div>
          {!slidegroup.unsaved_changes && <button type="button" className="btn btn-xs btn-primary" onClick={ _newSlide }><span className="fa fa-fw fa-plus" /> Add New Slide</button>}
          {slidegroup.unsaved_changes && <div className="alert-warning" style={{ fontSize: '14px', lineHeight: '20px', border: '1px dashed #aaa', padding: '0px 2px', borderRadius: '2px' }}>
            Unsaved changes
          </div>}
        </div>
        <DndProvider backend={HTML5Backend}>
          <div style={style}>{slidegroup.slidelist.map((card, i) => renderSlideCard(card, i))}</div>
        </DndProvider>
      </div>
      <div style={{ flex: '1 0 4%', minWidth: 0 }}></div>
      <div style={{ flex: '1 0 48%', minWidth: 0 }}>
        {templateData}
      </div>
    </div>
    <StatusBox data={slidegroup.actions.update} mainClassName="margin-top-10" />
    <StatusBox data={slidegroup.actions.removeSlide} mainClassName="margin-top-10" />
    {slideGetStatus && <StatusBox data={slideGetStatus} mainClassName="margin-top-10" hideOnSuccess={true} />}
    {slideUpdateStatus && <StatusBox data={slideUpdateStatus} mainClassName="margin-top-10" />}
  </div>

  const previewBox = <ConfirmBox
    title={'Previewing Slide ' + previewSlideID}
    titleIcon="fa fa-eye"
    style={{maxWidth: '800px'}}
    confirm_button_show={false}
    close_button_text='Close'
    close_button_action={_closePreviewPopup}
  >
    {!isNew && previewSlideID && <Slide
      layout="standard"
      data={slidegroup.slide[previewSlideID].data}
      preview={true}
    />}
  </ConfirmBox>

  return <div>
    <FusedFields data={data} onUpdate={_handleUpdate}>
      <div className="flex-standard-container form-action-container standard-border">
        <div className="flex-group" style={{ width: '100%' }}>
          <FieldText name='name' addonBefore='Name' groupStyle={{ width: '100%' }} />
        </div>
        <div className="flex-group flex-group-right">
          <FieldBase type="text" name='auction_id' data-type="int" groupStyle={{ width: '250px' }} disabled={data.type === 0} forceValue={data.type === 0} forcedValue={'Global'} addonBefore={<span><input type="checkbox" name="type" checked={ data.type === 1 } /><span className="margin-left-5" style={{ verticalAlign: 'top' }}>Auction ID</span></span>} />
          <button type="button" className="btn btn-u btn-u-sea" onClick={ _saveSlideGroup }><span className='fa fa-fw fa-arrow-up' />{ isNew ? ' Save New Group' : ' Save Changes' }</button>
        </div>
      </div>
      {isNew ? <div style={{ marginTop: '10px', padding: '10px', fontSize: '24px', color: '#aaa' }}>
          Enter a name and save the slide group to start adding slides
        </div>
      :
      content}

    </FusedFields>
    <ConfirmModal />
    <Modal
      closeTimeoutMS={150}
      isOpen={showPopup}
      onRequestClose={() => {}}
      style={modalStyles} >
      {actionBox}
    </Modal>
    <Modal
      closeTimeoutMS={150}
      isOpen={showPreviewPopup}
      onRequestClose={() => {}}
      style={modalStyles} >
      {previewBox}
    </Modal>
  </div>
}

export default SlideGroup

