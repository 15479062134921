import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getArticles } from 'rdx/modules/info/articles/archive'

import DataList from '../../shared/DataList'
import ArticleSummary from './ArticleSummary'
import { SiteView } from '../../base/components/siteview-context'
import PrintButton from '../../shared/PrintButton'

// if (typeof window !== 'undefined' && 'scrollRestoration' in window.history) {
//   history.scrollRestoration = 'manual'
// }

ArticleList.propTypes = {
  articles: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  siteinfo: PropTypes.object.isRequired,
  getArticles: PropTypes.func.isRequired,
}

ArticleList.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getArticles(location, params)),
  ])
}

function ArticleList({ articles, location, params, siteinfo, getArticles }) {
  useEffect(() => {
    getArticles(location, params)
  }, [location, params, getArticles])

  if (!articles.content.info.count === 0) {
    return <div>Loading...</div>
  }

  let content = <DataList
    itemDisplayName='article'
    errorCondition={articles.content.meta.status > 1}
    errorMessage={articles.content.meta.statusText}
    isLoading={articles.content.meta.processing}
    data={articles.content.data}
    totalItemCount={articles.content.info.count}
    datakey='id'
    pageSize={articles.content.info.pageSize}
    route_pathname={location.pathname}
    route_query={location.query}
    params={params}
    location={location}
  >
    <ArticleSummary
      siteinfo={siteinfo}
    />
  </DataList>

  return <SiteView.Consumer>
    {mode => (
      <div className="dashboard-content">
        {!mode.wrap_content && <PrintButton />}
        {content}
      </div>
    )}
  </SiteView.Consumer>
}

function mapStateToProps(state) {
  return {
    articles: state.info.articles.archive,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getArticles }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleList)