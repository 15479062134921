import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import classNames from 'classnames'

import {SiteView} from '../base/components/siteview-context'

import FusedFields from '../shared/fused-fields'

const fields_initialstate = {
  goToPage: '',
}

DataListPager.propTypes = {
  maxPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  itemDisplayName: PropTypes.string.isRequired,
  itemDisplayNamePlural: PropTypes.string,
  totalItemCount: PropTypes.number,
  showCount: PropTypes.bool,
  showPageChooser: PropTypes.bool,
  route_query: PropTypes.object,
  position: PropTypes.string,
  pagingControlSize: PropTypes.string,
}

DataListPager.defaultProps = {
  totalItemCount: 0,
  showCount: true,
  showPageChooser: true,
  route_query: {},
  position: "top",
  pagingControlSize: 'normal',
}

function DataListPager({ route_query, page, maxPage, totalItemCount, showCount, showPageChooser, position, pagingControlSize, itemDisplayName, itemDisplayNamePlural }) {
  const [fields, setFields] = useState(fields_initialstate)

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _clearPageChooseInput = () => {
    setFields(fields_initialstate)
  }

  const pageChange = () => {
    if (fields.goToPage !== '') {
      if (fields.goToPage <= maxPage && fields.goToPage !== page) {
        const { page, ...other } = route_query
        const newQuery = {
          ...other,
          ...(fields.goToPage > 1 && { page: fields.goToPage }),
        }
        _clearPageChooseInput()
        browserHistory.push({ ...location, query: newQuery })
        document.body.scrollTop = 0;
      } else {
        _clearPageChooseInput()
      }
    }
  }

  const handleNavFirst = () => {
    const { page, ...other } = route_query
    browserHistory.push({ ...location, query: other })
    document.body.scrollTop = 0;
  }

  const handleNavLast = () => {
    const { page, ...other } = route_query
    const newQuery = {
      ...other,
      ...(maxPage > 1 && { page: maxPage }),
    }
    browserHistory.push({ ...location, query: newQuery })
    document.body.scrollTop = 0;
  }

  const handleNavNext = () => {
    const { page, ...other } = route_query
    const newPage = page ? (+page < maxPage ? +page + 1 : maxPage) : 2
    const newQuery = {
      ...other,
      page: newPage,
    }
    browserHistory.push({ ...location, query: newQuery })
    document.body.scrollTop = 0;
  }

  const handleNavPrevious = () => {
    const { page, ...other } = route_query
    const newPage = +page - 1
    const newQuery = {
      ...other,
      ...(newPage > 1 && { page: newPage }),
    }
    browserHistory.push({ ...location, query: newQuery })
    document.body.scrollTop = 0;
  }

  const handle_local_keyDown = (key, keyCode) => {
    if (key === 'Enter' || keyCode === 13) {
      pageChange()
    }
  }

  const openPrintView = () => {
    window.open(browserHistory.createHref({ ...location, query: { ...route_query, printview: null } }))
  }

  const resolvedItemDisplayNamePlural = itemDisplayNamePlural ? itemDisplayNamePlural : itemDisplayName + 's'
  const pagerContainerStyle = position === "top" ? { marginBottom: '5px' } : { borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }

  const pagerContainerClasses = classNames({
    'row': true,
    'fixed-gutter-5': true,
    'pager-container': pagingControlSize !== 'slim',
    'pager-container-slim': pagingControlSize === 'slim',
    'clearfix': true,
  });
  const infoBoxClasses = classNames({
    'pager-element': true,
    'info-box': true,
    'info-box-standard': pagingControlSize !== 'slim',
    'info-box-slim': pagingControlSize === 'slim',
  });
  const pageChooserClasses = classNames({
    'pager-element': true,
    'page-chooser': true,
    'show-only-in-desktop': true,
    'page-chooser-standard': pagingControlSize !== 'slim',
    'page-chooser-slim': pagingControlSize === 'slim',
  });

  const navFirst = <button className='btn btn-u show-only-in-desktop' onClick={ handleNavFirst } disabled={page === 1}><span className='fa fa-angle-double-left' /> First</button>
  const navPrevious = <button className='btn btn-u' onClick={ handleNavPrevious } disabled={page === 1}><span className='fa fa-angle-left' /> Previous</button>

  const totalInfo = <div className={infoBoxClasses} style={{marginRight: '4px'}}>
    <span className='fa fa-database margin-right-10' />
    { totalItemCount + ' ' + (totalItemCount === 1 ? itemDisplayName : resolvedItemDisplayNamePlural)}
  </div>

  const pageInfo = <div className={infoBoxClasses} style={{marginRight: '4px'}}>
    <span className='fa fa-clone margin-right-10' />
    <span className='show-only-in-wide-view'>Page </span>
    <span>{page + ' of ' + maxPage}</span>
  </div>

  const pageChooser = <div className={pageChooserClasses} style={{marginRight: '4px'}}>
    <FusedFields data={fields} onUpdate={_handleUpdate} onKeyDown={handle_local_keyDown}>
      <input name="goToPage" placeholder="page" autoComplete="off" data-type='unsigned-int' />
      <span className='fa fa-share margin-left-10' onClick={ pageChange } />
    </FusedFields>
  </div>

  const navNext = <button className='btn btn-u pull-right' onClick={ handleNavNext } disabled={page === maxPage}><span>Next </span><span className='fa fa-angle-right' /></button>
  const navLast = <button className='btn btn-u pull-right show-only-in-desktop' onClick={ handleNavLast } disabled={page === maxPage}><span>Last </span><span className='fa fa-angle-double-right' /></button>
  const printview_link = <div className="print_button pager-element show-only-in-desktop"><a className="btn btn-u" onClick={openPrintView}><i className="fa fa-fw fa-print rounded"></i><span className="show-only-in-wide-view">Print View</span></a></div>

  return <SiteView.Consumer>
    {mode => (
      <div className={pagerContainerClasses} style={pagerContainerStyle}>
        <div className="col-xs-12 col-sm-6 col-sm-push-3">
          <div className="pager-elements-container">
            {totalItemCount > 0 && showCount ? totalInfo : null}
            {maxPage > 1 ? pageInfo : null}
            {maxPage > 1 && showPageChooser ? pageChooser : null}
            {mode.wrap_content && printview_link}
          </div>
        </div>
        <div className="col-xs-6 col-sm-3 col-sm-pull-6">
          {maxPage > 1 ? navFirst : null}
          {maxPage > 1 ? navPrevious : null}
        </div>
        <div className="col-xs-6 col-sm-3">
          {maxPage > 1 ? navLast : null}
          {maxPage > 1 ? navNext : null}
        </div>
      </div>
    )}
  </SiteView.Consumer>
}

export default DataListPager