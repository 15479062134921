import { useState, useEffect, useCallback } from 'react'

import { isClient } from 'lib/exenv'

function getVerticalScrollPosition() {
  if (!isClient) return 0
  const positionY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
  return positionY
}

export const useHeaderShrinker = () => {
  const [shrinkHeader, setShrinkHeader] = useState(false)

  const handleScrollEvent = useCallback((e) => {
    setShrinkHeader((prevShrinkHeader) => {
      const py = getVerticalScrollPosition()
      if ((py >= 20 && !prevShrinkHeader) || (py < 20 && prevShrinkHeader && document.documentElement.style.position !== 'fixed')) {
        return !prevShrinkHeader
      } else {
        return prevShrinkHeader
      }
    })
  }, [])

  useEffect(() => {
    const scrollListener = (e) => {
      handleScrollEvent(e)
    }
    window.addEventListener('scroll', scrollListener, { passive: true })

    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [handleScrollEvent])

  return shrinkHeader
}

export default useHeaderShrinker