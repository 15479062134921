import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { browserHistory, Link } from 'react-router'
import classNames from 'classnames'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import Modal from 'react-modal'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Keyboard, Lazy, Scrollbar } from 'swiper'
SwiperCore.use([Navigation, Keyboard, Lazy, Scrollbar])

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { processIncomingMessage } from 'rdx/modules/liveAuction'
import { getLot } from 'rdx/modules/lot'
import FieldBase from '../shared/FieldBase'
import InputGroupMultiple from '../shared/InputGroupMultiple'
import ConfirmBox from '../shared/ConfirmBox'

import { isClient } from 'lib/exenv'
import { forceInteger, round, formatMoneyInteger, loginWithMemory, jsonFormatter } from 'lib/utility'
import { modalStyles } from 'lib/constants'

LiveAuction.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  siteinfo: PropTypes.object.isRequired,
  baseData: PropTypes.object.isRequired,
  lotData: PropTypes.object.isRequired,
  adminData: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  auctionAuthorizationLevel: PropTypes.number.isRequired,
  processIncomingMessage: PropTypes.func.isRequired,
  getLot: PropTypes.func.isRequired,
}

function LiveAuction({ location, params, siteinfo, baseData, lotData, adminData, messages, isAuthenticated, auctionAuthorizationLevel, processIncomingMessage, getLot }) {
  const interface_mode = location.pathname.includes('/admin') ? 'admin' : (isAuthenticated ? 'user' : 'public')
  const socketURL = isClient ? (((window.location.protocol === "https:") ? "wss://" : "ws://") + window.location.host) : ''
  const [showPopup, setShowPopup] = useState(false)
  const [input, setInput] = useState('')
  const [swiper, setSwiper] = useState(null)
  const [backToCurrentSlideLinkStatus, setBackToCurrentSlideLinkStatus] = useState('hidden')
  const listRef = useRef(null)

  const _login = () => {
    loginWithMemory(location.pathname)
  }

  const closePopup = () => {
    setShowPopup(false)
  }

  const _goToAuctionArchives = () => {
    browserHistory.push('/catalog/archive')
  }

  const { lastJsonMessage, sendJsonMessage, readyState } = useWebSocket(socketURL, {
    queryParams: {
      interface_mode,
    },
    onOpen: () => console.log('ws-opened'),
    //Will attempt to reconnect on all close events, such as server shutting down
    // shouldReconnect: (closeEvent) => true,
    onMessage: (event) => {
      console.log(`Received message ${event.data} from server`)
      processIncomingMessage(event.data)
    },
    onClose: (event) => {
      console.log('close-event:', event)
      console.log('*reconnecting*')

    },
    onError: (event) => { console.log('error-event:', event)},
    // shouldReconnect: (closeEvent) => {
    //   /*
    //   useWebSocket will handle unmounting for you, but this is an example of a
    //   case in which you would not want it to automatically reconnect
    // */
    //   // return didUnmount.current === false;
    //   console.log('closeEvent:', closeEvent)
    //   if (closeEvent.reason === 'logout') {
    //     // reconnect with unauthenticated/public session
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // reconnectAttempts: 10,
    // reconnectInterval: 3000,
  },
)

  useEffect(() => {
    if (baseData.current_auction !== 0 && baseData.current_lot_number !== 0) {
      getLot(baseData.current_auction, baseData.current_lot_number)
    }
  }, [getLot, baseData.current_auction, baseData.current_lot_number])

  useEffect(() => {
    //bring the last item into view
    listRef.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }, [messages])

  useEffect(() => {
    // make the current lot slide the second visible one
    if (swiper && !swiper.destroyed && baseData.current_lot_number > 2) {
      swiper.slideTo(baseData.current_lot_number - 2, 500, false)
    }
  },[baseData.current_lot_number, swiper])

  const connectionOpen = readyState === ReadyState.OPEN

  // user actions
  const handleBidRequest = useCallback(() => sendJsonMessage({ action: 'bidRequest', payload: { item_id: baseData.item_id, auction_item_id: baseData.id, bidAmount: baseData.nextBidAmount }}), [sendJsonMessage, baseData.item_id, baseData.id, baseData.nextBidAmount])

  // admin actions
  const handleBidAccept = useCallback(() => sendJsonMessage({ action: 'bidAccept', payload: { lot_id: baseData.id, visible_high_bid: baseData.price_highbid, bidRequest: adminData.bidRequest }}), [sendJsonMessage, baseData.id, baseData.price_highbid, adminData.bidRequest])
  const handleNotification = useCallback((messages, { source=null }={}) => {
    if (source === null || (source === 'custom' && messages[0] !== '')) {
      sendJsonMessage({ action: 'adminNotification', payload: { messages }})
    }
    if (source === 'custom') {
      setInput('')
    }
  }, [sendJsonMessage])
  const handleLotAdvance = useCallback(() => sendJsonMessage({ action: 'lotAdvance', payload: { baseData }}), [sendJsonMessage, baseData])
  const handleCloseLiveAuction = useCallback(() => sendJsonMessage({ action: 'closeLiveAuction', payload: { baseData }}), [sendJsonMessage, baseData])
  const handlePlaceCompetingBid = useCallback(() => {
    sendJsonMessage({ action: 'placeCompetingBid', payload: { lot_id: baseData.id, visible_high_bid: baseData.price_highbid, bidAmount: baseData.nextBidAmount }})
    setShowPopup(false)
  }, [sendJsonMessage, baseData.id, baseData.price_highbid, baseData.nextBidAmount])

  const handleConfirmCompetingBid = () => {
    setShowPopup(true)
  }

  const biddingDisabled = typeof auctionAuthorizationLevel === 'undefined' || (auctionAuthorizationLevel < siteinfo.authorization_level_required)
  let content
  let infoPanelUpper = null
  let infoPanelLower = null
  let confirmContent = null

  if (siteinfo.auction_type !== 1 || siteinfo.auction_mode < 2) {
    content = <div className="owa-row">
      <Helmet>
        <title>{'Auction ' + siteinfo.current_auction}</title>
      </Helmet>
      <div style={{width: '100%', paddingTop: '100px', paddingBottom: '100px', fontSize: '16px', textAlign: 'center'}}>
      {interface_mode === 'admin' && (connectionOpen ? <div><span className='fa fa-circle connected' />Connected to Live Auction</div> : <a onClick={() => window.location.reload()}><div className="live-auction-status-disconnected"><span className='fa fa-circle disconnected' />Disconnected from Live Auction - Refresh Page</div></a>)}
        There is currently no Live Auction in progress
        <br /><br />
        In the meantime, you may <Link to={'/catalog/archive'}>browse the auction archive</Link> to see past auction lots
      </div>
    </div>
  } else if (baseData.closed < 2) {
    const bid_status_color = baseData.is_high_bidder ? (baseData.reserve_met ? 'color-green1' : 'color-orange') : 'color-red'
    const bid_status_icon = baseData.is_high_bidder ? (baseData.reserve_met ? <span className="fa-fw fa fa-check-circle"/> : <span className="fa-fw fa fa-exclamation-circle"/>) : <span className="fa-fw fa fa-exclamation-circle"/>
    const bid_status_message = baseData.is_high_bidder ? (baseData.reserve_met ? 'High Bidder' : 'High Bidder') : 'Not High Bidder'
    const baseImagePath = __DYNAMIC_IMAGES_RELATIVE_BASE_URL__ + "auctions/" + siteinfo.current_auction + "/lots/" + siteinfo.current_auction + "-"
    const button_ended = <button className='btn btn-u btn-u-darkred btn-block' style={{borderRadius: 0, fontWeight: 'bold'}} disabled={true}>{'The live auction has ended'}</button>

    switch (interface_mode) {
      case 'public':
      {
        infoPanelLower = <div>
          {baseData.closed > 0 ? button_ended: <button className='btn btn-u btn-u-darkred btn-block' style={{borderRadius: 0, fontWeight: 'bold'}} onClick={_login}>{'Login to Bid'}</button>}
        </div>
        break;
      }
      case 'user':
      {
        const button_unauthorized = <button className='btn btn-u btn-u-darkred btn-block' style={{borderRadius: 0, fontWeight: 'bold'}} disabled={true}>{'You are not approved to bid'}</button>
        const btnBidText = baseData.is_high_bidder ? 'You have the high bid' : (baseData.bidRequestPending ? ( baseData.is_pending_bidder ? 'Bid Request Submitted' : 'Competing Bid Pending...') : 'Bid ' + formatMoneyInteger(baseData.nextBidAmount) + ' Now!')
        const btnBidDisabled = baseData.is_high_bidder || baseData.bidRequestPending || !connectionOpen
        const button_bid = <button className="btn btn-u btn-u-darkred btn-block" onClick={handleBidRequest} disabled={btnBidDisabled}><span>{btnBidText}</span></button>
        infoPanelLower = <div>
          {baseData.closed > 0 ? button_ended: (biddingDisabled ? button_unauthorized : <div>{button_bid}<div className="color-darkred text-bold">CLICKING THIS BUTTON PLACES AN IMMEDIATE BID</div></div>)}
        </div>
        break;
      }
      case 'admin':
      {
        infoPanelUpper = <div className="live-auction-info-panel-upper">
          <div className="live-auction-admin-actions-upper">
            <button className="btn btn-u btn-u-darkred btn-block" onClick={handleBidAccept} disabled={!connectionOpen || !baseData.bidRequestPending}>
              <span>{ baseData.bidRequestPending ? 'Accept Bid ' + formatMoneyInteger(adminData.bidRequest?.bid_amount) : '--- no active request ---' }</span>
            </button>
          </div>
        </div>
        infoPanelLower = <div className="live-auction-info-panel-lower">
          <div className="live-auction-notice-actions">
            <InputGroupMultiple size="small" noMargins className="live-auction-notices-custom">
              <FieldBase type="rawinput" value={input} onInput={e => setInput(e.target.value)} className="form-control" name="custom_notice" autoComplete="off" placeholder="Custom Message" style={{ minWidth: '150px' }} disabled={!connectionOpen} />
              <span className="input-group-btn" style={{ width: 'unset' }}>
                <button className="btn btn-u btn-u-sm btn-u-primary" onClick={() => handleNotification([input], { source: 'custom' })} disabled={!connectionOpen} style={{ lineHeight: '24px' }}><span className="fa fa-fw fa-send" /></button>
              </span>
            </InputGroupMultiple>
            <div className="live-auction-notices-basic">
              <button className="btn btn-u btn-u-primary margin-right-5" onClick={() => handleNotification(['Bid Now!'])} disabled={!connectionOpen || baseData.closed > 0}><span>Bid Now! <span className="fa fa-fw fa-send" /></span></button>
              <button className="btn btn-u btn-u-primary" onClick={() => handleNotification(['Fair Warning!'])} disabled={!connectionOpen || baseData.closed > 0}><span>Fair Warning! <span className="fa fa-fw fa-send" /></span></button>
            </div>
          </div>
          <div className="live-auction-admin-actions-lower">
            {baseData.closed === 0 && <button className="btn btn-u btn-u-darkred" onClick={handleLotAdvance} disabled={!connectionOpen || baseData.bidRequestPending}><span>{baseData.reserve_met ? 'Sell Lot' : 'Pass'}</span></button>}
            {baseData.closed === 1 && <button className="btn btn-u btn-u-darkred" onClick={handleCloseLiveAuction} disabled={!connectionOpen}><span>Close Live Auction</span></button>}
            <button className="btn btn-u btn-u-darkred" onClick={handleConfirmCompetingBid} disabled={!connectionOpen || baseData.bidRequestPending || baseData.closed > 0}><i className="fa fa-fw fa-bolt"></i>{` Non-OWA Bid (${formatMoneyInteger(baseData.nextBidAmount)})`}</button>
          </div>
        </div>
        confirmContent = <Modal
          closeTimeoutMS={150}
          isOpen={showPopup}
          onRequestClose={() => {}}
          style={modalStyles}
          contentLabel="Confirm Non-OWA Bid"
        >
          <ConfirmBox
            title=" Confirm Non-OWA Bid"
            titleIcon="fa fa-bolt"
            style={{ maxWidth: '400px' }}
            confirm_button_text="Confirm Bid"
            confirm_button_action={handlePlaceCompetingBid}
            confirm_button_show={!baseData.bidRequestPending}
            close_button_action={closePopup}
            resultType="none"
          >
            <div className='font-bold' style={{marginBottom: 0, background: 'transparent', fontSize: '13px'}}>
              <h3>Lot {baseData.current_lot_number}</h3>
              <h6 style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{lotData.desc_title}</h6>
              <div className="alert alert-info">
                Non-OWA Bid: {formatMoneyInteger(baseData.nextBidAmount)}
              </div>
            </div>
          </ConfirmBox>
        </Modal>
        break;
      }
      default:
        break;
    }

    const images = Array.from({length: siteinfo.lot_count}, (_, i) => {
      return <SwiperSlide key={i + 1} className={classNames({ 'live-auction-current-lot': (i + 1) === baseData.current_lot_number })}>
          <div className="lot-preview-container">
            <div className="lot-preview-text-container">
              <div className="lot-preview-text-header">Lot {i + 1}</div>
              {/* <div className="lot-preview-text-content">Lorem Ipsum dolor imcat this is a longer piece of text</div> */}
            </div>
            <div className="lot-preview-image-container">
              <Link to={'/catalog/lot/' + siteinfo.current_auction + '/' + String(i + 1)} className="lot-preview-image-link" target="_blank">
                <img data-src={baseImagePath + String(i + 1).padStart(3, '0') + "A.jpg"} alt="" className="swiper-lazy" />
              </Link>
              <div className="swiper-lazy-preloader"></div>
            </div>
          </div>
      </SwiperSlide>
    })

    content = <div className='live-auction-container'>
      <div className='live-auction-description-panel funny-boxes box-shadow shadow-effect-1'>
        <div className="lot-detail-container">
          <div className="lot-detail-header">
            {/* {baseData.featured_text && <h2 className="color-red" style={{fontWeight: 'bold'}}>{lotData.featured_text}</h2>} */}
            <h3 style={{fontWeight: 'bold'}}>Lot {baseData.current_lot_number}: {lotData.desc_title}{lotData.creator && (', ' + lotData.creator)}</h3>
          </div>
          <div className="lot-detail-images">
            <img className="img-responsive" src={__DYNAMIC_IMAGES_RELATIVE_BASE_URL__ + "auctions/" + lotData.auction_id + "/lots/" + lotData.auction_id + "-" + String(lotData.lot_number).padStart(3, "0") + "A.jpg"} alt=""/>
          </div>
          <div className="lot-detail-attributes">
            <h4 style={{fontWeight: 'bold'}}>Subject: {lotData.desc_subject}</h4>
            <h4 style={{fontWeight: 'bold'}}>Period: {lotData.desc_period} {lotData.desc_datetype}</h4>
            <div className="lot-detail-attributes-size">
              <h5 style={{fontWeight: 'bold'}}>Size:</h5>
              {lotData.desc_width ? <h5 style={{fontWeight: 'bold'}}>{lotData.desc_width} x {lotData.desc_height} inches<br />{round(lotData.desc_width * 2.54, 1)} x {round(lotData.desc_height * 2.54, 1)} cm</h5> : <h5 style={{fontWeight: 'bold'}}>See Description</h5>}
            </div>
            <h4 style={{fontWeight: 'bold'}}>Condition: {lotData.desc_condition}</h4>
            <h5><Link to={'/catalog/lot/' + lotData.auction_id + '/' + baseData.current_lot_number} target="_blank" rel="noopener noreferrer">click here for full description</Link></h5>
          </div>
        </div>
      </div>
      <div className='live-auction-info-panel funny-boxes funny-boxes-top-blue box-shadow shadow-effect-1 text-center'>
        {connectionOpen ? <div><span className='fa fa-circle connected' />Connected to Live Auction</div> : <a onClick={() => window.location.reload()}><div className="live-auction-status-disconnected"><span className='fa fa-circle disconnected' />Disconnected from Live Auction - Refresh Page</div></a> }
        {baseData.closed === 0 ? <h4 className="text-bold text-left" style={{ borderBottom: '1px solid #aaa' }}><span>Lot {baseData.current_lot_number} open for bidding</span></h4> : <h4 className="text-bold text-center" style={{ borderBottom: '1px solid #aaa' }}><span>Live bidding has ended</span></h4>}
        <h5>Estimate: {formatMoneyInteger(baseData.price_estimate_low)} - {formatMoneyInteger(baseData.price_estimate_high)}</h5>
        <h4 className="text-bold">{baseData.closed === 1 && baseData.reserve_met ? 'Sold for: ' : 'Current High Bid: '}{formatMoneyInteger(baseData.price_highbid || 0)}</h4>
        <div className="text-left clearfix">{isAuthenticated && <h5 className={bid_status_color} style={{ display: 'inline-block'}}><span className="text-bold">{bid_status_icon}{bid_status_message}</span></h5>}<h5 className="text-bold pull-right">{baseData.reserve_met ? <span className="color-green1">Reserve Met</span> : <span className="color-red">Reserve Not Met</span>}</h5></div>
        {infoPanelUpper}
        <div className='live-auction-info-panel-mid live-auction-messages-container'>
          <div className='live-auction-messages-inner-container' ref={listRef}>
            {messages.map((message, idx) => (
              <div className='live-message-list-item' key={idx}>
                <div className='live-message-base'>
                  <span>{message ? message : null}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        {infoPanelLower}
      </div>
      <div className='live-auction-lot-previews-container'>
        {/* {backToCurrentSlideLinkStatus !== 'hidden' && <div className={"thumbnails-back-to-current thumbnails-back-to-current-" + backToCurrentSlideLinkStatus}>
          <button className="btn btn-u btn-brd btn-u-lightgrey" onClick={null}><span className="fa-fw fa fa-check-circle"/>Back to Current</button>
        </div>} */}
        <Swiper
          style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff' }}
          className="live-auction-standard-swiper"
          onSwiper={setSwiper}
          threshold={4}
          pagination={false}
          loop={false}
          spaceBetween={10}
          slidesPerView="auto"
          watchSlidesVisibility={true}
          watchSlidesProgress={true}
          navigation={true}
          keyboard={{ enabled: true, onlyInViewport: true, pageUpDown: false }}
          preloadImages={false}
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: 5,
          }}
          // scrollbar={{
          //   draggable: false,
          // }}
          onLazyImageReady={(swiper) => {swiper.update()}}
          onSlideChange={(swiper) => {
            // look into fully visible classes once we can use latest version of swiper
            if ((baseData.current_lot_number - 1) < swiper.visibleSlidesIndexes.at(0)) {
              setBackToCurrentSlideLinkStatus('left')
            } else if ((baseData.current_lot_number - 1) > swiper.visibleSlidesIndexes.at(-1)) {
              setBackToCurrentSlideLinkStatus('right')
            } else {
              setBackToCurrentSlideLinkStatus('hidden')
            }
          }}
        >
          {images}
        </Swiper>
      </div>
      {/* <div><pre>{JSON.stringify(siteinfo, null, 2)}</pre></div> */}
      {/* <div id='diagnostics_pane'>
        <pre>siteinfo:<div dangerouslySetInnerHTML={{__html: jsonFormatter.toHtml(siteinfo)}} /></pre>
        <pre>baseData:<div dangerouslySetInnerHTML={{__html: jsonFormatter.toHtml(baseData)}} /></pre>
        <pre>adminData:<div dangerouslySetInnerHTML={{__html: jsonFormatter.toHtml(adminData)}} /></pre>
        <pre>location:<div dangerouslySetInnerHTML={{__html: jsonFormatter.toHtml(location)}} /></pre>
        <pre>params:<div dangerouslySetInnerHTML={{__html: jsonFormatter.toHtml(params)}} /></pre>
      </div> */}
    </div>
  }

  return <>
    <div className='live-auction-base-container'>
      <Helmet>
        <title>Live Auction</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      {lotData.lot_number > 0 && content}
    </div>
    {confirmContent}
    <Modal
      isOpen={baseData.closed === 2}
      style={modalStyles}
      contentLabel="Live Auction Ended"
    >
      <ConfirmBox
        title=" Live Auction Ended"
        titleIcon="fa fa-rss"
        style={{ maxWidth: '400px' }}
        close_button_show={false}
        confirm_button_show={false}
        center_button_show={true}
        center_button_text="Browse Auction Archives"
        center_button_action={_goToAuctionArchives}
        resultType="none"
      >
        <div></div>
      </ConfirmBox>
    </Modal>
  </>
}

function mapStateToProps(state) {
  return {
    siteinfo: state.siteinfo.data,
    baseData: state.liveAuction.base,
    adminData: state.liveAuction.admin,
    lotData: state.lot.content.data,
    messages: state.liveAuction.messages,
    isAuthenticated: state.auth.login.data.isAuthenticated,
    auctionAuthorizationLevel: state.auth.login.data.userInfo.auctionAuthorizationLevel,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      processIncomingMessage,
      getLot,
     }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveAuction)
