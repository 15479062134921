import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'

import { connect } from 'react-redux'

import {SiteView} from '../base/components/siteview-context'
import Menu from '../base/components/Menu/Menu'
import BackToList from './components/BackToList'
import GoToLot from './GoToLot'
import PublicFilterPanel from './PublicFilterPanel'
import Sidebar from '../shared/Sidebar'

import { catalog_sidebar_groups } from 'lib/constants'
import { getLinkCanonical } from 'lib/utility'

Catalog.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  siteinfo: PropTypes.object.isRequired,
  listInfo: PropTypes.object.isRequired,
  listMeta: PropTypes.object.isRequired,
}

function Catalog({ children, location, params, siteinfo, listInfo, listMeta }) {
  const catalog_mode = siteinfo.auction_mode === 5 ? 'archive' : (params.auctionNumber ? (+params.auctionNumber === siteinfo.current_auction ? 'current' : 'archive') : location.pathname.includes('/archive') ? 'archive' : 'current')
  const interface_mode = (catalog_mode === 'current' && siteinfo.auction_mode === 0) ? 'upcoming' : ((catalog_mode === 'current' && siteinfo.auction_mode === 5) ? 'closed' : ((params.auctionNumber && siteinfo.current_auction < +params.auctionNumber) ? 'future' : 'normal'))

  let content

  if (interface_mode === 'upcoming') {
    content =
      <div className="owa-row">
        <Helmet>
          <title>{'Auction ' + params.auctionNumber + ' - Lot ' + params.lotNumber}</title>
        </Helmet>
        <div className="owa-column owa-content-margins" style={{width: '100%', paddingTop: '100px', paddingBottom: '100px', fontSize: '16px', textAlign: 'center'}}>
          Auction {siteinfo.current_auction} will be starting soon
          <br /><br />
          In the meantime, you may <Link to={'/catalog/archive'}>browse the auction archive</Link> to see past auction lots
        </div>
      </div>
  } else if (interface_mode === 'closed') {
    content =
      <div className="owa-row">
        <Helmet>
          <title>Catalog</title>
        </Helmet>
        <div className="owa-column owa-content-margins" style={{paddingTop: '100px', paddingBottom: '100px', fontSize: '16px', textAlign: 'center'}}>
          Auction {siteinfo.current_auction} has concluded
          <br />Look at the <Link to={'/info/auctions/upcoming'}>auction calendar</Link> for upcoming auction information
          <br /><br />
          In the meantime, you may <Link to={'/catalog/archive'}>browse the auction archive</Link> to see past auction lots
        </div>
      </div>
  } else if (interface_mode === 'future') {
    content =
      <div className="owa-row">
        <Helmet>
          <title>Catalog</title>
        </Helmet>
        <div className="owa-column owa-content-margins" style={{paddingTop: '100px', paddingBottom: '100px', fontSize: '16px', textAlign: 'center'}}>
          There is no catalog available yet for Auction {params.auctionNumber}
          <br />Look at the <Link to={'/info/auctions/upcoming'}>auction calendar</Link> for upcoming auction information
          <br /><br />
          In the meantime, you may <Link to={'/catalog/archive'}>browse the auction archive</Link> to see past auction lots
        </div>
      </div>
  } else {
    content = <div className="owa-row">
      <Helmet>
        <title>{'Catalog (' + (catalog_mode === 'current' ? 'Current' : 'Archive') + ')' }</title>
        <link rel="canonical" href={getLinkCanonical(location, ['printview', 'referral'])} />
      </Helmet>
      <SiteView.Consumer>
        {mode => (
          <div className="owa-row">
            {mode.show_sidebar && <Sidebar>
              {params.auctionNumber && <BackToList catalog_mode={catalog_mode} listInfo={listInfo} />}
              { catalog_mode === 'current' ? <GoToLot current_auction={siteinfo.current_auction} /> : null }
              <Menu
                id="standard-menu-catalog"
                hideOnMobile={true}
                data={catalog_sidebar_groups}
                siteinfo={siteinfo}
                location={location}
                params={params}
                context={{catalog_mode, siteinfo}}
                storedQuery={listInfo.location.query}
                storedParams={listInfo.params}
              />
            </Sidebar>}
            <div className="owa-column owa-content-margins">
              <div className="dashboard-element">
                {(!params.auctionNumber || mode.wrap_content) && <PublicFilterPanel catalog_mode={catalog_mode} location={location} listInfo={listInfo} listMeta={listMeta} siteinfo={siteinfo} />}
              </div>
              <div className="dashboard-element">
                <div className="page-title text-center">{catalog_mode === 'current' ? 'Current Auction' : 'Catalog Archive'}</div>
              </div>
              {React.Children.only(React.cloneElement(children, { catalog_mode, siteinfo }))}
            </div>
          </div>
        )}
      </SiteView.Consumer>
    </div>
  }

  return <>{content}</>
}

function mapStateToProps(state) {
  return {
    siteinfo: state.siteinfo.data,
    listInfo: state.lots.content.info,
    listMeta: state.lots.content.meta,
  }
}

export default connect(mapStateToProps)(Catalog)
