import React from 'react'
import PropTypes from 'prop-types'

const validationMap = {
  lowercase: value => /[a-z]/.test(value),
  uppercase: value => /[A-Z]/.test(value),
  special: value => /[!@#$%^&*+_\-~]/.test(value),
  numeric: value => /[0-9]/.test(value),
  length: value => value.length >= 8,
  confirmation: (value, confirmValue) => value === confirmValue,
}

Requirement.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  confirmValue: PropTypes.string,
}


function Requirement({ children, type, value, confirmValue }) {
  const predicate = validationMap[type]
  const isValid = predicate(value, confirmValue)

  return <li className="password-requirement">
    {!value ? <span className="requirement-pre"><i className="fa fa-circle" style={{ marginRight: '5px' }} /></span> : isValid ? <span className="requirement-met"><i className="fa fa-check" style={{ marginRight: '5px' }} /></span> : <span className="requirement-unmet"><i className="fa fa-times-circle" style={{ marginRight: '5px' }} /></span>}
    {children}
  </li>
}

PasswordValidator.propTypes = {
  value: PropTypes.string,
  confirmValue: PropTypes.string,
}

function PasswordValidator({ value='', confirmValue=null }) {
  return <div className="password-requirements-container">
    <ul>
      <div className="password-requirements-header"><i className="fa fa-lock" style={{ marginRight: '5px' }} />Password Requirements:</div>
      <Requirement value={value} type="length">
        8 Characters
      </Requirement>
      <Requirement value={value} type="uppercase">
        1 Uppercase letter
      </Requirement>
      <Requirement value={value} type="lowercase">
        1 Lowercase letter
      </Requirement>
      {/* <Requirement value={value} type="special">
        1 special character
      </Requirement> */}
      <Requirement value={value} type="numeric">
        1 Number
      </Requirement>
      <Requirement value={value} confirmValue={confirmValue} type="confirmation">
        Confirmation matches
      </Requirement>
    </ul>
  </div>
}

export default PasswordValidator