import React from 'react'
import PropTypes from 'prop-types'

import { formatMoneyInteger } from 'lib/utility'

BidTotals.propTypes = {
  mode: PropTypes.string.isRequired,
  order: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
}

BidTotals.defaultProps = {
  order: [],
  data: {},
}

function BidTotals(props) {
  const color_green = '#96FF96'
  const color_orange = '#FFB630'

  let content = null

  if (props.mode === 'consignor') {
    let low_estimate = 0
    let reserve = 0
    let bids_above_reserve = 0
    let bid_value = 0

    props.order.map(function(item) {
      const lot = props.data[item]
      low_estimate += lot.price_estimate_low
      reserve += lot.price_reserve
      bids_above_reserve += lot.reserve_met ? lot.price_highbid : 0
      bid_value += lot.price_highbid
    })
    content = <React.Fragment>
      <span style={{marginLeft: '20px'}}>Low Estimate: <span style={{color: color_orange}}>{formatMoneyInteger(low_estimate)}</span></span>
      <span style={{marginLeft: '20px'}}>Reserve: <span style={{color: color_orange}}>{formatMoneyInteger(reserve)}</span></span>
      <span style={{marginLeft: '20px'}}>Bids Above Reserve: <span style={{color: color_green}}>{formatMoneyInteger(bids_above_reserve)}</span></span>
      <span style={{marginLeft: '20px'}}>All Bids: <span style={{color: color_orange}}>{formatMoneyInteger(bid_value)}</span></span>
    </React.Fragment>
  } else {
    let high_bids_above_reserve = 0
    let high_bids_below_reserve = 0

    props.order.map(function(item) {
      const lot = props.data[item]
      high_bids_above_reserve += lot.is_high_bidder && lot.reserve_met ? lot.price_highbid : 0
      high_bids_below_reserve += lot.is_high_bidder && !lot.reserve_met ? lot.price_highbid : 0
    })
    content = <React.Fragment>
      <span style={{marginLeft: '20px'}}>Total High Bids Above Reserve: <span style={{color: color_green}}>{formatMoneyInteger(high_bids_above_reserve)}</span></span>
      <span style={{marginLeft: '20px'}}>Total High Bids Below Reserve: <span style={{color: color_orange}}>{formatMoneyInteger(high_bids_below_reserve)}</span></span>
    </React.Fragment>
  }

  return <div className="base-footer margin-bottom-20"><i className="fa fa-usd fa-fw"></i> Totals: <span className="pull-right">{content}</span></div>
}

export default BidTotals
