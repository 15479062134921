import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

export default class NotAuthorized extends Component {

  render() {
    return (
      <div>
        <Helmet>
          <title>Not Authorized</title>
        </Helmet>
        <div style={{padding: '20px'}}>
          <div className="tag-box tag-box-v3 margin-bottom-40">
            <h2>Not Authorized</h2>
            <p>You are not authorized to view this content</p>
          </div>
        </div>
      </div>
    )
  }
}
