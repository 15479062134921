import React, { Component } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getData, acceptUnderReserve, reduceReservePrice, inputChange, resetStatus } from 'rdx/modules/consignorStatus'

import StatusConsignorContent from './StatusConsignorContent'

class StatusConsignor extends Component {
  static readyOnActions(dispatch, params, location) {
    return Promise.all([
      dispatch(getData(location)),
    ])
  }

  componentDidMount() {
    // console.log('StatusUser-CDM')
    this.constructor.readyOnActions(this.props.dispatch, this.props.params, this.props.location)
  }

  render() {
    return <StatusConsignorContent {...this.props} />
  }
}

function mapStateToProps(state) {
  return {
    siteinfo: state.siteinfo.data,
    consignorStatus: state.consignorStatus,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getData, acceptUnderReserve, reduceReservePrice, inputChange, resetStatus }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusConsignor)