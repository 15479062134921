import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { Nav, NavItem } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import update from 'immutability-helper'

import { getData, reorderSlideGroups, saveSlideGroups, getSlideGroup, closeSlideGroup, selectTab, fieldUpdateSlideGroup, saveSlideGroup, removeSlideGroup, newSlide, getSlide, saveSlide, removeSlide, publishSlides, toggleActive, resetSlideStatus, fieldUpdateSlide, reorderSlides } from 'rdx/modules/admin/slidegroups'
import SlideGroup from './SlideGroup'

import ContentPanel from '../shared/ContentPanel'
import MovableItem from '../shared/MovableItem'
import Tab from '../shared/tab'
import StatusBox from '../shared/StatusBox'

import useConfirm from "../shared/hooks/useConfirm"

SlideGroups.propTypes = {
  slidegroups: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  saveSlideGroups: PropTypes.func.isRequired,
  getSlideGroup: PropTypes.func.isRequired,
  saveSlideGroup: PropTypes.func.isRequired,
  removeSlideGroup: PropTypes.func.isRequired,
  closeSlideGroup: PropTypes.func.isRequired,
  newSlide: PropTypes.func.isRequired,
  getSlide: PropTypes.func.isRequired,
  saveSlide: PropTypes.func.isRequired,
  removeSlide: PropTypes.func.isRequired,
  publishSlides: PropTypes.func.isRequired,
  reorderSlideGroups: PropTypes.func.isRequired,
  toggleActive: PropTypes.func.isRequired,
  resetSlideStatus: PropTypes.func.isRequired,
  selectTab: PropTypes.func.isRequired,
  fieldUpdateSlideGroup: PropTypes.func.isRequired,
  fieldUpdateSlide: PropTypes.func.isRequired,
}

function SlideGroups({ slidegroups, getData, saveSlideGroups, reorderSlideGroups, getSlideGroup, saveSlideGroup, removeSlideGroup, closeSlideGroup, newSlide, getSlide, saveSlide, removeSlide, publishSlides, toggleActive, resetSlideStatus, selectTab, fieldUpdateSlideGroup, fieldUpdateSlide, reorderSlides }) {
  const { isConfirmed } = useConfirm()
  useEffect(() => {
    getData()
  }, [getData])

  const _handleSelect = (key) => {
    selectTab(key)
  }

  const _handleEditSlideGroup = (e, item) => {
    console.log('item:', item)
    if (slidegroups.slidegroup[item.id]) {
      selectTab(item.id)
    } else {
      getSlideGroup(item.id, { name: item.name })
    }
  }

  const _handleRemoveSlideGroup = async (e, item) => {
    console.log('_handleRemoveSlideGroup:', item)
    const confirmed = await isConfirmed("Confirm removal of slide group " + item.name)
    if (confirmed) {
      removeSlideGroup(item.id)
    } else {
      // nothing
    }
  }

  const _toggleActive = async (e, item) => {
    console.log('_toggleActive-slidegroup:', item)
    toggleActive(item.id, { mode: 'slidegroup' })
  }

  const _onCloseClick = (e, id) => {
    e.stopPropagation()
    e.preventDefault()
    closeSlideGroup(id)
  }

  const moveSlideGroup = useCallback((dragIndex, hoverIndex) => {
      const dragCard = slidegroups.list.data[dragIndex]
      const newlist = update(slidegroups.list.data, {
          $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
          ],
      })
      reorderSlideGroups(newlist)
  }, [reorderSlideGroups, slidegroups.list.data])

  const renderSlideGroupCard = (card, index) => {
    return <MovableItem key={card.id} index={index} id={card.id} moveItem={moveSlideGroup} containerClass={card.active === 0 ? "item-disabled" : ""} >
      <div className="flex-standard-container">
        <div className="flex-group flex-group-left" style={{ minWidth: 0 }}>
          <span className={"fa fa-fw fa-lg " + (card.active === 1 ? "fa-toggle-on text-primary" : "fa-toggle-off")} style={{ cursor: 'pointer', marginRight: '10px' }} onClick={(e) => _toggleActive(e, card)} />
          <span className="text-bold" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{card.name}</span><span style={{ whiteSpace: 'nowrap' }}> ({card.slidecount} slides)</span>
        </div>
        <div className="flex-group flex-group-right">
          <span className="flex-bean bean-small bean-grey text-bold" style={{ whiteSpace: 'nowrap', marginRight: '20px' }}>{card.type === 0 ? 'Global' : 'Auction ' + card.auction_id}</span>
          {!slidegroups.unsaved_changes && <span className="fa fa-edit fa-border fa-hover text-info" style={{cursor: 'pointer', marginRight: '15px' }} onClick={(e) => _handleEditSlideGroup(e, card)} />}
          {!slidegroups.unsaved_changes && <span className="fa fa-close fa-border fa-hover text-danger" style={{cursor: 'pointer'}} onClick={(e) => _handleRemoveSlideGroup(e, card)} />}
        </div>
      </div>
    </MovableItem>
  }

  const nav = <Nav bsStyle='tabs' activeKey={slidegroups.selectedTab} onSelect={_handleSelect}>
    <NavItem eventKey="list" tabIndex="-1">{ 'Slide Groups' }</NavItem>
    {
      slidegroups.slidegroup_tab_order.map((o) => {
        if (o) {
          const name = slidegroups.slidegroup[o].originalData.name
          // let tabindicator = null
          // switch (slidegroups.slidegroup[o].actions.update.status) {
          //   case 1:
          //     tabindicator = <span className='fa fa-circle ok' />
          //     break
          //   case 2:
          //     tabindicator = <span className='fa fa-circle error' />
          //     break
          //   default:
          // }
          return <NavItem key={o} eventKey={o} tabIndex="-1">
            <div className="flex-container-base" style={{ maxWidth: '200px' }}>
              {/* {tabindicator && <div style={{ flex: '0 0 17px', minWidth: 0 }}>
                <span className='fa fa-circle ok' />
              </div>} */}
              <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{ name ? name : o }</span>
              <div style={{ flex: '1 0 14px', minWidth: 0 }}>
                <span className='fa fa-close' onClick={(e) => _onCloseClick(e, o)} />
              </div>
            </div>
          </NavItem>
        }
      })
    }
    <NavItem eventKey="new" tabIndex="-1"><span className={'fa fa-plus' + (slidegroups.slidegroup.new.meta.status === 2 ? ' error' : '')} />{' Add Slide Group'}</NavItem>
    {/* <NavItem eventKey="preview" tabIndex="-1">{ 'Preview Slides' }</NavItem> */}
  </Nav>

const style = {
  width: 400,
  border: '1px solid black',
  padding: '4px 4px 0 4px',
}

  const content_list = <div style={{ marginTop: '20px' }}>
    <div className="flex-container-base">
      <div style={{ flex: '1 0 48%', minWidth: 0 }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '5px' }}>
          <button type="button" className="btn btn-u btn-u-sea" onClick={ saveSlideGroups }><span className="fa fa-fw fa-arrow-up" /> Save Group Order/Attributes</button>
          {slidegroups.unsaved_changes && <div className="alert-warning" style={{ border: '1px dashed #aaa', padding: '2px', borderRadius: '2px' }}>
            Unsaved changes
          </div>}
        </div>
        <DndProvider backend={HTML5Backend}>
          <div style={{ border: '1px solid black', padding: '4px 4px 0 4px' }}>{slidegroups.list.data.map((card, i) => renderSlideGroupCard(card, i))}</div>
        </DndProvider>
      </div>
      <div style={{ flex: '1 0 4%', minWidth: 0 }}></div>
      <div style={{ flex: '1 0 48%', minWidth: 0 }}>
        {!slidegroups.unsaved_changes && <button type="button" className="btn btn-u btn-u-orange pull-right" onClick={ publishSlides }><span className="fa fa-fw fa-arrow-up" /> Publish slides to site</button>}
        {/* <div style={{ border: '1px solid #aaa', padding: '10px' }}>TODO: show cached content vs unpublished content</div> */}
      </div>
    </div>
    <StatusBox data={slidegroups.actions.saveSlideGroups} mainClassName="margin-top-10" />
    <StatusBox data={slidegroups.actions.getSlideGroup} mainClassName="margin-top-10" hideOnSuccess={true} />
    <StatusBox data={slidegroups.actions.removeSlideGroup} mainClassName="margin-top-10" />
    <StatusBox data={slidegroups.actions.publishSlides} mainClassName="margin-top-10" />
  </div>

  const content_preview = <div>
    <div style={{ marginBottom: '20px' }}>
      Live Slides (currently in cache)
      <div style={{ border: '1px solid #aaa', width: '400px', padding: '10px' }}>
        X + X + X
      </div>
    </div>
    <div style={{ marginBottom: '20px' }}>
      Updated Slides (based on defined settings)
      <div style={{ border: '1px solid #aaa', width: '400px', padding: '10px' }}>
        X + X + X
      </div>
    </div>
  </div>

  return <div>
    <Helmet>
      <title>Slide Management</title>
    </Helmet>
    <ContentPanel
      icon="fa fa-picture-o fa-fw"
      title={'Slide Management'}
    >
      <div className="page-base page-admin">
        <div className='tab-v1'>
          <div className='tab-container'>
            {nav}
            <div>
              <form action="#" className="basic-form">
                <Tab isActive={slidegroups.selectedTab === "list"}>
                  {content_list}
                </Tab>
                {
                  slidegroups.slidegroup_tab_order.map((o) => {
                      return <Tab key={o} isActive={slidegroups.selectedTab === o}>
                        <SlideGroup
                          slidegroup={slidegroups.slidegroup[o]}
                          isNew={false}
                          fieldUpdateSlideGroup={fieldUpdateSlideGroup}
                          saveSlideGroup={saveSlideGroup}
                          newSlide={newSlide}
                          getSlide={getSlide}
                          saveSlide={saveSlide}
                          removeSlide={removeSlide}
                          toggleActive={toggleActive}
                          resetSlideStatus={resetSlideStatus}
                          fieldUpdateSlide={fieldUpdateSlide}
                          reorderSlides={reorderSlides}
                        />
                      </Tab>
                  })
                }
                <Tab isActive={slidegroups.selectedTab === "new"}>
                  <SlideGroup
                    slidegroup={slidegroups.slidegroup.new}
                    isNew={true}
                    fieldUpdateSlideGroup={fieldUpdateSlideGroup}
                    saveSlideGroup={saveSlideGroup}
                    newSlide={newSlide}
                    getSlide={getSlide}
                    saveSlide={saveSlide}
                    removeSlide={removeSlide}
                    toggleActive={toggleActive}
                    resetSlideStatus={resetSlideStatus}
                    fieldUpdateSlide={fieldUpdateSlide}
                    reorderSlides={reorderSlides}
                  />
                </Tab>
                <Tab isActive={slidegroups.selectedTab === "preview"}>
                  {content_preview}
                </Tab>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ContentPanel>
  </div>
}

function mapStateToProps(state) {
  return {
    slidegroups: state.admin.slidegroups,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      getData,
      saveSlideGroups,
      reorderSlideGroups,
      getSlideGroup,
      closeSlideGroup,
      selectTab,
      fieldUpdateSlideGroup,
      saveSlideGroup,
      removeSlideGroup,
      newSlide,
      getSlide,
      saveSlide,
      removeSlide,
      publishSlides,
      toggleActive,
      resetSlideStatus,
      fieldUpdateSlide,
      reorderSlides,
    }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideGroups)

