import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getAuctionIndex } from 'rdx/modules/info/sitemap'

import DataList from '../../shared/DataList'
import DataListRow from '../../shared/DataListRow'
import StatusBox from '../../shared/StatusBox'

import { auctionIndex_specs } from 'lib/list_specifications'

import connectDataList from '../../shared/composers/connectDataList'

const DataListBound = connectDataList(DataList, state => ({ statebranch: state.info.sitemap.content.auctions }))

AuctionIndex.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  auctions: PropTypes.object.isRequired,
  getAuctionIndex: PropTypes.func.isRequired,
}

AuctionIndex.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getAuctionIndex()),
  ])
}

function AuctionIndex({ location, params, auctions, getAuctionIndex }) {
  useEffect(() => {
    getAuctionIndex()
  }, [getAuctionIndex])

  let content = null

  const title = 'Index of Past Auctions'

  if (auctions.meta.status > 1) {
    content = <StatusBox data={auctions.meta} />
  } else {
    content = <DataListBound
      useTable={true}
      showHeader={true}
      showTopPagingControl={false}
      showBottomPagingControl={false}
      metadata={auctionIndex_specs}
      datakey='id'
      anchorTemplate='/sitemap/auction/<param1>'
      anchorParam1='id'
      params={params}
      location={location}
    >
      <DataListRow />
    </DataListBound>
  }


  return <div>
    <Helmet>
      <title>{title}</title>
      {/* <link rel="canonical" href={location.pathname} /> */}
    </Helmet>
    <div className="titlebar heading heading-v1 text-center">
      <h2><i className="fa fa-calendar fa-fw"></i> {title}</h2>
    </div>
    <div className="text-center" style={{ marginBottom: '20px', borderTop: '0px solid #ccc' }}>
      <h4 className="">This index is a static list of all past auctions with links to each auction&apos;s full catalog</h4>
      <h4 className="">For a more interactive experience, <Link to="/info/auctions/past">click here to view past auctions with links to the searchable catalog</Link></h4>
    </div>
    {content}
  </div>
}

function mapStateToProps(state) {
  return {
    auctions: state.info.sitemap.content.auctions,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getAuctionIndex }, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionIndex)