import React from 'react'
import PropTypes from 'prop-types'

import FormRow from './FormRow'

FormContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  rowClassName: PropTypes.string,
  rowStyle: PropTypes.object,
  //other props get passed through to any FormRow children
}

function FormContainer({ className, style, rowClassName, rowStyle, children, ...other }) {
  const childrenWithProps = React.Children.map(children,
    (child) => {
      if(!child || child.type !== FormRow) {
        return child
      } else {
        return React.cloneElement(child, { className: rowClassName, ...(rowStyle && {style: rowStyle}), ...other })
      }
    }
  )
  return <div className={className} style={style} >
    {childrenWithProps}
  </div>
}

export default FormContainer