import { round } from 'lib/utility'
import { invoice_options } from './constants'

// Invoice Utility Functions

export function calcInvoiceInsuranceCost(amountToBeInsured) {
  return round(Math.ceil(amountToBeInsured / 100) * 0.8, 2)
}

export function calcInvoiceSignatureCost(carrier) {
  const carrier_props = (carrier !== '0' && carrier !== null) ? invoice_options.options_carrier_us.find(x => x.value === carrier) : null
  const carrier_base = carrier_props ? carrier_props.base : null
  switch (carrier_base) {
    case 'usps':
      return 3.50
    case 'ups':
      return 5.90
    case 'fedex':
      return 6.75
    default:
      return 0
  }
}

export function calcInvoiceCosts({ primaryData, auxData, options, carrier='', foreign, state='', mode='user' }) {
  const digital_images_cost_total = options.inv_options_digital_images === 1 ? primaryData.digital_images_available * 5 : 0

  const taxable_amount = (!state || (state && state.toUpperCase() !== 'VA')) ? 0 : Math.max(0, +auxData.amount_misc + primaryData.bids_total + primaryData.buyer_premium + digital_images_cost_total - auxData.amount_deposit)
  const tax = (mode === 'admin' && auxData.amount_tax_override !== 1) ? round(taxable_amount * 0.06, 2) : auxData.amount_tax

  // baseline amount from which to calculate insurance
  const baseline_total = primaryData.bids_total + primaryData.buyer_premium - +auxData.amount_deposit

  // leaving in case we wan't to enable custom insurance amount
  // const options_insurance_cost = options.inv_options_insurance === 0 ? 0 : (options.inv_options_insurance === 1 ? calcInvoiceInsuranceCost(baseline_total) : calcInvoiceInsuranceCost(options.inv_options_insurance_custom))

  const options_insurance_cost = options.inv_options_insurance === 0 ? 0 : calcInvoiceInsuranceCost(baseline_total)
  const options_signature_cost = options.inv_options_delivery_signature === 0 || foreign ? 0 : calcInvoiceSignatureCost(carrier)

  return {
    digital_images_cost_total,
    options_insurance_cost,
    options_signature_cost,
    baseline_total,
    tax,
    total_due: baseline_total + +auxData.amount_shipping + +auxData.amount_misc + +tax + options_insurance_cost + options_signature_cost + digital_images_cost_total,
  }
}
