import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { browserHistory } from 'react-router'

import FilterBuilder from '../shared/filter-builder'
import FilterTagsContainer from '../shared/filter-tags-container'
import { forceInteger } from 'lib/utility'

FilterPanel.propTypes = {
  location: PropTypes.object.isRequired,
  filterbuilderitems: PropTypes.array.isRequired,
  lookupData: PropTypes.object.isRequired,
}

function FilterPanel({ location, filterbuilderitems, lookupData }) {
  const _handleAddFilter = (state) => {
    const { page, zops={}, ...other } = location.query
    const o = qs.parse(zops)

    const newFilter = {
      f: state.filterItem.displayname,
      o: state.operator,
      v: state.filterValue,
    }

    const newFilters = [ ...(o.f || []), newFilter ]
    browserHistory.push({ ...location, query: { ...other, zops:  qs.stringify({ ...o, f: newFilters })} })
  }

  const _handleRemoveFilter = (toBeRemoved) => {
    const { page, zops={}, ...other } = location.query
    const o = qs.parse(zops)

    let remainingFilters = []
    if (typeof toBeRemoved !== 'undefined') {
      const removeIndex = forceInteger(toBeRemoved)
      remainingFilters = o.f.filter((item, i) => i !== removeIndex)
    }
    browserHistory.push({ ...location, query: { ...other, ...(remainingFilters.length && { zops:  qs.stringify({ ...o, f: remainingFilters }) }) } })
  }

  return <div>
    <div id="owa-filter-box" className="panel-collapse collapse in">
      <FilterBuilder filterItems={filterbuilderitems} lookupData={lookupData} onAddFilter={_handleAddFilter} />
    </div>
    <FilterTagsContainer location={location} onRemoveFilter={_handleRemoveFilter} />
  </div>
}

export default React.memo(FilterPanel)