import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'

import FusedFields from '../../shared/fused-fields'
import ClickElement from '../../shared/ClickWrapper'

import { article_categories } from 'lib/constants'
import { buildPath, hydrateQueryFields } from 'lib/utility'

const searchFields_initialstate = {
  universal_search: '',
}

ArticleFilterPanel.propTypes = {
  location: PropTypes.object.isRequired,
  listInfo: PropTypes.object.isRequired,
}

ArticleFilterPanel.defaultProps = {
  catalog_mode: 'current',
}

function ArticleFilterPanel({ location, listInfo }) {
  const category = listInfo.params && listInfo.params.category ? listInfo.params.category : null
  const { page, ...baseQuery } = listInfo.location && listInfo.location.query ? listInfo.location.query : {}
  const [searchFields, setSearchFields] = useState({ ...searchFields_initialstate, ...hydrateQueryFields(location.query, searchFields_initialstate) })

  const _handleUpdate = (field, value) => {
    setSearchFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _handleFilter = ({ source=null }) => {
    let resolvedCategory = listInfo.params.category
    let resolvedQuery = baseQuery
    let resolvedState = null

    switch (source) {
      case 'universal-search':
        setSearchFields(prev => ({
          ...searchFields_initialstate,
          universal_search: prev['universal_search']
        }))
        resolvedQuery = { ...(searchFields['universal_search'] && { universal_search: searchFields['universal_search'] }) }
        break
      case 'search-clear':
        setSearchFields(searchFields_initialstate)
        resolvedQuery = { }
        break
      case 'category-clear':
        resolvedCategory = null
        break
      case 'refresh':
        resolvedState = { timestamp: Date.now(), forceRefresh: true }
        break
      default:
        break
    }

    const resolvedLocation = {
      ...listInfo.location,
      pathname: buildPath('/info/articles', resolvedCategory),
      ...(resolvedQuery && { query: resolvedQuery }),
      ...(resolvedState && { state: resolvedState }),
    }
    browserHistory.push({ ...resolvedLocation })
  }

  const universal_search_active = baseQuery.universal_search && baseQuery.universal_search !== ''

  const searchButtonBasic = <ClickElement type="button" className="btn btn-u btn-u-darkred btn-u-contrast-border" style={{marginLeft: '6px', borderRadius: 0, fontWeight: 'bold'}} handler={_handleFilter} options={{ source: 'universal-search' }}>Search</ClickElement>

  let filterbox = null

  const basicContent = (
    <div className="input-group">
      <span className="input-group-addon">
        <span className="fa fa-search"></span>
      </span>
      <FusedFields data={searchFields} errors={null} onUpdate={_handleUpdate} onLeave={_handleUpdate}>
        <input type="text" name="universal_search" className="form-control" />
      </FusedFields>
      <span className="input-group-btn">
        {searchButtonBasic}
      </span>
    </div>
  )

  const searchHeader =  basicContent
  const categoryBox = (category ? <div style={{borderBottom: "1px solid #CCC"}}><h5 className="no-margin"><strong>Filtered by Category:</strong><span className="color-green1 font-bold margin-left-10 margin-right-10">{article_categories[category].displayname}</span><ClickElement type="a" className="color-darkred" handler={_handleFilter} options={{ source: 'category-clear' }}>(Show All Categories)</ClickElement></h5></div> : null)

  const universalStatusMessage = (universal_search_active ? <div><h5 className="no-margin"><strong>Showing results for:</strong><span className="color-green1 font-bold margin-left-10 margin-right-10">{baseQuery.universal_search}</span><ClickElement type="button" className="btn btn-link no-margin no-padding color-darkred" style={{verticalAlign: "baseline"}} handler={_handleFilter} options={{ source: 'search-clear' }}>(Clear Search)</ClickElement></h5></div> : null)

  filterbox = categoryBox != null || universalStatusMessage != null ?
    <div className="detailed-search-content">
      {categoryBox}
      {universalStatusMessage}
    </div>
    : null

  const content = <div>
    <div className="row">
      <div className="col-lg-12 search-header">
        {searchHeader}
      </div>
    </div>
    {filterbox}
  </div>

  return <div className="margin-bottom-5 dark-grey-background" style={{ borderRadius: '5px', border: '1px solid #ddd', padding: '5px' }}>
    {content}
  </div>
}

export default ArticleFilterPanel