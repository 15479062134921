import { get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

const base = 'owa/siteinfo/'

const GET_SITE_INFO = defineAction(base, 'GET_SITE_INFO', 'async')

const initialState = {
  data: {
    timestamp: 0,
    localtime: 0,
  },
  meta: status_defaults,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SITE_INFO.request:
      return {
        ...state,
        meta: {
          ...status_defaults,
          processing: true,
          timestamp: state.meta.timestamp,
        }
      }
    case GET_SITE_INFO.success:
      return {
        ...state,
        data: {
          ...action.payload,
          localtime: action.localtime,
        },
        meta: {
          ...status_defaults,
          ...action.meta,
        }
      }
    case GET_SITE_INFO.fail:
      return {
        ...state,
        meta: {
          ...status_defaults,
          ...action.meta,
        }
      }
    default:
      return state
  }
}

export function getSiteInfo() {
  return (dispatch) => {
    return dispatch(
      get({
        types: GET_SITE_INFO,
        endpoint: '/api/public/info/siteinfo',
      })
    )
  }
}
