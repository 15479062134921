import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

NotificationBox.propTypes = {
  children: PropTypes.any.isRequired,
  size: PropTypes.string,
  mode: PropTypes.string,
  containerClass: PropTypes.string,
  containerStyle: PropTypes.object,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  iconRaw: PropTypes.string,
}

NotificationBox.defaultProps = {
  size: 'large',
  mode: 'info',
  containerClass: 'margin-bottom-20',
  containerStyle: {},
}

function NotificationBox(props) {
  const { children, size, mode, containerClass, containerStyle, icon, iconSize, iconRaw } = props

  const modeClass = 'alert-' + mode
  const iconBoxModeClass = 'icon-box-' + modeClass
  const finalContainerClass = classNames('notification-container', containerClass)
  const finalNotificationIconBoxClass = classNames('alert', modeClass, 'fade', 'in', 'notification-component', iconBoxModeClass)
  const finalNotificationMessageClass = classNames('alert', modeClass, 'fade', 'in', 'notification-component', 'notification-message')

  let componentPaddingVertical = '15px'
  let componentPaddingHorizontal = '15px'
  let fa_icon = icon && icon
  let fa_iconSize = iconSize || 'fa-3x'

  switch (size) {
    case 'small':
      componentPaddingVertical = '5px'
      componentPaddingHorizontal = '5px'
      fa_iconSize = iconSize || 'fa-2x'
      break
    case 'micro':
      componentPaddingVertical = '2px'
      componentPaddingHorizontal = '5px'
      fa_iconSize = iconSize || 'fa-2x'
      break
  }

  let fa_iconClass = ''
  if (!iconRaw) {
    if (!fa_icon) {
      switch (mode) {
        case 'success':
          fa_icon = 'fa-check-circle'
          break;
        case 'warning':
          fa_icon = 'fa-exclamation-triangle'
          break;
        case 'danger':
          fa_icon = 'fa-times-circle'
          break;
        default:
          fa_icon = 'fa-info-circle'
      }
    }
    fa_iconClass = classNames('fa', 'fa-fw', fa_icon, fa_iconSize)
  } else {
    fa_iconClass = iconRaw
  }

  const baseComponentStyle = { padding: componentPaddingVertical + ' ' + componentPaddingHorizontal }

  return (
    <div className={finalContainerClass}>
      <div className={finalNotificationIconBoxClass} style={{...baseComponentStyle, ...containerStyle}}>
        <div>
          <i className={fa_iconClass} />
        </div>
      </div>
      <div className={finalNotificationMessageClass} style={{...baseComponentStyle, ...containerStyle}}>
        <div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default NotificationBox