import React, { useState } from 'react'
import Modal from 'react-modal'
import { Link } from 'react-router'

import DataListContainer from '../shared/DataListContainer'
import DataListBody from '../shared/DataListBody'

import BidTotals from './components/BidTotals'
import ConfirmBox from '../shared/ConfirmBox'
import LotCard from '../shared/LotCard'
import ContentPanel from '../shared/ContentPanel'
import useRefreshTimer from '../shared/hooks/useRefreshTimer'
import useMedia from '../shared/hooks/useMedia'

import { formatMoneyInteger } from 'lib/utility'
import { auctionStatusRefreshInterval, bid_extension_time, query_mobile_view, modalStyles, user_consignorstatus_table_definitions } from 'lib/constants'

function StatusConsignorContent(props) {
  const [showPopup, setShowPopup] = useState(false)
  const [selectedLot, setSelectedLot] = useState({ data: {}, action: null })
  const mobileView = useMedia(query_mobile_view)

  const refreshData = () => {
    props.getData(props.location)
  }

  const { elapsedSeconds, secondsTillRefresh } = useRefreshTimer(refreshData, props.consignorStatus.meta, { deps: [props.location.query], refreshInterval: auctionStatusRefreshInterval })

  const closePopup = () => {
    setShowPopup(false)
    props.resetStatus()
  }

  const _promptAcceptUnderReserve = (lot) => {
    setSelectedLot({ data: lot, action: 'accept' })
    setShowPopup(true)
  }

  const _confirmAcceptUnderReserve = () => {
    props.acceptUnderReserve(selectedLot.data.id)
  }

  const _promptReduceReserve = (lot) => {
    setSelectedLot({ data: lot, action: 'reduceReserve' })
    setShowPopup(true)
  }

  const _confirmReduceReserve = () => {
    props.reduceReservePrice(selectedLot.data.id)
  }

  const { siteinfo, consignorStatus, location } = props
  const view = mobileView ? 'mobile' : 'standard'

  if (consignorStatus.lots_order === []) {
    return <div>Loading...</div>
  }

  const auction_mode = siteinfo.auction_mode

  const refresh_text = secondsTillRefresh > 0 ? "(refreshing in " + secondsTillRefresh + " seconds)" : consignorStatus.meta.status <= 1 ? "(attempting to refresh...)" : null
  const problem_lots = (elapsedSeconds > (auctionStatusRefreshInterval + 5) && consignorStatus.meta.status !== 1) || consignorStatus.meta.status > 1 ? <span style={{color: 'orange', paddingLeft: '5px'}}><i className="fa fa-fw fa-exclamation-circle"></i>There is a problem.  Try reloading the page ({elapsedSeconds} seconds since last refresh)</span> : null

  //used for testing when polled refresh is disabled
  //const button_refresh = <button className="btn btn-warning btn-xs" onClick={() => refreshData()}><i className="fa fa-bolt"></i> Refresh</button>

  const bid_legend = <div className="bidstatus-legend">
    <div className="bidstatus-legend-item"><span className="highbidder"><i className="fa fa-check"></i> Reserve Met/Sold</span></div>
    <div className="bidstatus-legend-item"><span className="reservenotmet"><i className="fa fa-exclamation-circle"></i> Under Reserve</span></div>
    <div className="bidstatus-legend-item"><span className="nothighbidder"><i className="fa fa-minus-circle"></i> No Bids</span></div>
    <div className="bidstatus-legend-item"><span className="noreserve"><span className="label rounded label-primary" style={{padding: '2px 5px'}}>NR</span> No Reserve</span></div>
  </div>

  const confirmBox_AcceptUnderReserve = <ConfirmBox
    title={'Confirm Accept'}
    style={{ maxWidth: '600px' }}
    confirm_button_text="Accept"
    close_button_text="Cancel"
    confirm_button_action={_confirmAcceptUnderReserve}
    close_button_action={closePopup}
    data={consignorStatus.actions.acceptUnderReserve}
  >
    <div style={{ padding: '5px' }}>
      <span>{'Allow lot ' + selectedLot.data.lot_number + ' to be sold for the high bid of ' + formatMoneyInteger(selectedLot.data.price_highbid)}</span>
      <br />
      <span>{'(subject to OWA review)'}</span>
    </div>
  </ConfirmBox>

  const confirmBox_ReduceReserve = <ConfirmBox
    title={'Confirm Lower Reserve Price'}
    style={{ maxWidth: '600px' }}
    confirm_button_text="Confirm Change"
    close_button_text="Cancel"
    confirm_button_action={_confirmReduceReserve}
    close_button_action={closePopup}
    data={consignorStatus.actions.reduceReserve}
  >
    <div style={{ padding: '5px' }}>
      <span>{'Lower reserve price for lot ' + selectedLot.data.lot_number + ' to ' + formatMoneyInteger(selectedLot.data.potential_reduced_reserve_price)}</span>
      <br />
      <span>{'(subject to OWA review)'}</span>
    </div>
  </ConfirmBox>

  const consignments = <ContentPanel
    title={'Consignor Status'}
    titleSuffix={refresh_text}
    titleAuxElement={problem_lots}
    icon="fa fa-star fa-fw"
    auxClassContent="zero-padding no-mobile-background"
    footerElement={<BidTotals mode="consignor" order={consignorStatus.lots_order} data={consignorStatus.lots} />}
  >
    <div className="base-section">
      {!mobileView && bid_legend}
      <DataListContainer
        key={location.query.sort_direction_db} // ZZZ - hack to force all css animation highlights on every sort change
        useTable={!mobileView}
        tableClass='table table-striped datalist-table-status table-text-vertical-center'
        showHeader={true}
        headerColumnVisibleKey={auction_mode}
        showTopPagingControl={false}
        showBottomPagingControl={false}
        metadata={user_consignorstatus_table_definitions}
        totalItemCount={consignorStatus.lots_order.length}
        pageSize={0}
        location={location}
      >
        <DataListBody
          dataIndex={consignorStatus.lots_order}
          data={consignorStatus.lots}
          datakey='lot_number'
          useTable={!mobileView}
        >
          {<LotCard nature={'consignor_status'} view={view} auction_mode={auction_mode} elapsedSeconds={elapsedSeconds} bid_extension_time={bid_extension_time} inputChange={props.inputChange} promptAcceptUnderReserve={_promptAcceptUnderReserve} promptReduceReserve={_promptReduceReserve} />}
        </DataListBody>
      </DataListContainer>
    </div>
  </ContentPanel>

  const container = <div className="owa-content">
    {(auction_mode === 0 && <div style={{paddingTop: '100px', paddingBottom: '100px', fontSize: '16px', textAlign: 'center'}}>
          Auction {siteinfo.current_auction} will be starting soon
          <br />
          To review past consignments, view your <Link to={'/user/consignment-history'}>Consignment History</Link> page
          <br /><br />
          In the meantime, you may <Link to={'/catalog/archive'}>browse the auction archive</Link> to see past auction lots
        </div>)}
    {(auction_mode > 0) && consignments}
    <Modal
      closeTimeoutMS={150}
      isOpen={showPopup}
      onRequestClose={() => {}}
      style={modalStyles}
      contentLabel="Confirm Window" >
      {selectedLot.action === 'reduceReserve' ? confirmBox_ReduceReserve : confirmBox_AcceptUnderReserve}
    </Modal>
  </div>

  return <div>
    {container}
  </div>
}

export default StatusConsignorContent