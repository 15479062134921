import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ConfirmBox from '../shared/ConfirmBox'
import FieldCheckbox from './FieldCheckbox'
import FormRow from './FormRow'
import FusedFields from './fused-fields'

// import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff'

import { formatMoneyInteger } from 'lib/utility'
import { resolveBuyerPremiumPercentage } from 'lib/helper_auction'
import FormContainer from './FormContainer'

const fields_initialstate = {
  ignoreConstraints: false,
  ignoreReserve: false,
}

BidResult.propTypes = {
  potentialBid: PropTypes.object.isRequired,
  closeAction: PropTypes.func,
  confirmAction: PropTypes.func,
  allowAdminOverrides: PropTypes.bool,
}

BidResult.defaultProps = {
  allowAdminOverrides: false,
}

function BidResult({ potentialBid, closeAction, confirmAction, allowAdminOverrides }) {
  const [fields, setFields] = useState(fields_initialstate)

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _confirmBid = () => {
    confirmAction({ ignore_constraints: fields.ignoreConstraints, ignore_reserve: fields.ignoreReserve })
  }

  const bidresult = potentialBid.bidresult

  const lot_number = potentialBid.lot_number || 0
  const lot_title = potentialBid.lot_title || null
  const bid_type = potentialBid.bid_type || 0
  const original_bid_type = potentialBid.original_bid_type === -1 ? bid_type : potentialBid.original_bid_type
  const valid = potentialBid.valid || false
  const proper = potentialBid.proper || false

  const original_max_bid = potentialBid.original_max_bid || 0
  const sanitized_max_bid = potentialBid.sanitized_max_bid || 0
  const resolved_bid = potentialBid.resolved_bid || 0

  const final_max_bid = potentialBid.final_max_bid || 0
  const new_high_bid = potentialBid.new_high_bid || 0
  const original_high_bid = potentialBid.original_high_bid || 0
  const is_reserve_met = potentialBid.is_reserve_met || false
  const is_high_bidder = potentialBid.is_high_bidder || false

  const unconstrained = potentialBid.unconstrained || false
  const ignore_reserve = potentialBid.ignore_reserve || false

  let p_title = 'Confirm Bid'
  let p_confirm_button_show = potentialBid.meta.status < 2 ? true : false
  let p_confirm_button_text = 'Confirm Bid'
  let p_close_button_text = potentialBid.meta.status < 2 ? 'Cancel' : 'Close'
  let bid_text = original_bid_type === 1 ? 'Your QwikBid' : 'Your Bid'
  let bid_text_suffix = null
  let bid_amount_display = potentialBid.resolved_bid

  let block_status = null
  let block_sanitized = null
  let block_question = null
  let block_reserve = null
  let block_admin_overrides = null

  const content_admin_overrides = unconstrained || ignore_reserve ? <div className={"alert alert-warning"}>
    <div><span className='fa fa-exclamation-triangle' /> Admin Override Warnings</div>
    {unconstrained && <div>
      <hr style={{ margin: '15px 0' }} />
      This bid is unconstrained by increment rules
    </div>}
    {ignore_reserve && <div>
      <hr style={{ margin: '15px 0' }} />
      Reserve price override in effect
    </div>}
  </div> : null

  const message_info = potentialBid.message_info.map((item, i) => {
    return <div key={i + item.text} style={item.style && item.style}>{item.text}</div>
  })

  if (bid_type === 2) {
    p_title = 'Confirm Purchase'
    p_confirm_button_text = 'Confirm Purchase'
    bid_text ='Price'
    bid_text_suffix = ` (plus ${resolveBuyerPremiumPercentage({ buyerPremiumInfo: potentialBid.buyerPremiumInfo, amount: bid_amount_display })} Buyer's Premium)`
    switch (bidresult) {
      case 0:
        // initial pre-confirm status
        break
      case 1:
        p_title = 'Purchase Results'
        p_close_button_text = 'Close'
        p_confirm_button_show = false
        block_status = <div className={"alert alert-success"}>
          <div>
            {message_info}
          </div>
        </div>
        break
      default:
        // malformed data
        p_title = 'Purchase Error'
        p_close_button_text = 'Close'
        p_confirm_button_show = false
        block_status = <div className={"alert alert-danger"}>
          <div>
            {message_info}
          </div>
        </div>
        block_question = <div className={"alert alert-danger"}>
          <div>
            <div>Unable to continue</div>
            <div>Please close and try again</div>
          </div>
        </div>
        break
    }
  } else {
    switch (bidresult) {
      case 0:
        // initial pre-confirm status
        break
      case 1:
        // successful bid

        // important data:
        // bid_type
        // final_max_bid
        // new_high_bid
        // original_high_bid
        // is_reserve_met
        // is_high_bidder
        p_title = original_bid_type === 1 ? 'QwikBid Results' : 'Bid Results'
        p_close_button_text = 'Close'
        p_confirm_button_show = false
        bid_amount_display = final_max_bid
        block_admin_overrides = content_admin_overrides
        block_status = <div className={"alert " + (is_high_bidder ? 'alert-success' : 'alert-warning')}>
          <div>
            {message_info}
          </div>
          <div>
            <hr style={{ margin: '15px 0' }} />
            {(new_high_bid !== original_high_bid ? "The high bid is now: " : "The high bid is unchanged at: ") + formatMoneyInteger(new_high_bid)}
          </div>
        </div>
        block_reserve = <div className={"alert " + (is_reserve_met ? 'alert-success' : 'alert-warning')}>
          <div>{is_reserve_met ? "The reserve price is met" : "The high bid is below the seller's reserve price"}</div>
        </div>
        break
      case 2:
        // bid needs review

        // important data:
        // bid_type
        // original_max_bid
        // sanitized_max_bid
        // resolved_bid
        p_title = original_bid_type === 1 ? 'Review QwikBid' : 'Review Bid'
        p_confirm_button_text = 'Confirm Adjusted Bid'
        bid_text = original_bid_type === 1 ? 'Your original QwikBid' : 'Your original Bid'
        bid_amount_display = original_max_bid
        block_admin_overrides = content_admin_overrides
        if (!proper) {
          block_sanitized = <div className={"alert alert-warning"}>
            <div>
              Potential bid was lowered to nearest allowed bid increment<br />{formatMoneyInteger(original_max_bid)} &rarr; {formatMoneyInteger(sanitized_max_bid)}
            </div>
          </div>
        }
        // only show if bid is not valid (not >= lowest minimum bid)
        if (!valid) {
          block_status = <div className={"alert alert-warning"}>
            <div>
              {message_info}
            </div>
            <div>
              <hr style={{ margin: '15px 0' }} />
              {"The lowest valid bid is: " + formatMoneyInteger(resolved_bid)}
            </div>
          </div>
        }
        // show bid adjustment question
        block_question = <div className={"alert alert-info"}>
          <div>
            {"Go ahead and place bid of " + formatMoneyInteger(resolved_bid) + "?"}
          </div>
        </div>
        break
      default:
        // malformed data
        p_title = 'Bid Error'
        p_close_button_text = 'Close'
        p_confirm_button_show = false
        bid_amount_display = original_max_bid
        block_status = <div className={"alert alert-danger"}>
          <div>
            {message_info}
          </div>
        </div>
        block_question = <div className={"alert alert-danger"}>
          <div>
            <div>Unable to continue</div>
            <div>Please close and try again</div>
          </div>
        </div>
        break
    }
  }

  const p_title_final = p_title + ': Lot ' + lot_number

  const content = <div className='font-bold' style={{marginBottom: 0, background: 'transparent', fontSize: '13px'}}>
    <h6 style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{lot_title}</h6>
    <div className="alert alert-info">
      {bid_text}: {formatMoneyInteger(bid_amount_display)}{bid_text_suffix}
    </div>
    {allowAdminOverrides && bidresult === 0 && <div className="margin-bottom-20" style={{ padding: '10px', border: '1px solid #ccc' }}>
      Admin Overrides:
      <hr style={{ margin: '15px 0' }} />
      <FusedFields data={fields} onUpdate={_handleUpdate}>
        <FormContainer rowClassName="fixed-gutter-5" size='small' noMargins={true}>
          <FormRow>
            <FieldCheckbox name="ignoreConstraints" label="Allow bids that don't align with increments" groupClassName='col-lg-12' checkboxDivStyle={{ margin: 0 }} labelStyle={{ fontWeight: 'bold' }} data-type="boolean" />
          </FormRow>
          <FormRow>
            <FieldCheckbox name="ignoreReserve" label="Lower Reserve to match bid (if under reserve)" groupClassName='col-lg-12' checkboxDivStyle={{ margin: 0 }} labelStyle={{ fontWeight: 'bold' }} data-type="boolean" />
          </FormRow>
        </FormContainer>
      </FusedFields>
    </div>}
    {!potentialBid.meta.processing && potentialBid.meta.status < 2 &&
      <div>
        {block_admin_overrides && block_admin_overrides}
        {block_sanitized && block_sanitized}
        {block_status && block_status}
        {block_reserve && block_reserve}
        {block_question && block_question}
      </div>
    }
    {/* <pre dangerouslySetInnerHTML={{__html: jsonFormatter.toHtml(diff(potentialBid.ebid_old, potentialBid.ebid))}} />
    <pre dangerouslySetInnerHTML={{__html: jsonFormatter.toHtml(diff(potentialBid.ebid, potentialBid.ebid_old))}} />
    <pre dangerouslySetInnerHTML={{__html: jsonFormatter.toHtml(potentialBid.ebid)}} /> */}
  </div>

  const container = <ConfirmBox
    title={p_title_final}
    titleIcon={'fa fa-question-circle'}
    style={{ maxWidth: '480px' }}
    confirm_button_show={p_confirm_button_show}
    confirm_button_text={p_confirm_button_text}
    confirm_button_action={_confirmBid}
    close_button_text={p_close_button_text}
    close_button_action={closeAction}
    showStatusCodeText={false}
    data={potentialBid.meta}
    footerTopMargin={'0'}
  >
    {content}
  </ConfirmBox>

  return container
}

export default BidResult
