import React from 'react'
import PropTypes from 'prop-types'

BaseContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

function BaseContainer({ children }) {
  return <div className="owa-column owa-content-margins">
    {children}
  </div>
}

export default BaseContainer
