import React from 'react'
import { parseISO } from 'date-fns'

import { round, formatMoneyInteger, numOrZero, formatValue, formatMoney } from 'lib/utility'
import { getShipMethod, getTrackingUrl } from 'lib/utility.shipping'
import { invoice_optionsNEW as invoice_options } from 'lib/constants'
import { calcInvoiceCosts } from './utility_invoice'

export const auctionIndex_specs = {
  defaults: {
    row_link: true,
  },
  columns: [
    {
      field_name: 'id',
      display_name: 'Auction #',
      style: { width: '120px' },
    },
    {
      field_name: 'date_description',
      display_name: 'Timeframe/Description',
    },
    {
      field_name: 'date_closing',
      display_name: 'Closed',
      style: { width: '200px' },
    },
  ]
}

export const lotIndex_specs = {
  defaults: {
    row_link: true,
  },
  columns: [
    {
      field_name: 'lot_number',
      display_name: 'Lot #',
      style: { width: '80px' },
    },
    {
      field_name: 'desc_title',
      display_name: 'Title',
    },
    {
      field_name: 'desc_subject',
      display_name: 'Subject',
      style: { width: '120px' },
    },
    {
      field_name: 'creator',
      display_name: 'Creator',
      style: { width: '120px' },
    },
    {
      field_name: 'desc_period',
      display_name: 'Period',
      style: { width: '120px' },
    },
    {
      field_name: 'desc_condition',
      display_name: 'Condition',
      style: { width: '80px' },
    },
    {
      field_name: 'sold_for',
      display_name: 'Sold For',
      type: 'currency',
      style: { width: '150px' },
    },
  ]
}

export const auctionList_specs = {
  defaults: {
    row_clickable: true,
  },
  columns: [
    {
      field_name: 'indicator',
      display_name: '',
      type: 'custom',
      style: { width: '40px' },
      generator: (data) => {
        if (data.archived) {
          return <div className="legend-general-item"><span className="caution"><i className="fa fa-stop-circle"></i></span></div>
        } else if (parseISO(data.datetime_starting) > Date.now()) {
          return <div className="legend-general-item"><span className="ready"><i className="fa fa-chevron-circle-up"></i></span></div>
        } else {
          return <div className="legend-general-item"><span className="paid"><i className="fa fa-star"></i></span></div>
        }
      }
    },
    {
      field_name: 'id',
      source_table: 'a',
      display_name: '#',
      style: { width: '45px' },
      tdStyle: { fontWeight: 'bold' },
      sortable: true,
    },
    {
      field_name: 'auction_type',
      source_table: 'a',
      display_name: 'Type',
      style: { width: '45px' },
      sortable: true,
    },
    {
      field_name: 'datetime_starting',
      display_name: 'Starting',
      style: { width: '120px' },
    },
    {
      field_name: 'datetime_ending',
      display_name: 'Ending',
      style: { width: '120px' },
    },
    {
      field_name: 'post_auction_sale',
      source_table: 'a',
      display_name: 'Post-Auction Sale',
      style: { width: '105px' },
    },
    {
      field_name: 'buyer_premium_base',
      display_name: 'Buyer Premium Base',
      format: 'percent',
      style: { width: '120px' },
    },
    {
      field_name: 'buyer_premium_tierA',
      display_name: 'TierA',
      format: 'percent',
      style: { width: '120px' },
    },
    {
      field_name: 'buyer_premium_tierA_threshold',
      display_name: 'TierA Threshold',
      style: { width: '120px' },
    },
  ]
}

export const bidHistory_specs = {
  defaults: {
    row_clickable: true,
  },
  helpers: {
    rowStyleFunction: (lot_won) => lot_won === 0 ? {color: 'red'} : {color: 'green'}
  },
  columns: [
    {
      field_name: 'auction_lot_combined',
      display_name: 'Lot#',
      style: { width: '85px' },
      tdStyle: { height: '50px' },
    },
    {
      field_name: 'desc_subject',
      display_name: 'Subject',
      style: { width: '120px' },
    },
    {
      field_name: 'desc_title',
      display_name: 'Title',
      tdStyle: { maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    },
    {
      field_name: 'timestamp',
      display_name: 'Timestamp',
      style: { width: '160px' },
    },
    {
      field_name: 'max_bid',
      display_name: 'Max Bid',
      format: 'currency',
      style: { width: '65px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
    },
    {
      field_name: 'price_highbid',
      display_name: 'High Bid',
      format: 'currency',
      style: { width: '65px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
    },
  ]
}

export const consignmentHistory_specs = {
  defaults: {
    row_clickable: true,
  },
  columns: [
    {
      field_name: 'auction_lot_combined',
      display_name: 'Lot#',
      style: { width: '85px' },
      tdStyle: { height: '30px' },
    },
    {
      field_name: 'desc_subject',
      display_name: 'Subject',
      style: { width: '120px' },
    },
    {
      field_name: 'creator',
      display_name: 'Creator',
      style: { width: '120px' },
      tdStyle: { maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    },
    {
      field_name: 'desc_title',
      display_name: 'Title',
      tdStyle: { maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    },
    {
      field_name: 'desc_period',
      display_name: 'Date',
      style: { width: '80px' },
    },
    // {
    //   field_name: 'price_sold',
    //   display_name: 'Sold for',
    //   format: 'currency',
    //   style: { width: '65px', textAlign: 'right' },
    //   tdStyle: { textAlign: 'right', paddingRight: '4px' },
    // },
    {
      field_name: 'price_sold',
      display_name: 'Sold for',
      type: 'custom',
      style: { width: '65px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
      generator: (data) => {
        if (data.price_sold > 0) {
          return formatMoneyInteger(data.price_sold)
        } else {
          return 'Unsold'
        }
      }
    },
  ]
}

export const purchaseHistory_specs = {
  defaults: {
    row_clickable: true,
  },
  columns: [
    {
      field_name: 'auction_lot_combined',
      display_name: 'Lot#',
      style: { width: '85px' },
      tdStyle: { height: '30px' },
    },
    {
      field_name: 'desc_subject',
      display_name: 'Subject',
      style: { width: '120px' },
    },
    {
      field_name: 'creator',
      display_name: 'Creator',
      style: { width: '120px' },
      tdStyle: { maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    },
    {
      field_name: 'desc_title',
      display_name: 'Title',
      tdStyle: { maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    },
    {
      field_name: 'desc_period',
      display_name: 'Date',
      style: { width: '80px' },
    },
    {
      field_name: 'cost',
      display_name: 'Cost',
      format: 'currency',
      style: { width: '65px', textAlign: 'right' },
      tdStyle: { textAlign: 'right', paddingRight: '4px' },
    },
  ]
}

export const invoiceList_specs = {
  admin: {
    defaults: {
      row_clickable: true,
    },
    columns: [
      {
        field_name: 'indicator',
        display_name: '',
        type: 'custom',
        style: { width: '40px' },
        generator: (data) => {
          if (!data.inv_ready) {
            return <div className="legend-general-item"><span className="caution"><i className="fa fa-exclamation-circle"></i></span></div>
          } else if (!data.inv_approved) {
            return <div className="legend-general-item"><span className="ready"><i className="fa fa-flag"></i></span></div>
          } else if (!data.inv_paid) {
            return <div className="legend-general-item"><span className="resolved"><i className="fa fa-check"></i></span></div>
          } else {
            return <div className="legend-general-item"><span className="paid"><i className="fa fa-usd"></i></span><span className="resolved"><i className="fa fa-check"></i></span></div>
          }
        }
      },
      {
        field_name: 'id',
        source_table: 'ui',
        display_name: 'Invoice ID',
        style: { width: '90px' },
        tdStyle: { fontWeight: 'bold' },
        sortable: true,
      },
      {
        field_name: 'auction_id',
        source_table: 'ui',
        display_name: 'Auction #',
        style: { width: '90px' },
        sortable: true,
      },
      {
        field_name: 'fullname',
        display_name: 'Name',
        style: { width: '150px' },
        sortable: true,
      },
      {
        field_name: 'inv_ship_country',
        source_table: 'ui',
        display_name: 'Country',
        style: { width: '140px' },
        sortable: true,
      },
      {
        field_name: 'inv_ready',
        source_table: 'ui',
        display_name: 'Ready',
        format: 'yesno',
        style: { width: '80px' },
      },
      {
        field_name: 'inv_changed',
        source_table: 'ui',
        display_name: 'Changed',
        format: 'yesno',
        style: { width: '80px' },
      },
      {
        field_name: 'inv_approved',
        source_table: 'ui',
        display_name: 'Approved',
        format: 'yesno',
        style: { width: '90px' },
      },
      {
        field_name: 'inv_paid',
        source_table: 'ui',
        display_name: 'Paid',
        format: 'yesno',
        style: { width: '80px' },
      },
      {
        field_name: 'bids_total',
        display_name: 'Total Bids',
        format: 'currency',
        style: { width: '100px' },
        sortable: true,
      },
      // {
      //   field_name: 'amount_total',
      //   display_name: 'Total Due',
      //   format: 'currency',
      //   sortable: true,
      // },
      {
        field_name: 'calc_total_due',
        display_name: 'Total Due',
        type: 'custom',
        generator: (data) => {
          return formatMoney(calcInvoiceCosts({ primaryData: data, auxData: data, options: data, carrier: data.inv_ship_carrier_preference, foreign: data.inv_ship_country !== 'US', state: data.inv_ship_state, mode: 'admin' }).total_due)
        }
      },
    ],
  },
  user: {
    defaults: {
      row_clickable: true,
      tableStyle: { minWidth: '840px' },
    },
    columns: [
      {
        field_name: 'indicator',
        display_name: '',
        type: 'custom',
        style: { width: '40px' },
        tdStyle: { paddingTop: '10px', paddingBottom: '10px' },
        generator: (data) => {
          if (!data.inv_ready) {
            return <div className="legend-general-item"><span className="caution"><i className="fa fa-exclamation-circle"></i></span></div>
          } else if (!data.inv_approved) {
            return <div className="legend-general-item"><span className="ready"><i className="fa fa-flag"></i></span></div>
          } else if (!data.inv_paid) {
            return <div className="legend-general-item"><span className="resolved"><i className="fa fa-check"></i></span></div>
          } else {
            return <div className="legend-general-item"><span className="resolved"><i className="fa fa-check"></i></span><span className="paid"><i className="fa fa-usd"></i></span></div>
          }
        }
      },
      {
        field_name: 'id',
        source_table: 'ui',
        display_name: 'Invoice #',
        style: { width: '90px' },
        tdStyle: { fontWeight: 'bold' },
        sortable: true,
      },
      {
        field_name: 'meta_date_created',
        source_table: 'ui',
        display_name: 'Date',
        style: { width: '90px' },
        sortable: true,
      },
      {
        field_name: 'auction_id',
        source_table: 'ui',
        display_name: 'Auction #',
        style: { width: '80px' },
        sortable: true,
      },
      {
        field_name: 'inv_ready',
        source_table: 'ui',
        display_name: 'Ready',
        format: 'yesno',
        style: { width: '70px' },
        sortable: true,
      },
      {
        field_name: 'inv_approved',
        source_table: 'ui',
        display_name: 'Approved',
        format: 'yesno',
        style: { width: '70px' },
        sortable: true,
      },
      {
        field_name: 'inv_paid',
        source_table: 'ui',
        display_name: 'Paid',
        format: 'yesno',
        style: { width: '70px' },
        sortable: true,
      },
      {
        field_name: 'bids_total',
        display_name: 'Total Bids',
        format: 'int_currency',
        style: { width: '90px' },
        sortable: true,
      },
      // {
      //   field_name: 'amount_total',
      //   display_name: 'Total Due',
      //   format: 'currency',
      //   style: { width: '120px' },
      //   sortable: true,
      // },
      {
        field_name: 'calc_total_due',
        display_name: 'Total Due',
        type: 'custom',
        style: { width: '120px' },
        generator: (data) => {
          return formatMoney(calcInvoiceCosts({ primaryData: data, auxData: data, options: data, carrier: data.inv_ship_carrier_preference, foreign: data.inv_ship_country !== 'US', state: data.inv_ship_state }).total_due)
        }
      },
      {
        field_name: 'action_button',
        display_name: '',
        type: 'custom',
        generator: (data) => {
          if (!data.inv_ready) {
            return <div className="datalist-button"><button className="btn btn-xs btn-caution">Preview Invoice <i className="fa fa-arrow-right"></i></button></div>
          } else if (!data.inv_approved) {
            return <div className="datalist-button"><button className="btn btn-xs btn-standard">Approve/Pay Invoice <i className="fa fa-arrow-right"></i></button></div>
          } else {
            return <div className="datalist-button"><button className="btn btn-xs btn-resolved">View Invoice <i className="fa fa-arrow-right"></i></button></div>
          }
        }
      },
    ]
  }
}

export const invoiceLots_specs = {
  admin: {
    defaults: {
      row_clickable: true,
    },
    columns: [
      {
        field_name: 'lot_number',
        display_name: 'Lot#',
        style: { width: '55px' },
      },
      {
        field_name: 'desc_title',
        display_name: 'Title',
        tdStyle: { maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      },
      {
        field_name: 'creator',
        display_name: 'Creator',
        style: { width: '220px' },
        tdStyle: { maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      },
      {
        field_name: 'desc_period',
        display_name: 'Period',
        style: { width: '80px' },
      },
      {
        field_name: 'ship_type',
        display_name: 'Ship Exception',
        style: { width: '160px' },
      },
      {
        field_name: 'price_highbid',
        display_name: 'Winning$',
        format: 'currency',
        style: { width: '85px' },
        tdStyle: { textAlign: 'right', paddingRight: '4px' },
      },
    ],
  },
  user: {
    defaults: {
      row_clickable: true,
    },
    columns: [
      {
        field_name: 'lot_number',
        display_name: 'Lot#',
        style: { width: '55px' },
      },
      {
        field_name: 'desc_title',
        display_name: 'Title',
        tdStyle: { maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      },
      {
        field_name: 'creator',
        display_name: 'Creator',
        style: { width: '220px' },
        tdStyle: { maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      },
      {
        field_name: 'desc_period',
        display_name: 'Period',
        style: { width: '80px' },
      },
      {
        field_name: 'price_highbid',
        display_name: 'Winning$',
        format: 'currency',
        style: { width: '85px' },
        tdStyle: { textAlign: 'right', paddingRight: '4px' },
      },
    ],
  }
}

export const invoicePackages_specs = {
  admin: {
    defaults: {
      row_clickable: false,
    },
    columns: [
      {
        field_name: 'ship_date',
        display_name: 'Shipped',
        style: { width: '70px' },
      },
      {
        field_name: 'carrier',
        display_name: 'Carrier',
        style: { width: '55px' },
      },
      {
        field_name: 'tracking',
        display_name: 'Tracking#',
        type: 'custom',
        generator: (data) => {
          // ZZZ - look into allowing for a custom type that returns a full react component (this would allow for memoization of internals)
          const trackingUrl = getTrackingUrl(data.tracking, { carrier: getShipMethod(data.carrier).carrier })
          if (trackingUrl) {
            return <a href={trackingUrl} target="_blank" rel="noopener noreferrer">{data.tracking}</a>
          } else {
            return data.tracking
          }
        },
        tdStyle: { maxWidth: '245px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      },
      {
        field_name: 'customs_declaration',
        display_name: 'Customs',
        type: 'custom',
        generator: (data) => {
          return data.customs_declaration > 0 ? invoice_options.options_customs_declaration[data.customs_declaration - 1].text.substring(0,5) : 0
        },
        style: { width: '60px' },
        visibleFunc: (country) => country !== 'US',
      },
      {
        field_name: 'insure_full',
        display_name: 'Insured',
        format: 'yesno',
        style: { width: '55px' },
      },
      {
        field_name: 'signature_required',
        display_name: 'SigReq',
        format: 'yesno',
        style: { width: '55px' },
        visibleFunc: (country) => country === 'US',
      },
      {
        field_name: 'email_sent',
        display_name: 'Notified',
        format: 'yesno',
        style: { width: '55px' },
      },
      {
        field_name: 'remover',
        display_name: '',
        type: 'remove',
        style: { width: '30px', textAlign: 'right' },
        tdStyle: { textAlign: 'right', paddingRight: '4px' },
      },
    ],
  },
  user: {
    defaults: {
      row_clickable: false,
    },
    columns: [
      {
        field_name: 'ship_date',
        display_name: 'Date',
        style: { width: '70px' },
      },
      {
        field_name: 'carrier',
        display_name: 'Carrier',
        style: { width: '55px' },
      },
      {
        field_name: 'tracking',
        display_name: 'Tracking#',
        type: 'custom',
        generator: (data) => {
          // ZZZ - look into allowing for a custom type that returns a full react component (this would allow for memoization of internals)
          const trackingUrl = getTrackingUrl(data.tracking, { carrier: getShipMethod(data.carrier).carrier })
          if (trackingUrl) {
            return <a href={trackingUrl} target="_blank" rel="noopener noreferrer">{data.tracking}</a>
          } else {
            return data.tracking
          }
        },
        tdStyle: { maxWidth: '245px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      },
      {
        field_name: 'customs_declaration',
        display_name: 'Customs',
        type: 'custom',
        generator: (data) => {
          return data.customs_declaration > 0 ? invoice_options.options_customs_declaration[data.customs_declaration - 1].text.substring(0, 5) : 0
        },
        style: { width: '60px' },
        visibleFunc: (country) => country !== 'US',
      },
      {
        field_name: 'insure_full',
        display_name: 'Insured',
        format: 'yesno',
        style: { width: '55px' },
      },
      {
        field_name: 'signature_required',
        display_name: 'SigReq',
        format: 'yesno',
        style: { width: '55px' },
        visibleFunc: (country) => country === 'US',
      },
    ],
  },
}

export const statementList_specs = {
  admin: {
    defaults: {
      clickable: true,
    },
    columns: [
      {
        field_name: 'indicator',
        display_name: '',
        type: 'custom',
        style: { width: '40px' },
        generator: (data) => {
          if (!data.st_ready) {
            return <div className="legend-general-item"><span className="caution"><i className="fa fa-exclamation-circle"></i></span></div>
          } else if (!data.st_paid) {
            return <div className="legend-general-item"><span className="ready"><i className="fa fa-flag"></i></span></div>
          } else {
            return <div className="legend-general-item"><span className="paid"><i className="fa fa-usd"></i></span><span className="ready"><i className="fa fa-flag"></i></span></div>
          }
        }
      },
      {
        field_name: 'id',
        source_table: 'us',
        display_name: 'Statement ID',
        style: { width: '90px' },
        sortable: true,
      },
      {
        field_name: 'auction_id',
        source_table: 'us',
        display_name: 'Auction #',
        style: { width: '90px' },
        sortable: true,
      },
      {
        field_name: 'fullname',
        display_name: 'Name',
        style: { width: '150px' },
        sortable: true,
      },
      {
        field_name: 'st_ready',
        source_table: 'us',
        display_name: 'Ready',
        format: 'yesno',
        style: { width: '80px' },
        sortable: true,
      },
      {
        field_name: 'st_paid',
        source_table: 'us',
        display_name: 'Paid',
        format: 'yesno',
        style: { width: '80px' },
        sortable: true,
      },
      {
        field_name: 'calc_sales',
        source_table: 'us',
        display_name: 'Sales',
        format: 'currency',
        style: { width: '100px' },
        sortable: true,
      },
      {
        field_name: 'calc_commission',
        source_table: 'us',
        display_name: 'Commission',
        format: 'currency',
        style: { width: '100px' },
        sortable: true,
      },
      {
        field_name: 'calc_commission_percent',
        display_name: '%',
        format: 'percent',
        style: { width: '60px' },
      },
      {
        field_name: 'calc_misc',
        source_table: 'us',
        display_name: 'Expenses/Credits',
        format: 'currency',
        style: { width: '100px' },
        sortable: true,
      },
      {
        field_name: 'calc_net_due',
        display_name: 'Net Due',
        format: 'currency',
        style: { textAlign: 'right', width: '100px' },
        tdStyle: { textAlign: 'right', paddingRight: '4px' },
      },
    ],
  },
  user: {
    defaults: {
      clickable: true,
    },
    columns: [
      {
        field_name: 'indicator',
        display_name: '',
        type: 'custom',
        style: { width: '40px' },
        tdStyle: { paddingTop: '10px', paddingBottom: '10px' },
        generator: (data) => {
          if (!data.st_ready) {
            return <div className="legend-general-item"><span className="caution"><i className="fa fa-exclamation-circle"></i></span></div>
          } else if (!data.st_paid) {
            return <div className="legend-general-item"><span className="ready"><i className="fa fa-flag"></i></span></div>
          } else {
            return <div className="legend-general-item"><span className="paid"><i className="fa fa-usd"></i></span><span className="ready"><i className="fa fa-flag"></i></span></div>
          }
        }
      },
      {
        field_name: 'id',
        source_table: 'us',
        display_name: 'Statement ID',
        style: { width: '90px' },
        sortable: true,
      },
      {
        field_name: 'auction_id',
        source_table: 'us',
        display_name: 'Auction #',
        style: { width: '90px' },
        sortable: true,
      },
      {
        field_name: 'st_ready',
        source_table: 'us',
        display_name: 'Ready',
        format: 'yesno',
        style: { width: '80px' },
      },
      {
        field_name: 'st_paid',
        source_table: 'us',
        display_name: 'Paid',
        format: 'yesno',
        style: { width: '80px' },
      },
      {
        field_name: 'calc_sales',
        source_table: 'us',
        display_name: 'Sales',
        format: 'currency',
        style: { width: '100px' },
        sortable: true,
      },
      {
        field_name: 'calc_commission',
        source_table: 'us',
        display_name: 'Commission',
        format: 'currency',
        style: { width: '100px' },
        sortable: true,
      },
      {
        field_name: 'calc_commission_percent',
        display_name: '%',
        format: 'percent',
        style: { width: '60px' },
      },
      {
        field_name: 'calc_misc',
        source_table: 'us',
        display_name: 'Expenses/Credits',
        format: 'currency',
        style: { width: '100px' },
        sortable: true,
      },
      {
        field_name: 'calc_net_due',
        display_name: 'Net Due',
        format: 'currency',
        style: { textAlign: 'right', width: '100px' },
        tdStyle: { textAlign: 'right', paddingRight: '4px' },
      },
    ]
  }
}

export const statementLineItems_specs = {
  admin : {
    lineitems: {
      defaults: {
        clickable: false,
      },
      columns: [
        {
          field_name: 'desc',
          display_name: 'Description',
        },
        {
          field_name: 'amount',
          display_name: 'Amount',
          format: 'currency',
          style: { width: '85px', textAlign: 'right' },
          tdStyle: { textAlign: 'right', paddingRight: '4px' },
        },
        {
          field_name: 'remover',
          display_name: '',
          type: 'remove',
          style: { width: '30px', textAlign: 'right' },
          tdStyle: { textAlign: 'right', paddingRight: '4px' },
        },
      ],
    },
    lots: {
      defaults: {
        clickable: false,
      },
      columns: [
        {
          field_name: 'lot_number',
          display_name: 'Lot#',
          style: { width: '55px' },
          clickable: true,
        },
        {
          field_name: 'consignor_serial',
          display_name: 'Serial#',
          style: { width: '55px' },
          clickable: true,
        },
        {
          field_name: 'desc_title',
          display_name: 'Title',
          tdStyle: { maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
        },
        {
          field_name: 'creator',
          display_name: 'Creator',
          style: { width: '180px' },
          tdStyle: { maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
        },
        {
          field_name: 'desc_period',
          display_name: 'Period',
          style: { width: '80px' },
        },
        {
          field_name: 'winning_bid',
          display_name: 'Winning$',
          calculated: true,
          calculation: (lot) => {
            return lot.sold ? formatValue(lot.winning_bid, 'currency-integer') : 'unsold'
          },
          style: { width: '85px' },
          tdStyle: { textAlign: 'right', paddingRight: '4px' },
        },
        {
          field_name: 'calculated_base_commission',
          display_name: 'Base',
          format: 'currency',
          calculated: true,
          calculation: (lot, commission_scheme) => {
            return numOrZero(Math.max(round(lot.winning_bid * commission_scheme.com_percent, 2), commission_scheme.com_minimum))
          },
          style: { width: '85px' },
          tdStyle: { textAlign: 'right', paddingRight: '4px' },
        },
        {
          field_name: 'adjustment',
          display_name: 'Adjustment',
          type: 'input',
          dataType: 'float',
          options: { group: 'lots' },
          style: { width: '85px' },
          tdStyle: { textAlign: 'right', paddingRight: '4px' },
        },
        {
          field_name: 'final_commission',
          display_name: 'Commission',
          format: 'currency',
          calculated: true,
          calculation: (lot, commission_scheme) => {
            const c = Math.max(round(lot.winning_bid * commission_scheme.com_percent, 2), commission_scheme.com_minimum)
            return numOrZero(c) + numOrZero(lot.adjustment)
          },
          style: { width: '85px' },
          tdStyle: { textAlign: 'right', paddingRight: '4px' },
        },
      ],
    }
  },
  user : {
    lineitems: {
      defaults: {
        clickable: false,
      },
      columns: [
        {
          field_name: 'desc',
          display_name: 'Description',
        },
        {
          field_name: 'amount',
          display_name: 'Amount',
          format: 'currency',
          style: { width: '85px', textAlign: 'right' },
          tdStyle: { textAlign: 'right', paddingRight: '4px' },
        },
      ],
    },
    lots: {
      defaults: {
        clickable: false,
      },
      columns: [
        {
          field_name: 'lot_number',
          display_name: 'Lot#',
          style: { width: '55px' },
          clickable: true,
        },
        {
          field_name: 'consignor_serial',
          display_name: 'Serial#',
          style: { width: '55px' },
          clickable: true,
        },
        {
          field_name: 'desc_title',
          display_name: 'Title',
          tdStyle: { maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
        },
        {
          field_name: 'creator',
          display_name: 'Creator',
          style: { width: '180px' },
          tdStyle: { maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
        },
        {
          field_name: 'desc_period',
          display_name: 'Period',
          style: { width: '80px' },
        },
        {
          field_name: 'winning_bid',
          display_name: 'Winning$',
          calculated: true,
          calculation: (lot) => {
            return lot.sold ? formatValue(lot.winning_bid, 'currency-integer') : 'unsold'
          },
          style: { width: '85px' },
          tdStyle: { textAlign: 'right', paddingRight: '4px' },
        },
        {
          field_name: 'final_commission',
          display_name: 'Commission',
          format: 'currency',
          style: { width: '85px' },
          tdStyle: { textAlign: 'right', paddingRight: '4px' },
        },
      ],
    }
  }
}
