import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getConsignorSummary } from 'rdx/modules/admin/consignor_summary'

import ContentPanel from '../shared/ContentPanel'
import DataList from '../shared/DataList'
import DataListRow from '../shared/DataListRow'

import FieldText from '../shared/FieldText'
import FormRow from '../shared/FormRow'
import FusedFields from '../shared/fused-fields'
import StatusBox from '../shared/StatusBox'
import LoadingOverlay from '../shared/LoadingOverlay'

import { formatQueryParams, hydrateQueryFields } from 'lib/utility'
import { admin_consignor_summary_table_definitions } from 'lib/constants'

import connectDataList from '../shared/composers/connectDataList'

const DataListBound = connectDataList(DataList, state => ({ statebranch: state.admin.consignor_summary.content }))

const fields_initialstate = {
  auction_id: '',
}

ConsignorSummary.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  siteinfo: PropTypes.object.isRequired,
  consignor_summary: PropTypes.object.isRequired,
  getConsignorSummary: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
}

ConsignorSummary.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getConsignorSummary(location)),
  ])
}

function ConsignorSummary({ location, params, siteinfo, consignor_summary, getConsignorSummary, router }) {
  const [fields, setFields] = useState({ ...fields_initialstate, ...(hydrateQueryFields(location.query, fields_initialstate))})
  useEffect(() => {
    getConsignorSummary(location)
  }, [location, getConsignorSummary])

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _handleFilter = () => {
    let new_query = {}
    Object.keys(fields).map(key => {
      if (typeof fields[key] !== 'undefined' && fields[key] !== ''){ new_query[key] = fields[key] }
    })
    router.push({ ...location, query: new_query })
  }

  const _clearFilter = () => {
    router.push({ ...location, query: {} })
    setFields(fields_initialstate)
  }

  const content = <DataListBound
    useTable={true}
    showHeader={true}
    showTopPagingControl={false}
    pagingControlSize='slim'
    metadata={admin_consignor_summary_table_definitions}
    datakey='id'
    params={params}
    location={location}
  >
    <DataListRow />
  </DataListBound>

  return <ContentPanel
    title="Consignor Summary"
    icon="fa fa-bar-chart fa-fw"
    titleSuffix={' - Auction ' + (typeof location.query.auction_id !== 'undefined' ? location.query.auction_id : siteinfo.current_auction)}
    filteredText={formatQueryParams(location.query)}
  >
    <div>
      <div className='filter-container'>
        <FusedFields data={fields} onUpdate={_handleUpdate}>
          <FormRow>
            <FieldText name='auction_id' addonBefore='Auction #' groupClassName='col-lg-3' data-type='unsigned-int' />
            <button type='submit' className='btn btn-u btn-u-dark-blue' onClick={_handleFilter} style={{ marginRight: '10px' }}>
              <span className='fa fa-filter fa-fw' />
              {' Filter'}
            </button>
            <button type='submit' className='btn btn-u btn-u-red' onClick={_clearFilter}>
              <span className='fa fa-times fa-fw' />
              {' Clear Filter'}
            </button>
          </FormRow>
        </FusedFields>
      </div>
      <StatusBox data={consignor_summary.content.meta} hideUntilError={true} />
      <LoadingOverlay meta={consignor_summary.content.meta}>{content}</LoadingOverlay>
    </div>
  </ContentPanel>
}

function mapStateToProps(state) {
  return {
    siteinfo: state.siteinfo.data,
    consignor_summary: state.admin.consignor_summary,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getConsignorSummary }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsignorSummary)