import React from 'react'
import classNames from 'classnames'
import { CountryDropdown } from 'react-country-region-selector'
import SmartSelect from './SmartSelect'

import { FormGroup, InputGroup, FormControl, ControlLabel } from 'react-bootstrap'

function FieldBase({ addonBefore, addonBeforeStyle, addonAfter, addonAfterStyle, groupClassName, groupStyle, checkboxDivStyle, standalone, label, labelStyle, required, validationState, size, noMargins, children, options, displayFormat, displayWhenNull, forceValue, forcedValue, mode, toggleHandler, optionsSmartSelect, ...other }) {
  const fg_class = classNames({
    'form-group': !noMargins,
    'form-group-sm': size === 'small',
    'form-group-md': size === 'medium',
    'form-group-lg': size === 'large',
    'required': typeof required !== 'undefined',
  }, groupClassName);
  const base_inputgroup_style = { display: 'flex', width: 'auto', alignItems: 'center' }
  let root_element = null
  let input_group_wrap = false
  let form_group_wrap = true
  let control_label = typeof label !== 'undefined' ? <ControlLabel>{label}</ControlLabel> : null
  let content = null

  switch (other.type) {
    case 'checkbox':
      if (mode === 'button-group') {
        const labelClass = classNames('btn btn-default checkbox-button', {
          'checkbox-button-checked': other.value,
        }, other.className)
        root_element = <label className={labelClass} style={other.style}>
          <input {...other} />
          <span className="control-label">{label}</span>
        </label>
        control_label = null
        input_group_wrap = false
        form_group_wrap = mode !== 'button-group'
      } else {
        root_element = <div className='checkbox' style={checkboxDivStyle}>
          <label>
            <input {...other} />
            <span className="control-label" style={labelStyle}>{label}</span>
          </label>
        </div>
        control_label = null
        input_group_wrap = false
      }
      break;
    case 'checkbox-button':
      root_element = <div className='checkbox' style={checkboxDivStyle}>
        <label>
          <input {...other} />
          <span className="control-label" style={labelStyle}>{label}</span>
        </label>
      </div>
      control_label = null
      input_group_wrap = false
      break;
    case 'select':
      root_element = <FormControl {...other} componentClass="select">
        {children}
      </FormControl>
      input_group_wrap = true
      break;
    case 'rawselect':
      root_element = <FormControl {...other} componentClass="select">
        {children}
      </FormControl>
      input_group_wrap = true
      form_group_wrap = false
      break;
    case 'selectWithOptions':
      root_element = <FormControl {...other} componentClass="select">
        {options}
      </FormControl>
      input_group_wrap = true
      break;
    case 'countryDropdown':
      root_element = <CountryDropdown {...other} valueType="short" onChange={(value)=>other.onChange({ target: { name: other.name, value: value } })} onBlur={(value)=>other.onBlur({ target: { name: other.name, value: value } })} classes="form-control" />
      input_group_wrap = true
      break;
    case 'textarea':
      root_element = <FormControl {...other} componentClass="textarea" className={validationState && validationState == 'error' ? 'has-error' : null} />
      input_group_wrap = false
      break;
    case 'rawinput':
      root_element = <FormControl {...other} className={validationState && validationState == 'error' ? 'has-error' : null} />
      input_group_wrap = false
      form_group_wrap = false
      break;
    case 'password':
      root_element = <FormControl {...other} type="password" />
      input_group_wrap = true
      break;
    case 'date':
      root_element = <FormControl {...other} type="date" className={validationState && validationState == 'error' ? 'has-error' : null} />
      input_group_wrap = true
      break;
    case 'datetime-local':
      root_element = <FormControl {...other} type="datetime-local" />
      input_group_wrap = true
      break;
    case 'SmartSelect':
      root_element = <SmartSelect {...optionsSmartSelect} {...other} />
      input_group_wrap = true
      break;
    default:
      root_element = <FormControl {...other} />
      input_group_wrap = true
      break;
  }

  if (input_group_wrap && !standalone) {
    root_element = <InputGroup style={{ display: 'flex' }}>
      {addonBefore ? <InputGroup.Addon style={{...base_inputgroup_style, ...addonBeforeStyle}}>{addonBefore}</InputGroup.Addon> : null}
      {root_element}
      {addonAfter ? <InputGroup.Addon style={{...base_inputgroup_style, ...addonAfterStyle}}>{addonAfter}</InputGroup.Addon> : null}
    </InputGroup>
  }

  if (form_group_wrap) {
    content = <FormGroup bsClass={fg_class} style={groupStyle} validationState={validationState}>
      {control_label}
      {root_element}
    </FormGroup>
  } else {
    content = root_element
  }

  return content
}

// experimenting with isEqual function passed to React.memo
// not ready to put into production because too many scenarios could cause unintended bugs with input state
// for example, with useState hooks the callback updater form must be used (because we need access to previous state) or the memoized components will be buggy due to asynchronous state updates
// function propsAreEqual(prevProps, nextProps) {
//   if (prevProps !== nextProps) {
//     return prevProps.value === nextProps.value
//   } else {
//     return true
//   }
// }

export default React.memo(FieldBase)