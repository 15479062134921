import qs from 'qs'

import { processQuery, get } from 'lib/api'
import { defineAction, status_defaults, validation_defaults, buildError } from 'lib/state_defaults'
import { verify } from 'lib/validation'
import { meta_fields_lot } from 'lib/constants'

const base = 'owa/admin/lots/'

const DATA = defineAction(base, 'DATA', 'async')

const DATA_LOT = defineAction(base, 'DATA_LOT', 'async', { customActions: ['close', 'paste'] })
const ADD_LOT = defineAction(base, 'ADD', 'async')
const UPDATE_LOT = defineAction(base, 'UPDATE', 'async')
const CONSIGNOR_SERIAL_LOOKUP = defineAction(base, 'CONSIGNOR_SERIAL_LOOKUP', 'async', { customActions: ['reset']})

const SELECT_TAB =   base.concat('SELECT_TAB')
const FIELD_UPDATE =   base.concat('FIELD_UPDATE')
const LOCATION_SAVE = base.concat('LOCATION_SAVE')

const info_defaults = {
  pageSize: 20,
  count: 0,
}

const lot_detail_defaults = {
  originalData: {},
  data: {},
  meta: status_defaults,
  update: status_defaults,
  serial: status_defaults,
  validation: validation_defaults,
}

const initialState = {
  list: {
    data: [],
    info: info_defaults,
    meta: status_defaults,
    location: null,
  },
  lot: {
    new: lot_detail_defaults,
  },
  lot_order: [],
  selectedTab: "list",
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case DATA.success:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          info: {
            ...state.list.info,
            count: action.payload.count,
          },
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA.fail:
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA_LOT.request:
      return {
        ...state,
        lot: {
          ...state.lot,
          [action.foundation.id]: {
            ...lot_detail_defaults,
            ...state.lot[action.foundation.id],
            data: {
              id: action.foundation.id,
              ...(state.lot[action.foundation.id] && { ...state.lot[action.foundation.id].data }),
            },
            originalData: {
              id: action.foundation.id,
              ...(state.lot[action.foundation.id] && { ...state.lot[action.foundation.id].originalData }),
            },
            meta: {
              ...status_defaults,
              processing: true,
            },
            ...action.foundation.newborn && {
              update: {
                ...status_defaults,
                status: 1,
                statusText: 'Lot added successfully',
              }
            }
          }
        },
        lot_order: [ ...state.lot_order, action.foundation.id ],
      }
    case DATA_LOT.success:
      return {
        ...state,
        lot: {
          ...state.lot,
          [action.foundation.id]: {
            ...state.lot[action.foundation.id],
            originalData: action.payload.data,
            data: action.payload.data,
            meta: {
              ...status_defaults,
              ...action.meta,
            }
          }
        },
        selectedTab: action.foundation.id,
      }
    case DATA_LOT.fail:
      return {
        ...state,
        lot: {
          ...state.lot,
          [action.foundation.id]: {
            ...state.lot[action.foundation.id],
            meta: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
    case DATA_LOT.paste:
    {
      return {
        ...state,
        lot: {
          ...state.lot,
          [action.cargo.key]: {
            ...state.lot[action.cargo.key],
            data: {
              ...state.lot[action.cargo.key].data,
              ...action.cargo.activeclips,
            }
          }
        }
      }
    }
    case DATA_LOT.close:
    {
      const { [action.id]: _, ...remaining } = state.lot
      return {
        ...state,
        lot: remaining,
        lot_order: state.lot_order.filter(item => item !== action.id),
        selectedTab: state.selectedTab === action.id ? "list" : state.selectedTab,
      }
    }
    case ADD_LOT.request:
      return {
        ...state,
        lot: {
          ...state.lot,
          new: {
            ...state.lot.new,
            update: {
              ...status_defaults,
              processing: true,
            },
            validation: validation_defaults,
          }
        }
      }
    case ADD_LOT.success:
      return {
        ...state,
        lot: {
          ...state.lot,
          new: {
            ...state.lot.new,
            update: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
    case ADD_LOT.fail:
      return {
        ...state,
        lot: {
          ...state.lot,
          new: {
            ...state.lot.new,
            update: {
              ...status_defaults,
              ...action.meta,
            },
            validation: action.validation ? action.validation : validation_defaults,
          }
        }
      }
    case ADD_LOT.reset:
      return {
        ...state,
        lot: {
          ...state.lot,
          new: lot_detail_defaults,
        }
      }
    case UPDATE_LOT.request:
      return {
        ...state,
        lot: {
          ...state.lot,
          [action.foundation.id]: {
            ...state.lot[action.foundation.id],
            update: {
              ...status_defaults,
              processing: true,
            },
            validation: validation_defaults,
          }
        }
      }
    case UPDATE_LOT.success:
      return {
        ...state,
        lot: {
          ...state.lot,
          [action.foundation.id]: {
            ...state.lot[action.foundation.id],
            originalData: state.lot[action.foundation.id].data,
            update: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
    case UPDATE_LOT.fail:
      return {
        ...state,
        lot: {
          ...state.lot,
          [action.foundation.id]: {
            ...state.lot[action.foundation.id],
            update: {
              ...status_defaults,
              ...action.meta,
            },
            validation: action.validation,
          }
        }
      }
    case CONSIGNOR_SERIAL_LOOKUP.request:
      return {
        ...state,
        lot: {
          ...state.lot,
          [action.foundation.key]: {
            ...state.lot[action.foundation.key],
            serial: {
              ...status_defaults,
              processing: true,
            }
          }
        }
      }
    case CONSIGNOR_SERIAL_LOOKUP.success:
      return {
        ...state,
        lot: {
          ...state.lot,
          [action.foundation.key]: {
            ...state.lot[action.foundation.key],
            data: {
              ...state.lot[action.foundation.key].data,
              consignor_code: action.payload.data.consignor_code,
              consignor_serial: action.payload.data.consignor_serial + 1,
            },
            serial: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
    case CONSIGNOR_SERIAL_LOOKUP.fail:
      return {
        ...state,
        lot: {
          ...state.lot,
          [action.foundation.key]: {
            ...state.lot[action.foundation.key],
            serial: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
      case CONSIGNOR_SERIAL_LOOKUP.reset:
        return {
          ...state,
          lot: {
            ...state.lot,
            [action.foundation.key]: {
              ...state.lot[action.foundation.key],
              data: {
                ...state.lot[action.foundation.key].data,
                consignor_code: null,
                consignor_serial: null,
              },
              serial: status_defaults,
            }
          }
        }
    case SELECT_TAB:
      return {
        ...state,
        selectedTab: action.key,
      }
    case FIELD_UPDATE:
      return {
        ...state,
        lot: {
          ...state.lot,
          [action.key]: {
            ...state.lot[action.key],
            data: {
              ...state.lot[action.key].data,
              [action.field]: action.value,
            },
          }
        }
      }
    case LOCATION_SAVE:
      return {
        ...state,
        list: {
          ...state.list,
          location: action.location,
        }
      }
    default:
      return state
  }
}

export function fieldUpdate(field, value, key='new', options={}) {
  return (dispatch, getState) => {
    dispatch({ type: FIELD_UPDATE, field, value, key })
    // handle post-change hooks here
    // typeahead components only have proper value in onChange, not onBlur (leaving)
    // ZZZ - can probably streamline this once we replace outdated typeahead component by moving all post-change hooks to onLeave
    switch (field) {
      case 'consignor_id': {
        if (value !== '') {
          dispatch(
            get({
              types: CONSIGNOR_SERIAL_LOOKUP,
              endpoint: '/api/admin/consignor-serial/' + value,
              foundation: { key },
            })
          )
        } else {
          dispatch({ type: CONSIGNOR_SERIAL_LOOKUP.reset, foundation: { key } })
        }
        break
      }
      case 'desc_region': {
        //update subject field and set implicit category fields
        const s = getState()
        const data = s.admin.lots.lot[key].data
        let fdisplay, cat1, cat2
        cat1 = cat2 = null;
        s.admin.lookupData.data.item_regions.forEach(function(m) {
          if(m.code === value) {
            fdisplay = m.description
            cat1 = m.category_id_1
            cat2 = m.category_id_2
          }
        })
        dispatch({ type: FIELD_UPDATE, field: 'desc_category_id_1', value: cat1, key })
        dispatch({ type: FIELD_UPDATE, field: 'desc_category_id_2', value: cat2, key })
        if (!data.desc_subject) {
          dispatch({ type: FIELD_UPDATE, field: 'desc_subject', value: fdisplay, key })
        }
        break
      }
    }
  }
}

export function fieldLeave(field, value, key='new', options={}) {
  return (dispatch, getState) => {
    // handle post-change hooks here for non-typeahead components
    switch (field) {
      case 'price_initialreserve':
      {
        const data = getState().admin.lots.lot[key].data
        if (!data.price_reserve) {
          dispatch({ type: FIELD_UPDATE, field: 'price_reserve', value, key })
        }
        break
      }
    }
  }
}

export function selectTab(key) {
  return {
    type: SELECT_TAB,
    key: key,
  }
}

export function closeLot(id) {
  return {
    type: DATA_LOT.close,
    id: id,
  }
}

// ZZZ - need to handle any side-effects, like region updating tagged-in field
export function pasteFromClipboard(initialKey) {
  const key = initialKey ? initialKey : 'new'
  return (dispatch, getState) => {
    const clipboard = getState().admin.clipboard

    const activeclips = clipboard.order.reduce((o, item) => {
      if (clipboard.info[item].active) {
        o[item] = clipboard.data[item]
      }
      return o
    }, {})

    return dispatch({
      type: DATA_LOT.paste,
      cargo: { key, activeclips },
    })
  }
}

export function refreshSerial(initialKey) {
  const key = initialKey ? initialKey : 'new'
  return (dispatch, getState) => {
    const s = getState().admin.lots.lot[key]
    return dispatch(
      get({
        types: CONSIGNOR_SERIAL_LOOKUP,
        endpoint: '/api/admin/consignor-serial/' + s.data.consignor_id,
        foundation: { key },
      })
    )
  }
}

export function getData(location) {
  return (dispatch, getState) => {
    const state = getState()
    const { zops={}, ...remainingQuery } = location.query

    dispatch({ type: LOCATION_SAVE, location: location })

    const querydata = processQuery(remainingQuery, { whitelist: [], pageSize: state.admin.lots.list.info.pageSize })
    const endpoint = '/api/admin/lots' + querydata

    const o = qs.parse(zops)

    return dispatch(
      get({
        types: DATA,
        endpoint: endpoint,
        config: {
          method: 'post',
          body: o,
        },
      })
    )
  }
}

export function getLot(id, { newborn=false }={}) {
  return dispatch => {
    return dispatch(
      get({
        types: DATA_LOT,
        endpoint: '/api/admin/lots/' + id,
        foundation: { id, newborn },
      })
    )
  }
}

export function saveLot(id, { isNew=false }={}) {
  return (dispatch, getState) => {
    const state = getState()

    const data = isNew ? state.admin.lots.lot.new.data : state.admin.lots.lot[id].data
    const endpoint = isNew ? '/api/admin/lots/add' : '/api/admin/lots/' + id
    const types = isNew ? ADD_LOT : UPDATE_LOT

    const errors_present = verify(meta_fields_lot, data)

    if (errors_present) {
      return dispatch(
        buildError(types.fail, { errors_present: errors_present, foundation: { id: id } })
      )
    } else {
      return dispatch(
        get({
          types: types,
          endpoint: endpoint,
          config: {
            method: 'post',
            body: data,
          },
          foundation: { id: id },
          ...isNew && { postSuccess: (json) => {
            dispatch(getLot(json.payload.id, { newborn: true }))
            dispatch({ type: ADD_LOT.reset })
            dispatch(getData(state.admin.lots.list.location))
          } },
          ...!isNew && { postSuccess: (json) => {
            dispatch(getData(state.admin.lots.list.location))
          } }
        })
      )
    }
  }
}
