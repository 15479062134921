import PropTypes from 'prop-types'

Info.propTypes = {
  children: PropTypes.node,
}

function Info({ children }) {
  return children
}

export default Info
