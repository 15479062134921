import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { company_address_line1, company_address_line2 } from 'lib/constants'

function content(props) {
  return (
    <div className="general-table-defaults">
      <Helmet>
        <title>About Us</title>
        <link rel="canonical" href={props.location.pathname} />
      </Helmet>
      <div className="titlebar heading heading-v1 text-center">
        <h2>A Little About Us...</h2>
      </div>

      <div>
        <p>Old World Auctions (OWA) is owned and operated by Eliane and Jon Dotson of Richmond, Virginia. The company began operations in 1978 and through the years has grown tremendously from a small mail auction to one of the largest specialist auctions in the United States. The Dotsons purchased the company from Curt and Marti Griggs in 2011 and have worked hard to build upon the excellent reputation built by the Griggs.</p>
        <img className="element-left-wrap" src="/static/img/about-us_2020-11-03.jpg" />
        <p>Prior to running OWA, the Dotsons received their MBAs from the Darden School of Business at the University of Virginia and established careers in the corporate world in Fortune 500 companies. After realizing that the corporate world was not for them, they sought business opportunities that were more meaningful and rewarding. The Dotsons learned about OWA through Eliane&apos;s father, Duncan Payne, an avid map collector for over 50 years. Since taking over the business, Eliane and Jon have catalogued over 30,000 maps, books, globes, and prints. They love coming to work each day and learning something new.</p>
        <p>Old World Auctions specializes in genuine antique maps, atlases and decorative graphics originating between the 14th and early 20th centuries. Our auction operates on a consignment basis, with all material personally inspected by the Dotsons prior to going into auction.  Each item is carefully researched and described and we guarantee the authenticity and condition of each item with a 100% money-back guarantee. <b>The auction operates entirely online without a live floor auction.</b> All bidding is received either online, or by email or telephone.</p>
        <p>We pride ourselves on excellent, personal service to our customers. We are committed to making the auction market accessible to an ever-growing group of buyers and sellers. Please feel free to contact us with any questions you may have. While we are an internet business, we love opportunities to get to know our customers, whether talking with them by phone or welcoming them in person at our office.</p>
      </div>

      <p className="text-center">
        <b>Eliane &amp; Jon Dotson</b><br />
        <b>Old World Auctions</b><br />
        {company_address_line1}<br />
        {company_address_line2}<br />
        Tel: (804) 290-8090<br />
        Email: info(at)oldworldauctions.com<br />
      </p>
    </div>
  )
}

export default content
