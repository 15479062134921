import React from 'react'
import PropTypes from 'prop-types'

import DataListContainer from './DataListContainer'
import DataListBody from './DataListBody'

DataList.propTypes = {
  children: PropTypes.element.isRequired,
  pageSize: PropTypes.number,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  dataIndex: PropTypes.array,
  totalItemCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  sortKey: PropTypes.string,
  datakey: PropTypes.string,
  auxDataKey: PropTypes.string,
  showCount: PropTypes.bool,
  showHeader: PropTypes.bool,
  headerClass: PropTypes.string,
  headerColumnVisibleKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  showTopPagingControl: PropTypes.bool,
  showBottomPagingControl: PropTypes.bool,
  pagingControlSize: PropTypes.string,
  useTable: PropTypes.bool,
  tableClass: PropTypes.string,
  tableLayoutFixed: PropTypes.bool,
  tableStyle: PropTypes.object,
  metadata: PropTypes.object,
  auxData1: PropTypes.object,
  auxData2: PropTypes.object,
  clickTargetFunction: PropTypes.func,
  clickTargetTemplate: PropTypes.string,
  clickTargetParam1: PropTypes.string,
  clickTargetParam2: PropTypes.string,
  anchorTemplate: PropTypes.string,
  anchorParam1: PropTypes.string,
  anchorParam2: PropTypes.string,
  removeFunction: PropTypes.func,
  updateFunction: PropTypes.func,
  allowUpdateFunction: PropTypes.func,
  inputHandleUpdate: PropTypes.func,
  inputHandleLeave: PropTypes.func,
  rowStyleFunction: PropTypes.func,
  rowStyleDataKey: PropTypes.string,
  clickTargetQueryParams: PropTypes.object,
  errorCondition: PropTypes.bool,
  errorMessage: PropTypes.string,
  emptyMessage: PropTypes.string,
  itemDisplayName: PropTypes.string,
  itemDisplayNamePlural: PropTypes.string,
}

DataList.defaultProps = {
  location: {},
  itemDisplayName: 'item',
  tableStyle: {},
  metadata: {},
}

function DataList({ children, isLoading, errorCondition, errorMessage, emptyMessage, showHeader, headerClass, headerColumnVisibleKey, showTopPagingControl, showBottomPagingControl, pagingControlSize, showCount, useTable, tableClass, tableLayoutFixed, tableStyle, pageSize, totalItemCount, metadata, data, location, sortKey, dataIndex, datakey, auxDataKey, auxData1, auxData2, rowStyleFunction, rowStyleDataKey, clickTargetTemplate, clickTargetFunction, clickTargetParam1, clickTargetParam2, clickTargetQueryParams, anchorTemplate, anchorParam1, anchorParam2, inputHandleUpdate, inputHandleLeave, removeFunction, updateFunction, allowUpdateFunction, itemDisplayName, itemDisplayNamePlural, ...other }) {
  const rowData = <DataListBody
    data={data}
    dataIndex={dataIndex}
    datakey={datakey}
    auxDataKey={auxDataKey}
    metadata={metadata}
    useTable={useTable}
    auxData1={auxData1}
    auxData2={auxData2}
    rowStyleFunction={rowStyleFunction}
    rowStyleDataKey={rowStyleDataKey}
    clickTargetTemplate={clickTargetTemplate}
    clickTargetFunction={clickTargetFunction}
    clickTargetQueryParams={clickTargetQueryParams}
    clickTargetParam1={clickTargetParam1}
    clickTargetParam2={clickTargetParam2}
    anchorTemplate={anchorTemplate}
    anchorParam1={anchorParam1}
    anchorParam2={anchorParam2}
    inputHandleUpdate={inputHandleUpdate}
    inputHandleLeave={inputHandleLeave}
    removeFunction={removeFunction}
    updateFunction={updateFunction}
    allowUpdateFunction={allowUpdateFunction}
    headerColumnVisibleKey={headerColumnVisibleKey}
    {...other}
  >
    {children}
  </DataListBody>

  return <DataListContainer
    isLoading={isLoading}
    errorCondition={errorCondition}
    errorMessage={errorMessage}
    emptyMessage={emptyMessage}
    showHeader={showHeader}
    headerClass={headerClass}
    headerColumnVisibleKey={headerColumnVisibleKey}
    showTopPagingControl={showTopPagingControl}
    showBottomPagingControl={showBottomPagingControl}
    pagingControlSize={pagingControlSize}
    showCount={showCount}
    useTable={useTable}
    tableClass={tableClass}
    tableLayoutFixed={tableLayoutFixed}
    tableStyle={tableStyle}
    pageSize={pageSize}
    totalItemCount={totalItemCount}
    metadata={metadata}
    location={location}
    sortKey={sortKey}
    itemDisplayName={itemDisplayName}
    itemDisplayNamePlural={itemDisplayNamePlural}
  >
    {rowData}
  </DataListContainer>
}

export default DataList