import { processQuery, get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

const base = 'owa/lots/'

const DATA = defineAction(base, 'DATA', 'async')

const LOCATION_SAVE = base.concat('LOCATION_SAVE')

const initialState = {
  content: {
    data: [],
    info: {
      location: {},
      params: {},
      pageSize: 50,
      count: 0,
    },
    meta: status_defaults,
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        content: {
          ...state.content,
          meta: {
            ...status_defaults,
            initialQueryTimestamp: state.content.meta.initialQueryTimestamp,
            processing: true,
          }
        }
      }
    case DATA.success:
      return {
        ...state,
        content: {
          ...state.content,
          data: action.payload.data,
          info: {
            ...state.content.info,
            count: action.payload.count,
          },
          meta: {
            ...status_defaults,
            status: 1,
            initialQueryTimestamp: action.payload.initialQueryTimestamp,
          }
        }
      }
    case DATA.fail:
      return {
        ...state,
        content: {
          ...state.content,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case LOCATION_SAVE:
    {
      return {
        ...state,
        content: {
          ...state.content,
          info: {
            ...state.content.info,
            location: action.location,
            params: action.params,
          }
        }
      }
    }
    default:
      return state
  }
}

export function getLots(location, params) {
  return (dispatch, getState) => {
    const state = getState()
    const query = location.query
    const clearInitialQueryTimestamp = location.state && location.state.forceRefresh ? true : false
    const catalog_mode = location.pathname.includes('/archive') ? 'archive' : 'current'
    const category = params.category ? params.category : null

    dispatch({ type: LOCATION_SAVE, location: location, params: params })

    // add category data to query so we can pass all parameters to api via get request
    const combined_query = { ...query, catalog_mode, ...category && {category} }

    const querydata = processQuery(combined_query, { whitelist: ['catalog_mode', 'category', 'featured', 'noreserve', 'open', 'unsold', 'universal_search', 'auction_id', 'auction_year_start', 'auction_year_end', 'desc_title', 'desc_subject', 'creator', 'desc_text', 'desc_period', 'price_estimate_low_min', 'price_estimate_low_max', 'desc_condition'], pageSize: state.lots.content.info.pageSize, initialQueryTimestamp: clearInitialQueryTimestamp ? 0 : state.lots.content.meta.initialQueryTimestamp })
    console.log('getLots-querydata', querydata)
    const endpoint = '/api/public/catalog/lots'

    return dispatch(
      get({
        types: DATA,
        endpoint: endpoint + querydata,
      })
    )
  }
}
