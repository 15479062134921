import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Input Groups with multiple input elements together with no margins between
class InputGroupMultiple extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    inputGroupStyle: PropTypes.object,
    addonBefore: PropTypes.string,
    addonBeforeStyle: PropTypes.object,
    size: PropTypes.string,
    noMargins: PropTypes.bool,
  }

  static defaultProps = {
    inputGroupStyle: { display: 'flex' },
  }

  render() {
    const { className, inputGroupStyle, addonBefore, addonBeforeStyle, size, noMargins, children } = this.props;
    const fg_class = classNames({
      'form-group': !noMargins,
      'form-group-sm': size === 'small',
      'form-group-md': size === 'medium',
      'form-group-lg': size === 'large',
    }, className);
    const addonBeforeBaseStyle = { display: 'flex', width: 'auto', alignItems: 'center' }

    return (
      <div className={fg_class}>
        <span className='input-group' style={inputGroupStyle}>
          { addonBefore ? <span className='input-group-addon' style={{...addonBeforeBaseStyle, ...addonBeforeStyle}}>{ addonBefore }</span> : null }
          { children }
        </span>
      </div>
    );
  }
}

export default InputGroupMultiple