import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import {useCombobox} from 'downshift'
import classNames from 'classnames'
import reactStringReplace from 'react-string-replace'

import { escapeRegExp } from 'lib/utility'

// Modes of Operation (selection-bound or freeform)

// selection-bound
// requires valueField and will only update external value when a selection is chosen
// requires options to be an array of objects [{<valueField>:'value', <textField>:'displayedValue'},...]

// leaving field without a selection will:
//  1) if a suggested hint is shown, this becomes the selection
//  2) if no hint is shown, the internal state is reset to initial values
// set textField to valueField if textField is null

// freeform
// if textField is not null, requires options to be an array of objects [{<textField>:'displayedValue'},...], otherwise requires options to be an array of strings
// updates external value on input change (to actual input value) and doesn't require a selection based on suggestion
// hint not used (as it could be confusing and we don't set to hint on tab out)


// STILL TODO - all modes
// 1) home and end keys should go to beginning and end of input rather than menu
// 2) change position of menu (dropdown) based on viewport (floating-ui?)

// STILL TODO - selection bound mode
// 1) if no selection is made, auto-choose hinted value on enter or tab (if no hint, then enter does nothing and tab resets to initial value then leaves field

// STILL TODO - freeform mode
// 1) first needs basic implementation before we can come up with todo list

SmartSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  label: PropTypes.string,
  displayField: PropTypes.string,
  valueField: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

SmartSelect.defaultProps = {
  name: null,
  value: null,
  disabled: false,
}

export default function SmartSelect({ name, value, options, displayField, valueField, placeholder, disabled, onChange}) {
  const getItemFromValue = useCallback((value) => {
    const result = options.find(({ [valueField]: v }) => v === value)
    return result || null
  },[options, valueField])

  const [items, setItems] = useState([])
  const [glowValue, setGlowValue] = useState('')
  const [closestMatch, setClosestMatch] = useState('')

  const filterOptions = useCallback((inputValue) => {
    const lowerCasedInputValue = inputValue.toLowerCase()
    return options.filter(function (option) {
      if (this.count < 15 && (!inputValue || option[displayField].toLowerCase().includes(lowerCasedInputValue))) {
        this.count++
        return true
      }
      return false
    }, {count: 0})
  },[options, displayField])

  const findClosestOption = useCallback((inputValue) => {
    if (inputValue.length < 3) return { hintValue: '', item: null }
    const lowerCasedInputValue = inputValue.toLowerCase()
    const filtered = options.find(({ [displayField]: t }) => t.toLowerCase().startsWith(lowerCasedInputValue))
    return {
      hintValue: inputValue + (filtered ? filtered[displayField].substring(inputValue.length) : ''),
      item: filtered,
    }
  },[options, displayField])

  const stateReducer = useCallback((state, actionAndChanges) => {
    const {type, changes} = actionAndChanges
    // console.log('sr:', type, changes)
    switch (type) {
      case useCombobox.stateChangeTypes.InputClick:
      case useCombobox.stateChangeTypes.InputChange:
        return {
          ...changes,
          isOpen: true,
          ...(!changes.inputValue.length && { selectedItem: null }),
        }
      case useCombobox.stateChangeTypes.InputKeyDownArrowDown:
      case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
        if (!changes.inputValue) return changes
        return {
          ...changes,
          inputValue: items[changes.highlightedIndex][displayField],
        }
      case useCombobox.stateChangeTypes.InputKeyDownEscape:
        return {
          ...changes,
          inputValue: glowValue,
        }
      // case useCombobox.stateChangeTypes.ItemClick:
      // case useCombobox.stateChangeTypes.InputKeyDownEnter:
      case useCombobox.stateChangeTypes.InputBlur:
        return {
          ...changes,
          ...(valueField && (changes.selectedItem ? { inputValue: changes.selectedItem[displayField].toString() } : { inputValue: ''})),
        }
      default:
        return changes
    }
  }, [items, glowValue, displayField, valueField])
  const {
    inputValue,
    selectedItem,
    isOpen,
    highlightedIndex,
    getInputProps,
    getMenuProps,
    getItemProps,
    setInputValue,
    selectItem,
    openMenu,
  } = useCombobox({
    onInputValueChange(changes) {
      const { type, inputValue, highlightedIndex } = changes
      // console.log('onInputValueChange:', changes)
      if (
        type !== useCombobox.stateChangeTypes.InputKeyDownArrowDown &&
        type !== useCombobox.stateChangeTypes.InputKeyDownArrowUp
      ) {
        setGlowValue(inputValue)
        setItems(filterOptions(inputValue))
        valueField && setClosestMatch(findClosestOption(inputValue))
      } else {
        valueField && setClosestMatch(items[highlightedIndex])
      }
      if (!valueField) {
        onChange({
          target: {
            name,
            // we should create function to get custom attributes that are passed to SmartSelect, but for now just ignore these
            getAttribute: () => null,
            value: inputValue,
          }
        })
      }
    },
    items,
    itemToString(item) {
      return item ? item[displayField] : ''
    },
    stateReducer,
    onSelectedItemChange: (changes) => {
      // console.log('onSelectedItemChange:', changes)
      changes.selectedItem && setGlowValue(changes.selectedItem[displayField])
      changes.selectedItem && setItems(filterOptions(changes.selectedItem[displayField]))
      if (valueField) {
        onChange({
          target: {
            name,
            // we should create function to get custom attributes that are passed to SmartSelect, but for now just ignore these
            getAttribute: () => null,
            value: changes.selectedItem ? changes.selectedItem[valueField] : '',
          }
        })
      }
    },
  })

  useEffect(() => {
    valueField ? selectItem(getItemFromValue(value)) : setInputValue(value.toString())
  }, [getItemFromValue, value, valueField, selectItem, setInputValue])

  const ss_diagnostics = <div className='ss-diagnostics'>
    <table>
      <tbody>
        <tr>
          <td colSpan={2} className='ss-diagnostic-header'>
            internal
          </td>
        </tr>
        <tr>
          <td>inputValue:</td>
          <td>{inputValue}</td>
        </tr>
        <tr>
          <td>selectedItem:</td>
          <td>{JSON.stringify(selectedItem)}</td>
        </tr>
        <tr>
          <td>highlightedIndex:</td>
          <td>{highlightedIndex}</td>
        </tr>
        <tr>
          <td>isOpen:</td>
          <td>{isOpen ? 'true' : 'false'}</td>
        </tr>
        <tr>
          <td colSpan={2} className='ss-diagnostic-header'>
            external: {value}
          </td>
        </tr>
        <tr>
          <td>glowValue:</td>
          <td>{glowValue}</td>
        </tr>
        <tr>
          <td>closestMatch:</td>
          <td>{JSON.stringify(closestMatch)}</td>
        </tr>
        <tr>
          <td>items.length:</td>
          <td>{items.length}</td>
        </tr>
        </tbody>
    </table>
  </div>

  return <div className='smartselect'>
    <input className='form-control ss-hint' readOnly placeholder={closestMatch.hintValue} autoComplete="off" spellCheck="false" tabIndex="-1" aria-hidden="true" dir="ltr" />
    <input className='form-control ss-input' {...getInputProps({
      disabled,
      placeholder,
      onFocus: () => { openMenu() },
      onKeyDown: event => {
        if (event.key === 'Home' || event.key === 'End') {
          // Prevent Downshift's default home/end key behavior
          event.nativeEvent.preventDownshiftDefault = true
        }
      },
    })} />
    <ul
      className={`ss-menu ${
        !(isOpen && inputValue.length && items.length) && 'hidden'
      }`}
      {...getMenuProps()}
    >
      {isOpen &&
        items.map((item, index) => {
          const highlightedMatch = reactStringReplace(item[displayField], new RegExp(`(${escapeRegExp(glowValue)})`, "gi"), (match, i) => (
            <span key={i} className='ss-highlighted-match-chars'>{match}</span>
          ))
          return <li
            className={classNames('ss-result', { 'highlighted': highlightedIndex === index })}
            key={item.id}
            {...getItemProps({item, index})}
          >
            {highlightedMatch}
          </li>
        })
      }
    </ul>
    {/* {ss_diagnostics} */}
  </div>
}
