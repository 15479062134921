import React from 'react'
import PropTypes from 'prop-types'

HiresDownloader.propTypes = {
  auctionNumber: PropTypes.string.isRequired,
  lotNumber: PropTypes.string.isRequired,
  catalog_mode: PropTypes.string.isRequired,
  numImages: PropTypes.number.isRequired,
  closeAction: PropTypes.func,
  unavailable: PropTypes.bool,
}

HiresDownloader.defaultProps = {
  unavailable: false,
}

function HiresDownloader({ auctionNumber, lotNumber, catalog_mode, numImages, closeAction, unavailable }) {

  const localBasePath = __DYNAMIC_IMAGES_RELATIVE_BASE_URL__ + 'auctions/'
  const basepath = catalog_mode === 'current' ? localBasePath : __B2_IMAGE_BASE_URL__
  const lowres_path = localBasePath + auctionNumber + '/lots/'
  const hires_path = basepath + auctionNumber + '/lots-hires/'
  const lot_id = auctionNumber + "-" + lotNumber.padStart(3, '0')

  const imagelist = Array.apply(0, Array(numImages)).map((a, i) => {
    const suffix = String.fromCharCode(i+65)
    const imageFileName = lot_id + suffix + '.jpg'
    return <div key={imageFileName}>
      <div className="hires-preview-image-container">
        <a download={imageFileName} href={hires_path + imageFileName} target="_blank" rel="noopener noreferrer"><img src={lowres_path + imageFileName} /></a>
      </div>
    </div>
  })

  let error_message = unavailable ?
    <div id="errorbox" className="hiresdownloader-error">
      <div className="text-center">
        <h4>Hi-resolution imagery is currently not available for this lot, but will be coming soon</h4>
      </div>
    </div>
  : null

  return <div className="hiresdownloader-outer-container">
    <div className="hiresdownloader-close-container">
      <span className="hiresdownloader-close" onClick={closeAction}>
        <span className='fa fa-fw fa-times' style={{ fontSize: '24px', verticalAlign: '-1px' }} />
        <span style={{ fontSize: '20px', lineHeight: '20px' }}>Close</span>
      </span>
    </div>
    <div className="hiresdownloader-inner-container">
      <h5 className="text-center margin-bottom-0 margin-left-5 margin-right-5" style={{ borderBottom: '1px solid #ccc'}}>Right click on an image and choose &quot;save link as&quot; to download the high resolution version.  For a better viewing experience use the zoom viewer on the lot detail page.</h5>
      <div className="hiresdownloader-content">
        {error_message}
        <div className="hiresdownloader-image-list flex-generic-container child-gutter-10">
          {imagelist}
        </div>
      </div>
    </div>
  </div>
}

export default HiresDownloader
