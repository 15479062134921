import { useState, useEffect } from 'react'

import { requestInterval } from 'lib/timers'

const useCountSinceDataLoaded = (meta) => {
  const [count, setCount] = useState(0)
  useEffect(() => {
    if (meta.status === 1) {
      setCount(0)
    }
    const interval = new requestInterval(() => setCount(c => c + 1), 1000)
    return () => {
      interval.clear()
    }
  }, [meta.status, meta.timestamp])

  return count
}

export default useCountSinceDataLoaded