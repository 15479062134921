import { get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

import { lotRefresh } from './resolution'

const base = 'owa/admin/lot_actions/'

const DATA_BIDS = defineAction(base, 'DATA_BIDS', 'async')
const DATA_USER_LOOKUP = defineAction(base, 'DATA_USER_LOOKUP', 'async')
const BID = defineAction(base, 'BID', 'async', { customActions: ['register', 'needs_review'] })
const BID_DELETE = defineAction(base, 'BID_DELETE', 'async')
const ADVANCED_ADJUSTMENT = defineAction(base, 'ADVANCED_ADJUSTMENT', 'async')

const FULL_RESET = base.concat('FULL_RESET')
const STATUS_RESET = base.concat('STATUS_RESET')
const INPUT_CHANGE = base.concat('INPUT_CHANGE')

const info_defaults = {
  pageSize: 20,
  count: 0,
}

const lotBids_defaults = {
  data: {},
  order: [],
  info: info_defaults,
  meta: status_defaults,
}

const potentialBid_defaults = {
  bid_source: 0,
  bid_type: 0,
  original_bid_type: -1,
  resolved_bid: 0,
  lot_id: null,
  lot_number: 0,
  lot_title: null,
  bid_user_id: null,
  visible_high_bid: null,
  original_max_bid: null,
  sanitized_max_bid: null,
  final_max_bid: null,
  new_high_bid: null,
  original_high_bid: null,
  is_reserve_met: false,
  is_high_bidder: false,
  bidresult: 0,
  proper: false,
  valid: false,
  unconstrained: false,
  ignore_reserve: false,
  message_info: [],
  meta: status_defaults,
}

const userLookup_defaults = {
  data: {},
  meta: status_defaults,
}

const fields_defaults = {
  admin_bid_user_id: null,
  admin_bid_value: null,
  new_price_reserve: null,
}

const initialState = {
  lotBids: lotBids_defaults,
  potentialBid: potentialBid_defaults,
  userLookup: userLookup_defaults,
  advancedAdjustment: status_defaults,
  bidDelete: status_defaults,
  fields: fields_defaults,
}

function mapBids(action) {
  let _order = []

  const _lotBids = action.payload.data.reduce((o, item, i) => {
    o[item.id] = item
    _order[i] = item.id
    return o
  }, {})

  return {
    data: _lotBids,
    order: _order,
    info: {
      ...info_defaults,
      count: action.payload.count,
    },
    meta: {
      ...status_defaults,
      ...action.meta,
    }
  }
}

function updatePotentialBid(previous, action) {
  return {
    ...previous,
    ...action.payload.ebid.values,
    message_info: action.payload.ebid.message_info,
    bidresult: action.payload.bidresult,
    proper: action.payload.ebid.proper,
    valid: action.payload.ebid.valid,
    unconstrained: action.payload.ebid.unconstrained,
    ignore_reserve: action.payload.ebid.ignore_reserve,
    meta: {
      ...status_defaults,
      ...action.meta,
    }
  }
}

function initPotentialBid(fields, action) {
  const lot = action.cargo.lot
  return {
    ...potentialBid_defaults,
    bid_type: 0,
    lot_id: lot.id,
    lot_number: lot.lot_number,
    lot_title: lot.desc_title,
    visible_high_bid: lot.price_highbid,
    resolved_bid: fields.admin_bid_value,
    bid_user_id: fields.admin_bid_user_id,
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FULL_RESET:
      return initialState
    case STATUS_RESET:
      return {
        ...state,
        advancedAdjustment: status_defaults,
        bidDelete: status_defaults,
      }
    case DATA_BIDS.reset:
      return {
        ...state,
        lotBids: lotBids_defaults,
      }
    case DATA_BIDS.request:
      return {
        ...state,
        lotBids: {
          ...state.lotBids,
          meta: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case DATA_BIDS.success:
      return {
        ...state,
        lotBids: mapBids(action),
      }
    case DATA_BIDS.fail:
      return {
        ...state,
        lotBids: {
          ...state.lotBids,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case ADVANCED_ADJUSTMENT.request:
      return {
        ...state,
        advancedAdjustment: {
          ...status_defaults,
          processing: true,
        }
      }
    case ADVANCED_ADJUSTMENT.success:
      return {
        ...state,
        advancedAdjustment: {
          ...status_defaults,
          ...action.meta,
        }
      }
    case ADVANCED_ADJUSTMENT.fail:
      return {
        ...state,
        advancedAdjustment: {
          ...status_defaults,
          ...action.meta,
        }
      }
    case BID.register:
      return {
        ...state,
        potentialBid: initPotentialBid(state.fields, action),
      }
    case BID.request:
      return {
        ...state,
        potentialBid: {
          ...state.potentialBid,
          meta: {
            ...status_defaults,
            processing: true,
          }
        },
      }
    case BID.needs_review:
      return {
        ...state,
        potentialBid: updatePotentialBid(state.potentialBid, action),
      }
    case BID.success:
      return {
        ...state,
        potentialBid: updatePotentialBid(state.potentialBid, action),
        fields: {
          ...state.fields,
          admin_bid_value: null,
        }
      }
    case BID.fail:
      return {
        ...state,
        potentialBid: {
          ...state.potentialBid,
          meta: {
            processing: false,
            ...action.meta,
          }
        }
      }
    case BID_DELETE.request:
      return {
        ...state,
        bidDelete: {
          ...status_defaults,
          processing: true,
        }
      }
    case BID_DELETE.success:
      return {
        ...state,
        bidDelete: {
          ...status_defaults,
          ...action.meta,
        }
      }
    case BID_DELETE.fail:
      return {
        ...state,
        bidDelete: {
          ...status_defaults,
          ...action.meta,
        }
      }
    case DATA_USER_LOOKUP.request:
      return {
        ...state,
        userLookup: {
          data: {},
          meta: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case DATA_USER_LOOKUP.success:
      return {
        ...state,
        userLookup: {
          data: action.payload.data,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case DATA_USER_LOOKUP.fail:
      return {
        ...state,
        userLookup: {
          ...state.userLookup,
          meta: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case INPUT_CHANGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.name]: action.input,
        }
      }
    default:
      return state
  }
}

export function fullReset() {
  return {
    type: FULL_RESET,
  }
}

export function statusReset() {
  return {
    type: STATUS_RESET,
  }
}

export function inputChange(name, value, options={}) {
  return (dispatch) => {
    dispatch({ type: INPUT_CHANGE, name: name, input: value })
    switch (name) {
      case 'admin_bid_user_id':
        dispatch(
          get({
            types: DATA_USER_LOOKUP,
            endpoint: '/api/admin/user-lookup/' + value,
          })
        )
        break
    }
  }
}

export function bidRegister(lot) {
  return {
    type: BID.register,
    cargo: {
      lot: lot,
    }
  }
}

export function bidConfirm({ ignore_constraints=false, ignore_reserve=false }={}) {
  console.log('**bidConfirm**')
  return (dispatch, getState) => {
    const state = getState()
    const pb = state.admin.lot_actions.potentialBid
    const endpoint = '/api/admin/resolution/admin-bid/' + pb.lot_id
    const postdata = { bid_type: pb.bid_type, bid_value: pb.resolved_bid, bid_user_id: pb.bid_user_id, visible_high_bid: pb.visible_high_bid, ignore_constraints: ignore_constraints, ignore_reserve: ignore_reserve }

    return dispatch(
      get({
        types: BID,
        endpoint: endpoint,
        config: {
          method: 'post',
          body: postdata,
        },
        onSuccess: (response) => {
          if (response.payload.bidresult === 1) {
            // success
            return dispatch({ type: BID.success, payload: response.payload })
          } else {
            // needs review
            return dispatch({ type: BID.needs_review, payload: response.payload })
          }
        },
        postSuccess: () => {
          dispatch(lotRefresh(pb.lot_id))
          dispatch(getLotBids(pb.lot_id))
        }
      })
    )
  }
}

export function advancedAdjustment(adjustmentType, lot) {
  console.log('**advancedAdjustment:', adjustmentType, lot)
  return (dispatch, getState) => {
    const s = getState().admin.lot_actions

    let endpoint
    let postdata

    if (adjustmentType === 2) {
      // set new reserve price
      endpoint = '/api/admin/resolution/admin-adjust-reserve-price/' + lot.id
      postdata = { new_price_reserve: s.fields.new_price_reserve }
    } else {
      // invalid adjustment type
      return dispatch({ type: ADVANCED_ADJUSTMENT.fail, meta: { statusText: 'invalid adjustment type' } })
    }

    return dispatch(
      get({
        types: ADVANCED_ADJUSTMENT,
        endpoint: endpoint,
        config: {
          method: 'post',
          body: postdata,
        },
        postSuccess: () => {
          dispatch(lotRefresh(lot.id))
        }
      })
    )
  }
}

export function getLotBids(id) {
  return (dispatch) => {

    const endpoint = '/api/admin/resolution/lotbids/' + id
    return dispatch(
      get({
        types: DATA_BIDS,
        endpoint: endpoint,
      })
    )
  }
}

export function removeUserBid(bid_id, lot_id) {
  console.log('**removeUserBid:', lot_id, bid_id)
  return (dispatch) => {

    const endpoint = '/api/admin/resolution/admin-remove-user-bid/' + bid_id
    return dispatch(
      get({
        types: BID_DELETE,
        endpoint: endpoint,
        config: {
          method: 'post',
        },
        postSuccess: () => {
          dispatch(lotRefresh(lot_id))
          dispatch(getLotBids(lot_id))
        }
      })
    )
  }
}
