import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

function content(props) {
  return (
    <div className="general-table-defaults">
      <Helmet>
        <title>Copyright Notice</title>
        <link rel="canonical" href={props.location.pathname} />
      </Helmet>
      <div className="titlebar heading heading-v1 text-center">
        <h2>Copyright Notice</h2>
      </div>

      <p>Information and images on this site are Copyright &copy;{new Date().getFullYear()} Old World Auctions. All rights reserved. The information presented here may not under any circumstances be resold or redistributed for compensation of any kind without prior written permission from Old World Auctions.</p>
    </div>
  )
}

export default content
