import React from 'react'
import PropTypes from 'prop-types'

FormRow.propTypes = {
  children: PropTypes.any.isRequired,
  style: PropTypes.object,
  sizeClass: PropTypes.string,
  className: PropTypes.string,
}

function FormRow({ style, sizeClass, className, children, ...other }) {
  const childrenWithProps = React.Children.map(children,
    (child) => {
      if(!child) {
        return child
      }

      if(child.type !== 'button' && child.type !== 'div') {
        return React.cloneElement(child, { ...other, groupClassName: child.props.groupClassName ? child.props.groupClassName : sizeClass || 'col-lg-3' })
      }
      return child
    })
  return <div className={'clearfix row ' + (className || '') } style={style}>
    {childrenWithProps}
  </div>
}

export default FormRow