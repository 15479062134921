
import React from 'react'
import PropTypes from 'prop-types'

Admin.propTypes = {
  children: PropTypes.node.isRequired,
}

function Admin({ children }) {
  return <>{children}</>
}

export default Admin
