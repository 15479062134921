import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import FieldBase from './FieldBase'
import FieldText from './FieldText'
import FieldCheckbox from './FieldCheckbox'
import FieldSelect from './FieldSelect'
import FieldSmartSelect from './FieldSmartSelect'
import FieldBitButton from './FieldBitButton'

import { resolveImplicitInputOptions, sanitizeInput, formatValue } from 'lib/utility'

FusedFields.propTypes = {
  children: PropTypes.any.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object,
  wrapped: PropTypes.bool,
  classes: PropTypes.string,
  onUpdate: PropTypes.func,
  onLeave: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  options: PropTypes.object,
}

FusedFields.defaultProps = {
  wrapped: true,
  classes: '',
}

export default function FusedFields({children, data, errors, wrapped, classes, onUpdate, onLeave, onFocus, onKeyPress, onKeyDown, options=null}) {
  const handleUpdate = useCallback((e) => {
    const resolvedOptions = {
      ...resolveImplicitInputOptions(e.target),
      ...(options && { ...options }),
    }

    const sanitized_value = sanitizeInput(e.target)
    if (sanitized_value !== null) {
      onUpdate(e.target.name, sanitized_value, resolvedOptions)
    }
  },[onUpdate, options])

  const handleLeave = useCallback((e) => {
    if (onLeave) {
      const resolvedOptions = {
        ...resolveImplicitInputOptions(e.target),
        ...(options && { ...options }),
      }

      const sanitized_value = sanitizeInput(e.target, { leaving: true })
      if (sanitized_value !== null) {
        onLeave(e.target.name, sanitized_value, resolvedOptions)
      }
    }
  },[onLeave, options])

  const handleFocus = useCallback((e) => {
    if (onFocus) {
      onFocus(e.target.name, e.target.value)
    }
  },[onFocus])

  const handleKeyPress = useCallback((e) => {
    if (onKeyPress) {
      //console.log('handleKeyPress:', e.key, e.charCode, e.keyCode)
      onKeyPress(e.key, e.charCode)
    }
  },[onKeyPress])

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      if (e.target.type !== 'textarea') {
        //console.log('disabling default enter key behavior')
        e.preventDefault()
      }
    }
    if (onKeyDown) {
      //console.log('handleKeyDown:', e.key, e.charCode, e.keyCode)
      onKeyDown(e.key, e.keyCode)
    }
  },[onKeyDown])

  const connectElements = useCallback(function connectElements(children) {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) {
        return child
      }

      let element = child
      if (child.props.children) {
        element = React.cloneElement(child, {
          children: connectElements(child.props.children),
        })
      }

      if (element.type === 'input' || element.type === 'select' || element.type === 'textarea' || element.type === FieldBase || element.type === FieldText || element.type === FieldCheckbox || element.type === FieldSelect || element.type === FieldSmartSelect || element.type === FieldBitButton) {

        const name = element.props.name;
        const generatedProps = {
          value: (data && (typeof data[name] !== 'undefined') && (data[name] !== null)) ? (!element.props.forceValue ? (typeof element.props.displayFormat !== 'undefined' ? formatValue(data[name], element.props.displayFormat) : data[name]) : element.props.forcedValue) : (typeof element.props.displayWhenNull !== 'undefined' ? element.props.displayWhenNull : ''),
          onChange: handleUpdate,
          onBlur: handleLeave,
          onFocus: handleFocus,
          onKeyPress: handleKeyPress,
          onKeyDown: handleKeyDown,
          ...element.type !== 'input' && element.type !== 'select' && element.type !== 'textarea' && { validationState: (errors && errors[name]) ? 'error' : null },
        }

        // connect addonBefore and addonAfter props that are actual elements
        const connectedPropElements = {
          ...(element.type === FieldBase && React.isValidElement(element.props.addonBefore) && { addonBefore: connectElements(element.props.addonBefore) }),
          ...(element.type === FieldBase && React.isValidElement(element.props.addonAfter) && { addonAfter: connectElements(element.props.addonAfter) }),
        }

        return React.cloneElement(element, { ...generatedProps, ...connectedPropElements })
      }
      return element
    })
  }, [data, errors, handleFocus, handleKeyDown, handleKeyPress, handleLeave, handleUpdate])

  const content = connectElements(children)

  return wrapped ? <div className={classes}>{content}</div> : content
}