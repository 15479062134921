import React, { useState } from 'react'
import PropTypes from 'prop-types'

import FusedFields from '../shared/fused-fields'
import FormRow from '../shared/FormRow'
import FieldText from '../shared/FieldText'
import FieldImage from '../shared/FieldImage'
import FieldBase from '../shared/FieldBase'
import StatusBox from '../shared/StatusBox'
import FieldBitButton from '../shared/FieldBitButton'

import { makeOptions } from 'lib/utility'
import { admin_slide_options } from 'lib/constants'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

SlideDetails.propTypes = {
  slidegroupID: PropTypes.number.isRequired,
  slidegroupType: PropTypes.number.isRequired,
  templateData: PropTypes.object.isRequired,
  slide: PropTypes.object,
  isNew: PropTypes.bool.isRequired,
  saveSlide: PropTypes.func.isRequired,
  closeSlide: PropTypes.func.isRequired,
  fieldUpdate: PropTypes.func.isRequired,
}

SlideDetails.defaultProps = {
  slide: { update: {}},
}

function SlideDetails({ slidegroupID, slidegroupType, templateData, slide, isNew, saveSlide, closeSlide, fieldUpdate }) {
  const [image, setImage] = useState("")

  if (!isNew && (!slide.meta || slide.meta.status === 0)) { return <div>Loading...</div> }

  const { data, validation } = slide

  const _handleUpdate = (field, value) => {
    fieldUpdate(field, value, slidegroupID, data.id)
  }

  const _handleImageChange = (rawImage) => {
    setImage(rawImage)
  }

  const _saveSlide = async () => {
    saveSlide(data.id, { isNew, slidegroupID, image })
  }

  const _closeSlide = () => {
    closeSlide()
  }

  const _removeExistingImage = () => {
    fieldUpdate('image_filename', null, slidegroupID, data.id)
  }

  const _restoreExistingImage = () => {
    // fieldUpdate('image_filename', null, slidegroupID, data.id)
  }

  // templateData.auctionYear="2021"
  // templateData.auctionMonth="November"
  // templateData.auctionEndDay="November 17"
  // templateData.postAuctionEndDay="November 24"
  // templateData.nextAuctionYear="2022"
  // templateData.nextAuctionStartDay="January 26"
  // templateData.nextAuctionEndDay="February 9"

  const _templateApply = (template) => {
    switch (template) {
      case 'next-auction':
        fieldUpdate('header', 'Next Auction: ' + templateData.nextAuctionStartDay + ' - ' + templateData.nextAuctionEndDay + ', ' + templateData.nextAuctionYear, slidegroupID, data.id)
        fieldUpdate('link1_text', 'Register Now', slidegroupID, data.id)
        fieldUpdate('link1_url', '/register', slidegroupID, data.id)
        fieldUpdate('show_in_modes', 1, slidegroupID, data.id)
        break;
      case 'current-auction':
        fieldUpdate('header', 'Current Auction: Closes ' + templateData.auctionEndDay + ', ' + templateData.auctionYear, slidegroupID, data.id)
        fieldUpdate('link1_text', 'View Lot Details', slidegroupID, data.id)
        fieldUpdate('show_in_modes', 24, slidegroupID, data.id)
        break;
      case 'current-auction-sold':
        fieldUpdate('header', 'Just sold for $', slidegroupID, data.id)
        fieldUpdate('link1_text', 'View Lot Details', slidegroupID, data.id)
        fieldUpdate('show_in_modes', 7, slidegroupID, data.id)
        break;
      case 'post-auction':
        fieldUpdate('header', '<span>The ' + templateData.auctionMonth + ' auction has closed but you can still buy<br />unsold lots at the reserve price through ' + templateData.postAuctionEndDay + '!</span>', slidegroupID, data.id)
        fieldUpdate('link1_text', 'View Unsold Lots', slidegroupID, data.id)
        fieldUpdate('link1_url', '/catalog/current?unsold=true', slidegroupID, data.id)
        fieldUpdate('show_in_modes', 6, slidegroupID, data.id)
        break;
      default:
        console.log(template + ' shortcut template not defined!')
        break;
    }
  }

  return <div className="padding-top-5">
    <FusedFields data={data} onUpdate={_handleUpdate}>
      <FormRow>
        <div className="col-md-6">
          <FormRow>
            <FieldBase type="select" name="layout" addonBefore="Type" groupClassName="col-md-8" data-type="int">
              { makeOptions(admin_slide_options.layout) }
            </FieldBase>
            <div className="btn-group col-md-4">
              <button type="button" className="btn btn-default dropdown-toggle pull-right" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Template <span className="caret"></span>
              </button>
              <ul className="dropdown-menu dropdown-menu-right">
                {slidegroupType === 1 && <li><a style={{ cursor: 'pointer' }} onClick={() => _templateApply('next-auction')}>Next Auction</a></li>}
                {slidegroupType === 1 && <li><a style={{ cursor: 'pointer' }} onClick={() => _templateApply('current-auction')}>Current Auction</a></li>}
                {slidegroupType === 1 && <li><a style={{ cursor: 'pointer' }} onClick={() => _templateApply('current-auction-sold')}>Current Auction Sold</a></li>}
                {slidegroupType === 1 && <li><a style={{ cursor: 'pointer' }} onClick={() => _templateApply('post-auction')}>Post-Auction</a></li>}
                {slidegroupType === 1 && <li role="separator" className="divider"></li>}
                <li><a style={{ cursor: 'pointer' }} onClick={() => _templateApply('global-placeholder')}>Global Placeholder</a></li>
              </ul>
            </div>
          </FormRow>
          <FormRow>
            <div className="form-group col-md-12">
              <FieldBitButton name="show_in_modes" numBits={5} label="Show in Modes:" bitLabels={['1','2','3','4','5']} />
            </div>
          </FormRow>
          <FormRow>
            <FieldText name="header" label="Header" groupClassName="col-md-12" />
          </FormRow>
        </div>
        <div className="col-md-6">
          {data.image_filename ? <div style={{...baseStyle, position: 'relative' }}>
            <span style={{ cursor: 'pointer', position: 'absolute', top: '0px', right: '0px', width: '35px', height: '35px', backgroundColor: '#a94442', clipPath: 'polygon(100% 0%, 100% 100%, 0% 0%)' }} onClick={(e) => _removeExistingImage()}>
              <span className="fa fa-lg fa-close fa-inverse" style={{ position: 'absolute', top: '2px', right: '2px' }}/>
            </span>

            <img src={`${__DYNAMIC_IMAGES_RELATIVE_BASE_URL__}slides/${data.image_filename}`} style={{ maxWidth: '100%', maxHeight: '250px' }} />
          </div>
          : <FieldImage handleImageChange={_handleImageChange} tabIndex="-1" />}
        </div>
        <div className="col-md-12">
          <FormRow>
            <FieldText name="title" label="Title" groupClassName="col-md-12" />
          </FormRow>
          <FormRow>
            <FieldText name="subtitle" label="Subtitle" groupClassName="col-md-6" />
            <FieldText name="description" label="Description" groupClassName="col-md-6" />
          </FormRow>
          <FormRow>
            <FieldText name="link1_text" label="Link Text" groupClassName="col-md-12" />
          </FormRow>
          <FormRow>
            <FieldBase type="text" name='link1_url' label="Link URL" addonAfter={<span><input type="checkbox" name="link1_new_window" checked={ data.link1_new_window === 1 } /><span className="margin-left-5" style={{ verticalAlign: 'top' }}>Open in New Window</span></span>} groupClassName='col-lg-12' />
          </FormRow>
          {data.layout === 1 && <>
            <FormRow>
              <FieldText name="link2_text" label="Link2 Text" groupClassName="col-md-12" />
            </FormRow>
            <FormRow>
              <FieldBase type="text" name='link2_url' label="Link2 URL" addonAfter={<span><input type="checkbox" name="link2_new_window" checked={ data.link2_new_window === 1 } /><span className="margin-left-5" style={{ verticalAlign: 'top' }}>Open in New Window</span></span>} groupClassName='col-lg-12' />
            </FormRow>
          </>}
        </div>
      </FormRow>
      <div className="flex-standard-container">
        <div className="flex-group flex-group-left">
          <button type="button" className="btn-u btn-u-red btn" onClick={ _closeSlide }>Cancel</button>
        </div>
        <div className="flex-group flex-group-center">
          <StatusBox data={slide.update} size="micro" />
        </div>
        <div className="flex-group flex-group-right">
          <button type="button" className="btn btn-u btn-u-sea" onClick={ _saveSlide }><span className='fa fa-fw fa-arrow-up' />{ isNew ? ' Save New Slide' : ' Save Changes' }</button>
        </div>
      </div>
    </FusedFields>
  </div>
}

export default SlideDetails

