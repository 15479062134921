import { isClient } from 'lib/exenv'
import ReactGA from 'react-ga4'

if (isClient && __TRACKING_ACTIVE__) {
  console.log('analyticsTracking-init')
  if (__GOOGLE_ANALYTICS_ID__) {
    console.log('ga-init:', __GOOGLE_ANALYTICS_ID__)
    ReactGA.initialize(__GOOGLE_ANALYTICS_ID__, {
      gtagOptions: {
        send_page_view: false,
      }
    })
  }
}

const trackPage = (page) => {
  if (__GOOGLE_ANALYTICS_ID__) {
    ReactGA.send({ hitType: "pageview", page, title: page });
  }
}

let currentPage = ''

export const analyticsTracking = store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.pathname}${action.payload.search}`
    console.log('analyticsTracking-prev:', currentPage)
    console.log('analyticsTracking-next:', nextPage)

    if (currentPage !== nextPage) {
      currentPage = nextPage
      trackPage(nextPage)
    }
  }

  return next(action)
}