import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

BidIncrementInfo.propTypes = {
  hide: PropTypes.bool,
  minimumBid: PropTypes.number,
  highBid: PropTypes.number,
  showTerms: PropTypes.bool,
  showAllIncrements: PropTypes.bool,
}

function BidIncrementInfo({ hide=false, minimumBid=1, highBid=0, showTerms=true, showAllIncrements=false }={}) {
  if (hide) return null
  const indicatorValue = Math.max(minimumBid, highBid)

  return <div style={{ marginBottom: '10px' }}>
    <div className="bid-increment-box">
      <div className="input-group" style={{ width: '100%', backgroundColor: '#FFF', padding: '4px'}}>
        <div>
          <div className="title clearfix" style={{borderBottom: '1px dotted #555', fontWeight: 'bold'}}>Bid Increments</div>
          {(indicatorValue < 50 || showAllIncrements) && <div className="clearfix" style={{borderBottom: '1px dotted #555'}}><span className='pull-left'>Under $50</span><span className='pull-right'>$1</span></div>}
          {(indicatorValue < 100 || showAllIncrements) && <div className="clearfix" style={{borderBottom: '1px dotted #555'}}><span className='pull-left'>$50 - $99</span><span className='pull-right'>$5</span></div>}
          {(indicatorValue < 250 || showAllIncrements) && <div className="clearfix" style={{borderBottom: '1px dotted #555'}}><span className='pull-left'>$100 - $249</span><span className='pull-right'>$10</span></div>}
          {(indicatorValue < 500 || showAllIncrements) && <div className="clearfix" style={{borderBottom: '1px dotted #555'}}><span className='pull-left'>$250 - $499</span><span className='pull-right'>$25</span></div>}
          {(indicatorValue < 1000 || showAllIncrements) && <div className="clearfix" style={{borderBottom: '1px dotted #555'}}><span className='pull-left'>$500 - $999</span><span className='pull-right'>$50</span></div>}
          {(indicatorValue < 2500 && indicatorValue >= 100 || showAllIncrements) && <div className="clearfix" style={{borderBottom: '1px dotted #555'}}><span className='pull-left'>$1,000 - $2,499</span><span className='pull-right'>$100</span></div>}
          {(indicatorValue < 5000 && indicatorValue >= 250 || showAllIncrements) && <div className="clearfix" style={{borderBottom: '1px dotted #555'}}><span className='pull-left'>$2,500 - $4,999</span><span className='pull-right'>$250</span></div>}
          {(indicatorValue < 10000 && indicatorValue >= 500 || showAllIncrements) && <div className="clearfix" style={{borderBottom: '1px dotted #555'}}><span className='pull-left'>$5,000 - $9,999</span><span className='pull-right'>$500</span></div>}
          {(indicatorValue < 25000 && indicatorValue >= 1000 || showAllIncrements) && <div className="clearfix" style={{borderBottom: '1px dotted #555'}}><span className='pull-left'>$10,000 - $24,999</span><span className='pull-right'>$1,000</span></div>}
          {(indicatorValue < 50000 && indicatorValue >= 2500 || showAllIncrements) && <div className="clearfix" style={{borderBottom: '1px dotted #555'}}><span className='pull-left'>$25,000 - $49,999</span><span className='pull-right'>$2,500</span></div>}
          {(indicatorValue < 100000 && indicatorValue >= 5000 || showAllIncrements) && <div className="clearfix" style={{borderBottom: '1px dotted #555'}}><span className='pull-left'>$50,000 - $99,999</span><span className='pull-right'>$5,000</span></div>}
          {(indicatorValue >= 10000 || showAllIncrements) && <div className="clearfix" style={{borderBottom: '1px dotted #555'}}><span className='pull-left'>Above $100,000</span><span className='pull-right'>$10,000</span></div>}
        </div>
      </div>
    </div>
    {showTerms && <Link to="/resources/terms-and-conditions" target="_blank" rel="noopener noreferrer"><div>Terms & Conditions</div></Link>}
  </div>
}

export default BidIncrementInfo
