import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

function content(props) {
  return (
    <div className="general-table-defaults">
      <Helmet>
        <title>Subscribe to our Newsletter</title>
        <link rel="canonical" href={props.location.pathname} />
        <link rel="stylesheet" type="text/css" href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" />
        <style type="text/css">{`
          #mc_embed_signup { background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
        `}</style>
      </Helmet>
      <div className="titlebar heading heading-v1 text-center">
        <h2>Subscribe to our Newsletter</h2>
      </div>

      <div id="mc_embed_signup">
      <form action="https://oldworldauctions.us7.list-manage.com/subscribe/post?u=42ffe85f0375589a144048218&amp;id=57570955bc" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
          <div id="mc_embed_signup_scroll">
      <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
      <div className="mc-field-group">
        <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span>
      </label>
        <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
      </div>
      <div className="mc-field-group">
        <label htmlFor="mce-FNAME">First Name  <span className="asterisk">*</span>
      </label>
        <input type="text" name="FNAME" className="required" id="mce-FNAME" />
      </div>
      <div className="mc-field-group">
        <label htmlFor="mce-LNAME">Last Name  <span className="asterisk">*</span>
      </label>
        <input type="text" name="LNAME" className="required" id="mce-LNAME" />
      </div>
      <div className="mc-field-group">
        <label htmlFor="mce-MMERGE6">COUNTRY  <span className="asterisk">*</span>
      </label>
        <input type="text" name="MMERGE6" className="required" id="mce-MMERGE6" />
      </div>
      <div className="mc-field-group input-group">
          <strong>Types of emails I would like to receive: </strong>
          <ul><li><input type="checkbox" value="1" name="group[6757][1]" id="mce-group[6757]-6757-0" /><label htmlFor="mce-group[6757]-6757-0">Auction Alerts - receive an email when an auction goes online and two days before it closes</label></li>
      <li><input type="checkbox" value="2" name="group[6757][2]" id="mce-group[6757]-6757-1" /><label htmlFor="mce-group[6757]-6757-1">Monthly Newsletters -  info on antique maps, collecting tips, and events in the map community</label></li>
      <li><input type="checkbox" value="4" name="group[6757][4]" id="mce-group[6757]-6757-2" /><label htmlFor="mce-group[6757]-6757-2">Additional Last Minute Auction Alert - receive an email 12 hours before the auction closes</label></li>
      </ul>
      </div>
      <div className="mc-field-group input-group">
          <strong>Email Format </strong>
          <ul><li><input type="radio" value="html" name="EMAILTYPE" id="mce-EMAILTYPE-0" /><label htmlFor="mce-EMAILTYPE-0">html</label></li>
      <li><input type="radio" value="text" name="EMAILTYPE" id="mce-EMAILTYPE-1" /><label htmlFor="mce-EMAILTYPE-1">text</label></li>
      </ul>
      </div>
        <div id="mce-responses" className="clear">
          <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
          <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
        </div>
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_42ffe85f0375589a144048218_57570955bc" tabIndex="-1" /></div>
          <div className="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
          </div>
      </form>
      </div>

    </div>
  )
}

export default content
