import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

ZoomViewer.propTypes = {
  auctionNumber: PropTypes.string.isRequired,
  lotNumber: PropTypes.string.isRequired,
  catalog_mode: PropTypes.string.isRequired,
  numImages: PropTypes.number.isRequired,
  closeAction: PropTypes.func,
  initialZoomImage: PropTypes.number,
  unavailable: PropTypes.bool,
}

ZoomViewer.defaultProps = {
  initialZoomImage: 0,
  unavailable: false,
}

function ZoomViewer({ auctionNumber, lotNumber, catalog_mode, numImages, closeAction, initialZoomImage, unavailable }) {
  const [errored, setErrored] = useState(false)
  const [exception, setException] = useState(false)
  const viewerRef = useRef(null)

  const handleOSDLoad = useCallback(
    async () => {
      try {
        const localBasePath = __DYNAMIC_IMAGES_RELATIVE_BASE_URL__ + 'auctions/'
        const basepath = catalog_mode === 'current' ? localBasePath : __B2_IMAGE_BASE_URL__
        const lot_id = auctionNumber + "-" + lotNumber.padStart(3, '0')
        const OpenSeadragon = await import(/* webpackChunkName: "openseadragon" */ 'openseadragon')

        const imagelist = Array.apply(0, Array(numImages)).map((a, i) => {
          const suffix = String.fromCharCode(i+65)
          return basepath + auctionNumber + '/zoomdata/' + lot_id + suffix + '.dzi'
        })

        OpenSeadragon.default.setString("Errors.OpenFailed", "")
        const osd = OpenSeadragon.default({
          id: "zoomContainer",
          showNavigator:  true,
          sequenceMode: true,
          initialPage: initialZoomImage,
          showReferenceStrip: true,
          prefixUrl: "/static/openseadragon/images/",
          tileSources: imagelist,
          minZoomImageRatio: 0.8,
          // minZoomLevel: 0.8,
        })
        osd.addHandler("open", function() {
            // To improve load times, ignore the lowest-resolution Deep Zoom
            // levels.  This is a hack: we can't configure the minLevel via
            // OpenSeadragon configuration options when the viewer is created
            // from DZI XML.
            // console.log('osd-open-handler:', osd.source)
            osd.source.minLevel = 7
            // console.log('minLevel:', osd.source.minLevel)

            // don't think below is necessary, the above setting seems to work even for multiple image zoom viewers
            // const tiledImage = osd.world.getItemAt(0) // Assuming you just have a single image in the viewer.
            // tiledImage.source.minLevel = 7 // Or whatever value you want.
        })
        osd.addHandler("open-failed", () => {
          // console.log('osd-open-failed')
          setErrored(true)
        })
        osd.addHandler("page", (p) => {
          // console.log('osd-page:', p)
          setErrored(false)
        })
        viewerRef.current = osd
      } catch(error) {
        console.log('zoom-exception:', error.message)
        setException(true)
      }
    },
    [auctionNumber, lotNumber, catalog_mode, initialZoomImage, numImages],
  )

  useEffect(() => {
    handleOSDLoad()
    return () => {
      // console.log('effect-osd-load-cleanup')
      // console.log('osd-destroy:', viewerRef.current)
      viewerRef.current && viewerRef.current.destroy()
      viewerRef.current = null
    }
  }, [handleOSDLoad, auctionNumber, lotNumber, catalog_mode, initialZoomImage, numImages])

  const reloadAction = () => {
    if (exception) {
      viewerRef.current && viewerRef.current.destroy()
      viewerRef.current = null
      handleOSDLoad()
    } else {
      viewerRef.current.goToPage(viewerRef.current.currentPage())
    }
  }

  let error_message = unavailable ?
    <div id="errorbox" className="zoomviewer-error">
      <div className="text-center">
        <h4>Hi-resolution imagery is currently not available for this lot, but will be coming soon</h4>
      </div>
    </div>
  :
  exception || errored ?
    <div id="errorbox" className="zoomviewer-error">
      <div className="text-center">
        <h4>There was a problem loading the high resolution data for this image</h4>
        <button type='submit' className='btn btn-u btn-u-dark-blue' onClick={reloadAction}>
          <span className='fa fa-refresh fa-fw' />
          {' Attempt Refresh'}
        </button>
      </div>
    </div>
  : null

  return <div className="zoomviewer-outer-container">
    <div className="zoomviewer-close-container">
      <span className="zoomviewer-close" onClick={closeAction}>
        <span className='fa fa-fw fa-times' style={{ fontSize: '24px', verticalAlign: '-1px' }} />
        <span style={{ fontSize: '20px', lineHeight: '20px' }}>Close</span>
      </span>
    </div>
    <div className="zoomviewer-inner-container">
      {error_message}
      <div id="zoomContainer" className="zoomviewer"></div>
    </div>
  </div>
}

export default ZoomViewer