import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Nav, NavItem } from 'react-bootstrap'

import FilterPanel from './filter-panel'
import FormUser from './form-user'

import { getData, getUser, closeUser, selectTab, fieldUpdate, saveUser, makeConsignor, generateConsignorCode } from 'rdx/modules/admin/users'

import Tab from '../shared/tab'
import DataList from '../shared/DataList'
import DataListRow from '../shared/DataListRow'

import { admin_user_list_options, admin_user_filter_options } from 'lib/constants'

import connectDataList from '../shared/composers/connectDataList'

const DataListBound = connectDataList(DataList, state => ({ statebranch: state.admin.users.list }))

TabGroupUser.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  lookupData: PropTypes.object.isRequired, //injected via DashboardAdmin
  users: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  closeUser: PropTypes.func.isRequired,
  makeConsignor: PropTypes.func.isRequired,
  generateConsignorCode: PropTypes.func.isRequired,
  selectTab: PropTypes.func.isRequired,
  fieldUpdate: PropTypes.func.isRequired,
}

TabGroupUser.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getData(location)),
  ])
}

function TabGroupUser({ location, params, lookupData, users, getData, getUser, saveUser, closeUser, makeConsignor, generateConsignorCode, selectTab, fieldUpdate }) {
  useEffect(() => {
    getData(location)
  }, [location, getData])

  const _handleSelect = (key) => {
    selectTab(key)
  }

  const _onItemClick = (item) => {
    if (users.user[item.id]) {
      selectTab(item.id)
    } else {
      getUser(item.id, item.user_firstname, item.user_lastname)
    }
  }

  const _onCloseClick = (e, id) => {
    e.stopPropagation()
    e.preventDefault()
    closeUser(id)
  }

  const nav = <Nav bsStyle='tabs' activeKey={users.selectedTab} onSelect={_handleSelect}>
    <NavItem eventKey="list">{ 'User List' }</NavItem>
    {
      users.user_order.map((o) => {
        if (o) {
          let tabindicator = ''
          const fullname = [users.user[o].originalData.user_lastname, users.user[o].originalData.user_firstname].filter(Boolean).join(", ")
          switch (users.user[o].update.status) {
            case 1:
              tabindicator = <span className='fa fa-circle ok' />
              break
            case 2:
              tabindicator = <span className='fa fa-circle error' />
              break
            default:
          }
          return <NavItem key={o} eventKey={o} tabIndex="-1">
            {tabindicator}
            { fullname ? fullname : o }
            <span className='fa fa-close' onClick={(e) => _onCloseClick(e, o)} />
          </NavItem>
        }
      })
    }
    <NavItem eventKey="new" tabIndex="-1"><span className={'fa fa-plus' + (users.user.new.meta.status === 2 ? ' error' : '')} />{' Add User'}</NavItem>
  </Nav>

  return <div className='tab-v1 owa-margin10'>
    <div className="tab-container">
      {nav}
      <Tab isActive={users.selectedTab === "list"}>
        <FilterPanel
          lookupData={lookupData}
          filterbuilderitems={admin_user_filter_options}
          location={location}
        />
        <DataListBound
          useTable={true}
          showHeader={true}
          showTopPagingControl={true}
          pagingControlSize='slim'
          metadata={admin_user_list_options}
          datakey='id'
          clickTargetFunction={_onItemClick}
          params={params}
          location={location}
        >
          <DataListRow />
        </DataListBound>
      </Tab>
      {
        users.user_order.map((o) => {
            return <Tab key={o} isActive={users.selectedTab === o}>
              <FormUser
                user={users.user[o]}
                isNew={false}
                lookupData={lookupData}
                fieldUpdate={fieldUpdate}
                saveUser={saveUser}
                makeConsignor={makeConsignor}
                generateConsignorCode={generateConsignorCode}
              />
            </Tab>
        })
      }
      <Tab isActive={users.selectedTab === "new"}>
        <FormUser
          user={users.user.new}
          isNew={true}
          lookupData={lookupData}
          fieldUpdate={fieldUpdate}
          saveUser={saveUser}
          makeConsignor={makeConsignor}
          generateConsignorCode={generateConsignorCode}
        />
      </Tab>
    </div>
  </div>
}

function mapStateToProps(state) {
  return {
    users: state.admin.users,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({
      getData,
      getUser,
      closeUser,
      selectTab,
      fieldUpdate,
      saveUser,
      makeConsignor,
      generateConsignorCode,
    }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabGroupUser)
