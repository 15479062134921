import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { bid_extension_time } from 'lib/constants'
import { toMMSS } from 'lib/utility'

LotTimeRemaining.propTypes = {
  auction_mode: PropTypes.number.isRequired,
  display_mode: PropTypes.number.isRequired,
  elapsedSeconds: PropTypes.number.isRequired,
  time_since_lastbid: PropTypes.number,
}

function LotTimeRemaining({ auction_mode, display_mode, elapsedSeconds, time_since_lastbid=null }) {
  const [resolvedTimeSinceLastBid, setResolvedTimeSinceLastBid] = useState(time_since_lastbid)

  // hack to avoid out-of-sync time display with time_since_lastbid and elapsedSeconds (works by essentially delaying the updated time_since_lastbid by a render cycle)
  useEffect(() => {
    setResolvedTimeSinceLastBid(prev => {
      return (time_since_lastbid !== prev)
        ? time_since_lastbid
        : prev
    })
  }, [time_since_lastbid])

  if (auction_mode === 2 && display_mode === 1 && resolvedTimeSinceLastBid !== null) {
    const time_remaining = Math.max(0, bid_extension_time - (resolvedTimeSinceLastBid + elapsedSeconds)) || 0
    return <h5><span style={{fontWeight: 'bold', color: time_remaining <= 60 ? 'red' : 'orange' }}>{time_remaining > 0 ? ('Closing in: ' + toMMSS(time_remaining)) : 'Pending Close...'}</span></h5>
  } else {
    return null
  }
}

export default LotTimeRemaining
