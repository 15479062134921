import React from 'react'
import PropTypes from 'prop-types'

import FieldBase from './FieldBase'

FieldCheckbox.propTypes = {
  mode: PropTypes.string,
}

FieldCheckbox.defaultProps = {
  mode: 'standard',
}

function FieldCheckbox(props) {
  return <FieldBase {...props} type={'checkbox'} />
}

export default FieldCheckbox