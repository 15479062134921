import React from "react"
import useConfirm from "./hooks/useConfirm"
import Modal from 'react-modal'

import FormRow from './FormRow'

import { modalStyles } from 'lib/constants'

const ConfirmModal = () => {
  const {
    prompt = '',
    isOpen = false,
    proceed,
    cancel,
  } = useConfirm()

  const placeholder = <div style={{ paddingTop: '20px' }}>Confirm or Cancel Action</div>

  const confirmButton = <a className="btn-u btn-u-sea pull-right" onClick={proceed}>Confirm</a>
  const buttonBar = <FormRow>
    <div className="col-md-12">
      <a className="btn-u btn-u-red" onClick={cancel}>Cancel</a>
      {confirmButton}
    </div>
  </FormRow>

  const container = <div className="confirm-box">
    <div className="confirm-box-header">
      <h2><i className="fa fa-cog"></i>Confirm</h2>
    </div>
    <div className="confirm-box-content">
      <div style={{ marginTop: '20px', marginBottom: '10px' }}>{prompt === '' ? placeholder : prompt}</div>
      {buttonBar}
    </div>
  </div>

  const modal = <Modal
    closeTimeoutMS={150}
    isOpen={isOpen}
    onRequestClose={() => {}}
    style={modalStyles}
    contentLabel="contentLabel" >
      {container}
  </Modal>

  return modal
}
export default ConfirmModal