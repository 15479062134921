
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'

import { connect } from 'react-redux'
import { processContactForm } from 'rdx/modules/auth/contact'

import FusedFields from '../../shared/fused-fields'
import FormRow from '../../shared/FormRow'
import FieldBase from '../../shared/FieldBase'
import FieldText from '../../shared/FieldText'
import StatusBox from '../../shared/StatusBox'

import { company_address_line1, company_address_line2, contact_phone, contact_email_obscured } from 'lib/constants'

const fields_initialstate = {
  firstname: '',
  lastname: '',
  email: '',
  message: '',
}

ContactUs.propTypes = {
  location: PropTypes.object.isRequired,
  meta: PropTypes.object,
  validation: PropTypes.object,
  processContactForm: PropTypes.func.isRequired,
}

function ContactUs({ location, meta, validation, processContactForm }) {
  const [fields, setFields] = useState(fields_initialstate)
  const recaptchaRef = useRef()

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _processContactForm = () => {
    processContactForm(fields, recaptchaRef.current)
  }

  const sidebar = <div className="col-sm-6">
    <h2 style={{ textAlign: 'center' }}>Our location</h2>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3159.2864612527633!2d-77.57271594836445!3d37.64246882743286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b1401d7d2df80b%3A0xc01045efdd6875c0!2sOld+World+Auctions!5e0!3m2!1sen!2sus!4v1565226205488!5m2!1sen!2sus" frameBorder="0" style={{width: '100%', height: '477px', border:'0'}} allowFullScreen></iframe>
  </div>

  const form_contact = <form className="form-general clearfix" style={{ height: '100%' }}>
    {meta.status !== 1 && <FusedFields data={fields} errors={validation.required} onUpdate={_handleUpdate}>
      <FormRow>
        <FieldText name="firstname" required label="First Name" groupClassName="col-md-12" />
      </FormRow>
      <FormRow>
        <FieldText name="lastname" required label="Last Name" groupClassName="col-md-12" />
      </FormRow>
      <FormRow>
        <FieldText name="email" required label="Email Address" groupClassName="col-md-12" />
      </FormRow>
      <FormRow>
        <FieldBase type="textarea" name="message" required label="Question or Message" style={{ height: '150px' }} groupClassName="col-lg-12" maxLength={ 1000 } />
      </FormRow>
      <div style={{ zIndex: '1000' }}>
        <ReCAPTCHA
          size="invisible"
          ref={recaptchaRef}
          sitekey={__RECAPTCHA_KEY__}
          onExpired={() => {
            console.log('ReCAPTCHA-expired')
          }}
          onErrored={() => {
            console.log('ReCAPTCHA-errored')
          }}
        />
      </div>
    </FusedFields>}
    <StatusBox data={meta} size="small" persist={true} className='margin-bottom-15' />
    {meta.status === 1 && <div style={{ fontSize: '16px' }}>
      <p>We&apos;ll review your message and answer any questions.</p>
      <p>If you don&apos;t hear from us soon, try contacting us via our phone or email address below.</p>
    </div>}
    {meta.status !== 1 && <FormRow>
      <div className="form-group col-md-12 col-sm-12 col-xs-12">
        <button type='button' className='pull-right btn btn-u btn-u-sea' onClick={_processContactForm}>
          <span className='fa fa-check' />
          {' Send Message'}
        </button>
      </div>
    </FormRow>}
  </form>

  return <div className="resource-container resource-locked-max-width-extra shadow-wrapper">
    <div className="tag-box tag-box-v3 box-shadow shadow-effect-2 resource-content">
      <Helmet>
        <title>Contact or Find Us</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div className="titlebar heading heading-v1 text-center">
        <h2>Contact or Find Us</h2>
      </div>
      <div>
        <div className="row">
          <div className="col-sm-6">
            <div>
              <h2 style={{ textAlign: 'center' }}>Send us a message</h2>
              {form_contact}
            </div>
          </div>
          {sidebar}
        </div>
        <div className="text-center margin-top-20" style={{borderTop: "1px solid #ddd"}}>
          <h4>Eliane &amp; Jon Dotson</h4>
          <h4>Old World Auctions</h4>
          {company_address_line1}<br />
          {company_address_line2}<br />
          Tel: {contact_phone}<br />
          Email: {contact_email_obscured}<br />
        </div>
      </div>
    </div>
  </div>
}

function mapStateToProps(state) {
  return {
    meta: state.auth.contact.actions.processContactForm.meta,
    validation: state.auth.contact.actions.processContactForm.validation,
  }
}

export default connect(mapStateToProps, { processContactForm })(ContactUs)
