import { status_defaults, validation_defaults, defineAction, buildErrorNew } from 'lib/state_defaults'
import { verify, processRecaptcha, validatePassword } from 'lib/validation'

const base = 'owa/auth/registration/'

const REGISTER = defineAction(base, 'REGISTER', 'async')

const initialState = {
  actions: {
    register: {
      meta: status_defaults,
      validation: validation_defaults,
    }
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REGISTER.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          register: {
            meta: {
              ...status_defaults,
              processing: true,
            },
            validation: validation_defaults,
          }
        }
      }
    case REGISTER.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          register: {
            meta: {
              ...status_defaults,
              ...action.meta,
            },
            validation: validation_defaults,
          }
        }
      }
    case REGISTER.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          register: {
            meta: {
              ...status_defaults,
              ...action.meta,
            },
            validation: action.validation,
          }
        }
      }
    default:
      return state
  }
}

export function processRegistration(fields, recaptchaInstance) {
  console.log('processRegistration')
  return async (dispatch) => {
    const required_fields = ['user_firstname', 'user_lastname', 'user_email', 'user_email_verify', 'user_password', 'user_password_verify', 'primary_phone', 'primary_address_line1', 'primary_city', 'primary_state', 'primary_postalcode', 'primary_country', 'user_referralsource']

    const errors_present = verify({
      required: required_fields,
    }, fields)

    // ZZZ - need to refactor verify function to handle other validation functions (like confirmation field matching, password requirements, etc)
    const errors_special = []
    if (fields.user_email !== fields.user_email_verify) {
      errors_special.push('Email addresses do not match')
    }
    if (!validatePassword(fields.user_password)) {
      errors_special.push('Password doesn\'t meet the requirements')
    }
    if (fields.user_password !== fields.user_password_verify) {
      errors_special.push('Passwords do not match')
    }

    if (errors_present || errors_special.length > 0) {
      console.log('client-verify-errors-present')
      return dispatch(
        // temporary function until buildError can be refactored
        buildErrorNew(REGISTER.fail, { statusText: 'Please review the below issues and try again:', validation: { required: errors_present, other: errors_special } })
      )
    }

    return processRecaptcha(recaptchaInstance, dispatch, REGISTER, '/api/auth/register', fields)
  }
}
