import React from 'react'
import PropTypes from 'prop-types'
import Collapsible from 'react-collapsible'
import classNames from 'classnames'

import MenuShell from './MenuShell'
import MenuLink from './MenuLink'

import { objectHasOneOfKeys, filterObjectKeys } from 'lib/utility'

Menu.propTypes = {
  id: PropTypes.string.isRequired,
  menuOpen: PropTypes.bool,
  toggleMenu: PropTypes.func,
  type: PropTypes.string,
  hideOnMobile: PropTypes.bool,
  position: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  titleIcon: PropTypes.element,
  titleImg: PropTypes.element,
  titleTarget: PropTypes.string,
  titleStyle: PropTypes.object,
  width: PropTypes.number,
  hideBurgerIcon: PropTypes.bool,
  data: PropTypes.object,
  actions: PropTypes.object,
  context: PropTypes.object,
  storedQuery: PropTypes.object,
  storedParams: PropTypes.object,
}

Menu.defaultProps = {
  type: 'standard',
  menuOpen: false,
  hideOnMobile: false,
  position: 'right',
  titleIcon: null,
  width: 270,
  hideBurgerIcon: false,
  data: {},
  actions: null,
  context: {},
  storedQuery: {},
  storedParams: {},
}

export default function Menu(props) {
  const { id, menuOpen, toggleMenu, type, hideOnMobile, position, className, title, titleIcon, titleImg, titleTarget, titleStyle, width, hideBurgerIcon, data, actions, context, storedQuery, storedParams } = props
  const global_options = data.global_options ? data.global_options : {}
  const resolvedContext = {
    ...context,
    storedQuery,
    storedParams,
  }

  const resolvedClassName = classNames({
    'menu-base': className ? false : true,
    [`menu-${type}`]: className ? false : true,
    [`${className}`]: className ? true : false,
    "show-only-in-desktop": hideOnMobile,
  })

  const styles = {
    burgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      ...(position === 'right' && { right: '10px' }),
      ...(position === 'left' && { left: '10px' }),
      top: '10px'
    },
    burgerBars: {
      background: '#d4d4d4'
    },
    menu: {
      background: '#373a47',
      padding: '0',
      fontSize: '1.15em',
      // WebkitOverflowScrolling: 'touch',
      // overflow: 'auto',
    },
    itemList: {
      color: '#b8b7ad',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.7)'
    }
  }

  const closeButtonStyle = {
    position: 'fixed',
    width: '48px',
    height: '48px',
    top: '0',
    ...(position === 'right' && { right: '270px' }),
    ...(position === 'left' && { left: '270px' }),
    backgroundColor: '#a10f2b',
    color: '#fff',
    fontSize: '20px',
    zIndex: '1200',
    transition: 'opacity 0.5s ease 0.5s',
    opacity: '0',
    // transform: 'translate3d(0,0,0)',
  }

  function build_query(q={}, persistQuery=global_options.persistQuery) {
    const { page, ...base } = storedQuery
    let resolvedBase = base
    if (objectHasOneOfKeys(q, global_options.exclusiveQueryParams)) {
      resolvedBase = filterObjectKeys(resolvedBase, global_options.exclusiveQueryParams)
    }
    return persistQuery ? { ...resolvedBase, ...q } : q
  }

  function closeMenu() {
    if (toggleMenu) {
      toggleMenu(id, { isOpen: false })
    }
  }

  function build_entry(item) {
    if (typeof item.visibleFunc !== 'undefined') {
      if (!item.visibleFunc(resolvedContext)) {
        return null
      }
    }

    const targetResolver = item.targetResolver ? item.targetResolver : (global_options.targetResolver ? global_options.targetResolver : null)
    let newLocation = undefined
    let resolvedAction = undefined
    switch (item.targetType) {
      case 'action':
      {
        resolvedAction = actions ? item.target(actions) : undefined
        break
      }
      default:
      {
        const resolvedTarget = targetResolver ? targetResolver(resolvedContext, item.target) : item.target
        const resolvedQuery = build_query(item.q, item.persistQuery)
        const resolvedState = item.state ? item.state() : null
        newLocation = {
          ...(resolvedTarget && { pathname: resolvedTarget }),
          query: resolvedQuery,
          ...(resolvedState && { state: resolvedState }),
        }
      }
    }

    let inner_element = null
    let outerStyle = item.outerStyle ? item.outerStyle : null
    let outerClassName = item.outerClassName ? item.outerClassName : null
    let textContainerStyle = item.textContainerStyle ? item.textContainerStyle : { paddingLeft: '17px', margin: '0' }
    switch (item.elementType) {
      case 'button':
        inner_element = <button className={item.className} style={item.style}><i className={ 'icon fa ' + item.icon } /><span style={textContainerStyle}>{item.data.displayname}</span>{item.iconPost && <i className={ 'fa ' + item.iconPost } style={{ paddingTop: '3px', paddingLeft: '6px' }} />}</button>
        break
      default:
        inner_element = <React.Fragment><i className={ 'icon fa ' + item.icon } />{item.badgetext && <span className="badge badge-u">{item.badgetext}</span>}<p style={textContainerStyle}>{item.data.displayname}{item.iconPost && <i className={ 'fa ' + item.iconPost } style={{ paddingTop: '3px', paddingLeft: '6px' }} />}</p></React.Fragment>
        break
    }
    const actionelement = <MenuLink newLocation={newLocation} action={resolvedAction} {...(outerClassName && { contentClassName: outerClassName })} {...(outerStyle && { contentStyle: outerStyle })} {...(type === 'mobile' && { closeMenu: closeMenu })}>{inner_element}</MenuLink>
    return <div key={item.data.displayname}>{actionelement}</div>
  }

  let header = null
  if (title) {
    const header_content = <div className="menu-title-block">{titleImg && titleImg}<span className={"menu-title-text " + (titleImg ? 'vertical-centered' : '')}>{titleIcon && titleIcon}{title}</span></div>
    header = titleTarget ? <MenuLink newLocation={titleTarget} contentStyle={titleStyle} {...(type === 'mobile' && { closeMenu: closeMenu })}>{header_content}</MenuLink> : <div className="menu-link-content" style={{ padding: '10px', color: '#fff', backgroundColor: '#373a47' }}>{header_content}</div>
  }

  const content = <div className="menu-content">
    {header}
    {
      data.groups.map((group) => {
        if (group.hideCollapsible) {
          return <div key={group.groupid}>
            {
              group.items.map((item) => build_entry(item))
            }
          </div>
        } else {
          return <Collapsible key={group.groupid} trigger={group.displayname} transitionTime={140} triggerClassName="menu-section" triggerOpenedClassName="menu-section" open={group.expanded}>
            {
              group.items.map((item) => build_entry(item))
            }
        </Collapsible>
        }
      })
    }
  </div>

  if (type === 'mobile') {
    const resolved_close_button_style = { ...closeButtonStyle, transition: menuOpen ? 'visibility 0s, opacity 0.1s ease 0.45s' : 'visibility 0s, opacity 0s 0s', opacity: menuOpen ? '1' : '0', visibility: menuOpen ? 'visible' : 'hidden' }
    return <div id={id}>
      <div className="mobile-close-button" style={resolved_close_button_style}><button  onClick={closeMenu} style={{ border: '0', padding: '6px', margin: '2px 5px 2px 5px', backgroundColor: 'transparent' }}><span className='fa fa-times fa-fw' /></button></div>
      <MenuShell
        id={id}
        right={ position === 'right' }
        className={resolvedClassName}
        styles={styles}
        width={width}
        isOpen={menuOpen}
        toggleMenu={toggleMenu}
        {...(hideBurgerIcon ? {customBurgerIcon: false} : {})}
      >
        {content}
      </MenuShell>
    </div>
  } else {
    return <div id={id} className={resolvedClassName}>
      {content}
    </div>
  }
}
