import { push } from 'react-router-redux'

import { get } from 'lib/api'
import { clearData as clearAuctionData } from '../auction'
import { status_defaults, validation_defaults, defineAction, buildError } from 'lib/state_defaults'
import { verify, processRecaptcha } from 'lib/validation'

const base = 'owa/auth/login/'

const LOGIN = defineAction(base, 'LOGIN', 'async')
const LOGOUT = defineAction(base, 'LOGOUT', 'async')
const FORGOT_PASSWORD = defineAction(base, 'FORGOT_PASSWORD', 'async')

const initialState = {
  data: {
    isAuthenticated: false,
    userInfo: {},
  },
  actions: {
    login: {
      meta: status_defaults,
      validation: validation_defaults,
    },
    logout: {
      meta: status_defaults,
    },
    forgotPassword: {
      meta: status_defaults,
      validation: validation_defaults,
    }
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          login: {
            meta: {
              ...status_defaults,
              processing: true,
            },
            validation: validation_defaults,
          }
        }
      }
    case LOGIN.success:
      return {
        ...state,
        data: {
          isAuthenticated: true,
          userInfo: action.payload.user,
        },
        actions: {
          ...state.actions,
          login: {
            ...state.actions.login,
            meta: {
              ...status_defaults,
              ...action.meta,
              // statusText: 'You have been successfully logged in.',
            },
            validation: validation_defaults,
          }
        }
      }
    case LOGIN.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          login: {
            ...state.actions.login,
            meta: {
              ...status_defaults,
              ...action.meta,
            },
            validation: {
              ...validation_defaults,
              ...action.validation,
            }
          }
        }
      }
    case LOGOUT.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          logout: {
            meta: {
              ...status_defaults,
              processing: true,
            },
          }
        }
      }
    case LOGOUT.success:
      return {
        ...state,
        data: {
          isAuthenticated: false,
          userInfo: {},
        },
        actions: {
          ...state.actions,
          logout: {
            ...state.actions.logout,
            meta: {
              ...status_defaults,
              ...action.meta,
              // statusText: 'You have been successfully logged out.',
            },
          },
          login: {
            meta: status_defaults,
            validation: validation_defaults,
          },
        }
      }
    case LOGOUT.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          logout: {
            ...state.actions.logout,
            meta: {
              ...status_defaults,
              ...action.meta,
            },
          }
        }
      }
    case FORGOT_PASSWORD.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          forgotPassword: {
            meta: {
              ...status_defaults,
              processing: true,
            },
            validation: validation_defaults,
          }
        }
      }
    case FORGOT_PASSWORD.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          forgotPassword: {
            ...state.actions.forgotPassword,
            meta: {
              ...status_defaults,
              ...action.meta,
              // statusText: 'A password reset email has been sent.  Please check your inbox.',
            },
            validation: validation_defaults,
          }
        }
      }
    case FORGOT_PASSWORD.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          forgotPassword: {
            ...state.actions.forgotPassword,
            meta: {
              ...status_defaults,
              ...action.meta,
            },
            validation: {
              ...validation_defaults,
              ...action.validation,
            }
          }
        }
      }
    default:
      return state
  }
}

// ZZZ - need to clear out all state data upon logout so there is no stale data associated with a different user when logging in again
export function logout() {
  return (dispatch) => {
    return dispatch(
      get({
        types: LOGOUT,
        endpoint: '/api/auth/logout',
        config: {
          method: 'post',
          body: { logout: true },
        },
        postSuccess: () => {
          dispatch(clearAuctionData())
        }
      })
    )
  }
}

// ZZZ - do we need to await the logout action result before redirecting?
export function logoutAndRedirect() {
  return (dispatch, state) => {
    dispatch(logout());
    return dispatch(push('/login'));
  }
}

export function login(fields, redirect="/") {
  return (dispatch) => {
    const required_fields = ['user_email', 'user_password']

    const errors_present = verify({
      required: required_fields,
    }, fields)

    if (errors_present) {
      return dispatch(
        buildError(LOGIN.fail, { statusText: 'You must enter an email address and a password!', errors_present: errors_present })
      )
    } else {
      return dispatch(
        get({
          types: LOGIN,
          endpoint: '/api/auth/login',
          config: {
            method: 'post',
            body: { username: fields.user_email, password: fields.user_password },
          },
          postSuccess: () => {
            return dispatch(push(redirect))
          }
        })
      )
    }
  }
}

export function processForgotPassword(fields, recaptchaInstance) {
  return async (dispatch) => {
    const required_fields = ['user_email']

    const errors_present = verify({
      required: required_fields,
    }, fields)

    if (errors_present) {
      return dispatch(
        buildError(FORGOT_PASSWORD.fail, { statusText: 'You must enter an email address!', errors_present: errors_present })
      )
    }

    return processRecaptcha(recaptchaInstance, dispatch, FORGOT_PASSWORD, '/api/auth/forgot-password', fields)
  }
}
