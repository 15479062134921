import { useState, useEffect } from 'react'

import { isClient } from 'lib/exenv'

export default function useMedia(query) {
  // ZZZ - need to do some device detection on server-side to better handle reloading
  const initialMatch = isClient ? window.matchMedia(query).matches : false

  const [matches, setMatches] = useState(initialMatch)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) setMatches(media.matches)
    const listener = () => setMatches(media.matches)
    media.addListener(listener)
    return () => media.removeListener(listener)
  }, [matches, query])

  return matches
}
