import { processQuery, get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

import { getSiteInfo } from './siteinfo'

const base = 'owa/consignorStatus/'

const DATA = defineAction(base, 'DATA', 'async')
const ACCEPT_UNDER_RESERVE = defineAction(base, 'ACCEPT_UNDER_RESERVE', 'async')
const REDUCE_RESERVE_PRICE = defineAction(base, 'REDUCE_RESERVE_PRICE', 'async')

const RESET_STATUS = base.concat('RESET_STATUS')
const INPUT_CHANGE = base.concat('INPUT_CHANGE')

const actions_defaults = {
  acceptUnderReserve: status_defaults,
  reduceReserve: status_defaults,
}

const initialState = {
  lots: {},
  lots_order: [],
  meta: status_defaults,
  actions: actions_defaults,
}

function mapLots(state, action) {
  const _lots_order = []

  const _lots = action.payload.data.reduce((o, item, i) => {
    o[item.id] = {
      ...item,
      potential_reduced_reserve_price: state.lots[item.id] ? state.lots[item.id].potential_reduced_reserve_price : null,
    }
    _lots_order[i] = item.id
    return o
  }, {})
  return {
    lots: _lots,
    lots_order: _lots_order,
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        meta: {
          ...status_defaults,
          //initialized: state.meta.initialized,
          timestamp: state.meta.timestamp,
          processing: true,
        }
      }
    case DATA.success:
      return {
        ...state,
        ...mapLots(state, action),
        meta: {
          ...status_defaults,
          ...action.meta,
        }
      }
    case DATA.fail:
      return {
        ...state,
        meta: {
          ...status_defaults,
          ...action.meta,
          //initialized: state.content.meta.initialized,
        }
      }
    case ACCEPT_UNDER_RESERVE.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          acceptUnderReserve: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case ACCEPT_UNDER_RESERVE.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          acceptUnderReserve: {
            ...status_defaults,
            ...action.meta,
          }
        },
        lots: {
          ...state.lots,
          [action.foundation.lot_id]: {
            ...state.lots[action.foundation.lot_id],
            cs_accept: true,
          }
        }
      }
    case ACCEPT_UNDER_RESERVE.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          acceptUnderReserve: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case REDUCE_RESERVE_PRICE.request:
      return {
        ...state,
        actions: {
          ...state.actions,
          reduceReserve: {
            ...status_defaults,
            processing: true,
          }
        }
      }
    case REDUCE_RESERVE_PRICE.success:
      return {
        ...state,
        actions: {
          ...state.actions,
          reduceReserve: {
            ...status_defaults,
            ...action.meta,
          }
        },
        lots: {
          ...state.lots,
          [action.foundation.lot_id]: {
            ...state.lots[action.foundation.lot_id],
            cs_reduce_reserve: true,
          }
        }
      }
    case REDUCE_RESERVE_PRICE.fail:
      return {
        ...state,
        actions: {
          ...state.actions,
          reduceReserve: {
            ...status_defaults,
            ...action.meta,
          }
        }
      }
    case INPUT_CHANGE:
      return {
        ...state,
        lots: {
          ...state.lots,
          [action.lot_id]: {
            ...state.lots[action.lot_id],
            potential_reduced_reserve_price: action.input,
          }
        }
      }
    case RESET_STATUS:
      return {
        ...state,
        actions: actions_defaults,
      }
    default:
      return state
  }
}

export function inputChange(name, value, options={}) {
  return {
    type: INPUT_CHANGE,
    input: value,
    lot_id: options.lot_id,
  }
}

export function resetStatus() {
  return {
    type: RESET_STATUS,
  }
}

export function getData(location) {
  return (dispatch, getState) => {
    const query = location.query
    const querydata = processQuery(query, { pageSize: 0 })
    const endpoint = '/api/user/consignor/getconsignorlots' + querydata
    return dispatch(
      get({
        types: DATA,
        endpoint: endpoint,
        postSuccess: (result) => {
          if (getState().siteinfo.data.cachetime !== result.payload.siteinfo_cachetime) {
            console.log('*SITEINFO NEEDS REFRESH!*')
            dispatch(getSiteInfo())
          }
        }
      })
    )
  }
}

export function acceptUnderReserve(lot_id) {
  return (dispatch) => {
    const endpoint = '/api/user/consignor/accept-under-reserve/' + lot_id

    return dispatch(
      get({
        types: ACCEPT_UNDER_RESERVE,
        endpoint: endpoint,
        config: {
          method: 'post',
        },
        foundation: { lot_id },
      })
    )
  }
}

export function reduceReservePrice(lot_id) {
  return (dispatch, getState) => {
    const endpoint = '/api/user/consignor/reduce-reserve-price/' + lot_id
    const reduce_reserve_price = getState().consignorStatus.lots[lot_id].potential_reduced_reserve_price

    return dispatch(
      get({
        types: REDUCE_RESERVE_PRICE,
        endpoint: endpoint,
        config: {
          method: 'post',
          body: { reduce_reserve_price },
        },
        foundation: { lot_id },
      })
    )
  }
}
