
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Helmet } from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'

import { connect } from 'react-redux'
import { processForgotPassword } from 'rdx/modules/auth/login'

import FusedFields from '../shared/fused-fields'
import FormRow from '../shared/FormRow'
import FieldText from '../shared/FieldText'
import StatusBox from '../shared/StatusBox'

const fields_initialstate = {
  user_email: '',
}

ForgotPassword.propTypes = {
  location: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  processForgotPassword: PropTypes.func.isRequired,
}

function ForgotPassword({ location, meta, validation, processForgotPassword }) {
  const [fields, setFields] = useState(fields_initialstate)
  // const StatusBoxMemo = useMemo(() => <StatusBox data={meta} size="small" />, [meta])
  const recaptchaRef = useRef()

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _processForgotPassword = () => {
    processForgotPassword(fields, recaptchaRef.current)
  }

  const formContainer =
  <form className="reg-page">
    <div className="reg-header">
      <h2>{meta.status === 1 ? 'Password Reset Email Sent' : 'Forgot your Password?'}</h2>
    </div>
    <div>
      {meta.status !== 1 && <h5>Enter your account email address<br />and we will send you a link to reset your password</h5>}
      {meta.status !== 1 && <FusedFields data={fields} errors={validation.required} onUpdate={_handleUpdate}>
        <FormRow>
          <FieldText name="user_email" addonBefore={<i className="fa fa-fw fa-envelope-o" />} placeholder="Email (Username)" groupClassName="col-lg-12" />
        </FormRow>
      </FusedFields>}
      <StatusBox data={meta} size="small" persist={true} className='margin-bottom-20' />
      {meta.status !== 1 && <div className="row">
        <div className="col-md-12">
          <button type="button" className="btn-u pull-right" onClick={_processForgotPassword}>{'Send Password Reset Email '}<span className='fa fa-angle-double-right' /></button>
        </div>
      </div>}
    </div>
    <hr />
    <h5>{<Link to="/login">Back to Login <i className="fa fa-arrow-right" aria-hidden="true"></i></Link>}</h5>
  </form>

  return (
    <div className="container content">
      <Helmet>
        <title>Forgot Password</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div className="row">
        <div className="col-sm-6 col-sm-offset-3">
          {formContainer}
        </div>
        <div style={{ zIndex: '1000' }}>
          <ReCAPTCHA
            size="invisible"
            ref={recaptchaRef}
            sitekey={__RECAPTCHA_KEY__}
            onExpired={() => {
              console.log('ReCAPTCHA-expired')
            }}
            onErrored={() => {
              console.log('ReCAPTCHA-errored')
            }}
          />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    meta: state.auth.login.actions.forgotPassword.meta,
    validation: state.auth.login.actions.forgotPassword.validation,
  };
}

export default connect(mapStateToProps, { processForgotPassword })(ForgotPassword)
