import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import { connect } from 'react-redux'

import Slide from '../shared/Slide'

const slick_settings = {
  autoplay: true,
  autoplaySpeed: 8000,
  arrows: true,
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  // useTransform: false,
  //lazyLoad: true,
}

SliderManager.propTypes = {
  slides: PropTypes.array.isRequired,
}

function SliderManager({ slides }) {
  return <div className="home-slider-container">
    <Slider {...slick_settings} className="home-slider">
      {slides.map((s) => {
        return <Slide key={s.id} data={s} />
      })}
    </Slider>
  </div>
}

function mapStateToProps(state) {
  return {
    slides: state.slides.content.data,
  }
}

export default connect(mapStateToProps)(SliderManager)