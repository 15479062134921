import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { getLookupData } from 'rdx/modules/admin/lookupData'

import { SiteView } from '../base/components/siteview-context'
import Sidebar from '../shared/Sidebar'
import Menu from '../base/components/Menu/Menu'
import StatusBox from '../shared/StatusBox'

import { menu_content_admin } from 'lib/constants'

DashboardAdmin.propTypes = {
  children: PropTypes.node.isRequired,
  routes: PropTypes.array.isRequired,
  lookupData: PropTypes.object.isRequired,
  getLookupData: PropTypes.func.isRequired,
}

function DashboardAdmin({ children, routes, lookupData, getLookupData }) {
  useEffect(() => {
    getLookupData()
  }, [getLookupData])

  const hide_sidebar = Object.prototype.hasOwnProperty.call(routes[routes.length - 1], 'hide_sidebar')
  return <SiteView.Consumer>
    {mode => (
      <div className="owa-row">
        {mode.show_sidebar && !hide_sidebar ? <Sidebar>
          <Menu
            id="standard-menu-admin"
            hideOnMobile={true}
            data={menu_content_admin}
          />
        </Sidebar> : null}
        <div className="owa-column owa-content-margins">
          <StatusBox data={lookupData.meta.all} hideOnSuccess={true} />
          {lookupData.meta.all.status === 1 && children && React.cloneElement(children, {lookupData: lookupData.data})}
        </div>
      </div>
    )}
  </SiteView.Consumer>
}

function mapStateToProps(state) {
  return {
    lookupData: state.admin.lookupData,
  }
}

export default connect(mapStateToProps, {
  getLookupData,
})(DashboardAdmin)
