import { isClient } from 'lib/exenv'
import { basepath, fetcher, parseResult, parseError } from 'lib/api'

export const fetchManager = store => next => action => {
  const f = action['@@fetch']
  if (typeof f === 'undefined') {
    return next(action)
  }

  // console.log('fetchManager:', f)
  const state = store.getState()
  let cookie = {}

  if (isClient && state.ssr[f.endpoint] === true) {
    return next({
      type: 'owa/ssr/UNFLAG_SSR',
      endpoint: f.endpoint,
    })
  }

  if (f.abortCondition) {
    // abort the fetch before it even occurs
    // needs to happen after any ssr unflagging
    return
  }

  if (!isClient) {
    cookie = state.cookie
  }

  next({ ...(f.foundation && { foundation: f.foundation }), type: f.types.request })
  return fetcher(basepath + f.endpoint, f.config, cookie)
  .then(json => {
    if (json.meta && json.meta.status === 1) {
      if (!isClient) {
        next({
          type: 'owa/ssr/FLAG_SSR',
          endpoint: f.endpoint,
        })
      }
      const formattedResult = typeof f.resultsFormatter !== 'undefined' ? f.resultsFormatter(json) : json
      // console.log('formattedResult:', formattedResult)
      const enhancedResult = typeof f.inject !== 'undefined' ? { ...formattedResult, ...f.inject } : formattedResult
      // console.log('enhancedResult:', enhancedResult)
      const finalResult = parseResult({ ...(f.foundation && { foundation: f.foundation }), ...enhancedResult })
      // console.log('finalResult:', finalResult)
      if (typeof f.onSuccess !== 'undefined') {
        f.onSuccess(finalResult)
        // don't need to call next since we deferred to provided onSuccess function, but we could if we want to denote the end of success processing
        // next({ type: f.types.end })
      } else {
        next({ ...finalResult, type: f.types.success })
      }
      if (typeof f.postSuccess !== 'undefined') {
        f.postSuccess(json)
      }
      // console.info('completed action:', action)
      return true
    } else {
      if (json.meta) {
        next({ ...(f.foundation && { foundation: f.foundation }), type: f.types.fail, ...parseError(f.types.id, json) })
      } else {
        throw Error('No meta object in json response: ' + basepath + f.endpoint)
      }
    }
  })
  .catch(error => {
    console.log(f.types.id + ':fetch-error', error)
    next({ ...(f.foundation && { foundation: f.foundation }), type: f.types.fail, ...parseError(f.types.id, error) })
  })
}
