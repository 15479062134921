import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getData, setFieldValue, adminUpdateStatement, lineitemAdd, lineitemRemove } from 'rdx/modules/statement'

import ContentPanel from '../shared/ContentPanel'
import StatementContentUser from './StatementContentUser'
import StatementContentAdmin from './StatementContentAdmin'
import { formatQueryParams } from 'lib/utility'

StatementDetail.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  lookupData: PropTypes.object,
  statement: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  adminUpdateStatement: PropTypes.func.isRequired,
  lineitemAdd: PropTypes.func.isRequired,
  lineitemRemove: PropTypes.func.isRequired,
}

StatementDetail.readyOnActions = (dispatch, params, location) => {
  const statement_id = params && params.statement_id ? parseInt(params.statement_id) : 0
  return Promise.all([
    dispatch(getData(location.pathname.includes('/admin/') ? 'admin' : 'user', statement_id)),
  ])
}

function StatementDetail({ location, params, lookupData, statement, router, getData, setFieldValue, adminUpdateStatement, lineitemAdd, lineitemRemove }) {
  useEffect(() => {
    const statement_id = params && params.statement_id ? parseInt(params.statement_id) : 0
    getData(location.pathname.includes('/admin/') ? 'admin' : 'user', statement_id)
  }, [location, params, getData])

  const mode = location.pathname.includes('/admin/') ? 'admin' : 'user'

  const _backToList = () => {
    let new_query = Object.assign({}, location.query)
    router.push({ ...location, pathname: '/' + mode + '/statements', query: new_query })
  }

  const _prev = () => {
    let new_query = Object.assign({}, location.query)
    router.push({ ...location, pathname: '/' + mode + '/statement/' + statement.content.prev, query: new_query })
  }

  const _next = () => {
    let new_query = Object.assign({}, location.query)
    router.push({ ...location, pathname: '/' + mode + '/statement/' + statement.content.next, query: new_query })
  }

  const adminMode = mode === 'admin'
  if (statement.meta.status === 0 || statement.meta.processing || (adminMode && !statement.content.admin_info.meta.loaded)) {
    return <div>Loading...</div>
  }

  const status = statement.content.main.st_paid ? 'Paid' : (statement.content.main.st_ready ? 'Ready' : 'Not Ready')

  const content = adminMode && typeof location.query.printview === 'undefined' ?
    <StatementContentAdmin
      statement={statement}
      setFieldValue={setFieldValue}
      adminUpdateStatement={adminUpdateStatement}
      lineitemAdd={lineitemAdd}
      lineitemRemove={lineitemRemove}
      consignor_commission_schemes={lookupData.consignor_commission_schemes}
    />
  :
    <StatementContentUser
      statement={statement}
    />

  return <div>
    <Helmet>
      <title>{'Statement #' + statement.content.main.auction_id + '-' + statement.content.main.id}</title>
    </Helmet>
    <ContentPanel
      icon="fa fa-file-text-o fa-fw"
      title={"Statement #" + statement.content.main.auction_id + '-' + statement.content.main.id}
      titleSuffix={'(' + (typeof location.query.printview === 'undefined' ? status : statement.content.main.fullname) + ')'}
      titleRight={status}
      showBackToList={adminMode}
      backToListAction={adminMode ? _backToList : null}
      prevAction={statement.content.prev ? _prev : null}
      nextAction={statement.content.next ? _next : null}
      filteredText={adminMode ? formatQueryParams(location.query) : null}
      showPagingControls={true}
    >
      {content}
    </ContentPanel>
  </div>
}

function mapStateToProps(state) {
  return {
    statement: state.statement,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getData, setFieldValue, adminUpdateStatement, lineitemAdd, lineitemRemove }, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StatementDetail)