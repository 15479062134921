import { configureStore } from '@reduxjs/toolkit'
import { routerMiddleware } from 'react-router-redux'
import { browserHistory } from 'react-router'

import rootReducer from '../modules/reducer'

import { isClient } from 'lib/exenv'
import { fetchManager } from '../middleware/fetchManager'
import { analyticsTracking } from '../middleware/analyticsTracking'

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware => {
      const middleware = getDefaultMiddleware().concat(fetchManager, routerMiddleware(browserHistory))
      if (isClient && __TRACKING_ACTIVE__) {
        middleware.push(analyticsTracking)
      }
      return middleware
    },
    // Turn off devtools in prod, or pass options in dev
    // devTools: process.env.NODE_ENV === 'production' ? false : {
    //   stateSanitizer: stateSanitizerForDevtools,
    // }
    devTools: process.env.NODE_ENV === 'production' ? false : true,
  })
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../modules/reducer', () => store.replaceReducer(rootReducer))
  }
  return store
}
