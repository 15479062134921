import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getAuctions } from 'rdx/modules/info/auctions'

import SimpleContainer from '../../shared/SimpleContainer'
import DataList from '../../shared/DataList'
import DataListRow from '../../shared/DataListRow'
import StatusBox from '../../shared/StatusBox'

import connectDataList from '../../shared/composers/connectDataList'

import { info_auctions_options } from 'lib/constants'

const DataListBound = connectDataList(DataList, state => ({ statebranch: state.info.auctions.content }))

Auctions.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  auctions: PropTypes.object.isRequired,
  getAuctions: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
}

Auctions.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getAuctions(params)),
  ])
}

function Auctions({ location, params, auctions, getAuctions, router }) {
  useEffect(() => {
    getAuctions(params)
  }, [params, getAuctions])

  const _onGridRowClick = (row) => {
    const path = '/catalog/archive'
    const query = { auction_id: row.id }
    router.push({ ...location, pathname: path, query: query })
  }

  let content = null

  const title = params.auctionType === 'upcoming' ? 'Auction Calendar' : 'List of Past Auctions'

  if (auctions.content.meta.status > 1) {
    content = <StatusBox data={auctions.content.meta} />
  } else {
    content = <DataListBound
      useTable={true}
      showHeader={true}
      showTopPagingControl={false}
      showBottomPagingControl={false}
      metadata={info_auctions_options[params.auctionType]}
      datakey='id'
      clickTargetFunction={_onGridRowClick}
      params={params}
      location={location}
    >
      <DataListRow />
    </DataListBound>
  }

  return <SimpleContainer format='center-container-shadowed'>
    <div>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <div className="titlebar heading heading-v1 text-center">
        <h2><i className="fa fa-calendar fa-fw"></i> {title}</h2>
      </div>
      {params.auctionType === 'past' && <div className="text-center" style={{ marginBottom: '20px', borderTop: '0px solid #ccc' }}>
        <h4 className="colored-text">Click on a past auction to browse/search lots in the interactive auction catalog</h4>
      </div>}
      {content}
    </div>
  </SimpleContainer>
}

function mapStateToProps(state) {
  return {
    auctions: state.info.auctions,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getAuctions }, dispatch),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auctions)