import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getData } from 'rdx/modules/admin/auctions'

import ContentPanel from '../shared/ContentPanel'
import DataList from '../shared/DataList'
import DataListRow from '../shared/DataListRow'

import FieldText from '../shared/FieldText'
import FormContainer from '../shared/FormContainer'
import FormRow from '../shared/FormRow'
import FusedFields from '../shared/fused-fields'

import { formatQueryParams, hydrateQueryFields } from 'lib/utility'
import { auctionList_specs } from 'lib/list_specifications'

import connectDataList from '../shared/composers/connectDataList'

const DataListBound = connectDataList(DataList, state => ({ statebranch: state.admin.auctions.content }))

const fields_initialstate = {
  auction_id: '',
  user_lastname: '',
  country_type: '',
  ready: '',
  changed: '',
  approved: '',
  paid: '',
}

AuctionList.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
}

AuctionList.readyOnActions = (dispatch, params, location) => {
  return Promise.all([
    dispatch(getData(location)),
  ])
}

function AuctionList({ location, params, router, getData }) {
  const [fields, setFields] = useState({ ...fields_initialstate, ...(hydrateQueryFields(location.query, fields_initialstate))})
  useEffect(() => {
    getData(location)
  }, [location, getData])

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _handleFilter = () => {
    let new_query = {}
    Object.keys(fields).map(key => {
      if (typeof fields[key] !== 'undefined' && fields[key] !== ''){ new_query[key] = fields[key] }
    })
    router.push({ ...location, query: new_query })
  }

  const _clearFilter = () => {
    router.push({ ...location, query: {} })
    setFields(fields_initialstate)
  }

  let content = <DataListBound
    useTable={true}
    showHeader={true}
    showTopPagingControl={false}
    pagingControlSize='slim'
    metadata={auctionList_specs}
    datakey='id'
    clickTargetTemplate={location.pathname === '/admin/auctions' ? '/admin/auction/<param1>' : '/user/auction/<param1>'}
    clickTargetParam1='id'
    clickTargetQueryParams={location.query}
    params={params}
    location={location}
  >
    <DataListRow />
  </DataListBound>

  const search_fields = <FusedFields data={fields} onUpdate={_handleUpdate}>
    <FormContainer rowClassName="fixed-gutter-5 margin-bottom-5" size='small' noMargins={true} addonBeforeStyle={{ textAlign: 'left', flexShrink: '0' }}>
      <FormRow>
        <FieldText name='auction_id' addonBefore='Auction #' groupClassName='col-lg-4' data-type='unsigned-int' />
        <div className='col-lg-8'>
          <button type='submit' className='btn btn-u btn-u-dark-blue pull-right' onClick={_handleFilter} style={{ marginLeft: '10px' }}>
            <span className='fa fa-filter fa-fw' />
            {' Filter'}
          </button>
          <button type='submit' className='btn btn-u btn-u-red pull-right' onClick={_clearFilter}>
            <span className='fa fa-times fa-fw' />
            {' Clear Filter'}
          </button>
        </div>
      </FormRow>
    </FormContainer>
  </FusedFields>

  return <ContentPanel
    title="Auctions"
    icon="fa fa-gavel fa-fw"
    filteredText={formatQueryParams(location.query)}
  >
    <div>
      <div className="clearfix">
        {search_fields}
      </div>
      {content}
    </div>
  </ContentPanel>
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ getData }, dispatch),
    dispatch,
  }
}

export default connect(null, mapDispatchToProps)(AuctionList)