import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Helmet } from 'react-helmet'

import { connect } from 'react-redux'
import { verifyToken, resetPassword } from 'rdx/modules/auth/process'

import FusedFields from '../shared/fused-fields'
import FormRow from '../shared/FormRow'
import FieldBase from '../shared/FieldBase'
import NotificationBox from '../shared/NotificationBox'
import StatusBox from '../shared/StatusBox'
import PasswordValidator from '../shared/PasswordValidator'

const fields_initialstate = {
  user_password_new: '',
  user_password_new_confirm: '',
}

ProcessingAction.propTypes = {
  message: PropTypes.string.isRequired,
}

function ProcessingAction({ message }) {
  return <div>
    <h1 style={{ textAlign: 'center' }}>{ message }</h1>
    <div className="col-lg-12">
      <div className="text-center">
        <img src="/static/img/loading_dots.gif" alt=""/>
      </div>
    </div>
  </div>
}

ProcessAccountAction.propTypes = {
  params: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  verifyToken: PropTypes.func,
  resetPassword: PropTypes.func,
}

function ProcessAccountAction({ params, actions, verifyToken, resetPassword }) {
  const [fields, setFields] = useState({ ...fields_initialstate })
  useEffect(() => {
    verifyToken(params.mode, params.token)
  }, [params, verifyToken])

  const _handleUpdate = (field, value) => {
    setFields(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const _processResetPassword = () => {
    // e.preventDefault();
    resetPassword(params.token, fields)
  }

  let validation_problems = false
  let contentVerified, contentRejected, contentSuccess, contentFail

  const formErrorsNotification = <FormRow>
    <div>
      <NotificationBox mode='danger'>
        {actions.resetPassword.meta.statusText ? <div>{actions.resetPassword.meta.statusText}</div> : null}
        {actions.resetPassword.validation.required ? <div>Some required data is missing</div> : null}
        {actions.resetPassword.validation.other && actions.resetPassword.validation.other.length ?
          actions.resetPassword.validation.other.map((message, i) => {
            return <div key={i}>{message}</div>
          })
          : null
        }
      </NotificationBox>
    </div>
  </FormRow>

  switch (params.mode) {
    case 'confirm-registration':
    {
      contentRejected = <div className="form-general" style={{ fontSize: '16px' }}>
        <p>The account information could not be verified.</p>
        <p>It&apos;s possible that the registration expired, the link was improperly formatted in the email, or you already verified your registration using this link.  If you still need to verify your registration, please go back to the email and try clicking the link again or copying and pasting the link into your browser&apos;s address bar.</p>
        <p>If you continue to have problems you may need to try creating your account again.  If you would like to do that please <Link to="/register">click here</Link>.</p>
        <p>If you have any difficulties, please contact us.</p>
      </div>
      contentVerified = <div className="form-general" style={{ fontSize: '16px' }}>
        <p>Your account is ready to use!</p>
        <p>To login, please <Link to="/login">click here</Link>.</p>
        <p>If you have any difficulties, please contact us.</p>
      </div>
      break
    }
    case 'reset-password':
    {
      validation_problems = actions.resetPassword.validation.required || actions.resetPassword.validation.other.length > 0
      contentRejected = <div className="form-general" style={{ fontSize: '16px' }}>
        <p>The Forgot Password link could not be verified.</p>
        <p>It&apos;s possible that the link expired, was improperly formatted in the email, or you already reset your password using this link.  If you still need to reset your password, please go back to the email and try clicking the link again or copying and pasting the link into your browser&apos;s address bar.</p>
        <p>If you continue to have problems you may need to go through the Forgot Password steps again on the Login page.  If you would like to do that please <Link to="/login">click here</Link>.</p>
        <p>If you have any difficulties, please contact us.</p>
      </div>
      contentVerified = <div>
        <div>
          <h1 style={{ textAlign: 'center' }}>Reset your Password</h1>
          <form action="#" className="form-general clearfix" style={{ height: '100%', position: 'relative' }}>
            {actions.resetPassword.meta.processing && <div style={{ display: 'inline-block', position: 'absolute', zIndex: '1000', backgroundColor: '#bbbbbb80', height: '100%', width: '100%', top: '0', left: '0' }} />}
            <FusedFields data={fields} errors={actions.resetPassword.validation.required} onUpdate={_handleUpdate}>
              <FormRow>
                <div className="col-md-6">
                  <div className="row">
                    <FieldBase type="password" name="user_password_new" autoComplete="new-password" required label="Enter your new password" groupClassName="col-md-12" />
                  </div>
                  <div className="row">
                    <FieldBase type="password" name="user_password_new_confirm" autoComplete="new-password" required label="Confirm your new password" groupClassName="col-md-12" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <PasswordValidator value={fields.user_password_new} confirmValue={fields.user_password_new_confirm} />
                  </div>
                </div>
              </FormRow>
              {actions.resetPassword.meta.status > 1 ? formErrorsNotification : null}
              <FormRow>
                <div className="form-group col-md-12">
                  <button type='button' className='pull-right btn btn-u' onClick={_processResetPassword} disabled={fields.user_password_new === '' || fields.user_password_new !== fields.user_password_new_confirm}>
                    {'Reset Password '}
                    <span className='fa fa-angle-double-right' />
                  </button>
                </div>
              </FormRow>
            </FusedFields>
          </form>
        </div>
      </div>
      contentSuccess = <div className="form-general" style={{ fontSize: '16px' }}>
        <p>To login, please <Link to="/login">click here</Link>.</p>
        <p>If you have any difficulties, please contact us.</p>
      </div>
      contentFail = <div className="form-general" style={{ fontSize: '16px' }}>
        <p>Unfortunately we are unable to reset your password.</p>
        <p>It&apos;s possible that the allowed time period expired or that the password was already reset.</p>
        <p>If you still need to reset your password, please go through the Forgot Password steps again on the Login page <Link to="/login">here</Link>.</p>
        <p>If you have any difficulties, please contact us.</p>
      </div>
      break
    }
    default:
      return <div>Invalid Action Mode</div>
  }

  let content = <div>
    {(actions.verifyToken.meta.status === 2 || (actions.resetPassword.meta.status === 2 && !validation_problems)) && <NotificationBox mode='danger'>
      <span style={{ fontSize: '20px' }}>Something went wrong</span>
    </NotificationBox>}
    {(actions.verifyToken.meta.status === 1 && params.mode === 'confirm-registration') && <NotificationBox mode='success'>
      <span style={{ fontSize: '20px' }}>Registration verified!</span>
    </NotificationBox>}
    {(actions.resetPassword.meta.status === 0 || (actions.resetPassword.meta.status === 2 && validation_problems)) && <StatusBox data={actions.verifyToken.meta} format="hidden" auxContentProcessing={<ProcessingAction message="Verifying Request" />} auxContentSuccess={contentVerified} auxContentFail={contentRejected} />}
    {(actions.resetPassword.meta.status === 1) && <NotificationBox mode='success'>
      <span style={{ fontSize: '20px' }}>Your password has been reset!</span>
    </NotificationBox>}
    <StatusBox data={actions.resetPassword.meta} format="hidden" auxContentProcessing={<ProcessingAction message="Processing password reset" />} auxContentSuccess={contentSuccess} auxContentFail={!validation_problems ? contentFail : null} />
  </div>

  return (
    <div className="container content">
      <Helmet>
        <title>Account Action</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="row">
        <div className="col-sm-offset-2 col-sm-8">
          {content}
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    actions: state.auth.process.actions,
  }
}

export default connect(mapStateToProps, { verifyToken, resetPassword })(ProcessAccountAction)
