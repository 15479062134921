import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { getOperationsInfo, expireArticleCache, testAuctionClosedEmail, resendAuctionClosedEmail, getRemoteContacts, updateAllRemoteContacts, resolveContacts, performDiagnosticAction, resetOperationsStatus } from 'rdx/modules/admin/operations'

import ContentPanel from '../shared/ContentPanel'
import StatusBox from '../shared/StatusBox'

AuctionOperations.propTypes = {
  operations: PropTypes.object.isRequired,
  getOperationsInfo: PropTypes.func.isRequired,
  expireArticleCache: PropTypes.func.isRequired,
  testAuctionClosedEmail: PropTypes.func.isRequired,
  resendAuctionClosedEmail: PropTypes.func.isRequired,
  getRemoteContacts: PropTypes.func.isRequired,
  updateAllRemoteContacts: PropTypes.func.isRequired,
  resolveContacts: PropTypes.func.isRequired,
  performDiagnosticAction: PropTypes.func.isRequired,
  resetOperationsStatus: PropTypes.func.isRequired,
}

function AuctionOperations({ operations, getOperationsInfo, expireArticleCache, testAuctionClosedEmail, resendAuctionClosedEmail, getRemoteContacts, updateAllRemoteContacts, resolveContacts, performDiagnosticAction, resetOperationsStatus }) {
  useEffect(() => {
    getOperationsInfo()
    resetOperationsStatus()
  }, [getOperationsInfo, resetOperationsStatus])

  const content = <form className='page-base'>
    <div className="tag-box tag-box-v3">
      <div className="section-summary" style={{ fontSize: '18px' }}>
        <span className='fa fa-minus-circle fa-fw' /> Clear Cached Article Data
      </div>
      <div className="section-description clearfix">
        This action will expire the cached article data (both the recent articles section in the footer and the article archive)
      </div>
      <div className="section-description clearfix margin-top-10">
        <button type='button' className='btn btn-u btn-u-dark-red pull-right' onClick={expireArticleCache}>
          Clear Cached Article Data
          <span className='fa fa-angle-double-right fa-fw' />
        </button>
      </div>
      <StatusBox data={operations.actions.expireArticleCache} style={{ marginTop: '10px' }} />
    </div>
    <div className="tag-box tag-box-v3">
      <div className="section-summary" style={{ fontSize: '18px' }}>
        <span className='fa fa-arrow-right fa-fw' /> Test Auction Closed Email
      </div>
      <div className="section-description clearfix">
        <div className="flex-generic-outer-container margin-bottom-10">
          <div className="flex-generic-container child-gutter-10">
            <div><span className='fa fa-calendar fa-fw' /> Under Reserve Offer Process Date:<br /><span className='fa fa-fw' /> <span className="text-bold">{operations.operationsInfo.data.text_date_under_reserve_offer_process}</span></div>
            <div><span className='fa fa-calendar fa-fw' /> Post-Auction Sale End Date/Time:<br /><span className='fa fa-fw' /> <span className="text-bold">{operations.operationsInfo.data.text_date_post_sale_end}</span></div>
          </div>
        </div>
        This action will test the Auction Closed Email for the current auction by sending it only to the admin address. <span className="text-bold">No emails will be sent to any users/bidders.</span>
      </div>
      <div className="section-description clearfix margin-top-10">
        <button type='button' className='btn btn-u btn-u-dark-red pull-right' onClick={testAuctionClosedEmail}>
          Test Auction Closed Email
          <span className='fa fa-angle-double-right fa-fw' />
        </button>
      </div>
      <StatusBox data={operations.actions.testAuctionClosedEmail} style={{ marginTop: '10px' }} />
    </div>
    <div className="tag-box tag-box-v3">
      <div className="section-summary" style={{ fontSize: '18px' }}>
        <span className='fa fa-arrow-right fa-fw' /> Resend Auction Closed Email
      </div>
      <div className="section-description clearfix">
        <div className="flex-generic-outer-container margin-bottom-10">
          <div className="flex-generic-container child-gutter-10">
            <div><span className='fa fa-calendar fa-fw' /> Under Reserve Offer Process Date:<br /><span className='fa fa-fw' /> <span className="text-bold">{operations.operationsInfo.data.text_date_under_reserve_offer_process}</span></div>
            <div><span className='fa fa-calendar fa-fw' /> Post-Auction Sale End Date/Time:<br /><span className='fa fa-fw' /> <span className="text-bold">{operations.operationsInfo.data.text_date_post_sale_end}</span></div>
          </div>
        </div>
        This action will resend the Auction Closed Email to ALL bidders for the current auction.  This should only be necessary if there was incorrect content in the email that needed to be modified or some other issue that prevented the original email from being delivered.  <span className="text-bold">Only perform this operation after any necessary content updates have been made.</span>
      </div>
      <div className="section-description clearfix margin-top-10">
        <button type='button' className='btn btn-u btn-u-dark-red pull-right' onClick={resendAuctionClosedEmail}>
          Resend Auction Closed Email
          <span className='fa fa-angle-double-right fa-fw' />
        </button>
      </div>
      <StatusBox data={operations.actions.resendAuctionClosedEmail} style={{ marginTop: '10px' }} />
    </div>
    <div className="tag-box tag-box-v3">
      <div className="section-summary" style={{ fontSize: '18px' }}>
        <span className='fa fa-arrow-right fa-fw' /> Download copy of remote contacts into local table
      </div>
      <div className="section-description clearfix">
        This will create a snapshot of the Mailchimp contact data in a local table.  This is used as part of a manual sync process, and also to examine/compare contact data between local/remote sources.
      </div>
      <div className="section-description clearfix margin-top-10">
        <button type='button' className='btn btn-u btn-u-dark-red pull-right' onClick={getRemoteContacts}>
          Download Remote Contacts
          <span className='fa fa-angle-double-right fa-fw' />
        </button>
      </div>
      <StatusBox data={operations.actions.getRemoteContacts} style={{ marginTop: '10px' }} />
    </div>
    <div className="tag-box tag-box-v3">
      <div className="section-summary" style={{ fontSize: '18px' }}>
        <span className='fa fa-arrow-right fa-fw' /> Update all remote contacts with local data
      </div>
      <div className="section-description clearfix">
        This will replace all Mailchimp contact data with the local OWA info for each user.  Be careful as this will overwrite any Mailchimp data for users with matching email addresses.
      </div>
      <div className="section-description clearfix margin-top-10">
        <button type='button' className='btn btn-u btn-u-dark-red pull-right' onClick={updateAllRemoteContacts}>
          Update all remote contacts
          <span className='fa fa-angle-double-right fa-fw' />
        </button>
      </div>
      <StatusBox data={operations.actions.updateAllRemoteContacts} style={{ marginTop: '10px' }} />
    </div>
    <div className="tag-box tag-box-v3">
      <div className="section-summary" style={{ fontSize: '18px' }}>
        <span className='fa fa-arrow-right fa-fw' /> Resolve Contacts
      </div>
      <div className="section-description clearfix">
        This will perform an analysis of the current snapshot of remote contact data (x_remote_contacts) with the user table.  The results will be stored in the x_contact_resolution table.
      </div>
      <div className="section-description clearfix margin-top-10">
        <button type='button' className='btn btn-u btn-u-dark-red pull-right' onClick={resolveContacts}>
          Resolve Contacts
          <span className='fa fa-angle-double-right fa-fw' />
        </button>
      </div>
      <StatusBox data={operations.actions.resolveContacts} style={{ marginTop: '10px' }} />
    </div>
    <div className="tag-box tag-box-v3">
      <div className="section-summary" style={{ fontSize: '18px' }}>
        <span className='fa fa-arrow-right fa-fw' /> Perform One-Time Diagnostic or Action
      </div>
      <div className="section-description clearfix">
        This action is to be used when a specific diagnostic or one-time procedure needs to be performed.  Normally this will be disabled
      </div>
      <div className="section-description clearfix margin-top-10">
        <button type='button' className='btn btn-u btn-u-dark-red pull-right' onClick={performDiagnosticAction}>
          Perform One-Time Action
          <span className='fa fa-angle-double-right fa-fw' />
        </button>
      </div>
      <StatusBox data={operations.actions.performDiagnosticAction} style={{ marginTop: '10px' }} />
    </div>
  </form>

  return <ContentPanel
    title="Operations"
    icon="fa fa-star fa-fw"
  >
    <div>
      {content}
    </div>
  </ContentPanel>
}

function mapStateToProps(state) {
  return {
    operations: state.admin.operations,
  }
}

export default connect(mapStateToProps, {
  getOperationsInfo,
  expireArticleCache,
  testAuctionClosedEmail,
  resendAuctionClosedEmail,
  getRemoteContacts,
  updateAllRemoteContacts,
  resolveContacts,
  performDiagnosticAction,
  resetOperationsStatus,
})(AuctionOperations)