import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import RecentArticles from './RecentArticles'

import { company_address_line1, company_address_line2 } from 'lib/constants'

const currentYear = new Date().getFullYear()

Footer.propTypes = {
  articles: PropTypes.object.isRequired,
}

function Footer({ articles }) {
  return <div className="footer-v1">
    <div className="footer">
      <div className="container">
        <div className="row">
          {/* About */}
          <div className="col-md-3 md-margin-bottom-40">
            <div className="headline"><h2>About Us</h2></div>
            <p>Old World Auctions specializes in genuine antique maps, atlases and decorative graphics originating between the 14th and 19th centuries. </p>
            <p><Link to="/resources/about-us"><i className="fa fa-chevron-circle-right"></i> Read more about us...</Link></p>
          </div>{/*/col-md-3*/}
          {/* End About */}

          {/* Latest */}
          <div className="col-md-3 md-margin-bottom-40">
            <RecentArticles articles={articles} />
          </div>{/*/col-md-3*/}
          {/* End Latest */}

          {/* Link List */}
          <div className="col-md-3 md-margin-bottom-40">
            <div className="headline"><h2>General Info</h2></div>
            <ul className="list-unstyled link-list">
              <li><Link to="/resources/about-us"><div>About us<i className="fa fa-angle-right"></i></div></Link></li>
              <li><Link to="/resources/faq"><div>FAQ<i className="fa fa-angle-right"></i></div></Link></li>
              <li><Link to="/resources/terms-and-conditions"><div>Terms & Conditions<i className="fa fa-angle-right"></i></div></Link></li>
              <li><Link to="/resources/references-cited"><div>References Cited<i className="fa fa-angle-right"></i></div></Link></li>
            </ul>
          </div>{/*/col-md-3*/}
          {/* End Link List */}

          {/* Address */}
          <div className="col-md-3 map-img md-margin-bottom-40">
            <div className="headline"><h2>Contact Info</h2></div>
            <address className="md-margin-bottom-40">
              {company_address_line1}<br />
              {company_address_line2}<br />
              Phone: 804 290 8090<br />
              Email: info@oldworldauctions.com
            </address>
          </div>{/*/col-md-3*/}
          {/* End Address */}
        </div>
      </div>
    </div>{/*/footer*/}

    <div className="copyright">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p>
              <span style={{ paddingRight: '3px' }}>&copy; {currentYear} - All Rights Reserved</span> |
              <Link to="/resources/privacy-policy">Privacy Policy</Link> | <Link to="/resources/copyright">Copyright Notice</Link> | <Link to="/sitemap/auctions">Sitemap</Link>
            </p>
          </div>

          {/* Social Links */}
          <div className="col-md-6" style={{display: 'none'}}>
            <ul className="footer-socials list-inline">
              <li>
                <a href="#" className="tooltips" data-toggle="tooltip" data-placement="top" title="" data-original-title="Facebook">
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#" className="tooltips" data-toggle="tooltip" data-placement="top" title="" data-original-title="Skype">
                  <i className="fa fa-skype"></i>
                </a>
              </li>
              <li>
                <a href="#" className="tooltips" data-toggle="tooltip" data-placement="top" title="" data-original-title="Google Plus">
                  <i className="fa fa-google-plus"></i>
                </a>
              </li>
              <li>
                <a href="#" className="tooltips" data-toggle="tooltip" data-placement="top" title="" data-original-title="Linkedin">
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a href="#" className="tooltips" data-toggle="tooltip" data-placement="top" title="" data-original-title="Pinterest">
                  <i className="fa fa-pinterest"></i>
                </a>
              </li>
              <li>
                <a href="#" className="tooltips" data-toggle="tooltip" data-placement="top" title="" data-original-title="Twitter">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
            </ul>
          </div>
          {/* End Social Links */}
        </div>
      </div>
    </div>{/*/copyright*/}
  </div>
}

export default Footer