import { processQuery, get } from 'lib/api'
import { status_defaults, defineAction } from 'lib/state_defaults'

const base = 'owa/info/sitemap/'

const DATA = defineAction(base, 'DATA', 'async')

const initialState = {
  content: {
    auctions: {
      data: [],
      info: {
        count: 0,
      },
      meta: status_defaults,
    },
    lots: {
      data: [],
      info: {
        count: 0,
        auction_id: 0,
      },
      meta: status_defaults,
    },
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DATA.request:
      return {
        ...state,
        content: {
          ...state.content,
          [action.foundation.entity]: {
            ...state.content[action.foundation.entity],
            meta: {
              ...status_defaults,
              processing: true,
            }
          }
        }
      }
    case DATA.success:
      return {
        ...state,
        content: {
          ...state.content,
          [action.foundation.entity]: {
            ...state.content[action.foundation.entity],
            data: action.payload.data,
            info: {
              ...state.content[action.foundation.entity].info,
              count: action.payload.count,
              ...(action.foundation.entity === 'lots' && { auction_id: action.foundation.auction_id }),
            },
            meta: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
    case DATA.fail:
      return {
        ...state,
        content: {
          ...state.content,
          [action.foundation.entity]: {
            ...state.content[action.foundation.entity],
            meta: {
              ...status_defaults,
              ...action.meta,
            }
          }
        }
      }
    default:
      return state
  }
}

export function getAuctionIndex() {
  return (dispatch) => {
    return dispatch(
      get({
        types: DATA,
        endpoint: '/api/public/info/auctions/past',
        foundation: { entity: 'auctions' },
      })
    )
  }
}

export function getLotIndex(params) {
  return (dispatch, getState) => {
    const state = getState()
    const q = { auction_id: params.auctionNumber }
    const querydata = processQuery(q, { whitelist: ['auction_id'] })
    const endpoint = '/api/public/catalog/lot-index' + querydata
    return dispatch(
      get({
        types: DATA,
        endpoint: endpoint,
        foundation: { entity: 'lots', auction_id: params.auctionNumber },
        abortCondition: state.info.sitemap.content.lots.info.auction_id === params.auctionNumber,
      })
    )
  }
}
