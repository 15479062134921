const base = 'owa/ssr/'

const FLAG_SSR = base.concat('FLAG_SSR')
const UNFLAG_SSR = base.concat('UNFLAG_SSR')

const initialState = {}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FLAG_SSR:
      return {
        ...state,
        [action.endpoint]: true,
      }
    case UNFLAG_SSR:
      return {
        ...state,
        [action.endpoint]: false,
      }
    default:
      return state
  }
}
